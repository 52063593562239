import styled from 'styled-components';
import { withTheme } from 'src/contexts/ThemeContext';

export const StyledSpan = withTheme(styled.span`
  color: ${({ themeColors }) => themeColors.data};
  font-size: 18px;
  font-weight: bold;
`);

export default StyledSpan;
