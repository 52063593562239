import React from 'react';
import PropTypes from 'prop-types';
import { TimeSeries } from 'pondjs';
import moment from 'utils/moment';
import ChartKpiVolume from './ChartKpiVolume';

function filterDaysOff(timeseries, daysOff) {
  const filtered = timeseries.filter(
    event =>
      daysOff.findIndex(dayOff => dayOff[0].isSame(event[0], 'days')) === -1
  );
  return filtered;
}

function getTimeSeries(funnel, graphId) {
  const timeseries =
    funnel.funnelData &&
    funnel.funnelData.timeseries &&
    funnel.funnelData.timeseries;
  if (!timeseries || !timeseries.kpi1.length) return null;
  switch (graphId) {
    case 1:
      return {
        cpm: filterDaysOff(timeseries.kpi1, timeseries.dayoff),
        cpv: filterDaysOff(timeseries.kpi2, timeseries.dayoff),
        cpd: filterDaysOff(timeseries.kpi3, timeseries.dayoff),
        cpi: filterDaysOff(timeseries.kpi4, timeseries.dayoff),
        anom: timeseries.anom,
        dayoff: timeseries.dayoff
      };
    case 2:
      return {
        cpm: filterDaysOff(timeseries.volume1, timeseries.dayoff),
        cpv: filterDaysOff(timeseries.volume2, timeseries.dayoff),
        cpd: filterDaysOff(timeseries.volume3, timeseries.dayoff),
        cpi: filterDaysOff(timeseries.volume4, timeseries.dayoff),
        anom: timeseries.anom,
        dayoff: timeseries.dayoff
      };
    case 3:
      return {
        cid: filterDaysOff(timeseries.cr3_4, timeseries.dayoff),
        cdv: filterDaysOff(timeseries.cr2_3, timeseries.dayoff),
        cvi: filterDaysOff(timeseries.cr1_2, timeseries.dayoff),
        anom: timeseries.anom,
        dayoff: timeseries.dayoff
      };
    default:
      return null;
  }
}

/**
 * ChartKpiFunnel
 * @component
 *
 */
function ChartKpiFunnel(props) {
  const { graphId, funnel } = props;
  const timeSeries = getTimeSeries(funnel, graphId);
  const allkpi = funnel.measure.funnels.map(({ id }) => id);
  const allname = funnel.measure.funnels.map(({ name }) => name);

  if (!timeSeries) return <div>ERROR DATA</div>;
  if (graphId !== 3) {
    const series = new TimeSeries({
      name: 'CPM',
      columns: ['time', 'CPM'],
      points: timeSeries.cpm
    });
    const e = series.max('CPM');
    return (
      <ChartKpiVolume
        start={moment(funnel.start, 'DD-MM-YYYY').subtract(1, 'days')}
        end={moment(funnel.end, 'DD-MM-YYYY')}
        series={series}
        seriescpv={
          new TimeSeries({
            name: 'CPV',
            columns: ['time', 'CPV'],
            points: timeSeries.cpv
          })
        }
        seriescpd={
          new TimeSeries({
            name: 'CPD',
            columns: ['time', 'CPD'],
            points: timeSeries.cpd
          })
        }
        seriescpi={
          new TimeSeries({
            name: 'CPI',
            columns: ['time', 'CPI'],
            points: timeSeries.cpi
          })
        }
        seriesanom={
          new TimeSeries({
            name: 'ANOM',
            columns: ['time', 'ANOM'],
            points: timeSeries.anom
          })
        }
        seriesdayoff={
          new TimeSeries({
            name: 'DAYOFF',
            columns: ['time', 'DAYOFF'],
            points: timeSeries.dayoff
          })
        }
        funnel={funnel}
        seriesBegin={0}
        seriesEnd={e + 1}
        seriesZoom={e / 200}
        graphId={graphId}
        allkpi={allkpi}
        allname={allname}
      />
    );
  }
  const series = new TimeSeries({
    name: 'CPM',
    columns: ['time', 'CPM'],
    points: timeSeries.cid
  });
  const e = series.max('CPM');
  return (
    <ChartKpiVolume
      series={series}
      seriescpv={
        new TimeSeries({
          name: 'CPV',
          columns: ['time', 'CPV'],
          points: []
        })
      }
      seriescpd={
        new TimeSeries({
          name: 'CPD',
          columns: ['time', 'CPD'],
          points: timeSeries.cdv
        })
      }
      seriescpi={
        new TimeSeries({
          name: 'CPI',
          columns: ['time', 'CPI'],
          points: timeSeries.cvi
        })
      }
      seriesanom={
        new TimeSeries({
          name: 'ANOM',
          columns: ['time', 'ANOM'],
          points: timeSeries.anom
        })
      }
      seriesdayoff={
        new TimeSeries({
          name: 'DAYOFF',
          columns: ['time', 'DAYOFF'],
          points: timeSeries.dayoff
        })
      }
      allkpi={allkpi}
      allname={allname}
      end={moment(funnel.end, 'DD-MM-YYYY')}
      funnel={funnel}
      graphId={graphId}
      seriesBegin={0}
      seriesEnd={150}
      seriesZoom={e / 200}
      start={moment(funnel.start, 'DD-MM-YYYY').subtract(1, 'days')}
    />
  );
}

ChartKpiFunnel.propTypes = {
  funnel: PropTypes.shape({
    newFormat: PropTypes.shape({
      timeseries: PropTypes.shape({
        kpi1: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        kpi2: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        kpi3: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        kpi4: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        anom: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        cr1_2: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        cr2_3: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        cr3_4: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        dayoff: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
      })
    }),
    measure: PropTypes.shape({
      funnels: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
      )
    }),
    start: PropTypes.string,
    end: PropTypes.string
  }).isRequired,
  graphId: PropTypes.number.isRequired
};

export default ChartKpiFunnel;
