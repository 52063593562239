import styled from 'styled-components';
import Input from 'components/Input';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseInputActivation
 * @component
 *
 */
const BaseInputActivation = styled(Input)`
  border: 1px solid
    ${({ themeColors, errorInput }) => {
      if (errorInput) return '#eb5757';
      return themeColors.activation;
    }};
  max-width: 110px;
  width: auto;
  cursor: text;
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    padding-right: 5px;
    margin: 0;
  }
`;

export const InputWrapper = withTheme(styled.div`
  display: inline-flex;
  align-items: center;
  cursor: text;
  width: 100px;
  > input {
    width: 100%;
    padding-right: 10%;
  }
  > *:nth-child(2) {
    color: ${({ themeColors }) => themeColors.light};
    position: relative;
    left: -1.2vw;
  }
`);

export default withTheme(BaseInputActivation);
