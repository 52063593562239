import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { ActionButton } from 'Insight/components/ButtonInsight';
import { useTheme } from 'src/contexts/ThemeContext';
import ModalBuySegmentForm from 'Insight/components/BuyDataFormModalInsight/ModalBuySegmentForm';
import {
  ModalBuySegmentBase,
  ModalBuySegmentTitle,
  ModalBuySegmentText
} from 'Insight/components/BuyDataFormModalInsight/style';

/**
 * ResultSavingInsight
 * @component
 *
 */
function SurveyModalInsight({ onClose, onNext, open, ...props }) {
  const [step, setStep] = React.useState(0);
  const [emailData, setEmailData] = React.useState(null);
  const { themeColors } = useTheme();

  useEffect(() => {
    setStep(0);
  }, [open]);

  return (
    <ModalBuySegmentBase
      {...props}
      onClose={onClose}
      open={open}
      style={{ width: '500px', backgroundColor: themeColors.primary }}
    >
      <ModalBuySegmentTitle small> LANCER LE SONDAGE </ModalBuySegmentTitle>
      {!step ? (
        <React.Fragment>
          <ModalBuySegmentText>
            Vous pouvez créer et envoyer un sondage personnalisé auprès d’un p
            anel de consommateurs correspondant au segment de profils souhaité.
          </ModalBuySegmentText>
          <ModalBuySegmentForm
            onClose={onClose}
            onNext={data => {
              setStep(1);
              setEmailData(data);
            }}
            {...props}
            style={{ backgroundColor: themeColors.primary }}
          />
        </React.Fragment>
      ) : (
        <>
          <ModalBuySegmentText>
            Vous serez rappelé sous 24h par l’équipe ERMES afin de finaliser
            votre commande et vous informer des prochaines étapes.
          </ModalBuySegmentText>
          <Grid container justify="center">
            <ActionButton
              width="180px"
              onClick={() => {
                onNext(emailData);
              }}
            >
              TERMINER
            </ActionButton>
          </Grid>
        </>
      )}
    </ModalBuySegmentBase>
  );
}
SurveyModalInsight.defaultProps = {
  open: false,
  onClose: () => {},
  onNext: () => {}
};
SurveyModalInsight.propTypes = {
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  open: PropTypes.bool,
  contacts: PropTypes.number.isRequired
};

export default SurveyModalInsight;
