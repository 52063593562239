import React from 'react';
import Modal from '@material-ui/core/Modal';
import HiderFunnel from 'Funnel/components/HiderFunnel';
import SidebarFunnel, {
  SideItemFunnel,
  ModalContent
} from 'Funnel/components/SidebarFunnel';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';
import { useFunnelGraph } from 'Funnel/contexts/FunnelGraphContext';
import { formatNumber } from 'utils/functions';
import { useTheme } from 'contexts/ThemeContext';
import moment from 'utils/moment';

function getMainLeverage(funnel) {
  if (
    funnel.funnelData &&
    funnel.funnelData.leverage &&
    funnel.funnelData.leverage.length
  ) {
    const mainImpact = funnel.funnelData.leverage.reduce((currentBest, kpi) => {
      return kpi.impact > currentBest.impact ? kpi : currentBest;
    }, funnel.funnelData.leverage[0]);
    return mainImpact.kpi;
  }
  return 'N/A';
}
function getKPI4HB(funnel) {
  try {
    return {
      kpi: funnel.measure.funnels[3].id,
      value: funnel.funnelData.synthesis.kpi4HB.real
    };
  } catch {
    return { kpi: 'CPI', value: '-,--' };
  }
}
function getAnomaliesCount(funnel) {
  if (funnel.funnelData && funnel.funnelData.anomalies) {
    const count = funnel.funnelData.anomalies.filter((current, index) => {
      return (
        index === 0 ||
        (index > 0 &&
          !moment(funnel.funnelData.anomalies[index - 1].date).isSame(
            moment(current.date),
            'day'
          ))
      );
    }).length;
    return count;
  }
  return 'N/A';
}
function getKPI1(funnel) {
  try {
    const bestCPM = funnel.funnelData.mediaDistrib.sort(
      (mediaA, mediaB) => mediaA.CPM - mediaB.CPM
    )[0];
    return {
      kpi: funnel.measure.funnels[0].id,
      media: bestCPM.name,
      value: bestCPM.CPM
    };
  } catch {
    return {
      kpi: 'CPM',
      media: 'XX',
      value: '-,--'
    };
  }
}

/**
 * InsightSummaryContainer
 * @container
 * @component
 *
 */
function InsightSummaryContainer() {
  const { themeColors } = useTheme();
  const [modalProfilIsOpen, openProfilModal] = React.useState(false);
  const [modalRetargetingOpen, openRetargetingModal] = React.useState(false);
  const { funnel } = useFunnel();
  const { isFinalStep } = useFunnelStep();
  const { setGraphTab, setSubTab, graphSubTab } = useFunnelGraph();
  const links = [];
  const dateEnd = moment(funnel.end);
  const duration = moment.duration(dateEnd.diff(moment(funnel.start)));
  const days = duration.asDays().toFixed(0);
  links.push({
    key: 'synthese',
    active: false,
    graphN: 0,
    svg: 'synthesis',
    name: 'Synthèse',
    subtitle: `${days} jours analysés`,
    surname: 'SYNTHESE'
  });
  links.push({
    key: 'analyse_temporelle',
    active: false,
    graphN: 6,
    svg: 'temporal',
    name: 'tabAnalysesTemp',
    subtitle: 'Comparaisons par période',
    surname: `ANALYSE TEMPORELLE`
  });
  links.push({
    key: 'evolution',
    active: false,
    graphN: 1,
    svg: 'evolution',
    name: 'Graphiques',
    subtitle: 'KPIs, volumes et taux de conversions',
    surname: 'EVOLUTION'
  });
  links.push({
    key: 'anomalies',
    active: false,
    graphN: 4,
    svg: 'anomalies',
    name: 'tabAnomalies',
    subtitle: `${getAnomaliesCount(funnel)}
      anomalies sur la période`,
    surname: 'ANOMALIES'
  });
  links.push({
    key: 'plan_media_reel',
    active: false,
    graphN: 6,
    svg: 'distribution',
    name: 'tabDistribMedia',
    subtitle: `Meilleur ${getKPI1(funnel).kpi}: ${
      getKPI1(funnel).media
    } (${formatNumber(getKPI1(funnel).value)} €)`,
    surname: 'PLAN MEDIA REEL'
  });
  links.push({
    key: 'analyse',
    active: false,
    graphN: 2,
    svg: 'analysis',
    name: 'tabAnalyse',
    subtitle: `Principal levier: ${getMainLeverage(funnel)}`,
    surname: 'ANALYSE'
  });
  links.push({
    key: 'baseline',
    active: false,
    graphN: 3,
    svg: 'baseline',
    name: 'tabBaseline',
    subtitle: `${getKPI4HB(funnel).kpi} hors baseline : ${formatNumber(
      getKPI4HB(funnel).value
    )} €`,
    surname: 'BASELINE'
  });
  links.push({
    key: 'previsions',
    active: false,
    graphN: 5,
    svg: 'previsions',
    name: 'graphPrevision',
    subtitle: 'Evolution sur la prochaine période',
    surname: 'PREVISIONS'
  });
  links.push({
    key: 'analyse_de_audience',
    active: false,
    graphN: 7,
    svg: 'audience',
    name: 'tabAnalysesAudience',
    subtitle: 'Données démographiques',
    surname: `ANALYSE DE L'AUDIENCE`
  });

  const selectLink = oneLink => {
    // if (oneLink.name === 'tabAnalysesAudience') openProfilModal(true);
    setGraphTab(oneLink.graphN);
    setSubTab(oneLink.name);
  };
  return (
    <HiderFunnel hide={!isFinalStep}>
      <SidebarFunnel handleRetargetting={() => openRetargetingModal(true)}>
        {links.map(link => (
          <SideItemFunnel
            key={link.key}
            active={link.name === graphSubTab}
            svg={link.svg}
            svgAttrs={{
              fill: themeColors.icon,
              dataStroke: themeColors.icon,
              stroke: themeColors.icon
            }}
            subtitle={link.subtitle}
            title={link.surname}
            handleCLick={() => selectLink(link)}
            hasActiveClass={link.name === graphSubTab}
          />
        ))}
      </SidebarFunnel>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalProfilIsOpen}
        onClose={() => openProfilModal(false)}
      >
        <ModalContent>
          <div className="modal-content modal-help text-center">
            <div>
              <center>
                <p>
                  <strong>Profils</strong>
                </p>
                <p>
                  Cette fonctionnalité est en cours de développement et sera
                  disponible très prochainement !
                </p>
                <p>
                  “Profils” vous permettra de découvrir votre audience réelle.
                  Vous pourrez ainsi analyser en détails les profils de vos
                  consommateurs et adapter votre communication.
                </p>
              </center>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalRetargetingOpen}
        onClose={() => openRetargetingModal(false)}
      >
        <ModalContent>
          <div className="modal-content modal-help text-center">
            <div>
              <center>
                <p>
                  <strong>Retargeting</strong>
                </p>
                <p>
                  Cette fonctionnalité est en cours de développement et sera
                  disponible très prochainement !
                </p>
                <p>
                  “Retargeting” vous permettra de relancer une campagne en
                  quelques clics auprès de toute ou partie de votre cible. Vous
                  pourrez ainsi toucher à nouveau de potentiels consommateurs
                  qui n’aurait pas été convertis la première fois.
                </p>
              </center>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </HiderFunnel>
  );
}

export default InsightSummaryContainer;
