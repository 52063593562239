import React from 'react';

import PropTypes from 'prop-types';
import BaseDropdownActivation from './style';

/**
 * DropdownActivation
 * @component
 *
 */
function DropdownActivation(props) {
  return <BaseDropdownActivation isMui noIcon {...props} />;
}

DropdownActivation.defaultProps = {
  defaultValue: 'Sélectionner un objectif'
};
DropdownActivation.propTypes = {
  defaultValue: PropTypes.string
};

export default DropdownActivation;
