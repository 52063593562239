const data = {
  kpi2: [
    {
      insight: 'Chien',
      popFr: 43,
      ermesData: 40,
      campaign: {
        value: 56,
        indicator: 1.3
      }
    },
    {
      insight: 'IOS',
      popFr: 21,
      ermesData: 25,
      campaign: {
        value: 40,
        indicator: 1.6
      }
    },
    {
      insight: 'Jeux Vidéo',
      popFr: 19,
      ermesData: 24,
      campaign: {
        value: 38,
        indicator: 1.6
      }
    },
    {
      insight: 'Région PACA',
      popFr: 18,
      ermesData: 15,
      campaign: {
        value: 27,
        indicator: 1.8
      }
    },
    {
      insight: 'Conso Responsable',
      popFr: null,
      ermesData: 10,
      campaign: {
        value: 20,
        indicator: 2
      }
    }
  ],

  kpi3: [
    {
      insight: 'Chien',
      popFr: 43,
      ermesData: 40,
      campaign: {
        value: 56,
        indicator: 1.3
      }
    },
    {
      insight: 'IOS',
      popFr: 21,
      ermesData: 29,
      campaign: {
        value: 40,
        indicator: 1.6
      }
    },
    {
      insight: 'Propriétaires',
      popFr: 17,
      ermesData: 21,
      campaign: {
        value: 38,
        indicator: 1.6
      }
    },
    {
      insight: 'Conso Responsable',
      popFr: null,
      ermesData: 14,
      campaign: {
        value: 27,
        indicator: 1.8
      }
    },
    {
      insight: 'Gastronomie Luxe',
      popFr: 16,
      ermesData: 11,
      campaign: {
        value: 20,
        indicator: 2
      }
    }
  ],

  kpi4: [
    {
      insight: 'Chien',
      popFr: 43,
      ermesData: 40,
      campaign: {
        value: 56,
        indicator: 1.3
      }
    },
    {
      insight: 'IOS',
      popFr: 21,
      ermesData: 30,
      campaign: {
        value: 40,
        indicator: 1.6
      }
    },
    {
      insight: 'Propriétaires',
      popFr: 17,
      ermesData: 24,
      campaign: {
        value: 38,
        indicator: 1.6
      }
    },
    {
      insight: 'Conso Responsable',
      popFr: null,
      ermesData: 20,
      campaign: {
        value: 27,
        indicator: 1.8
      }
    },
    {
      insight: 'Amazon Prime',
      popFr: null,
      ermesData: 10,
      campaign: {
        value: 20,
        indicator: 2
      }
    }
  ]
};

export default data;
