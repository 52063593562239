import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseWrapperModelisation
 * @component
 *
 */
const BaseWrapperModelisation = styled.div`
  display: grid;
  grid-template-columns: 8fr 60px 5fr;
  background-color: ${({ themeColors }) => themeColors.secondary};
  border-radius: 8px;
  box-shadow: 0 0 1px 1px ${({ themeColors }) => themeColors.shadow};
  margin: 0px 16px;
  padding: 8px 16px 16px;
  .column-modelisation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .column-modelisation.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row-modelisation {
    display: grid;
    grid-column-gap: 8px;
    align-items: center;
  }
  .row-modelisation.bar {
    grid-template-columns: 1fr 96px;
    margin-left: 100px;
  }
  .row-modelisation.info {
    grid-template-columns: 80px 4px 1fr;
    height: 50px;
  }
  .row-modelisation.infoSub {
    height: 50px;
    padding-top: 9px;
  }
`;

export default withTheme(BaseWrapperModelisation);
