import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseWrapperValidation
 * @component
 *
 */
const BaseWrapperValidation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px;
  overflow: hidden;
  .row-validation {
    display: flex;
    justify-content: space-evenly;
    min-width: 60%;
    margin: 8px;
  }
`;

export default withTheme(BaseWrapperValidation);
