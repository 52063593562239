import React from 'react';
import PropTypes from 'prop-types';

import { isEmptyObject } from 'utils/functions';

import BaseLoading, { BaseLoadingError } from './style';

/**
 * Loading
 * @component
 *
 */
function Loading(props) {
  const {
    error,
    loading,
    refetch,
    children,
    data,
    onLoadingSuccess,
    skip,
    src
  } = props;
  let canDislay = !error && !loading && !isEmptyObject(data);
  canDislay = canDislay || skip;
  const firstData = data && Object.values(data)[0];
  React.useEffect(() => {
    if (canDislay) {
      onLoadingSuccess(data, refetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, firstData, canDislay, data]);
  return (
    <React.Fragment>
      {error && <BaseLoadingError>{error.message}</BaseLoadingError>}
      {loading && <BaseLoading src={src} alt="loader" />}
      {canDislay && children}
    </React.Fragment>
  );
}

Loading.defaultProps = {
  children: undefined,
  data: null,
  error: null,
  loading: false,
  onLoadingSuccess: () => null,
  refetch: () => {},
  skip: false,
  src: '/assets/img/loader.svg'
};

Loading.propTypes = {
  children: PropTypes.node,
  data: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  onLoadingSuccess: PropTypes.func,
  refetch: PropTypes.func,
  skip: PropTypes.bool,
  src: PropTypes.string
};

export default Loading;
