import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';

import ErrorBoundary from 'ErrorBoundary';

import configuredGraphqlClient from 'utils/api/graphql';
import { useUser } from 'contexts/UserContext';
import { FUNNEL } from 'utils/graphQL';
import postProcessing from 'utils/api/ermes/postProcessing';

import FunnelProvider from 'Funnel/contexts/FunnelContext';
import FunnelStepProvider from 'Funnel/contexts/FunnelStepContext';
import FunnelGraphProvider from 'Funnel/contexts/FunnelGraphContext';
import Loading from 'components/Loading';
import QueryHandlingFunnel from 'Funnel/components/QueryHandlingFunnel';

import AppFunnel from './AppFunnel';

/**
 * useFunnelQuery
 * @param {String} id
 * @param {Boolean} isNotAccessible
 * @return Object {data, loading, error, ...}
 *
 */

function useFunnelQuery(id, isAccessible) {
  const tmp = useQuery(FUNNEL.GET_FUNNEL, {
    variables: { id },
    skip: !id || !isAccessible,
    client: configuredGraphqlClient
  });

  return id ? tmp : {};
}

function preProcessFunnel(funnel) {
  if (!funnel) return funnel;
  return {
    ...funnel,
    funnelData: postProcessing({ data: funnel.funnelData }, null, false)
  };
}
/**
 * AppFunnel
 * @desc :: If there is an id in url we fetch the corresponding funnel, else
 * we render a new empty funnel
 *
 */
function Funnel(props) {
  const { match, ...appProps } = props;
  const { id: urlID } = match.params;
  const { isAuthenticated } = useUser();
  const response = useFunnelQuery(urlID, isAuthenticated);
  if (response.error) {
    throw response.error;
  }
  return (
    <ErrorBoundary>
      <ApolloProvider client={configuredGraphqlClient}>
        <FunnelProvider>
          <FunnelStepProvider>
            <FunnelGraphProvider>
              {urlID ? (
                <QueryHandlingFunnel {...response}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {response.loading ? (
                    <Loading loading={response.loading} />
                  ) : (
                    <AppFunnel
                      {...appProps}
                      recoveredFunnel={preProcessFunnel(response.data.funnel)}
                    />
                  )}
                </QueryHandlingFunnel>
              ) : (
                <AppFunnel {...appProps} />
              )}
            </FunnelGraphProvider>
          </FunnelStepProvider>
        </FunnelProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}
Funnel.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(Funnel);
