import React from 'react';
// import PropTypes from 'prop-types'

import BaseText from './style';

/**
 * TextInsight
 * @component
 *
 */
function TextInsight(props) {
  return <BaseText {...props} />;
}
TextInsight.defaultProps = {};
TextInsight.propTypes = {};

export default TextInsight;
