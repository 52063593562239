import React, { useEffect, useState } from 'react';

import HistoryData, { HistoryDataItem } from 'Data/components/HistoryData';
import { useData } from 'Data/contexts/DataContext';
import Axios from 'axios';

import Loading from 'src/components/Loading';
/**
 * HistoryDataContainer
 * @component
 * @see HistoryContainer
 *
 *
 */
function HistoryDataContainer() {
  const { bases: historyData, token, basesLoading } = useData();
  const [isLoaded, setIsLoaded] = useState();

  /*
   * Set the loader when the component is rendered for the first time
   */
  useEffect(() => {
    setIsLoaded(basesLoading);
    // DO NOT LINT FOLLOWING LINE ==> INFINITE LOOP
    // eslint-disable-next-line
  }, []);
  if (!isLoaded && basesLoading) {
    setIsLoaded(basesLoading);
  }

  /**
   * Delete base by filename
   *
   * @param {string} filename
   * @returns
   */
  function deleteDataItem(data) {
    return Axios.delete(
      `${process.env.REACT_APP_TEMELIO_API_URL}/bases/${data.name}`,
      {
        headers: {
          Authorization: `bearer: ${token}`
        }
      }
    );
  }

  // Check if historyData exists and is not empty ==> Check if data is loaded
  if (Array.isArray(historyData) && isLoaded) {
    return (
      <HistoryData>
        {historyData.map(oneData => (
          <HistoryDataItem
            key={oneData.name}
            data={oneData}
            deleteData={deleteDataItem}
          />
        ))}
      </HistoryData>
    );
  }
  return <Loading loading src="/assets/img/loader_data.svg" />;
}

export default HistoryDataContainer;
