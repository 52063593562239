import moment from 'utils/moment';

function getKpiById({ funnel, id, start, end, mode }) {
  if (
    !funnel ||
    !funnel.funnelData ||
    !funnel.funnelData.timeseries ||
    !funnel.funnelData.timeseries[id]
  ) {
    return null;
  }
  const filtered = funnel.funnelData.timeseries[id].filter(
    kpi =>
      kpi[0].isBetween(start, end, null, '[)') || kpi[0].isSame(start, 'day')
  );

  if (mode === 'addition') {
    return filtered.reduce((acc, curr) => acc + curr[1], 0);
  }
  return filtered.reduce((acc, curr) => acc + curr[1], 0) / filtered.length;
}

function getBudget({ funnel, start, end }) {
  return getKpiById({ funnel, id: 'budgets', start, end, mode: 'addition' });
}

function computeKpiById({ funnel, id, start, end }) {
  const budget = getBudget({ funnel, start, end });
  const volume = getKpiById({
    funnel,
    id: `volume${id}`,
    start,
    end,
    mode: 'addition'
  });
  if (id === 1) {
    return (budget * 1000) / volume;
  }
  return budget / volume;
}

export function listForMonths(funnel, month) {
  const dateList = [];
  const dateStart = moment(funnel.start);
  const currentDate = moment(funnel.end);
  let i = 0.2;
  while (month > i) {
    let monthDate = moment(currentDate);
    monthDate.subtract(4, 'M');
    if (monthDate < dateStart) {
      monthDate = moment(funnel.start);
    }
    dateList.push({
      date: currentDate.format('MMMM'),
      datePreview: currentDate.format('MMMM'),
      nextDate: `${currentDate.format('')}`,
      prevDate: `${currentDate.startOf('month').format('DD/MM/YYYY')}`,
      type: 'month'
    });
    currentDate.subtract(4, 'M');
    i += 4;
  }
  return dateList;
}

export function listOfMonths(funnel, month) {
  const dateList = [];
  const currentDate = moment(funnel.end);
  let i = 0.2;
  while (month > i) {
    const monthDate = moment(currentDate);
    monthDate.subtract(1, 'M');
    dateList.push({
      date: currentDate.format('MMMM'),
      nextDate: `${currentDate.format('')}`,
      prevDate: `${currentDate.startOf('month').format('DD/MM/YYYY')}`,
      type: 'week'
    });
    currentDate.subtract(1, 'M');
    i += 1;
  }
  return dateList;
}

export function listOfWeeks(funnel, startList) {
  const dateStart = moment(funnel.start);
  let currentDate = moment(funnel.end);
  let dateList = [];
  while (currentDate > dateStart) {
    let weekDate = moment(currentDate);
    weekDate.day('Monday').add(1, 'days');
    if (currentDate.format('DD/MM') !== moment(funnel.end).format('DD/MM'))
      currentDate.add(1, 'days');
    if (weekDate < dateStart) weekDate = dateStart;
    dateList.push({
      date: `${weekDate.format('DD/MM')} - ${currentDate.format('DD/MM')}`,
      nextDate: `${currentDate.format('DD.MM.YYYY')}`,
      prevDate: `${weekDate.format('DD.MM.YYYY')}`,
      type: 'day'
    });
    currentDate = moment(weekDate);
    currentDate.subtract(2, 'days');
  }
  const end = dateList.findIndex(e => e.nextDate === startList.date);
  let start = end + 5;
  let trueEnd = end - 5;
  if (trueEnd < 0) trueEnd = 0;
  if (start > dateList.length - 1) start = dateList.length;
  dateList = dateList.slice(trueEnd, start);
  return dateList;
}

export function dateAll(funnel) {
  const dateEnd = moment(funnel.end);
  const currentDate = moment(funnel.start);
  const convRate = [];
  convRate.push({
    KPI1: getKpiById({
      funnel,
      id: 'kpi1',
      start: currentDate,
      end: dateEnd
    }),
    KPI2: getKpiById({
      funnel,
      id: 'kpi2',
      start: currentDate,
      end: dateEnd
    }),
    KPI3: getKpiById({
      funnel,
      id: 'kpi3',
      start: currentDate,
      end: dateEnd
    }),
    KPI4: getKpiById({
      funnel,
      id: 'kpi4',
      start: currentDate,
      end: dateEnd
    })
  });
  return convRate;
}

export function dateMonth(funnel, month) {
  const dateList = [];
  const currentDate = moment(funnel.end);
  let i = 0.2;
  while (month > i) {
    const monthDate = moment(currentDate).startOf('month');
    dateList.push({
      date: currentDate.format('MMMM'),
      datePreview: monthDate.format('MMMM'),
      datePreviewBis: `${currentDate.format('MMMM')}`,
      nextDate: `${currentDate.format('')}`,
      prevDate: `${moment(currentDate)
        .startOf('month')
        .format('DD/MM/YYYY')}`,
      type: 'month',
      BUDGET: getBudget({
        funnel,
        start: monthDate,
        end: currentDate
      }),
      KPI1: computeKpiById({
        funnel,
        id: 1,
        start: monthDate,
        end: currentDate
      }),
      KPI2: computeKpiById({
        funnel,
        id: 2,
        start: monthDate,
        end: currentDate
      }),
      KPI3: computeKpiById({
        funnel,
        id: 3,
        start: monthDate,
        end: currentDate
      }),
      KPI4: computeKpiById({
        funnel,
        id: 4,
        start: monthDate,
        end: currentDate
      })
    });
    currentDate.subtract(1, 'M').endOf('month');
    i += 1;
  }
  return dateList;
}

export function dateWeeks(funnel) {
  let currentDate = moment(funnel.end);
  const dateStart = moment(funnel.start);
  const dateList = [];
  while (currentDate > dateStart) {
    let weekDate = moment(currentDate);
    weekDate.day('Monday').add(1, 'days');
    if (currentDate.format('DD/MM') !== moment(funnel.end).format('DD/MM'))
      currentDate.add(1, 'days');
    if (weekDate < dateStart) weekDate = dateStart;
    if (moment(weekDate) < moment(funnel.end)) {
      dateList.push({
        date: `${weekDate.format('DD/MM')} - ${currentDate.format('DD/MM')}`,
        datePreview: `${weekDate.format('D MMMM YYYY')}`,
        datePreviewBis: `${currentDate.format('D MMMM YYYY')}`,
        nextDate: `${currentDate.format('')}`,
        prevDate: `${weekDate.format('DD/MM/YYYY')}`,
        month: `${currentDate.format('MMMM')}`,
        type: 'week',
        BUDGET: getBudget({
          funnel,
          start: weekDate,
          end: currentDate
        }),
        KPI1: computeKpiById({
          funnel,
          id: 1,
          start: weekDate,
          end: currentDate
        }),
        KPI2: computeKpiById({
          funnel,
          id: 2,
          start: weekDate,
          end: currentDate
        }),
        KPI3: computeKpiById({
          funnel,
          id: 3,
          start: weekDate,
          end: currentDate
        }),
        KPI4: computeKpiById({
          funnel,
          id: 4,
          start: weekDate,
          end: currentDate
        })
      });
    }
    currentDate = moment(weekDate);
    currentDate.subtract(2, 'days');
  }
  return dateList;
}

export function dateDays(funnel) {
  const dateList = [];
  const dateStart = moment(funnel.start);
  const currentDate = moment(funnel.end);
  while (currentDate > dateStart) {
    dateList.push({
      date: currentDate.format('DD.MM.YYYY'),
      datePreview: `${currentDate.format('D MMMM YYYY')}`,
      datePreviewBis: `${currentDate.format('D MMMM YYYY')}`,
      nextDate: `${currentDate.format('')}`,
      prevDate: `${currentDate.format('DD/MM/YYYY')}`,
      type: 'day',
      BUDGET: getBudget({
        funnel,
        start: currentDate,
        end: currentDate
      }),
      KPI1: computeKpiById({
        funnel,
        id: 1,
        start: currentDate,
        end: currentDate
      }),
      KPI2: computeKpiById({
        funnel,
        id: 2,
        start: currentDate,
        end: currentDate
      }),
      KPI3: computeKpiById({
        funnel,
        id: 3,
        start: currentDate,
        end: currentDate
      }),
      KPI4: computeKpiById({
        funnel,
        id: 4,
        start: currentDate,
        end: currentDate
      })
    });
    currentDate.subtract(1, 'days');
  }
  return dateList;
}
