import styled from 'styled-components';
import PropTypes from 'prop-types';

import { withTheme } from 'src/contexts/ThemeContext';

/**
 * Table
 * @component
 */
const Table = withTheme(styled.table`
  border-collapse: collapse; 
}`);

/**
 * Tr
 * @component
 */
export const Tr = withTheme(styled.tr``);

/**
 * Th
 * @component
 */
export const Th = withTheme(styled.th`
  border: ${({ themeColors }) => `1px solid ${themeColors.keep_dark}`};
  box-sizing: border-box;
  padding: 8px;
  border-bottom: ${({ themeColors, type }) => `5px solid ${themeColors[type]}`};
}`);
Th.defaultProps = {};
Th.propTypes = {
  type: PropTypes.oneOf(['success', 'danger']).isRequired
};

/**
 * Td
 * @component
 */
export const Td = withTheme(styled.td`
  border: ${({ themeColors }) => `1px solid ${themeColors.keep_dark}`};
  box-sizing: border-box;
  padding: 8px;
}`);

export default Table;
