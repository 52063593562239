import styled from 'styled-components';
import constants from 'utils/constants';

const { colors } = constants;

export const ErrorMessage = styled.p`
  color: ${colors.light};
  font-size: 14px;
  padding: 10px 40px;
`;

export default ErrorMessage;
