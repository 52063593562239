import _ from 'lodash';
import moment from 'utils/moment';

export default {
  getsynthese(connected, id, filter) {
    const NB_KPI = 4;
    const csvdata = [
      [''],
      ['Synthese de la periode'],
      ['', 'volume', 'kpi moyen', 'Top', 'Flop']
    ];
    for (let i = 0; i < NB_KPI; i += 1) {
      const connector = _.find(connected, c => c.funnelId === id[i]);
      const hasData = connector && connector.data.length;
      const connectorData = hasData ? _.filter(connector.data, filter) : [];
      const total = hasData ? _.sumBy(connectorData, c => c.value) : 0;
      const totalB = hasData ? _.sumBy(connectorData, c => c.budget) : 0;
      let reel = totalB / total;
      let top = 0;
      let flop = 0;
      if (hasData) {
        const min = _.minBy(connectorData, c => c.cost);
        const max = _.maxBy(connectorData, c => c.cost);
        top = min ? min.cost : 0;
        flop = max ? max.cost : 0;
        if (i === 0) {
          reel *= 1000;
          top *= 1000;
          flop *= 1000;
        }
      }
      csvdata.push([
        id[i],
        total.toFixed(2),
        reel.toFixed(2),
        top.toFixed(2),
        flop.toFixed(2)
      ]);
    }
    return csvdata;
  },

  getalldata(connected, id, funnel) {
    const csvdata = [['']];
    const cdate = [''];
    const cpv = [id[1]];
    const cpd = [id[2]];
    const cpi = [id[3]];
    connected.map(item => {
      item.data.map(i => {
        const date = moment(i.date);
        if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
          if (item.funnelId === id[1]) {
            cpv.push(i.graph.toFixed(2));
            cdate.push(date.format('YYYY-MM-DD'));
          }
          if (item.funnelId === id[2]) cpd.push(i.graph.toFixed(2));
          if (item.funnelId === id[3]) cpi.push(i.graph.toFixed(2));
        }
        return -1;
      });
      return 0;
    });
    csvdata.push([cdate.join(';')]);
    csvdata.push([cpv.join(';')]);
    csvdata.push([cpd.join(';')]);
    csvdata.push([cpi.join(';')]);
    return csvdata;
  },

  getallBudget(connected, id, funnel) {
    const csvdata = [['Toutes les donnees de la periode']];
    const cdate = [''];
    const budget = ['Budget Total'];
    const budgetMedia1 = [`Budget ${connected[4].data[0].mediaName1}`];
    const budgetMedia2 = [`Budget ${connected[4].data[0].mediaName2}`];
    const budgetMedia3 = [`Budget ${connected[4].data[0].mediaName3}`];
    const budgetMedia4 = [`Budget ${connected[4].data[0].mediaName4}`];
    const budgetMedia5 = [`Budget ${connected[4].data[0].mediaName5}`];
    const budgetMedia6 = [`Budget ${connected[4].data[0].mediaName6}`];
    const budgetMedia7 = [`Budget ${connected[4].data[0].mediaName7}`];
    connected[4].data.map(i => {
      const date = moment(i.date);
      if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
        cdate.push(date.format('YYYY-MM-DD'));
        budget.push(i.budget);
        budgetMedia1.push(i.mediaB1 * 1000);
        budgetMedia2.push(i.mediaB2 * 1000);
        budgetMedia3.push(i.mediaB3 * 1000);
        budgetMedia4.push(i.mediaB4 * 1000);
        budgetMedia5.push(i.mediaB5 * 1000);
        budgetMedia6.push(i.mediaB6 * 1000);
        budgetMedia7.push(i.mediaB7 * 1000);
      }
      return -1;
    });
    csvdata.push([cdate.join(';')]);
    csvdata.push([budget.join(';')]);
    csvdata.push([budgetMedia1.join(';')]);
    csvdata.push([budgetMedia2.join(';')]);
    csvdata.push([budgetMedia3.join(';')]);
    csvdata.push([budgetMedia4.join(';')]);
    csvdata.push([budgetMedia5.join(';')]);
    csvdata.push([budgetMedia6.join(';')]);
    csvdata.push([budgetMedia7.join(';')]);
    return csvdata;
  },

  getallImpression(connected, id, funnel) {
    const csvdata = [['']];
    const cdate = [''];
    const impression = ['Impressions Total'];
    const impressionMedia1 = [`Impressions ${connected[4].data[0].mediaName1}`];
    const impressionMedia2 = [`Impressions ${connected[4].data[0].mediaName2}`];
    const impressionMedia3 = [`Impressions ${connected[4].data[0].mediaName3}`];
    const impressionMedia4 = [`Impressions ${connected[4].data[0].mediaName4}`];
    const impressionMedia5 = [`Impressions ${connected[4].data[0].mediaName5}`];
    const impressionMedia6 = [`Impressions ${connected[4].data[0].mediaName6}`];
    const impressionMedia7 = [`Impressions ${connected[4].data[0].mediaName7}`];
    connected[4].data.map(i => {
      const date = moment(i.date);
      if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
        cdate.push(date.format('YYYY-MM-DD'));
        impressionMedia1.push(i.mediaI1 * 1000);
        impressionMedia2.push(i.mediaI2 * 1000);
        impressionMedia3.push(i.mediaI3 * 1000);
        impressionMedia4.push(i.mediaI4 * 1000);
        impressionMedia5.push(i.mediaI5 * 1000);
        impressionMedia6.push(i.mediaI6 * 1000);
        impressionMedia7.push(i.mediaI7 * 1000);
      }
      return -1;
    });
    connected[0].data.map(i => {
      const date = moment(i.date);
      if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
        impression.push(i.value);
      }
      return -1;
    });
    csvdata.push([cdate.join(';')]);
    csvdata.push([impression.join(';')]);
    csvdata.push([impressionMedia1.join(';')]);
    csvdata.push([impressionMedia2.join(';')]);
    csvdata.push([impressionMedia3.join(';')]);
    csvdata.push([impressionMedia4.join(';')]);
    csvdata.push([impressionMedia5.join(';')]);
    csvdata.push([impressionMedia6.join(';')]);
    csvdata.push([impressionMedia7.join(';')]);
    return csvdata;
  },

  getallcpm(connected, id, funnel) {
    const csvdata = [['']];
    const cdate = [''];
    const CPM = ['CPM'];
    const CPMMedia1 = [`CPM ${connected[4].data[0].mediaName1}`];
    const CPMMedia2 = [`CPM ${connected[4].data[0].mediaName2}`];
    const CPMMedia3 = [`CPM ${connected[4].data[0].mediaName3}`];
    const CPMMedia4 = [`CPM ${connected[4].data[0].mediaName4}`];
    const CPMMedia5 = [`CPM ${connected[4].data[0].mediaName5}`];
    const CPMMedia6 = [`CPM ${connected[4].data[0].mediaName6}`];
    const CPMMedia7 = [`CPM ${connected[4].data[0].mediaName7}`];
    connected[4].data.map(i => {
      const date = moment(i.date);
      if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
        cdate.push(date.format('YYYY-MM-DD'));

        const t =
          i.mediaI1 === '0'
            ? ''
            : parseFloat((i.mediaB1 / i.mediaI1) * 1000).toFixed(2);

        CPMMedia1.push(t);
        CPMMedia2.push(
          i.mediaI2 !== '0'
            ? parseFloat(((i.mediaB2 * 1000) / i.mediaI2) * 1000).toFixed(2)
            : ''
        );
        CPMMedia3.push(
          i.mediaI3 !== '0'
            ? parseFloat((i.mediaB3 / i.mediaI3) * 1000).toFixed(2)
            : ''
        );
        CPMMedia4.push(
          i.mediaI4 !== '0'
            ? parseFloat((i.mediaB4 / i.mediaI4) * 1000).toFixed(2)
            : ''
        );
        CPMMedia5.push(
          i.mediaI5 !== '0'
            ? parseFloat((i.mediaB5 / i.mediaI5) * 1000).toFixed(2)
            : ''
        );
        CPMMedia6.push(
          i.mediaI6 !== '0'
            ? parseFloat((i.mediaB6 / i.mediaI6) * 1000).toFixed(2)
            : ''
        );
        CPMMedia7.push(
          i.mediaI7 !== '0'
            ? parseFloat((i.mediaB7 / i.mediaI7) * 1000).toFixed(2)
            : ''
        );
      }
      return -1;
    });
    connected[0].data.map(i => {
      const date = moment(i.date);
      if (date >= moment(funnel.start) && date <= moment(funnel.end)) {
        CPM.push(((i.budget / i.value) * 1000).toFixed(2));
      }
      return -1;
    });
    csvdata.push([cdate.join(';')]);
    csvdata.push([CPM.join(';')]);
    csvdata.push([CPMMedia1.join(';')]);
    csvdata.push([CPMMedia2.join(';')]);
    csvdata.push([CPMMedia3.join(';')]);
    csvdata.push([CPMMedia4.join(';')]);
    csvdata.push([CPMMedia5.join(';')]);
    csvdata.push([CPMMedia6.join(';')]);
    csvdata.push([CPMMedia7.join(';')]);
    return csvdata;
  },

  getanalyse(connected, id, filter, funnel, name, objectifed) {
    const csvdata = [[''], ['Analyses'], ['', 'Objectif', 'Reel', 'Impact']];
    if (objectifed.length <= 3) {
      csvdata.push([
        '',
        'no goal entered',
        'no goal entered',
        'no goal entered'
      ]);
      return csvdata;
    }
    let affichet3 = 0;
    for (let j = 0; j < 4; j += 1) {
      const ids = id[j];
      const funnelIndex = _.findIndex(
        funnel.measure.funnels,
        f => f.id === ids
      );
      const objectif = _.find(objectifed, c => c.funnelId === ids);
      const value = parseFloat(objectif.value);
      const connector = _.find(connected, c => c.funnelId === ids);
      const hasData = connector && connector.data.length;
      const connectorData = _.filter(connector.data, filter);
      const total = hasData ? _.sumBy(connectorData, c => c.value) : 0;
      const totalB = hasData ? _.sumBy(connectorData, c => c.budget) : 0;
      let per = 1;
      if (ids === 'CPM') per = 1000;
      const avg = (totalB / total) * per;
      const reel = parseFloat(avg.toFixed(2));
      let objectifRate = 0;
      let totalParent;
      if (funnelIndex > 0) {
        const parentFunnel = funnel.measure.funnels[funnelIndex - 1];
        const parent = _.find(connected, c => c.funnelId === parentFunnel.id);
        const parentObjectif = _.find(
          objectifed,
          c => c.funnelId === parentFunnel.id
        );
        let parentValue = parseFloat(parentObjectif.value);
        const parentData = _.filter(parent.data, filter);
        totalParent = connector ? _.sumBy(parentData, c => c.value) : 0;
        if (parentFunnel.per !== 1) {
          parentValue /= parentFunnel.per;
        }
        objectifRate = ((parentValue / value) * 100).toFixed(2);
      }
      const cr = ((total / totalParent) * 100).toFixed(2);
      const t1 = funnelIndex !== 0 ? cr : reel.toFixed(2);
      const t2 = funnelIndex > 0 ? objectifRate : value.toFixed(2);
      const t3 =
        funnelIndex !== 0 ? (t1 - t2) / t2 : ((t1 - t2) / t2) * 100 * -1;
      const { title } = funnel.measure.funnels[funnelIndex];
      affichet3 =
        title === 'CPM'
          ? t3.toFixed(2) * -1
          : (((t1 - t2) / t2) * 100).toFixed(2) * -1;
      csvdata.push([
        title,
        funnelIndex > 0 ? objectifRate : value.toFixed(2),
        funnelIndex !== 0 ? cr : reel.toFixed(2),
        affichet3
      ]);
    }
    return csvdata;
  },

  getbaseline(connected, id, filter) {
    const csvdata = [
      [''],
      ['Baseline'],
      ['', 'Inscription Total', 'advertising', 'Baseline', 'Cout hors baseline']
    ];
    const connectorBaseline = _.find(connected, c => c.funnelId === id[4]);
    const hasDataBaseline = connectorBaseline && connectorBaseline.data.length;
    const connectorDataBaseline = hasDataBaseline
      ? _.filter(connectorBaseline.data, filter)
      : [];
    const totalB = hasDataBaseline
      ? _.sumBy(connectorDataBaseline, c => c.display)
      : 0;
    const totalBudget = hasDataBaseline
      ? _.sumBy(connectorDataBaseline, c => c.budget)
      : 0;

    const connector = _.find(connected, c => c.funnelId === id[3]);
    const hasData = connector && connector.data.length;
    const connectorData = hasData ? _.filter(connector.data, filter) : [];
    const totalI = hasData ? _.sumBy(connectorData, c => c.value) : 0;
    const total = totalI - totalB;
    const kpiHB = totalBudget / total;
    csvdata.push([
      'traffic naturel',
      totalI.toFixed(2),
      total.toFixed(2),
      totalB.toFixed(2),
      kpiHB.toFixed(2)
    ]);
    return csvdata;
  },

  dl(connected, id, filter, funnel, name, objectifed) {
    if (connected.length < 4) {
      return -1;
    }
    const csvsynthese = this.getsynthese(connected, id, filter);
    const csvalldata = this.getalldata(connected, id, funnel);
    const csvanalyse = this.getanalyse(
      connected,
      id,
      filter,
      funnel,
      name,
      objectifed
    );
    const csvbaseline = this.getbaseline(connected, id, filter);
    const csvallbudget = this.getallBudget(connected, id, funnel);
    const csvallimpression = this.getallImpression(connected, id, funnel);
    const csvallcpm = this.getallcpm(connected, id, funnel);

    const csvRow = [];

    for (let j = 0; j < csvallbudget.length; j += 1)
      csvRow.push(csvallbudget[j].join(';'));
    for (let j = 0; j < csvallimpression.length; j += 1)
      csvRow.push(csvallimpression[j].join(';'));
    for (let j = 0; j < csvallcpm.length; j += 1)
      csvRow.push(csvallcpm[j].join(';'));
    for (let j = 0; j < csvalldata.length; j += 1)
      csvRow.push(csvalldata[j].join(';'));
    for (let j = 0; j < csvsynthese.length; j += 1)
      csvRow.push(csvsynthese[j].join(';'));
    for (let j = 0; j < csvanalyse.length; j += 1)
      csvRow.push(csvanalyse[j].join(';'));
    for (let j = 0; j < csvbaseline.length; j += 1)
      csvRow.push(csvbaseline[j].join(';'));

    const csvString = csvRow.join('\n');
    const a = document.createElement('a');
    a.download = 'FunnelReports.csv';
    a.href = `data:text/csv;charset=utf-8,${csvString}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    return a;
  }
};
