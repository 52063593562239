import Cookie from 'js-cookie';
import parser from 'cookie';
import moment from 'moment';

export function isErrorPhoneNumber(phoneNum) {
  // Regex that match only valid format
  // Valid Format:
  // (123) 456-7890
  // (123)456-7890
  // 123-456-7890
  // 123.456.7890
  // 1234567890
  // +33102030405
  // 0102030405
  // 075-63546725
  const regexNumValid = new RegExp(
    '^[\\+]?[0-9]?[\\s]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'
  );
  // .test() return true if match
  return !regexNumValid.test(phoneNum);
}

export function formatNumber(
  number,
  minimumFractionDigits = 2,
  maximumFractionDigits = minimumFractionDigits
) {
  if (number === Infinity) {
    return 'N/A';
  }
  try {
    const floatValue = parseFloat(number);
    if (Number.isNaN(floatValue)) return number;
    return floatValue
      .toLocaleString('en-gb', { maximumFractionDigits, minimumFractionDigits })
      .replace(/,/g, ' ');
  } catch (e) {
    return number;
  }
}

export function formatDate(date) {
  return moment(date).format('DD/MM/YYYY');
}

export async function fetchFromCookie(req, options = {}) {
  return key => {
    if (req) {
      // server
      const cookies = req.headers.cookie;
      if (!cookies) return null;
      const cookieMap = parser.parse(cookies, options);
      return cookieMap[key];
    }
    // browser
    return Cookie.get(key);
  };
}

/**
 * isEmptyObject
 * @param {Object} obj
 * @desc check if an object is really empty eq = {}
 * @return Boolean
 *
 */
export function isEmptyObject(obj) {
  return obj && !Object.values(obj).length;
}

/**
 * Waits a number of ms
 *
 * @export
 * @param {number} time - sleep time in ms
 * @returns
 */
export async function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export default fetchFromCookie;
