import constants from 'utils/constants';

const { insightCriteriaTypes } = constants;
const { OR, SIMPLE, EXCLUDE, AND } = insightCriteriaTypes;

/**
 * DefaultOperator
 * @constructor
 * @param {Object} params
 * @deprecated
 *
 */
export default function DefaultOperator(params) {
  this.position = params.position || 0;
  this.type = OR;
}

/**
 * DefaultOperatorValue
 * @constructor
 * @param {Object} params
 */
export function DefaultSelected(params) {
  this.exclude = params.exclude || false;
  this.key = params.key;
  this.name = params.name;
  this.position = params.position;
  this.type = params.type || OR;
  this.tagFunnel = params.tagFunnel || undefined;
}

/**
 * DefaultSubGroup
 * @constructor
 *
 */
export function DefaultSubGroup() {
  this.type = AND;
  this.values = [];
}

/**
 * FilterSimple
 * @desc one criteria
 * @see CriteriaContainer
 * @param [string] key
 */
export function FilterSimple({ key, tagFunnel, exclude }) {
  this.type = exclude ? EXCLUDE.NAME : SIMPLE.NAME;
  this.criteriaKey = key;
  this.tagFunnel = tagFunnel || undefined;
}

/**
 * FilterComposed
 * @desc filters is a array of FilterSimple (defined above)
 * @param [string] type
 * @param [array] filters
 * @return constructors
 *
 */
export function FilterComposed(params = {}) {
  const { type, value } = params;
  this.type = (type && type.NAME) || OR.NAME;
  this.value = value || [];
}
