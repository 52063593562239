import React from 'react';
import PropTypes from 'prop-types';
import { TimeSeries, TimeRange } from 'pondjs';
import moment from 'utils/moment';
import { useTheme } from 'contexts/ThemeContext';

import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  Legend,
  LineChart,
  Resizable,
  styler,
  EventMarker
} from 'react-timeseries-charts';

import constants from 'utils/constants';
import GraphContainer, {
  LegendWrapper,
  ZoomButtonContainer,
  Container,
  LegendContainer,
  Description,
  linkStyle,
  linkStyleActive
} from './style';

const { measures } = constants;

const style = styler([
  { key: 'CPI', color: '#2F80ED', width: '2px' },
  { key: 'FCPI', color: '#009FE3', width: '2px' }
]);

function getCpi(funnel) {
  return (
    (funnel.funnelData &&
      funnel.funnelData.forecast &&
      funnel.funnelData.forecast.fkpi4) ||
    null
  );
}

const getTimeRange = funnel => {
  const cpi = getCpi(funnel);
  if (!cpi || !cpi.length)
    return [moment(new Date()), moment(new Date()).add(1, 'M')];
  const forecastStart = cpi[0][0];
  const forecastEnd = cpi[cpi.length - 1][0];
  return [forecastStart, forecastEnd];
};

/**
 *
 * ForecastChartFunnel
 * @component
 * TODO::: implement timezoom and timedezoom (@see ChartKpiVolume)
 *
 *
 */
function ForecastChartFunnel(props) {
  const { themeColors } = useTheme();
  const { funnel, kpi } = props;
  const ForecastMeasures = measures.find(a => a.name === funnel.measure.name);
  const defaultTimerange = new TimeRange(getTimeRange(funnel));
  const [timerange, setTimerange] = React.useState(defaultTimerange);
  const [tracker, setTracker] = React.useState(null);
  const [trackerValue, setTrackerValue] = React.useState('');
  const [trackerEvent, setTrackerEvent] = React.useState(null);
  const markerMode = 'flag';
  const mode = 'zoom';
  const getseriescpi = () => {
    const cpi = getCpi(funnel);
    return new TimeSeries({
      name: 'CPI',
      columns: ['time', 'CPI'],
      points: cpi
    });
  };

  const getseriesfcpi = () => {
    const fcpi = getCpi(funnel);
    const seriesfcpi = new TimeSeries({
      name: 'CPI',
      columns: ['time', 'CPI'],
      points: fcpi
    });
    return seriesfcpi;
  };

  const handleTimeRangeChange = nextTimerange => setTimerange(nextTimerange);

  const handleTrackerChanged = t => {
    const seriescpi = getseriescpi();
    let eventValue;
    if (t) {
      const e = seriescpi.atTime(t);
      eventValue = e.get('CPI');
      const m = `${eventValue.toFixed(2)}`;

      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );
      setTracker(eventTime);
      setTrackerValue(m);
      setTrackerEvent(e);
    } else {
      setTracker(null);
      setTrackerValue(null);
      setTrackerEvent(null);
    }
  };

  function renderMarker() {
    if (!tracker) return <g />;
    if (markerMode === 'flag') {
      return (
        <EventMarker
          type="flag"
          axis="y"
          event={trackerEvent}
          column="CPI"
          info={[
            {
              label: kpi === 'CPO' ? "Nombre d'offres" : kpi,
              value: trackerValue
            }
          ]}
          infoTimeFormat="%d/%m/%y"
          infoWidth={150}
          infoHeight={65}
          markerRadius={3}
          markerStyle={{ fill: themeColors.light }}
          infoStyle={{
            fill: themeColors.light,
            stroke: themeColors.dark
          }}
        />
      );
    }
    return null;
  }

  const renderChart = () => {
    if (!getCpi(funnel)) return <div>ERROR DATA</div>;

    const seriesfcpi = getseriesfcpi();
    const croppedSeries = seriesfcpi;
    const e = seriesfcpi.max('CPI');
    const m = seriesfcpi.min('CPI');
    return (
      <ChartContainer
        timeRange={timerange}
        onTimeRangeChanged={handleTimeRangeChange}
        onTrackerChanged={handleTrackerChanged}
        format="%d/%m"
      >
        <ChartRow height="300">
          <YAxis
            id="y"
            min={m - 0.1}
            max={e + 0.1}
            format=",.2f"
            width={90}
            type="linear"
          />
          <Charts>
            <LineChart
              axis="y"
              style={style}
              columns={['CPI']}
              series={croppedSeries}
            />
            {renderMarker()}
          </Charts>
        </ChartRow>
      </ChartContainer>
    );
  };

  const renderLegend = () => {
    return (
      <LegendWrapper>
        {ForecastMeasures && (
          <Legend
            type="line"
            align="left"
            stack
            style={style}
            categories={[
              { key: 'CPI', label: ForecastMeasures.forecast.legend }
            ]}
            select=""
          />
        )}
      </LegendWrapper>
    );
  };

  return (
    <>
      {ForecastMeasures ? (
        <GraphContainer>
          <Container>
            <ZoomButtonContainer>
              <span
                className="zoom_buttonplus"
                style={mode === 'zoom' ? linkStyleActive : linkStyle}
                onClick={() => {}}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              >
                +
              </span>
              <span
                className="zoom_buttonmoins"
                style={mode === 'dezoom' ? linkStyleActive : linkStyle}
                onClick={() => {}}
                onKeyPress={() => {}}
                role="button"
                tabIndex="-1"
              >
                -
              </span>
            </ZoomButtonContainer>
            <Description>
              Cliquez sur la légende pour sélectionner les courbes à afficher et
              remettre à l{`'`}échelle.
            </Description>
            <LegendContainer className="legend_container">
              {renderLegend()}
            </LegendContainer>
          </Container>
          <Resizable>{renderChart()}</Resizable>
        </GraphContainer>
      ) : (
        <>{`${"Vos données ne nous permettent pas d'afficher votre forcast"}`}</>
      )}
    </>
  );
}

ForecastChartFunnel.propTypes = {
  funnel: PropTypes.shape({
    end: PropTypes.string,
    newFormat: PropTypes.shape({
      timeseries: PropTypes.shape({
        kpi4: PropTypes.string
      }),
      forecast: PropTypes.shape({
        fkpi4: PropTypes.string
      })
    })
  }).isRequired,
  kpi: PropTypes.string.isRequired
};

export default ForecastChartFunnel;
