import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'utils/icons';
import { useTheme } from 'contexts/ThemeContext';

import BaseCategoriesInsight, {
  BaseCategoriesItem,
  CategoriesTitle
} from './style';

/**
 * CategoriesInsight
 * @component
 * @see CategoriesContainer
 *
 *
 */
function CategoriesInsight(props) {
  return <BaseCategoriesInsight {...props} />;
}
CategoriesInsight.defaultProps = {};
CategoriesInsight.propTypes = {};

export default CategoriesInsight;

/**
 * CategoriesItem
 * @see CategoriesInsight
 * @see CategoriesContainer
 *
 */
export const CategoriesItem = props => {
  const { themeColors } = useTheme();
  const { name, description, index, title, handleSelect, isActive } = props;
  const onClick = e => {
    e.preventDefault();
    handleSelect();
  };
  return (
    <BaseCategoriesItem href="#" onClick={onClick} active={isActive}>
      <div className="logo-insight">
        <Icon
          icon={`categoriesInsight_${index}`}
          attrs={{ fill: themeColors.icon_insight }}
          alt={name}
        />
      </div>
      <div className="infos-insight">
        <CategoriesTitle>{title}</CategoriesTitle>
        <div className="desc-insight">{description}</div>
      </div>
      <div className="border" />
    </BaseCategoriesItem>
  );
};
CategoriesItem.defaultProps = {
  description: ''
};
CategoriesItem.propTypes = {
  description: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};
