import React from 'react';
import PropTypes from 'prop-types';

import BaseResultInsight from './style';

/**
 * ResultInsight
 * @component
 */
const ResultInsight = ({ name, value, isData }) => {
  const strValue = [...value.toString()];
  const strLength = strValue.length;
  const result = strValue
    .map((r, i) => ([3, 6].includes(strLength - i) ? ` ${r}` : r))
    .join('');
  return (
    <BaseResultInsight
      style={{
        display:
          (isData && name === 'Cookies') ||
          (!isData && name === 'Contacts (ID ERMES)')
            ? 'inline-block'
            : 'none'
      }}
    >
      <h3 className="title">{name} :</h3>
      <div className="value-result-insight">{result}</div>
    </BaseResultInsight>
  );
};
ResultInsight.defaultProps = {
  value: 0,
  isData: false
};
ResultInsight.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  isData: PropTypes.bool
};

export default ResultInsight;
