import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * ConnectorOperator
 * @component
 *
 */
const BaseOperator = styled.a`
  display: ${({ hide }) => (hide ? 'none' : 'inline-flex')};
  text-decoration: none;
  background-color: ${({ themeColors }) => themeColors.keep_light};
  color: ${({ themeColors }) => themeColors.light};
  border-radius: ${({ rounded }) => (rounded ? '24px' : 'unset')};
  align-items: center;
  font-weight: 600;
  border-bottom: 1px solid ${({ themeColors }) => themeColors.keep_light};
  padding: 0px 3px;
  margin: 4px, 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 11.5px;
  @media (min-width: 1800px) {
    padding: 2px 6px;
    border-bottom: none;
    font-size: 14.5px;
  }
`;

export const BaseOperatorText = withTheme(styled.span`
  colors: ${({ themeColors }) => themeColors.light};
`);

export default withTheme(BaseOperator);
