import React from 'react';

const MODALS = {
  DATA: 'modal_data',
  INSIGHT: 'modal_insight'
};

const { DATA, INSIGHT } = MODALS;

const ModalActivationContext = React.createContext();

/**
 *
 * ModalActivationProvider
 * @context
 * @desc :::
 *   manage the activation modal behavior,
 *   to add modal you must:
 *   - add an item in MODALS constant (above),
 *   - create a new template in Activation/components/templateXXX,
 *   - then call the template in the Activation/containers/ModalContainer
 *
 */
function ModalActivationProvider(props) {
  const [currentModal, setCurrentModal] = React.useState('');
  const value = {
    isModalActive: Object.values(MODALS).includes(currentModal),
    isTemplateData: currentModal === DATA,
    isTemplateInsight: currentModal === INSIGHT,
    activeDataModal() {
      setCurrentModal(DATA);
    },
    activeInsightModal() {
      setCurrentModal(INSIGHT);
    },
    closeModal() {
      setCurrentModal('');
    }
  };

  return <ModalActivationContext.Provider {...props} value={value} />;
}

/**
 * useModalActivation
 * @return context
 *
 */
export const useModalActivation = () => {
  const context = React.useContext(ModalActivationContext);
  if (!context)
    throw new Error(
      'ModalActivationContext must be called in ModalActivationProvider'
    );
  return context;
};

export default ModalActivationProvider;
