import React from 'react';
import MuModal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';

import { useTheme } from 'src/contexts/ThemeContext';

/**
 * Modal
 * @component
 */
function Modal({ open, onClose, disableBackdropClick, children, style }) {
  const { themeColors } = useTheme();
  return (
    <MuModal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          backgroundColor: themeColors.keep_light,
          border: `1px solid ${themeColors.keep_dark}`,
          boxSizing: 'border-box',
          borderRadius: '10px',
          padding: '32px',
          maxHeight: '80vh',
          overflowY: 'auto',
          ...style
        }}
      >
        {children}
      </div>
    </MuModal>
  );
}
Modal.defaultProps = {
  open: false,
  onClose: () => {},
  style: {},
  disableBackdropClick: false
};
Modal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape(),
  disableBackdropClick: PropTypes.bool
};

export default Modal;
