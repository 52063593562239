import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';

import Title from 'components/Title';
import Text from 'src/components/Text';
import { useTheme } from 'src/contexts/ThemeContext';
import { useDataCible } from 'src/contexts/DataCibleContext';
import { formatNumber } from 'src/utils/functions';
import ButtonUploadFile from 'Data/components/ButtonUploadFile';
import ButtonBorder from 'Data/components/ButtonBorder';
import { STEPS, useData } from 'Data/contexts/DataContext';
import dataStatus from 'src/utils/constants/dataStatus';
import routes from 'src/utils/constants/routes';
import BuyProfile from './BlockBuyProfile';
import BuySegments from './BlockBuySegments';
import LaunchCampaign from './BlockLaunchCampaign';
import ModalImportFile from './Modals/ModalImportFile';
import ModalVerifFile from './Modals/ModalVerifFile';
import ModalMatchingProgress from './Modals/ModalMatchingProgress';
import ModalMatchingEnd from './Modals/ModalMatchingEnd';
import BlockGrid from './style';
import InsightDataContainer from '../InsightDataContainer';

function isEnrichir(step) {
  return !![STEPS.ENRICHIR_MODAL, STEPS.ENRICHIR_HOME].find(s => s === step);
}

function getMatchingEndDigits(base) {
  if (!base || !base.digitizing) {
    return {
      nbRecords: 0,
      nbDigitized: 0,
      matchingPercentage: 0
    };
  }
  const nbRecords = base.digitizing.total.nb_records || -1;
  const nbDigitized = base.digitizing.total.nb_digitized;
  return {
    nbRecords,
    nbDigitized,
    matchingPercentage: (nbDigitized * 100) / nbRecords
  };
}

/**
 * HomeData
 * @component
 */
function HomeData({ history }) {
  const {
    loginLoading: loading,
    loginError: error,
    token,
    step,
    setStep,
    currentBase,
    matchingProgress,
    reset
  } = useData();
  const { themeColors } = useTheme();
  const { setIsSegmentSetData, setNbProfileData } = useDataCible();

  function getStatus() {
    if (!currentBase || !currentBase.status) {
      return 'Matching en cours';
    }
    return dataStatus.live[currentBase.status];
  }

  function changeStep(nextStep) {
    if (nextStep === STEPS.INIT) {
      return reset();
    }
    return setStep(nextStep);
  }

  function onCloseModal() {
    history.push(routes.DATA);
    changeStep(STEPS.INIT);
  }

  if (error) {
    console.error(error);
    return error.message;
  }
  const { nbRecords, nbDigitized, matchingPercentage } = getMatchingEndDigits(
    currentBase
  );

  const handleLaunchCampain = () => {
    setNbProfileData(nbDigitized);
    setIsSegmentSetData(false);
    history.push(routes.ACTIVATION);
  };

  if (isEnrichir(step)) {
    return <InsightDataContainer />;
  }
  return (
    <Grid direction="column" alignItems="center" container>
      <Grid direction="column" alignItems="center" container>
        <Title small style={{ color: themeColors.light }} fontSize={24}>
          1. Ajouter votre base de données.
        </Title>

        {step !== STEPS.DONE ? (
          <>
            <ButtonUploadFile
              disabled={loading || !token}
              onClick={() => changeStep(STEPS.IMPORT_FILE)}
            />
            <Text style={{ color: themeColors.light }}>OU</Text>
            <ButtonBorder
              onClick={() => {
                history.push(`${routes.HISTORY}${routes.DATA}`);
              }}
            >
              SÉLECTIONNER UN FICHIER EXISTANT
            </ButtonBorder>
            <Grid item xs={12} md={8} lg={6}>
              <Text textAlign="center" style={{ color: themeColors.light }}>
                Grâce à notre référentiel de données propriétaires composé de 25
                millions d’individus reliés à 90 millions de cookies, nous
                digitalisons de façon sécurisée et anonymisée vos bases de
                clients offlines afin de vous permettre de reconnaître et de
                cibler ces populations sur l’écosystème digital.
                <br />
                Consulter notre{' '}
                <a
                  href="https://www.ermes.ai/faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>{' '}
                pour toute question concernant la sécurité, le format des
                données ainsi que la digitalisation.
              </Text>
            </Grid>
          </>
        ) : (
          <>
            <Text textAlign="center" style={{ color: themeColors.light }}>
              <strong style={{ color: themeColors.data, fontSize: '1.2em' }}>
                {formatNumber(nbDigitized, 0)} profils digitalisés
              </strong>
              <br />
              sur {formatNumber(nbRecords, 0)} profils uploadés.
              <br />
              <br />
              <span style={{ color: themeColors.data }}>
                Taux de matching de{' '}
                <strong style={{ fontSize: '1.2em' }}>
                  {formatNumber(matchingPercentage, 0)}%
                </strong>
              </span>{' '}
              sur les 25 millions de profils ERMES.
            </Text>
            <Grid direction="row" container style={{ width: 'auto' }}>
              <ButtonUploadFile
                disabled={loading || !token}
                text="Ajouter un autre fichier"
                onClick={() => {
                  history.push(routes.DATA);
                  changeStep(STEPS.INIT);
                }}
              />
              <Text
                style={{
                  color: themeColors.light,
                  margin: 'auto 16px'
                }}
              >
                OU
              </Text>
              <ButtonBorder
                onClick={() => {
                  history.push(`${routes.HISTORY}${routes.DATA}`);
                }}
              >
                SÉLECTIONNER UN FICHIER EXISTANT
              </ButtonBorder>
            </Grid>
          </>
        )}
      </Grid>

      <BlockGrid direction="column" alignItems="center" container>
        <Title small style={{ color: themeColors.light }} fontSize={24}>
          2. Quelle action souhaitez-vous effectuer ?
        </Title>
        <Grid direction="row" container spacing={5} justify="space-evenly">
          <Grid item xs={4}>
            <BuyProfile disabled={step !== STEPS.DONE} />
          </Grid>
          <Grid item xs={4}>
            <BuySegments
              disabled={step !== STEPS.DONE}
              onClick={() => changeStep(STEPS.ENRICHIR_MODAL)}
            />
          </Grid>
          <Grid item xs={4}>
            <LaunchCampaign
              disabled={step !== STEPS.DONE}
              onClick={() => handleLaunchCampain()}
            />
          </Grid>
        </Grid>
      </BlockGrid>

      <ModalImportFile
        open={step === STEPS.IMPORT_FILE}
        onClose={onCloseModal}
        onNext={() => changeStep(STEPS.CHANGE_HEADERS)}
      />
      <ModalVerifFile
        open={step === STEPS.CHANGE_HEADERS}
        onClose={onCloseModal}
        onNext={() => changeStep(STEPS.MATCHING_PROGRESS)}
      />
      <ModalMatchingProgress
        open={step === STEPS.MATCHING_PROGRESS}
        onClose={onCloseModal}
        progress={matchingProgress}
        status={getStatus()}
      />
      <ModalMatchingEnd
        open={step === STEPS.MATCHING_END}
        onClose={onCloseModal}
        onNext={() => changeStep(STEPS.DONE)}
        matchingPercentage={matchingPercentage}
        nbRecords={nbRecords}
        nbDigitized={nbDigitized}
      />
    </Grid>
  );
}
HomeData.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(HomeData);
