import React from 'react';

import BaseWrapperResume from './style';

/**
 * WrapperResume
 * @component
 *
 */
function WrapperResume(props) {
  return <BaseWrapperResume {...props} />;
}
WrapperResume.defaultProps = {};
WrapperResume.propTypes = {};

export default WrapperResume;
