import React from 'react';
import PropTypes from 'prop-types';
import Text from 'src/components/Text';
import Icon from 'utils/icons';
import BaseTooltip from './style';

/**
 * Tooltip
 * @component
 *
 */
function Tooltip(props) {
  const { text, label, themeColors, noIcon } = props;
  return (
    <>
      {!noIcon && (
        <div style={{ margin: '0 5px' }} data-tip data-for={label}>
          <Icon attrs={{ fill: themeColors.icon }} icon="tooltipHelp" />
        </div>
      )}
      <BaseTooltip id={label} {...props}>
        {text && <Text>{text}</Text>}
      </BaseTooltip>
    </>
  );
}
Tooltip.defaultProps = {
  text: null,
  label: null,
  themeColors: {},
  noIcon: false
};
Tooltip.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  themeColors: PropTypes.objectOf(PropTypes.any),
  noIcon: PropTypes.bool
};

export default Tooltip;
