import styled from 'styled-components';
import React from 'react';
import { PropTypes } from 'prop-types';
import { withTheme } from 'contexts/ThemeContext';

import constants from 'utils/constants';

const { fontSizes } = constants;

const DivWrapper = ({
  onSelectChange,
  bgColor,
  borderColor,
  maxWidth,
  minWidth,
  marginRight,
  fix,
  Icon,
  isMui,
  themeColors,
  ...rest
}) => {
  return <div {...rest} />;
};
DivWrapper.defaultProps = {
  onSelectChange: () => {},
  bgColor: null,
  borderColor: null,
  maxWidth: null,
  minWidth: null,
  marginRight: null,
  fix: true,
  Icon: null,
  isMui: false,
  themeColors: {}
};
DivWrapper.propTypes = {
  onSelectChange: PropTypes.func,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  marginRight: PropTypes.string,
  fix: PropTypes.bool,
  Icon: PropTypes.node,
  isMui: PropTypes.bool,
  themeColors: PropTypes.objectOf(PropTypes.any)
};

/* The dropdown container */
const BaseDropdown = styled(DivWrapper)`
  float: left;
  background-color: transparent;
  position: relative;
  .dropbtn,
  .dropSelect {
    cursor: pointer;
    border: 1px solid ${({ borderColor }) => borderColor || 'transparent'};
    outline: none;
    color: ${({ themeColors }) => themeColors.light};
    padding: 4px 0px 4px 16px;
    background-color: ${({ bgColor, themeColors }) =>
      bgColor || themeColors.light};
    font-family: inherit; /* Important for vertical align on mobile */
    border-radius: 4px;
    margin: 0; /* Important for vertical align on mobile phones */
    text-align: left;
    font-size: ${fontSizes.small};
  }
  .dropbtn {
    display: flex;
    flex-direction: row;
    align-self: center;
    padding: 4px 30px 4px 8px;
  }
  .dropSelect {
    padding: 0;
  }
  .dropSelect select {
    padding: 8px 0px 8px 16px;
    font-size: ${fontSizes.small};
    margin: 0px 33px 0 0px;
    overflow: hidden;
  }
  .dropSelect option {
    padding: 10px;
    width: 100%;
    cursor: pointer;
    color: ${({ themeColors }) => themeColors.keep_dark};
  }
  .dropSelect .custom_select_arrow {
    top: 7px;
    right: 3%;
  }
  option:disabled {
    opacity: 0.8;
    color: ${({ themeColors }) => themeColors.tertiary};
  }
  label {
    color: ${({ themeColors }) => themeColors.light};
  }
  .dropdown-content {
    display: none;
    min-width: max-content;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1600;
  }
  /* Links inside the dropdown */
  .dropdown-content a {
    min-width: max-content;
    display: inline-block;
    font-size: ${fontSizes.small};
    float: none;
    color: ${({ themeColors }) => themeColors.dark};
    padding: 6px 10px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  /* Add a grey background color to dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  /* Show the dropdown menu on hover */
  &:hover .dropdown-content {
    display: block;
  }
  @media (min-width: 1800px) {
    .dropbtn,
    .ropSelect {
      font-size: ${fontSizes.medium};
    }
    .dropbtn {
      padding: 12px 40px 12px 16px;
    }
    .dropdown-content a {
      font-size: ${fontSizes.medium};
      padding: 12px 16px;
    }
  }
`;

export const ArrowDown = styled.img`
  right: 4px;
  top: 11px;
  position: absolute;
  @media (min-width: 1800px) {
    right: 11px;
    top: 12px;
  }
`;

export default withTheme(BaseDropdown);
