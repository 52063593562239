/**
 * kpiList
 *
 * used in @see utils/constants/measures
 *
 */
export default {
  Impression: {
    name: 'Impression sur cible',
    shortLabel: 'CPM'
  },
  Tx_Clics: {
    default: null, // Sera défini par les calculs
    min: null, // idem,
    max: null, // idem
    name: 'Clics',
    shortLabel: 'CPV',
    txName: 'Tx Clics'
  },
  Tx_engagement: {
    max: 80 / 100,
    min: 20 / 100,
    default: 40 / 100,
    insight: 'Landing page',
    name: 'Début Lead',
    shortLabel: 'CPE',
    txName: 'Tx Engagement'
  },
  Tx_Download: {
    max: 90 / 100,
    min: 60 / 100,
    default: 75 / 100,
    insight: 'Descriptif store',
    name: 'Téléchargements',
    shortLabel: 'CPD',
    txName: 'Tx Engagement'
  },
  Tx_Panier: {
    max: 50 / 100,
    min: 20 / 100,
    default: 35 / 100,
    insight: 'Offre / UI',
    name: 'Paniers',
    shortLabel: 'CPPV',
    txName: 'Tx Panier'
  },
  Tx_Ropo: {
    max: 80 / 100,
    min: 40 / 100,
    default: 65 / 100,
    insight: 'Réseau physique',
    nameTaux: 'Taux ROPO',
    name: 'Visites en magasin',
    shortLabel: 'CPVM',
    txName: 'Tx ??'
  },
  Tx_engagement_plus: {
    max: 90 / 100,
    min: 50 / 100,
    default: 60 / 100,
    insight: 'Contenu site',
    name: 'Store Locator',
    shortLabel: 'CPE+',
    txName: 'Tx Engament +'
  },
  Tx_Inscription_appli: {
    max: 90 / 100,
    min: 60 / 100,
    default: 80 / 100,
    insight: 'Process inscription',
    name: 'Inscriptions',
    shortLabel: 'CPI',
    txName: 'Tx Inscription'
  },
  Tx_Inscription_lead: {
    max: 50 / 100,
    min: 25 / 100,
    default: 35 / 100,
    insight: 'valeur inscription',
    name: 'Lead fini',
    shortLabel: 'CPL',
    txName: 'Tx Inscription Lead'
  },
  Tx_conversion_on_line: {
    max: 25 / 100,
    min: 5 / 100,
    default: 10 / 100,
    insight: 'Offre',
    name: 'Achats en ligne',
    shortLabel: 'CPAL',
    tcxName: 'Tx Inscription On Line'
  },
  Tx_conversion_magasin: {
    max: 70 / 100,
    min: 10 / 100,
    default: 50 / 100,
    insight: 'Offre',
    name: 'Achats en magasin',
    shortLabel: 'CPAM',
    txName: 'Tx Conversion Magasin'
  },
  Tx_conversion_omnicanal: {
    max: 70 / 100,
    min: 10 / 100,
    default: 50 / 100,
    insight: 'Offre',
    name: 'Achats totaux',
    nameTaux: "Taux d'achats omnicanal",
    shortLabel: 'CPAT',
    txName: 'Tx Conversion total'
  }
};
