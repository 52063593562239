import React from 'react';
import Loading from 'components/Loading';

/**
 * BaseLoading
 * @component
 *
 */
const BaseLoading = props => <Loading {...props} />;
export default BaseLoading;
