import React from 'react';

import BaseModalActivation, { contentStyle } from './style';

/**
 * ModalActivation
 * @component
 *
 */
function ModalActivation(props) {
  return <BaseModalActivation contentStyle={contentStyle} {...props} />;
}
ModalActivation.defaultProps = {};
ModalActivation.propTypes = {};

export default ModalActivation;
