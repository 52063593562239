import styled from 'styled-components';
import { Path } from 'components/PlaneMap';
import { withTheme } from 'contexts/ThemeContext';
import { withInsight } from 'Insight/contexts/InsightContext';
import { dataOrInsight } from 'utils/functions/insight';

const regions = {
  reg1: ['22', '56', '29', '35'],
  reg2: ['50', '14', '61', '76', '27'],
  reg3: ['60', '62', '59', '02', '80'],
  reg4: ['77', '91', '78', '95', '93', '75', '92', '94'],
  reg5: ['51', '08', '10', '52', '67', '54', '68', '55', '57', '88'],
  reg6: ['70', '21', '58', '71', '39', '25', '90', '89'],
  reg7: ['28', '37', '45', '36', '41', '18'],
  reg8: ['2A', '2B'],
  reg9: ['13', '84', '83', '04', '06', '05'],
  reg10: ['85', '44', '49', '72', '53'],
  reg11: ['971', '972', '973', '974'],
  reg12: [
    '64',
    '40',
    '33',
    '24',
    '47',
    '17',
    '19',
    '23',
    '87',
    '86',
    '16',
    '79'
  ],
  reg13: [
    '03',
    '15',
    '43',
    '63',
    '01',
    '38',
    '74',
    '42',
    '69',
    '73',
    '07',
    '26'
  ],
  reg14: [
    '48',
    '30',
    '11',
    '34',
    '66',
    '65',
    '46',
    '09',
    '32',
    '31',
    '82',
    '12',
    '81'
  ]
};

const pickColor = data => {
  if (regions.reg1.includes(data.toString())) return '#67001f';
  if (regions.reg2.includes(data.toString())) return '#b2182b';
  if (regions.reg3.includes(data.toString())) return '#d6604d';
  if (regions.reg4.includes(data.toString())) return 'f4a582';
  if (regions.reg5.includes(data.toString())) return '#fddbc7';
  if (regions.reg6.includes(data.toString())) return '#f7f7f7';
  if (regions.reg7.includes(data.toString())) return '#d1e5f0';
  if (regions.reg8.includes(data.toString())) return '#92c5de';
  if (regions.reg9.includes(data.toString())) return '#4393c3';
  if (regions.reg10.includes(data.toString())) return '#2166ac';
  if (regions.reg11.includes(data.toString())) return '#053061';
  if (regions.reg12.includes(data.toString())) return '#67001f';
  if (regions.reg13.includes(data.toString())) return '#b2182b';
  if (regions.reg14.includes(data.toString())) return '#d6604d';
  return 'black';
};

/**
 * @component
 *
 */
const BaseDepartment = withInsight(styled(Path)`
  fill: ${({ checked, themeColors, isData, id }) =>
    checked ? dataOrInsight(isData, themeColors, 'data') : pickColor(id)};
  &:hover {
    fill: ${({ themeColors, isData }) =>
      dataOrInsight(isData, themeColors, 'data')};
  }
`);

export default withTheme(BaseDepartment);
