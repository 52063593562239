import React from 'react';

export default {
  facebook() {
    return (
      <svg
        width="129"
        height="30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 266 100"
        overflow="visible"
        enableBackground="new 0 0 266 100"
        xmlSpace="preserve"
      >
        <g>
          <rect fill="#3B5998" width="266" height="100" />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M140.0205078,49.5966797c-1.7841797,0-3.0703125,0.5849609-4.3740234,1.1811523v13.4858398
			c1.2490234,0.1191406,1.9648438,0.1191406,3.1503906,0.1191406c4.2822266,0,4.8691406-1.9609375,4.8691406-4.6992188v-6.4414062
			C143.6660156,51.2207031,142.9951172,49.5966797,140.0205078,49.5966797z M111.5825195,48.8608398
			c-2.9707031,0-3.6494141,1.6313477-3.6494141,3.6508789v1.1347656h7.2944336v-1.1347656
			C115.2275391,50.4921875,114.5498047,48.8608398,111.5825195,48.8608398z M56.4926758,62.8984375
			c0,1.5976562,0.7539062,2.4277344,2.4179688,2.4277344c1.7851562,0,2.8417969-0.5820312,4.1445312-1.1796875v-3.1992188
			h-3.9033203C57.3046875,60.9472656,56.4926758,61.2910156,56.4926758,62.8984375z M167.6835938,49.5966797
			c-2.9755859,0-4.0068359,1.6240234-4.0068359,3.6455078v7.3789062c0,2.0273438,1.03125,3.65625,4.0068359,3.65625
			c2.9677734,0,4.0068359-1.6289062,4.0068359-3.65625v-7.3789062
			C171.6904297,51.2207031,170.6513672,49.5966797,167.6835938,49.5966797z M41.9091797,71.171875h-8.7475586V49.9975586h-4.371582
			v-7.2963867h4.371582v-4.3808594c0-5.9526367,2.4672852-9.4921875,9.4790039-9.4921875h5.8378906v7.2978516h-3.6489258
			c-2.7299805,0-2.909668,1.0195312-2.909668,2.9228516l-0.0107422,3.6523438h6.6103516l-0.7734375,7.2963867h-5.8369141V71.171875
			z M71.8061523,71.2265625H64.515625l-0.315918-1.84375c-3.3291016,1.84375-6.300293,2.1425781-8.2602539,2.1425781
			c-5.3466797,0-8.1933594-3.5722656-8.1933594-8.5117188c0-5.828125,3.3208008-7.9082031,9.262207-7.9082031h6.046875v-1.2597656
			c0-2.9741211-0.3408203-3.8481445-4.9155273-3.8481445H50.659668l0.7314453-7.2963867h8.1762695
			c10.0380859,0,12.2387695,3.1708984,12.2387695,11.203125V71.2265625z M96.5991211,50.5327148
			c-4.5371094-0.7773438-5.8398438-0.9482422-8.0229492-0.9482422c-3.9213867,0-5.1064453,0.8652344-5.1064453,4.1948242v6.2988281
			c0,3.3300781,1.1850586,4.1992188,5.1064453,4.1992188c2.1831055,0,3.4858398-0.1738281,8.0229492-0.9550781v7.1171875
			c-3.9741211,0.890625-6.5629883,1.125-8.7514648,1.125c-9.3920898,0-13.1254883-4.9394531-13.1254883-12.0742188v-5.1113281
			c0-7.1411133,3.7333984-12.0888672,13.1254883-12.0888672c2.1884766,0,4.7773438,0.2353516,8.7514648,1.1298828V50.5327148z
			 M123.9750977,59.4902344h-16.0419922v0.5878906c0,3.3300781,1.1860352,4.1992188,5.1064453,4.1992188
			c3.5244141,0,5.675293-0.1738281,10.2041016-0.9550781v7.1171875c-4.3676758,0.890625-6.6435547,1.125-10.9291992,1.125
			c-9.3925781,0-13.1279297-4.9394531-13.1279297-12.0742188v-5.84375c0-6.2426758,2.7714844-11.3564453,12.3959961-11.3564453
			s12.3925781,5.0541992,12.3925781,11.3564453V59.4902344z M152.4121094,59.625
			c0,6.8964844-1.9707031,11.9257812-13.9111328,11.9257812c-4.3115234,0-6.8408203-0.3789062-11.5996094-1.1113281V31.019043
			l8.7451172-1.4594727v13.7890625c1.8896484-0.7021484,4.3359375-1.0585938,6.5625-1.0585938
			c8.7460938,0,10.203125,3.9208984,10.203125,10.2216797V59.625z M180.4453125,59.7753906
			c0,5.9492188-2.4560547,11.71875-12.7324219,11.71875c-10.28125,0-12.7832031-5.7695312-12.7832031-11.71875V54.03125
			c0-5.9521484,2.5019531-11.7226562,12.7832031-11.7226562c10.2763672,0,12.7324219,5.7705078,12.7324219,11.7226562V59.7753906z
			 M208.4589844,59.7753906c0,5.9492188-2.4589844,11.71875-12.7333984,11.71875
			c-10.28125,0-12.7832031-5.7695312-12.7832031-11.71875V54.03125c0-5.9521484,2.5019531-11.7226562,12.7832031-11.7226562
			c10.2744141,0,12.7333984,5.7705078,12.7333984,11.7226562V59.7753906z M237.2080078,71.171875h-9.4794922l-8.0166016-13.3828125
			V71.171875h-8.7480469V31.019043l8.7480469-1.4594727v25.8486328l8.0166016-12.7070312h9.4794922l-8.7519531,13.8671875
			L237.2080078,71.171875z M195.6962891,49.5966797c-2.9707031,0-4.0019531,1.6240234-4.0019531,3.6455078v7.3789062
			c0,2.0273438,1.03125,3.65625,4.0019531,3.65625c2.9667969,0,4.0166016-1.6289062,4.0166016-3.65625v-7.3789062
			C199.7128906,51.2207031,198.6630859,49.5966797,195.6962891,49.5966797z"
          />
          <path
            fill="#FFFFFF"
            d="M242.2011719,66.1777344c1.4726562,0,2.6464844,1.2011719,2.6464844,2.7011719
			c0,1.5234375-1.1738281,2.7109375-2.6572266,2.7109375c-1.4755859,0-2.6728516-1.1875-2.6728516-2.7109375
			c0-1.5,1.1972656-2.7011719,2.6728516-2.7011719H242.2011719z M242.1904297,66.5976562
			c-1.1865234,0-2.1582031,1.0214844-2.1582031,2.28125c0,1.2832031,0.9716797,2.2910156,2.1689453,2.2910156
			c1.1982422,0.0117188,2.1552734-1.0078125,2.1552734-2.2792969s-0.9570312-2.2929688-2.1552734-2.2929688H242.1904297z
			 M241.6865234,70.4511719h-0.4804688V67.4375c0.2519531-0.0351562,0.4921875-0.0703125,0.8515625-0.0703125
			c0.4560547,0,0.7539062,0.0957031,0.9365234,0.2265625c0.1767578,0.1328125,0.2724609,0.3359375,0.2724609,0.6230469
			c0,0.3984375-0.2617188,0.6367188-0.5849609,0.734375v0.0234375c0.2626953,0.0488281,0.4423828,0.2871094,0.5029297,0.7304688
			c0.0703125,0.46875,0.1425781,0.6484375,0.1904297,0.7460938h-0.5029297
			c-0.0712891-0.0976562-0.1435547-0.3730469-0.2041016-0.7695312c-0.0703125-0.3828125-0.2636719-0.5273438-0.6484375-0.5273438
			h-0.3330078V70.4511719z M241.6865234,68.7832031h0.3476562c0.3935547,0,0.7285156-0.1445312,0.7285156-0.5175781
			c0-0.2636719-0.1904297-0.5273438-0.7285156-0.5273438c-0.1572266,0-0.265625,0.0117188-0.3476562,0.0234375V68.7832031z"
          />
        </g>
      </svg>
    );
  }
};
