/* eslint-disable prefer-const */
import React from 'react';

const InsightCibleContext = React.createContext();

function InsightCibleProvider(props) {
  const cible = {
    id: '',
    nbProfile: undefined,
    isSegmentSet: false
  };
  const setId = id => {
    cible.id = id;
  };
  const setNbProfile = nb => {
    cible.nbProfile = nb;
  };
  const setIsSegmentSet = value => {
    cible.isSegmentSet = value;
  };

  const value = {
    cible,
    setNbProfile,
    setId,
    setIsSegmentSet
  };

  return <InsightCibleContext.Provider {...props} value={value} />;
}

export const useInsightCible = () => {
  const context = React.useContext(InsightCibleContext);
  if (!context)
    throw new Error('InsightCibleCtx must be called in InsightCibleProvider');
  return context;
};

export default InsightCibleProvider;
