import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from 'ErrorBoundary';

import Layout from 'components/Layout';
import ErrorPage from 'components/ErrorPage';
import DashboardContainer from 'containers/DashboardContainer';
import FeatureDetailsContainer from 'containers/FeatureDetailsContainer';
import FooterContainer from 'containers/FooterContainer';
import HeaderContainer from 'containers/HeaderContainer';
import HistoryContainer from 'containers/HistoryContainer';
import LoginContainer from 'containers/LoginContainer';
import Notification from 'components/Notification';
import DataModule from 'modules/Data';
import FunnelModule from 'modules/Funnel';
import InsightModule from 'modules/Insight';
import ActivationModule from 'modules/Activation';
import OauthContainer from 'containers/OauthContainer';

import { routes as routesPolicies } from 'utils/policies';
import constants from 'utils/constants';

const { routes } = constants;
const { AuthenticatedRoute } = routesPolicies;

/**
 * App
 * @component
 * @desc This is the App Entry Point
 * @return React component
 *
 */
function App() {
  return (
    <Layout>
      <Router>
        <HeaderContainer />
        <ErrorBoundary>
          <Notification>
            <Switch>
              <Route exact path={routes.DEFAULT} component={LoginContainer} />
              <Route exact path={routes.LOGIN} component={LoginContainer} />
              <Route
                path={`${routes.FEATURE}/:feature`}
                component={FeatureDetailsContainer}
              />
              <AuthenticatedRoute
                path={routes.DASHBOARD}
                component={DashboardContainer}
              />
              <AuthenticatedRoute
                exact
                path={routes.DATA}
                component={DataModule}
              />
              <AuthenticatedRoute
                exact
                path={`${routes.DATA}/:id`}
                component={DataModule}
              />
              <AuthenticatedRoute
                exact
                path={routes.FUNNEL}
                component={FunnelModule}
              />
              <AuthenticatedRoute
                path={`${routes.FUNNEL}/:id`}
                component={FunnelModule}
              />
              <AuthenticatedRoute
                exact
                path={routes.ACTIVATION}
                component={ActivationModule}
              />
              <AuthenticatedRoute
                path={`${routes.ACTIVATION}/:id`}
                component={ActivationModule}
              />
              <AuthenticatedRoute
                exact
                path={routes.INSIGHT}
                component={InsightModule}
              />
              <AuthenticatedRoute
                path={`${routes.INSIGHT}/:id`}
                component={InsightModule}
              />
              <AuthenticatedRoute
                path={`${routes.HISTORY}/:panel`}
                component={HistoryContainer}
              />
              <AuthenticatedRoute
                path={`${routes.OAUTH}`}
                component={OauthContainer}
              />
              <Route component={ErrorPage} />
            </Switch>
          </Notification>
        </ErrorBoundary>
        <FooterContainer />
      </Router>
    </Layout>
  );
}

export default App;
