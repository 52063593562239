import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseAnomalyTable
 * @component
 *
 */
const BaseAnomalyTable = styled.table`
  color: ${({ themeColors }) => themeColors.light};
  border-collapse: collapse;
  width: 100%;
  margin: 50px auto 0px auto;
  max-width: 1500px;
  th {
    border-bottom: 2px solid ${({ themeColors }) => themeColors.light};
    max-width: 150px;
    margin: 0px auto;
    text-align: left;
    padding: 15px;
    font-weight: bold;
    color: ${({ themeColors }) => themeColors.light};
  }
`;

export default withTheme(BaseAnomalyTable);
