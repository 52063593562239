import React from 'react';
import Text from 'components/Text';
import { PropTypes } from 'prop-types';

import OperatedCategoryInsight from 'Insight/components/OperatedCategoryInsight';
import OperatedCriteriaInsight from 'Insight/components/OperatedCriteriaInsight';
import OperatedGroupInsight from 'Insight/components/OperatedGroupInsight';
import OperatorInsight from 'Insight/components/OperatorInsight';
import { formatNumber } from 'src/utils/functions';
import {
  exists,
  getLabel,
  extractSubGroup,
  getEntries
} from 'Insight/containers/CriteriaContainer/functions';

import BaseContent, {
  Title,
  CommandRecap,
  CommandTitle,
  ContactLabel,
  IDLabel,
  ContactNumber,
  ContactRecap,
  CommandCriterias,
  OperatorLabel
} from './style';

/**
 * ResultSavingInsight
 * @component
 *
 */

function BuyDataModalInsight({ operators, contacts }) {
  const categories = Object.keys(operators)
    .map(key => getEntries(key, operators))
    .filter(exists);
  return (
    <BaseContent>
      <Title>RÉCAPITULATIF DE VOTRE COMMANDE</Title>
      <Text>
        Les profils de votre audience cible remplissent les conditions suivantes
        :
      </Text>
      <CommandCriterias>
        {categories.map(({ key: categoryKEY }, catIndex) => (
          <>
            <OperatedCategoryInsight
              style={{ borderTop: 'none' }}
              key={categoryKEY}
              rounded={catIndex === 1}
            >
              {extractSubGroup(operators, categoryKEY).map(
                ([subCategoryKEY, subGroup], indexSub) => (
                  <React.Fragment key={subCategoryKEY}>
                    <OperatorInsight
                      operator={getLabel(subGroup.type)}
                      rounded
                      hide={indexSub === 0}
                      interactive={false}
                    />
                    <OperatedGroupInsight>
                      {subGroup.values.map((value, valueIndex) => (
                        <React.Fragment key={value.key}>
                          <OperatorInsight
                            operator={getLabel(value.type)}
                            hide={valueIndex === 0}
                            interactive={false}
                          />
                          <OperatedCriteriaInsight
                            title={value.name}
                            isExcluded={value.exclude}
                            isFirst={value.position === 0}
                            isLast={
                              value.position === subGroup.values.length - 1
                            }
                            interactive={false}
                          />
                        </React.Fragment>
                      ))}
                    </OperatedGroupInsight>
                  </React.Fragment>
                )
              )}
            </OperatedCategoryInsight>
            {catIndex < categories.length - 1 && (
              <OperatorLabel>ET</OperatorLabel>
            )}
          </>
        ))}
      </CommandCriterias>
      <CommandRecap>
        <CommandTitle>Votre commande :</CommandTitle>
        <ContactRecap>
          <ContactLabel>CONTACTS</ContactLabel>
          <IDLabel> (ID ERMES):</IDLabel>
          <ContactNumber>{formatNumber(contacts, 0, 0)}</ContactNumber>
        </ContactRecap>
      </CommandRecap>
      <Text>
        Vous serez rappelé dans l’heure par l’équipe ERMES afin de finaliser
        votre commande et vous informer des prochaines étapes.
      </Text>
    </BaseContent>
  );
}
BuyDataModalInsight.defaultProps = {};
BuyDataModalInsight.propTypes = {
  operators: PropTypes.shape({}).isRequired,
  contacts: PropTypes.number.isRequired
};

export default BuyDataModalInsight;
