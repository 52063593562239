import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseTooltip
 * @component
 *
 */
const BaseTooltip = styled(ReactTooltip).attrs({
  className: 'fit-text'
})`
  color: ${({ color, themeColors }) => color || themeColors.dark} !important;
  box-shadow: 0 0 6px -2px ${({ themeColors }) => themeColors.dark};
  background-color: ${({ bgColor, themeColors }) =>
    bgColor || themeColors.icon} !important;
  opacity: ${({ opacity }) => opacity || 1} !important;
  max-width: ${({ maxWidth }) => maxWidth || '300px'};
  &.fit-text {
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
`;

export default withTheme(BaseTooltip);
