import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';
import { useInsight } from 'Insight/contexts/InsightContext';
import { dataOrInsight } from 'utils/functions/insight';
import Button, { BaseButtonModal } from './style';

const { colors } = constants;

export const ButtonPrimary = props => {
  const { themeColors } = useTheme();
  return (
    <Button
      bgcolor={themeColors.primary}
      {...props}
      themeColors={themeColors}
    />
  );
};

/**
 * BaseButtonModal
 * @component
 *
 */

export const DefaultButton = ({ inversed, ...rest }) => {
  const { themeColors } = useTheme();
  const { isData } = useInsight();
  return (
    <BaseButtonModal
      color={
        inversed ? dataOrInsight(isData, themeColors, 'data') : colors.light
      }
      bgColor={
        inversed ? 'transparent' : dataOrInsight(isData, themeColors, 'data')
      }
      {...rest}
    />
  );
};
DefaultButton.defaultProps = { inversed: false };
DefaultButton.propTypes = { inversed: PropTypes.bool };

export const UnColoredButton = ({ inversed, ...rest }) => (
  <BaseButtonModal
    color={inversed ? colors.light : colors.primary}
    bgColor={inversed ? 'transparent' : colors.light}
    {...rest}
  />
);
UnColoredButton.defaultProps = { inversed: false };
UnColoredButton.propTypes = { inversed: PropTypes.bool };

export const ButtonConnect = props => {
  const { themeColors } = useTheme();
  return (
    <Button
      disablehover={1}
      bgcolor={themeColors.funnel}
      themeColors={themeColors}
      customcolor="#FFFFFF"
      {...props}
    />
  );
};

export default props => {
  const { themeColors } = useTheme();
  return <Button {...props} themeColors={themeColors} />;
};
