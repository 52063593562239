import React from 'react';

export default {
  blindMan(options) {
    return (
      <svg
        width="364"
        height="370"
        viewBox="0 0 364 370"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...options}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.794 369.198C232.523 369.198 299.588 302.133 299.588 219.404C299.588 136.675 232.523 69.6099 149.794 69.6099C67.0651 69.6099 0 136.675 0 219.404C0 302.133 67.0651 369.198 149.794 369.198Z"
          fill="#BDBDBD"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="47"
          y="69"
          width="253"
          height="301"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M149.794 369.198C232.523 369.198 299.588 302.133 299.588 219.404C299.588 136.675 232.523 69.6099 149.794 69.6099C67.0651 69.6099 47 129.27 47 212C47 294.729 67.0651 369.198 149.794 369.198Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M261.284 180.915L262.87 286.13C262.87 286.13 204.602 306.854 111.065 305.558L86.4622 199.498C86.4622 199.498 204.403 205.851 261.284 180.915Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M262.485 277.483L262.109 248.588L262.485 277.483Z"
            fill="#A384A0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M329.698 307.252L291.415 286.266L262.256 286.22C262.256 286.22 204.12 306.955 110.795 305.658L120.744 338.622L113.451 389.681C113.451 389.681 164.616 395.592 148.973 379.967C133.329 364.342 122.829 378.2 122.829 378.2L135.891 343.711L135.027 319.759C150.497 320.195 196.171 319.958 257.795 305.509L287.239 300.692L330.554 319.153C330.554 319.153 370.631 277.329 346.248 279.874C324.051 282.193 329.698 307.252 329.698 307.252Z"
            fill="#403844"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M262.234 249.348L262.109 239.463H262.11L262.234 249.348Z"
            fill="#9B83A6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M262.174 249.349L262.109 244.026L262.174 249.349Z"
            fill="#927690"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M345.488 171.305L345.751 163.875L332.866 161.571L326.257 170.201L328.76 156.202L336.528 149.883L328.491 143.655L320.881 151.841L318.338 169.549L313.645 160.118L314.425 148.059L305.455 149.213L305.666 164.389L313.159 173.76L261.349 187.838L261.611 205.246L320.89 189.015L325.338 197.936L342.128 197.581L340.58 186.784L332.244 189.751L328.196 179.841L336.618 169.491L345.488 171.305Z"
          fill="#EECFB8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M364 237.321L352.165 227.608L339.781 227.563L124.647 216.651L124.482 233.616L311.177 242.085L304.656 247.856L308.175 258.656L318.358 255.406L315.721 248.211L325.123 243.082L332.776 250.269L326.571 259.383L333.079 263.034L342.384 251.896L336.918 240.78L348.256 249.437L350.313 259.212L359.577 254.972L355.781 244.478L341.151 234.081L351.484 235.328L360.578 242.372L364 237.321Z"
          fill="#EECFB8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M329.697 307.252L291.415 286.266L262.256 286.22C262.256 286.22 204.119 306.955 110.795 305.658C110.795 305.658 196.171 319.958 257.794 305.509L287.238 300.692L330.554 319.153C330.554 319.153 370.631 277.329 346.248 279.874C324.05 282.193 329.697 307.252 329.697 307.252Z"
          fill="#403844"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.349 180.569C261.349 180.569 262.724 -5.03399 122.431 67.8502C122.431 67.8502 79.3903 105.084 86.4945 199.154C202.51 216.075 261.349 180.569 261.349 180.569Z"
          fill="#EECFB8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M172.384 162.833C172.384 162.833 197.958 152.424 231.544 154.599C231.544 154.599 235.087 183.081 207.725 187.882C177.162 193.249 172.384 162.833 172.384 162.833Z"
          fill="#403844"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.192 144.416L151.855 129.768L154.85 125.406L176.187 140.055L173.192 144.416Z"
          fill="#403844"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M159.258 147.457L154.897 144.462L169.544 123.125L173.906 126.12L159.258 147.457Z"
          fill="#403844"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M237.063 132.25L215.727 117.601L218.722 113.24L240.059 127.889L237.063 132.25Z"
          fill="#403844"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M222.369 134.531L218.008 131.535L232.657 110.199L237.017 113.194L222.369 134.531Z"
          fill="#403844"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M260.133 0.704102C260.133 0.704102 283.288 114.953 127.519 98.0938L123.608 133.603C123.608 133.603 106.967 120.636 103.713 137.784C100.455 154.943 120.65 146.07 120.65 146.07C120.65 146.07 106.362 187.033 86.3886 199.916C87.2267 201.244 28.5288 18.8294 99.6218 43.0707C99.6218 43.0707 99.9618 12.6924 146.458 3.50837C200.258 -7.11866 224.412 56.6233 260.133 0.704102Z"
          fill="#242424"
        />
      </svg>
    );
  },
  spaceMan(options) {
    return (
      <svg
        width="541"
        height="499"
        viewBox="0 0 541 499"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...options}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M391.121 88.5562C392.499 91.5627 386.052 96.3793 383.958 99.0627C389.446 98.8576 405.305 97.6205 407.432 100.232C413.223 107.341 406.871 116.581 400.905 125.404C386.011 147.43 349.255 193.984 314.153 180.077C288.105 205.139 253.118 219.65 218.36 228.006C179.51 237.348 175.309 233.441 190.118 196.259C202.905 164.162 223.693 130.659 250.641 107.609C242.201 89.1531 257.887 72.5654 270.516 61.6261C282.87 50.9266 303.722 37.0972 320.537 29.8069C335.395 23.3661 341.92 20.3276 340.32 37.5889C339.54 46.0341 337.494 56.8175 335.377 65.5342C341.247 61.7627 356.764 49.0514 362.594 50.8115C376.841 55.1098 349.264 93.5267 344.896 99.5577C351.91 96.1024 382.625 81.8183 388.62 84.7695C389.761 85.3323 390.572 87.3612 391.121 88.5562Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M386.97 89.7187C386.288 88.6046 360.668 96.8307 338.486 110.472C351.062 104.752 366.692 100.471 378.972 99.3613C384.476 93.0918 387.286 90.2353 386.97 89.7187Z"
          fill="#BD402D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M334.967 29.5575C336.482 31.046 334.098 51.8635 326.276 72.1066C319.325 77.6754 312.683 83.4086 306.493 89.3941C295.314 86.9787 278.036 90.4635 254.101 105.315C249.748 94.9633 253.86 81.0691 273.121 64.3845C296.453 44.1729 332.563 27.1969 334.967 29.5575Z"
          fill="#BD402D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M404.69 103.848C402.996 101.542 365.609 101.242 340.084 113.587C341.942 116.931 343.161 120.546 343.375 124.738C344.31 143.045 332.961 160.32 320.012 174.061C329.591 178.551 343.33 175.749 361.401 160.095C384.733 139.883 406.684 106.561 404.69 103.848Z"
          fill="#BD402D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M187.082 226.07C183.058 222.117 207.978 151.244 247.062 117.387C270.687 96.9209 287.402 91.4159 302.325 92.7494C293.974 99.8773 288.877 105.28 282.168 114.969C274.418 126.163 278.834 136.732 287.732 129.685C299.113 120.668 315.279 108.971 327.905 109.454C330.019 109.533 330.326 109.692 331.617 111.297C334.857 115.325 337.388 119.538 337.67 125.031C338.47 140.708 328.856 159.98 303.207 182.199C264.124 216.056 191.105 230.025 187.082 226.07Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M286.814 130.36C294.946 124.81 311.884 109.6 326.934 110.41C328.381 110.488 328.813 110.644 329.885 111.714C335.293 117.114 349.422 138.325 301.013 180.329C272.678 204.914 229.54 218.133 205.387 223.034C183.01 227.575 183.329 222.078 203.175 217.964C263.523 205.458 373.188 112.155 286.814 130.36Z"
          fill="#D9D9D9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.976 165.207C222.089 155.974 226.465 143.702 234.105 136.564C241.746 129.427 257.233 126.32 266.121 135.552C275.01 144.784 274.348 158.91 264.642 167.098C254.938 175.286 239.863 174.438 230.976 165.207Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M363.158 55.6456C364.355 56.6365 350.665 85.6436 330.562 106.503C314.002 105.657 297.624 118.11 285.113 128.218C283.231 129.737 280.583 131.123 281.191 127.04C282.144 120.624 289.099 110.794 306.133 96.0387C329.466 75.8272 361.96 54.6551 363.158 55.6456Z"
          fill="#BD402D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M234.653 163.699C227.267 156.026 230.903 145.823 237.255 139.892C243.606 133.96 256.477 131.377 263.865 139.052C271.252 146.725 270.702 158.466 262.636 165.271C254.57 172.077 242.04 171.372 234.653 163.699Z"
          fill="#A2D7E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M232.501 157.92C236.055 148.221 242.554 140.57 251.507 135.4C250.536 135.405 249.563 135.481 248.601 135.63C240.035 140.138 234.454 147.006 231.921 155.753C232.045 156.479 232.241 157.206 232.501 157.92ZM233.814 160.524C237.38 149.789 244.193 141.079 254.635 135.653C256.614 135.746 259.341 137.003 261.223 138.474C251.166 136.819 234.832 157.998 238.318 164.988C237.051 164.02 235.119 162.533 233.814 160.524Z"
          fill="#FEFEFE"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="541"
          height="499"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.564476 89.0552L461.68 0.246497L540.456 409.27L79.3404 498.078"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M161.822 375.748C157.517 374.46 159.479 367.916 163.781 369.209C213.488 384.142 324.322 420.89 339.717 369.179C358.807 305.057 442.891 353.223 488.564 334.651C546.274 311.183 496.828 251.709 412.366 292.306C391.784 302.199 355.586 310.45 339.103 295.296C306.964 265.747 358.97 254.945 328.417 228.517C311.915 214.242 299.409 197.381 299.698 173.71C299.754 169.206 306.586 169.294 306.532 173.785C306.27 195.228 317.874 210.346 332.901 223.342C351.91 239.785 345.914 250.96 339.958 262.06C335.498 270.369 331.069 278.628 343.724 290.263C357.678 303.091 391.416 294.787 409.405 286.14C504.472 240.438 556.805 314.293 491.123 341.003C443.9 360.205 363.216 314.253 346.283 371.133C328.996 429.201 215.537 391.952 161.822 375.748Z"
            fill="#E4E6F7"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.5557 417.479C62.6705 431.44 67.9298 445.072 77.4885 448.96C83.6124 451.452 94.5618 452.088 94.6474 439.166C94.7035 430.61 98.5556 437.465 101.887 435.747C107.528 432.836 102.79 421.734 101.111 420.634C98.4154 418.866 98.9372 416.446 95.7808 415.438C93.9923 414.866 80.8323 418.624 79.4895 418.926C77.5829 419.359 76.9908 411.544 73.8933 413.142C70.7575 414.76 73.8398 416.06 71.5557 417.479Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.295 325.61C239.684 323.318 249.906 333.759 249.476 344.069C249.201 350.676 245.24 360.903 233.445 355.618C225.637 352.119 230.277 358.467 227.331 360.787C222.341 364.711 214.206 355.795 213.903 353.81C213.412 350.624 210.992 350.094 211.386 346.804C211.608 344.94 220.488 334.526 221.32 333.43C222.503 331.873 215.639 328.092 218.379 325.938C221.153 323.755 221.056 327.099 223.295 325.61Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.484 328.176C236.936 326.294 247.414 334.464 247.062 342.923C246.837 348.345 242.513 355.92 232.834 351.582C226.427 348.71 227.659 354.76 225.242 356.663C221.146 359.885 215.739 353.234 215.488 351.604C215.086 348.991 213.1 348.556 213.421 345.854C213.605 344.324 220.893 335.777 221.577 334.878C222.547 333.6 216.914 330.497 219.162 328.728C221.439 326.938 221.646 329.399 223.484 328.176Z"
          fill="#D9D9D9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.147 453.013C164.75 451.356 173.623 458.658 177.455 468.489C181.288 478.321 167.844 481.032 159.241 482.689C150.637 484.346 142.908 482.976 141.263 474.433C139.617 465.891 147.544 454.67 156.147 453.013Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.037 453.87C161.933 452.542 169.044 458.395 172.115 466.273C175.187 474.154 164.412 476.328 157.518 477.655C150.623 478.984 144.426 477.884 143.107 471.038C141.789 464.192 148.143 455.198 155.037 453.87Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240.391 337.479C241.183 342.236 236.791 346.391 230.585 346.758C224.377 347.124 218.701 343.563 217.911 338.805C217.119 334.048 221.512 329.893 227.717 329.526C233.924 329.16 239.6 332.722 240.391 337.479Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.8102 418.216C66.5214 429.678 69.6143 442.599 77.4585 445.786C82.4867 447.828 91.1718 447.032 91.2365 436.427C91.2784 429.406 96.2724 433.034 99.0063 431.621C103.637 429.231 99.8255 421.553 98.4471 420.649C96.2334 419.2 96.6615 417.213 94.0697 416.387C92.601 415.919 81.8017 419.008 80.7005 419.257C79.1343 419.612 78.6464 413.199 76.1042 414.512C73.5311 415.841 75.683 417.05 73.8102 418.216Z"
          fill="#D9D9D9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.3605 418.165C84.0958 417.253 88.3615 421.538 88.8853 427.734C89.4105 433.929 85.9947 439.693 81.2594 440.605C76.5227 441.517 72.2571 437.233 71.7332 431.037C71.2094 424.841 74.6252 419.077 79.3605 418.165Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211.227 412.699C216.166 405.463 227.607 404.393 237.252 408.67C246.899 412.948 239.268 424.342 234.331 431.58C229.393 438.817 222.942 443.291 215.756 438.387C208.57 433.484 206.289 419.935 211.227 412.699Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212.134 413.74C216.063 407.92 225.228 407.018 232.977 410.408C240.725 413.798 234.655 422.961 230.726 428.781C226.797 434.6 221.645 438.212 215.865 434.311C210.087 430.41 208.205 419.559 212.134 413.74Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.554 312.191C154.499 301.376 198.979 313.751 217.212 320.601C234.942 327.265 222.348 361.204 209.17 359.652C201.672 358.769 182.55 353.239 178.735 354.948C188.252 374.599 206.141 384.34 222.244 397.58C239.275 411.592 213.782 441.227 199.149 434.522C190.83 430.707 183.021 426.062 175.196 421.42C177.232 432.53 179.351 443.856 177.835 454.9C175.485 472.03 136.177 479.347 133.529 458.468C131.646 443.593 128.306 428.85 125.954 414.031C124.566 405.28 116.102 399.978 112.679 399.422C108.83 398.794 103.173 419.683 98.5351 422.946C88.3828 430.085 68.5068 425.249 68.1466 412.929C67.3297 391.265 78.4423 367.927 88.617 348.97C87.5797 342.343 88.2289 335.617 91.6367 329.649C99.955 315.075 120.049 312.099 135.554 312.191Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.2231 347.531C80.2491 369.857 70.5179 391.799 71.2885 411.629C71.7252 422.884 95.0267 427.186 98.5013 415.929C101.041 407.703 109.406 385.588 115.787 382.958C118.118 381.995 108.581 392.987 113.259 394.667C117.608 396.23 128.237 402.533 129.827 412.124C133 431.282 135.668 442.505 137.601 457.705C139.405 471.901 171.145 465.826 173.136 454.727C175.601 441.007 170.074 425.44 166.75 411.542C166.606 410.939 167.827 410.34 168.378 410.629C179.458 416.434 189.445 423.618 200.828 428.884C209.611 432.948 230.221 410.795 218.15 400.794C204.134 389.185 183.712 375.784 173.215 353.925C171.798 350.973 159.622 351.97 161.985 351.311C173.993 347.96 197.633 354.821 209.229 355.284C217.754 355.625 229.026 328.597 214.561 323.157C178.667 309.659 148.215 305.843 135.444 314.856C104.258 314.161 87.7082 325.874 92.2231 347.531Z"
          fill="#D9D9D9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.2232 347.531C88.0119 355.382 84.0836 363.181 80.7877 370.86C78.7239 379.802 73.7676 391.801 76.2965 404.932C79.4782 421.452 91.6999 408.994 98.2761 393.81C108.656 369.841 126.142 375.068 131.016 400.376C133.71 414.365 136.668 464.95 153.476 459.634C168.381 454.921 162.431 425.702 162.059 419.646C161.143 404.754 172.433 406.511 178.998 412.029C185.188 417.226 198.135 424.974 207.52 423.061C239.154 416.607 174.372 365.536 168.057 363.606C149.858 358.046 152.038 340.029 182.038 343.197C207.393 345.875 239.848 322.893 168.781 311.297C162.177 310.22 117.436 319.669 112.261 324.477C105.004 331.221 104.676 339.266 93.0225 341.511C92.4807 341.615 91.98 341.771 91.5147 341.971C91.5874 343.745 91.8208 345.596 92.2232 347.531Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.796 352.292C133.007 348.015 147.633 326.41 143.355 304.198C139.078 281.987 117.474 267.362 95.2627 271.64C73.0519 275.918 58.4238 297.521 62.7015 319.732C66.9794 341.944 88.5853 356.57 110.796 352.292Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.5445 274.645C115.487 270.804 134.776 283.861 138.616 303.803C142.327 323.068 130.267 341.715 111.473 346.427C136.124 335.722 130.14 295.901 115.176 306.855C101.951 316.536 78.0278 323.473 66.4981 318.265L66.3835 317.714C62.5428 297.773 75.6004 278.486 95.5445 274.645Z"
          fill="#D9D9D9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121.531 305.158C119.694 304.659 117.55 305.116 115.177 306.855C105.246 314.125 89.2842 319.845 77.0671 320.04C74.3203 316.823 72.3411 312.881 71.484 308.431C68.8448 294.727 77.8166 281.473 91.5216 278.834C105.225 276.194 118.479 285.167 121.119 298.871C121.527 300.99 121.653 303.096 121.531 305.158Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.923 308.169C118.607 317.896 111.438 326.184 101.025 328.189C92.9344 329.747 85.003 327.217 79.3566 322.047C82.2915 321.846 85.325 321.46 88.4145 320.865C100.79 318.482 111.027 313.237 116.954 307.489C118.626 305.866 121.593 305.35 120.923 308.169Z"
          fill="#575756"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.1128 275.592L81.6988 288.973C82.4579 290.791 88.0969 287.561 86.8371 286.046L77.4642 274.782C78.6067 273.785 79.2036 272.225 78.8963 270.63C78.4356 268.238 76.1227 266.671 73.7305 267.132C71.3383 267.592 69.7729 269.906 70.2336 272.299C70.6941 274.689 73.007 276.256 75.3992 275.796C75.6476 275.748 75.8844 275.677 76.1128 275.592Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.8434 321.942C75.0686 342.48 95.155 353.787 113.963 344.305C94.3518 349.227 75.4582 337.771 66.8434 321.942Z"
          fill="#575756"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.332 431.369C145.919 431.641 144.987 433.022 145.258 434.432L146.3 439.838C146.571 441.248 147.95 442.183 149.362 441.91L155.85 440.661C157.262 440.389 158.195 439.009 157.923 437.6L156.882 432.193C156.611 430.783 155.232 429.847 153.819 430.119L147.332 431.369ZM146.744 430.744L154.635 429.224C156.351 428.893 158.029 430.028 158.36 431.745L159.626 438.319C159.956 440.035 158.82 441.713 157.104 442.043L149.213 443.563C147.497 443.894 145.819 442.758 145.488 441.042L144.222 434.467C143.891 432.751 145.028 431.074 146.744 430.744Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M132.844 370.726L147.325 362.05C150.479 360.161 154.601 361.195 156.49 364.346L163.721 376.414C165.609 379.566 164.577 383.689 161.424 385.58L146.944 394.256C143.79 396.146 139.668 395.111 137.778 391.96L130.548 379.895C128.66 376.74 129.693 372.617 132.844 370.726Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.832 370.896L146.316 363.418C149.034 361.789 152.59 362.684 154.217 365.399L160.452 375.805C162.077 378.519 161.187 382.071 158.471 383.702L145.989 391.183C143.269 392.811 139.715 391.919 138.088 389.203L131.853 378.799C130.229 376.085 131.118 372.528 133.832 370.896Z"
          fill="#7DACC6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188.468 403.417C187.517 404.496 187.624 406.159 188.702 407.108L192.831 410.748C193.908 411.698 195.571 411.594 196.522 410.514L200.889 405.559C201.84 404.48 201.735 402.817 200.658 401.867L196.528 398.229C195.451 397.278 193.787 397.381 192.836 398.46L188.468 403.417ZM187.62 403.281L192.934 397.253C194.089 395.94 196.11 395.814 197.422 396.969L202.444 401.396C203.756 402.551 203.884 404.573 202.727 405.884L197.414 411.912C196.257 413.224 194.238 413.351 192.926 412.196L187.903 407.77C186.592 406.613 186.464 404.592 187.62 403.281Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.0539 364.479L89.405 366.949C92.0188 367.823 93.4619 370.701 92.5777 373.311L89.1928 383.373C88.315 385.995 85.4553 387.416 82.825 386.53L73.8775 383.523L74.8739 381.201L82.1674 383.652C84.3459 384.374 86.6851 383.205 87.4084 381.045L90.1886 372.789C90.9157 370.627 89.7516 368.257 87.5836 367.545L81.5996 365.537L82.0539 364.479Z"
          fill="#1D1D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M164.586 318.184L165.398 310.134L166.595 310.374L165.857 317.703C165.642 319.842 167.419 321.919 169.798 322.316L178.913 323.835C181.291 324.231 183.418 322.806 183.634 320.667L184.312 313.93L186.943 314.457L186.204 321.789C185.942 324.391 183.357 326.126 180.463 325.643L169.375 323.793C166.481 323.311 164.325 320.787 164.586 318.184Z"
          fill="#1D1D1B"
        />
      </svg>
    );
  }
};
