import React from 'react';
import moment from 'utils/moment';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useFunnelGraph } from 'Funnel/contexts/FunnelGraphContext';
import FunnelRow from 'Funnel/components/FunnelRow';
import api from 'utils/api/ermes';
import {
  TableSynthesis,
  TableBodySynthesis,
  TableCellSynthesis,
  TableHeadSynthesis,
  TableRowSynthesis,
  Date
} from 'Funnel/components/FunnelRow/style';
import { ErrorMessage } from './style';
import { formatNumber } from '../../../../utils/functions';

/**
 * @component
 * @see AppFunnel
 * TODO::: move setConnector to Funnel/containers/ConnectorsContainer
 *
 */
function SynthesisContainer() {
  const { funnel, setFunnel, setIsLoading } = useFunnel();
  const { addConversionRate } = useFunnelGraph();
  if (
    !funnel.start ||
    !funnel.end ||
    !moment(funnel.start).isValid() ||
    !moment(funnel.end).isValid() ||
    moment(funnel.start).isAfter(moment(funnel.end))
  ) {
    return (
      <div className="kpi_table_description align_center">
        <ErrorMessage>
          {
            'Vos données ne nous permettent pas d’afficher la synthèse de votre funnel'
          }
        </ErrorMessage>
      </div>
    );
  }
  const { connected, objectifed } = funnel;
  const setObjectif = async (funnelId, value) => {
    setIsLoading(true);
    const objectifIndex = objectifed.findIndex(ob => ob.funnelId === funnelId);
    const nextObjectifed = [...objectifed];
    if (objectifIndex === -1) nextObjectifed.push({ funnelId, value });
    else nextObjectifed[objectifIndex].value = value;
    const newFunnel = await api.updateFunnelAnalysis({
      ...funnel,
      objectifed: nextObjectifed
    });
    setFunnel(newFunnel);
    setIsLoading(false);
  };
  const saveDataAnalyse = () => null;

  function getBudget() {
    if (!funnel || !funnel.funnelData || !funnel.funnelData.synthesis) return 0;

    const { budget = 0 } = funnel.funnelData.synthesis;

    return formatNumber(budget);
  }
  return (
    <React.Fragment>
      <Date>
        {`Du `}
        <strong>{moment(funnel.start).format('DD/MM/YYYY')}</strong>
        {' au '}
        <strong>{moment(funnel.end).format('DD/MM/YYYY')}</strong>
        {' (soit '}
        {formatNumber(
          moment
            .duration(moment(funnel.end).diff(moment(funnel.start)))
            .asDays(),
          0,
          0
        )}
        {` jours analysés)\nBudget : ${getBudget()} €`}
      </Date>
      <TableSynthesis>
        <TableHeadSynthesis>
          <TableRowSynthesis>
            <TableCellSynthesis />
            <TableCellSynthesis />
            <TableCellSynthesis>Réel</TableCellSynthesis>
            <TableCellSynthesis>Top</TableCellSynthesis>
            <TableCellSynthesis>Flop</TableCellSynthesis>
            <TableCellSynthesis>Objectif</TableCellSynthesis>
            <TableCellSynthesis />
          </TableRowSynthesis>
        </TableHeadSynthesis>
        <TableBodySynthesis>
          {funnel.measure
            ? funnel.measure.funnels.map((f, i) => {
                const objectifFunnel = Object.values(objectifed).find(
                  c => c.funnelId === f.id
                );
                /**
                 * Getting info from funnel context
                 */
                const kpis = ['kpi1', 'kpi2', 'kpi3', 'kpi4'];
                const funnelRowData =
                  funnel.funnelData && funnel.funnelData.synthesis
                    ? funnel.funnelData.synthesis[kpis[i]]
                    : {};
                let cr = [null, 'cr1_2', 'cr2_3', 'cr3_4'];
                cr =
                  i === 0
                    ? null
                    : funnel.funnelData &&
                      funnel.funnelData.synthesis &&
                      funnel.funnelData.synthesis[cr[i]];
                return (
                  <FunnelRow
                    key={f.id}
                    {...f}
                    addConversionRate={addConversionRate}
                    connected={connected}
                    funnel={funnel}
                    funnelRowData={funnelRowData}
                    cr={cr}
                    funnelIndex={i}
                    objectif={objectifFunnel ? objectifFunnel.value : null}
                    saveDataAnalyse={saveDataAnalyse}
                    setObjectif={setObjectif}
                  />
                );
              })
            : null}
        </TableBodySynthesis>
      </TableSynthesis>
    </React.Fragment>
  );
}

SynthesisContainer.defaultProps = {};

SynthesisContainer.propTypes = {};

export default SynthesisContainer;
