import React from 'react';
import styled from 'styled-components';
import { useTheme } from 'contexts/ThemeContext';
import Grid from 'components/Grid';

/**
 * Wrapper
 * @component
 *
 */
const Wrapper = styled.div`
  background-color: ${({ themeColors }) => themeColors.secondary};
  padding: 8px 16px;
  box-shadow: 0 1.5px 2px 0px ${({ themeColors }) => themeColors.shadow};
  margin-bottom: 2px;
  position: relative;
  z-index: 1400;
`;

/**
 * IndicatorsWrapper
 * @component
 *
 */
const IndicatorsWrapper = props => {
  const { themeColors } = useTheme();
  return (
    <Wrapper themeColors={themeColors}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        className="navbar funnel"
        bgColor={themeColors.secondary}
        {...props}
      />
    </Wrapper>
  );
};
export default IndicatorsWrapper;
