import React from 'react';
import PropTypes from 'prop-types';
import constants from 'utils/constants';
import {
  ActionButtonB,
  ActionSVGarrow,
  ActionSVGarrowBis
} from 'Funnel/components/ActionFunnel/style';
import BaseSidebarFunnel, { Separator } from './style';

const { panels } = constants;
/**
 * SidebarFunnel
 * @component
 *
 */
function SidebarFunnel(props) {
  const { children, handleRetargetting } = props;
  return (
    <BaseSidebarFunnel panel={panels.funnel}>
      {children}
      <Separator />
      <div style={{ marginBottom: '3%' }}>
        <ActionButtonB
          className="btn"
          title=""
          role="button"
          onClick={handleRetargetting}
        >
          <ActionSVGarrow />
          {"RETARGETER L'AUDIENCE"}
        </ActionButtonB>
      </div>
      <div style={{ marginBottom: '3%' }}>
        <ActionButtonB
          style={{
            backgroundColor: 'white',
            color: 'rgba(49, 39, 130, 0.8)'
          }}
          className="btn"
          title=""
          role="button"
          onClick={handleRetargetting}
        >
          <ActionSVGarrowBis />
          {'FAIRE DU LOOK ALIKE'}
        </ActionButtonB>
      </div>
    </BaseSidebarFunnel>
  );
}
SidebarFunnel.propTypes = {
  children: PropTypes.node.isRequired,
  handleRetargetting: PropTypes.func.isRequired
};

export { SideItemFunnel, ModalContent } from './style';
export default SidebarFunnel;
