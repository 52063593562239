import axios from 'axios';

/**
 * getNotifyParams
 * @param {String} name
 * @return {title, message, variants}
 *
 */
const getNotifyParams = {
  pdfPending: () => ({
    title: 'Génération du PDF',
    message: `Le PDF est en cours de création`,
    variants: 'info'
  }),
  pdfSuccess: name => ({
    title: 'Génération du PDF',
    message: `La génération du PDF ${name} est terminée.`,
    variants: 'success'
  }),
  pdfError: () => ({
    title: 'Génération du PDF',
    message: `La génération du PDF a échoué`,
    variants: 'error'
  }),
  noObjective: () => ({
    title: "Pas d'objectif renseigné",
    message: `Veuillez remplir les objectifs dans votre synthèse afin de générer le PDF`,
    variants: 'error'
  })
};

/**
 * @param activation
 * @returns {Promise<unknown>}
 * request api-pdf
 */

const getActivationPDF = activation => {
  const payload = JSON.stringify(activation);
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_PDF_API_URL}/activation/export-pdf`,
          {
            activation: payload
          },
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf'
            }
          }
        )
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export default function downloadPdfActivation(
  activation,
  notify,
  user,
  objective,
  name
) {
  notify(getNotifyParams.pdfPending());
  getActivationPDF({
    activation,
    objective,
    name,
    username: user.attributes['custom:nickname']
  })
    .then(response => {
      // force local PDF download
      const nameActivation = `Activation.pdf`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nameActivation);
      document.body.appendChild(link);
      link.click();
      notify(getNotifyParams.pdfSuccess(nameActivation));
    })
    .catch(e => {
      console.error(e);
      notify(getNotifyParams.pdfError());
    });
}
