import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { formatNumber } from 'utils/functions';
import { useSegment } from 'Activation/contexts/SegmentContext';

import WrapperIndicator from 'Activation/components/WrapperIndicator';
import LabelActivation from 'Activation/components/LabelActivation';
import DropdownActivation from 'Activation/components/DropdownActivation';
import DatePickerActivation from 'Activation/components/DatePickerActivation';
import InputActivation from 'Activation/components/InputActivation';
import ButtonActivation from 'Activation/components/ButtonActivation';

/**
 *
 * IndicatorContainer
 * @container
 *
 * @desc :: At te top in the second page, contains all indicators
 *
 */
function IndicatorContainer(props) {
  const { thirdContainer } = props;
  const {
    activationMeasures,
    budget,
    endDate,
    objective,
    setBudget,
    setObjective,
    setPeriod,
    startDate,
    nexusSize,
    results
  } = useSegment();
  const [changes, setChanges] = React.useState(false);
  const [localBudget, setLocalBudget] = React.useState('');
  const handleSave = () => {};
  const handleClickActivation = () => {
    if (changes) {
      setChanges(false);
    } else {
      handleSave();
      setChanges(true);
    }
  };
  const updateBudget = e => {
    const { value } = e.target;
    setLocalBudget(formatNumber(localBudget));
    setBudget(value);
  };
  const weekDiff = results.Brief.Weeks;
  React.useEffect(() => {
    setLocalBudget(formatNumber(budget));
  }, [budget]);
  return (
    <WrapperIndicator>
      <div className="item-indicator">
        <LabelActivation>OBJECTIF:</LabelActivation>
        {changes ? (
          <DropdownActivation
            fix
            onSelectChange={setObjective}
            options={activationMeasures.map(({ name }) => name)}
            selected={objective}
          />
        ) : (
          <LabelActivation fixed>{objective}</LabelActivation>
        )}
      </div>
      <div className="item-indicator">
        <LabelActivation>DATES DE CAMPAGNE:</LabelActivation>
        {changes ? (
          <DatePickerActivation
            endDate={endDate}
            setPeriod={setPeriod}
            startDate={startDate}
          />
        ) : (
          <LabelActivation fixed>
            {moment(startDate).format('DD/MM/YYYY')}
            &nbsp;{`->`}&nbsp;
            {moment(endDate).format('DD/MM/YYYY')}
          </LabelActivation>
        )}
        &nbsp;
        <LabelActivation>
          SOIT&nbsp;
          <b>
            <span className="colored">{weekDiff}</span>
          </b>
          &nbsp;SEMAINE
          {weekDiff > 1 && 'S'}
        </LabelActivation>
      </div>
      <div className="item-indicator">
        <LabelActivation>BUDGET ESTIMÉ:</LabelActivation>
        {changes ? (
          <InputActivation
            defaultValue={formatNumber(budget, 0)}
            onBlur={updateBudget}
            onChange={e => setLocalBudget(e.target.value)}
            suffix={<span>€</span>}
            value={localBudget}
          />
        ) : (
          <LabelActivation fixed>
            {`${formatNumber(budget, 0)}€`}
          </LabelActivation>
        )}
      </div>
      <div className="item-indicator">
        <LabelActivation>PROFILS:</LabelActivation>
        <LabelActivation bold>{formatNumber(nexusSize, 0)}</LabelActivation>
      </div>
      {thirdContainer ? (
        ''
      ) : (
        <div className="item-indicator">
          <ButtonActivation onClick={handleClickActivation} inversed medium>
            <span>{changes ? 'SAUVEGARDER' : 'MODIFIER'}</span>
          </ButtonActivation>
        </div>
      )}
    </WrapperIndicator>
  );
}
IndicatorContainer.defaultProps = {
  thirdContainer: false
};
IndicatorContainer.propTypes = {
  thirdContainer: PropTypes.bool
};

export default IndicatorContainer;
