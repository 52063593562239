import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

import BaseModal, { ModalContent } from './style';

/**
 * ModalActivation
 * @component
 *
 */
function ModalActivation(props) {
  const {
    children,
    label,
    handleValidation,
    validationLabel,
    handleClose,
    isOpen,
    hasButton,
    closeLabel,
    isInputEmpty,
    disableBackdropClick
  } = props;
  const [validateClicked, setValidateClicked] = React.useState(false);
  React.useEffect(() => {
    if (isOpen) {
      setValidateClicked(false);
    }
  }, [isOpen]);

  function handleValidationWrapper(...args) {
    setValidateClicked(true);
    handleValidation(...args);
  }
  return (
    <BaseModal
      aria-labelledby={`${label} Activation`}
      aria-describedby="Activation"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick={disableBackdropClick}
    >
      <ModalContent>
        <div className="children">{children}</div>
        {hasButton ? (
          <div className="buttons-group">
            <Button
              disabled={validateClicked}
              type="button"
              className="cancel"
              onClick={handleClose}
            >
              {closeLabel}
            </Button>
            <Button
              disabled={!isInputEmpty}
              type="button"
              className="save"
              onClick={handleValidationWrapper}
            >
              {validationLabel}
            </Button>
          </div>
        ) : null}{' '}
      </ModalContent>
    </BaseModal>
  );
}
ModalActivation.defaultProps = {
  isOpen: false,
  hasButton: true,
  closeLabel: 'Annuler',
  validationLabel: 'Valider',
  disableBackdropClick: false
};
ModalActivation.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  hasButton: PropTypes.bool,
  label: PropTypes.string.isRequired,
  closeLabel: PropTypes.string,
  validationLabel: PropTypes.string,
  isInputEmpty: PropTypes.bool.isRequired,
  disableBackdropClick: PropTypes.bool
};

export default ModalActivation;
