const colors = {
  default: '#4C4E58',
  green: '#3EAC7B',
  red: '#FF0000'
};

const conversionTab = [
  {
    title: [{ content: '', color: colors.default }],
    total: [{ content: 'Total', color: colors.default }],
    sexe: [
      { content: ' H  ', color: colors.default },
      { content: ' F  ', color: colors.default }
    ],
    age: [
      { content: '<25 ', color: colors.default },
      { content: ' 35 ', color: colors.default },
      { content: ' 45 ', color: colors.default },
      { content: ' 55 ', color: colors.default },
      { content: ' 65 ', color: colors.default },
      { content: '>65 ', color: colors.default }
    ],
    csp: [
      { content: ' -  ', color: colors.default },
      { content: ' +  ', color: colors.default }
    ],
    zone: [
      { content: ' C  ', color: colors.default },
      { content: ' P  ', color: colors.default },
      { content: ' R  ', color: colors.default }
    ]
  },
  {
    title: [
      {
        content: 'Visiteurs Homepage > Visites en magasin',
        color: colors.default
      }
    ],
    total: [{ content: '67%', color: colors.default }],
    sexe: [
      { content: '75%', color: colors.green },
      { content: '61%', color: colors.default }
    ],
    age: [
      { content: '0%', color: colors.red },
      { content: '57%', color: colors.red },
      { content: '55%', color: colors.red },
      { content: '72%', color: colors.default },
      { content: '77%', color: colors.green },
      { content: '58%', color: colors.red }
    ],
    csp: [
      { content: '63%', color: colors.default },
      { content: '71%', color: colors.default }
    ],
    zone: [
      { content: '64%', color: colors.default },
      { content: '64%', color: colors.default },
      { content: '69%', color: colors.default }
    ]
  },
  {
    title: [
      {
        content: 'Visites en magasin > Ventes en magasin',
        color: colors.default
      }
    ],
    total: [{ content: '47%', color: colors.default }],
    sexe: [
      { content: '49%', color: colors.default },
      { content: '45%', color: colors.default }
    ],
    age: [
      { content: '0%', color: colors.red },
      { content: '47%', color: colors.default },
      { content: '47%', color: colors.default },
      { content: '52%', color: colors.green },
      { content: '49%', color: colors.default },
      { content: '13%', color: colors.red }
    ],
    csp: [
      { content: '47%', color: colors.default },
      { content: '47%', color: colors.default }
    ],
    zone: [
      { content: '54%', color: colors.green },
      { content: '56%', color: colors.green },
      { content: '38%', color: colors.red }
    ]
  }
];

const fillTab = objectif => {
  const res = conversionTab;
  res[1].title[0].content = `${objectif[0]} > ${objectif[1]}`;
  res[2].title[0].content = `${objectif[1]} > ${objectif[2]}`;
  return res;
};

export default fillTab;
