import React from 'react';
import PropTypes from 'prop-types';

import BaseHiderFunnel from './style';

/**
 * HiderFunnel
 * @component
 *
 */
function HiderFunnel({ children, hide }) {
  return <BaseHiderFunnel hide={hide}>{children}</BaseHiderFunnel>;
}

HiderFunnel.defaultProps = {};
HiderFunnel.propTypes = {
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool.isRequired
};

export default HiderFunnel;
