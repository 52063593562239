import React from 'react';

import OperatedCategoryInsight from 'Insight/components/OperatedCategoryInsight';
import OperatorLayoutInsight from 'Insight/components/OperatorLayoutInsight';
import OperatedCriteriaInsight from 'Insight/components/OperatedCriteriaInsight';
import OperatedGroupInsight from 'Insight/components/OperatedGroupInsight';
import OperatorInsight from 'Insight/components/OperatorInsight';
import { useOperator } from 'Insight/contexts/OperatorContext';
import constants from 'utils/constants';
import { useCategory } from 'Insight/contexts/CategoryContext';
import LoadingInsight from 'Insight/components/LoadingInsight/index';

const { insightCriteriaTypes } = constants;
const { OR, AND } = insightCriteriaTypes;
const getLabel = type => {
  const typeName = type.NAME.toLowerCase();
  if (typeName === OR.NAME.toLowerCase()) return OR.LABEL;
  if (typeName === AND.NAME.toLowerCase()) return AND.LABEL;
  return '--';
};
const getOperatorLabels = () => [OR, AND].map(elem => elem.LABEL);

const extractSubGroup = (groups, categoryKEY) => {
  const entry = Object.entries(groups).find(arr => arr[0] === categoryKEY);
  if (!entry) return [];
  const objSubGroups = entry[1];
  return Object.entries(objSubGroups).filter(arr => arr[1].values.length) || [];
};
const formatNameCategory = {
  age: value => `${value.name} Ans`,
  enfants: value =>
    value.key === 'C_374' || value.key === 'C_15'
      ? `${value.name} Enfants`
      : value.name,
  relation: value =>
    value.key === 'C_17' ? `Personne ${value.name}` : value.name,
  beaute: value => {
    if (value.key === 'C_220') return `Beauté ${value.name}`;
    if (value.key === 'C_221') return 'Parfum et Maquillage Femme';
    if (value.key === 'C_222') return 'Soins Femme';
    if (value.key === 'C_223') return 'Soins Corps Femme';
    if (value.key === 'C_224') return 'Soins cheveux Femme';
    if (value.key === 'C_226') return 'Parfum Femme';
    return value.name;
  },
  jeuxEtJouets: value =>
    value.key !== 'C_266' ? `Jeux & Jouets ${value.name}` : value.name,
  voyage: value => {
    if (value.key === 'C_285') return `Voyage ${value.name}`;
    if (value.key === 'C_286') return `Voyage en ${value.name}`;
    if (value.key === 'C_287') return `${value.name} Voyage`;
    return value.name;
  },
  luxe: value =>
    value.key === 'C_313' || value.key === 'C_317' || value.key === 'C_318'
      ? `${value.name} de Luxe`
      : value.name,
  auto: value => {
    if (value.key === 'C_344') return `Auto ${value.name}`;
    if (value.key === 'C_345') return `Auto ${value.name}`;
    if (value.key === 'C_346') return `Auto ${value.name}`;
    if (value.key === 'C_348') return `Auto ${value.name}`;
    if (value.key === 'C_350') return `Auto ${value.name}`;
    if (value.key === 'C_352') return `Auto ${value.name}`;
    return value.name;
  }
};

const formatName = (value, subCategoryKEY) => {
  if (formatNameCategory[subCategoryKEY] !== undefined)
    return formatNameCategory[subCategoryKEY](value);
  return value.name;
};

/**
 * OperatorContainer
 * @component
 * @see AppInsight
 *
 */
function OperatorContainer() {
  const {
    operatorsGroups,
    handleSelectedChange,
    handleSubGroupChange,
    toggleValueSelected
  } = useOperator();
  const { categories, categoriesLoading, categoriesError } = useCategory();
  const handleChange = params => {
    handleSelectedChange(params);
  };
  const operatorOptions = getOperatorLabels();
  if (categoriesLoading) return <LoadingInsight loading refetch={() => {}} />;
  if (categoriesError) return <p>Error when loading categories</p>;
  return (
    <OperatorLayoutInsight length={categories.length}>
      {categories.map(({ key: categoryKEY }, catIndex) => (
        <OperatedCategoryInsight key={categoryKEY} rounded={catIndex === 1}>
          {extractSubGroup(operatorsGroups, categoryKEY).map(
            ([subCategoryKEY, subGroup], indexSub) => (
              <React.Fragment key={subCategoryKEY}>
                <OperatorInsight
                  operator={getLabel(subGroup.type)}
                  rounded
                  hide={indexSub === 0}
                  handleChange={() =>
                    handleSubGroupChange({
                      subCategoryKEY,
                      categoryKEY
                    })
                  }
                  options={operatorOptions}
                />
                <OperatedGroupInsight>
                  {subGroup.values.map((value, valueIndex) => (
                    <React.Fragment key={value.key}>
                      <OperatorInsight
                        operator={getLabel(value.type)}
                        hide={valueIndex === 0}
                        handleChange={() =>
                          handleChange({
                            categoryKEY,
                            subCategoryKEY,
                            position: value.position,
                            value,
                            isTypeChange: true
                          })
                        }
                        options={operatorOptions}
                      />
                      <OperatedCriteriaInsight
                        title={formatName(value, subCategoryKEY)}
                        isExcluded={value.exclude}
                        isFirst={value.position === 0}
                        isLast={value.position === subGroup.values.length - 1}
                        toggleExclude={() =>
                          handleChange({
                            categoryKEY,
                            value,
                            subCategoryKEY,
                            position: value.position
                          })
                        }
                        removeCriteria={e => {
                          e.stopPropagation();
                          toggleValueSelected({
                            criteria: value,
                            subCategoryKEY,
                            categoryKEY
                          });
                        }}
                      />
                      {/* Criteria ending */}
                    </React.Fragment>
                  ))}
                </OperatedGroupInsight>
                {/* SubCategory Ending */}
              </React.Fragment>
            )
          )}
        </OperatedCategoryInsight>
      ))}
    </OperatorLayoutInsight>
  );
}

export default OperatorContainer;
