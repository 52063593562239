import React from 'react';
import PropTypes from 'prop-types';
import IndicatorsWrapper from './style';

/**
 * IndicatorFunnel
 * @component
 *
 */
function IndicatorsFunnel(props) {
  const { children } = props;
  return <IndicatorsWrapper>{children} </IndicatorsWrapper>;
}
IndicatorsFunnel.propTypes = {
  children: PropTypes.node.isRequired
};

export default IndicatorsFunnel;
