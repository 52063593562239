import styled from 'styled-components';
import { Grid } from '@material-ui/core';

/**
 *
 */
const BlockGrid = styled(Grid)`
  && {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 20px;
    max-width: 1200px;
  }
`;

export default BlockGrid;
