/* eslint-disable prefer-const */
import React from 'react';

const DataCibleContext = React.createContext();

function DataCibleProvider(props) {
  const data = {
    nbProfile: undefined,
    isSegmentSet: false
  };
  const setNbProfileData = nb => {
    data.nbProfile = nb;
  };
  const setIsSegmentSetData = value => {
    data.isSegmentSet = value;
  };

  const value = {
    data,
    setNbProfileData,
    setIsSegmentSetData
  };

  return <DataCibleContext.Provider {...props} value={value} />;
}

export const useDataCible = () => {
  const context = React.useContext(DataCibleContext);
  if (!context)
    throw new Error('DataCibleCtx must be called in DataCibleProvider');
  return context;
};

export default DataCibleProvider;
