import React from 'react';
import { withRouter } from 'react-router-dom';
import Home from 'components/Home';
import ErmesApi from 'utils/api/ermes';

/**
 * @component Get account data from third party.
 * i.e. Get accounts and campaigns from Facebook
 * @param {children} param0
 */
function OauthContainer() {
  const getAccountInfo = () => {
    ErmesApi.getCampaigns()
      .then(response => response.data)
      .then(data => {
        window.opener.setCredentialsAfterOauth({ connector: 'Facebook', data });
      })
      .catch(err => {
        console.error('TODO Error', err);
      });
  };
  React.useEffect(() => {
    if (window.opener) {
      getAccountInfo();
    }
  }, []);
  return (
    <Home>
      <div>Connection...</div>
    </Home>
  );
}
export default withRouter(OauthContainer);
