import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseAudiencesChoiceWrapper
 * @component
 *
 */
const BaseAudiencesChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  .row-audience {
    display: grid;
    grid-template-columns: 1fr 4px 1fr;
    height: 80px;
    > *:first-child:after {
      border-right: 1px solid ${({ themeColors }) => themeColors.light};
    }
    @media (min-width: 1024px) {
      padding: 0 12%;
    }
  }
  .column-audience {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
  }
  hr {
    border-color: ${({ themeColors }) => themeColors.activation_inversed};
  }
  .column-error {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    font-size: 14px;
    color: #eb5757;
    padding-top: 20px;
  }
`;

export default withTheme(BaseAudiencesChoiceWrapper);
