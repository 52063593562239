import React from 'react';
// import PropTypes from 'prop-types'

import BaseTableInsight from './style';

/**
 * TableInsight
 * @component
 *
 */
function TableInsight(props) {
  return <BaseTableInsight {...props} />;
}
TableInsight.defaultProps = {};
TableInsight.propTypes = {};

export { TableRowInsight, TableItemInsight, TableBodyInsight } from './style';

export default TableInsight;
