import React from 'react';
// import PropTypes from 'prop-types'

import BaseConnectorForm from './style';

/**
 * ConnectorFormFunnel
 * @component
 *
 */
function ConnectorFormFunnel(props) {
  return <BaseConnectorForm {...props} />;
}
ConnectorFormFunnel.defaultProps = {};
ConnectorFormFunnel.propTypes = {};

export default ConnectorFormFunnel;
