import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseHider
 * @component
 *
 */
const BaseHider = styled.div`
  position: relative;
  width: 100%;
  .hide-block {
    height: ${({ hide }) => (hide ? '100%' : '0')};
    background-color: ${({ themeColors }) => themeColors.primary};
    position: absolute;
    width: 100%;
    bottom: 0;
    opacity: 0.9;
    transition: height 0.5s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  }
`;

export default withTheme(BaseHider);
