import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { getFilenameFromHistory } from 'Data/utils/functions';
import { formatNumber } from 'utils/functions';

import { useTheme } from 'src/contexts/ThemeContext';
import Text from 'src/components/Text';
import { BaseButton, ConnectorSVG } from './ButtonUploadFile';
import ColorLinearProgress from './ColorLinearProgress';
import { FileUploaded } from './style';

function ErrorText() {
  return (
    <Text textAlign="center">
      {
        "Il y a eu un problème lors de l'ajout du fichier. Votre fichier a-t-il le bon format?"
      }
    </Text>
  );
}

function HistoryText({ historyName }) {
  return (
    <Text textAlign="center">
      Uploader à nouveaux votre fichier {`"`}
      <i>{getFilenameFromHistory(historyName)}</i>
      {`"`} afin de reprendre le traitement là où il
      {` s'était arrêté`}
    </Text>
  );
}
HistoryText.defaultProps = {
  historyName: null
};
HistoryText.propTypes = {
  historyName: PropTypes.string
};

/**
 * InputUploadFile
 * @component
 */
export default function InputUploadFile({
  onChange,
  progress,
  error,
  status,
  historyName,
  ...rest
}) {
  const [fileName, setFileName] = React.useState(null);
  const { themeColors } = useTheme();
  const handleCsv = d => {
    const file = d.target.files[0];
    setFileName(file.name);
    onChange(file);
  };

  return (
    <div
      style={{
        border: `0.5px solid ${themeColors.keep_dark}`,
        boxSizing: 'border-box',
        padding: '16px',
        color: themeColors.light,
        borderColor: themeColors.light
      }}
    >
      {!fileName ? (
        <Grid
          container
          justify="space-around"
          direction="column"
          alignItems="center"
        >
          <BaseButton {...rest} variant="contained" component="label">
            <ConnectorSVG />
            <strong>Ajouter un fichier</strong>
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={handleCsv}
            />
          </BaseButton>
          {historyName ? (
            <HistoryText historyName={historyName} />
          ) : (
            <Text>Aucun fichier</Text>
          )}
        </Grid>
      ) : (
        <Grid
          container
          justify="space-around"
          direction="column"
          alignItems="center"
        >
          <Text>
            {fileName}
            {progress === 100 && <FileUploaded icon="checkMark" />}
          </Text>
          {error ? (
            <ErrorText error={error} />
          ) : (
            progress < 100 && (
              <>
                <Text style={{ color: themeColors.data }}>
                  {formatNumber(progress, 0)}%
                </Text>
                <ColorLinearProgress
                  variant="determinate"
                  value={progress}
                  style={{
                    marginTop: 16,
                    width: '50%',
                    height: 10
                  }}
                />
                {status && (
                  <Text style={{ color: themeColors.data }}>{status}</Text>
                )}
              </>
            )
          )}
        </Grid>
      )}
    </div>
  );
}
InputUploadFile.defaultProps = {
  error: null,
  status: 'uploading',
  historyName: null
};
InputUploadFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  error: PropTypes.shape({}),
  historyName: PropTypes.string,
  status: PropTypes.string
};
