import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import client from 'utils/api/graphql';

import constants from 'utils/constants';
import Icon from 'utils/icons';
import { formatNumber } from 'utils/functions';
import { useModalActivation } from 'Activation/contexts/ModalActivationContext';
import { useSegment } from 'Activation/contexts/SegmentContext';

import ButtonActivation from 'Activation/components/ButtonActivation';
import LabelActivation from 'Activation/components/LabelActivation';
import LoadingActivation from 'Activation/components/LoadingActivation';
import SeparatorActivation from 'Activation/components/SeparatorActivation';
import TableActivation from 'Activation/components/TableActivation';
import TemplateImport from 'Activation/components/TemplateImport';
import TitleActivation from 'Activation/components/TitleActivation';

const GET_INSIGHTS = gql`
  {
    insightTargets {
      id
      name
      result {
        nbProfiles
      }
      filters
      updatedAt
    }
  }
`;

const extractInsight = data => (data && data.insightTargets) || [];
const HEADS = ['Nom de la cible', 'Contacts', 'Date de sauvegarde'];
const { routes } = constants;

/**
 * ImportInsightContainer
 *
 * @container
 *
 * @desc ::
 *      Dislayed in a modal called at the first page, allow to import Insight
 *
 * Called by @see Activation/containers/AudiencesChoiceContainer
 * Displayed in @see Activation/containers/ModalSwitchContainer
 *
 * @return {null}
 */
function ImportInsightContainer({ history }) {
  const { isTemplateInsight, closeModal } = useModalActivation();
  const { setSegmentProfiles, setFilters } = useSegment();
  const response = useQuery(GET_INSIGHTS, { client, fetchPolicy: 'no-cache' });
  const [selected, setSelected] = React.useState({});
  const handleSelect = insight => {
    setSelected(insight);
    setFilters(insight.filters);
  };
  const handleAdd = () => history.push(routes.INSIGHT);
  const handleValidate = () => {
    setSegmentProfiles(selected.result.nbProfiles);
    closeModal();
  };
  return !isTemplateInsight ? null : (
    <TemplateImport>
      <TitleActivation medium left bold>
        IMPORTEZ UN SEGMENT SAUVEGARDÉ À PARTIR DES AUDIENCES ERMES
      </TitleActivation>
      <LabelActivation>
        Sélectionnez un segment existant dans la liste ci-dessous :
      </LabelActivation>
      <TableActivation.Container>
        <TableActivation.RowHead length={HEADS.length} isHead>
          {HEADS.map(headTitle => (
            <TableActivation.Item key={headTitle} left>
              {headTitle}
            </TableActivation.Item>
          ))}
        </TableActivation.RowHead>
        <TableActivation.Body>
          <LoadingActivation {...response}>
            {extractInsight(response.data).length === 0 && (
              <LabelActivation>
                <span>Aucun historique actuellement enregistré.</span>
              </LabelActivation>
            )}
            {extractInsight(response.data).map(insight => {
              return (
                <TableActivation.RowBody
                  key={insight.id}
                  length={HEADS.length}
                  selected={selected.id === insight.id}
                >
                  <TableActivation.Item
                    onClick={() => handleSelect(insight)}
                    left
                  >
                    {insight.name}
                  </TableActivation.Item>
                  <TableActivation.Item
                    onClick={() => handleSelect(insight)}
                    left
                  >
                    {formatNumber(insight.result.nbProfiles, 0)}
                  </TableActivation.Item>
                  <TableActivation.Item
                    onClick={() => handleSelect(insight)}
                    left
                  >
                    {new Date(insight.updatedAt).toLocaleDateString()}
                  </TableActivation.Item>
                </TableActivation.RowBody>
              );
            })}
          </LoadingActivation>
        </TableActivation.Body>
      </TableActivation.Container>
      <div className="row-import">
        <SeparatorActivation>OU</SeparatorActivation>
      </div>
      <div className="row-import">
        <ButtonActivation onClick={handleAdd}>
          <Icon icon="add" />
          <span>Nouveau segment</span>
        </ButtonActivation>
      </div>
      <div className="row-import">
        <small>
          <i>Vous serez redirigé vers le module INSIGHT</i>
        </small>
      </div>
      <div className="row-import bottom">
        <ButtonActivation onClick={closeModal} inversed>
          <span>ANNULER</span>
        </ButtonActivation>
        <ButtonActivation onClick={handleValidate} disabled={!selected.id}>
          <span>SUIVANT</span>
        </ButtonActivation>
      </div>
    </TemplateImport>
  );
}
ImportInsightContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(ImportInsightContainer);
