import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseEvolution
 * @component
 * @see LayoutFunnel (overwritten by styles in LayoutFunnel)
 * TODO:: move LayoutFunnel style here
 *
 */
const BaseEvolution = styled.div`
  .filter-headers {
    display: inline-flex;
    font-size:14px;
    grid-column-gap: 4px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    padding: 0 8px;
    .btn {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid ${({ themeColors }) => themeColors.light};
      cursor: pointer;
      margin: 0 5px;
      text-align: center;
      color ${({ themeColors }) => themeColors.light};
    }
    .btn:hover {
      border-color: ${({ themeColors }) => themeColors.funnel};
      color: ${({ themeColors }) => themeColors.funnel};
    }
    @media(min-width:1800px){
      font-size: 16px;
    }
  }
  .filter-headers.bis {
    grid-template-columns: 170px 170px 170px 170px;
  }
  .graph_container {
  }
;`;

export default withTheme(BaseEvolution);
