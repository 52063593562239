import React from 'react';

import BaseMatchingVisualization from './style';

/**
 * WrapperMatchingVisualization
 * @component
 *
 */
function WrapperMatchingVisualization(props) {
  return <BaseMatchingVisualization {...props} />;
}
WrapperMatchingVisualization.defaultProps = {};
WrapperMatchingVisualization.propTypes = {};

export default WrapperMatchingVisualization;
