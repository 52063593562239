import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BaseCheckbox from './style';

/**
 * Checkbox
 * @component
 *
 */
function Checkbox(props) {
  const { checked, label, onChange } = props;
  const { themeColors } = useTheme();
  const handleChange = () => {
    onChange(!checked);
  };
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <BaseCheckbox
            themeColors={themeColors}
            checked={checked}
            onChange={handleChange}
            value="checkedB"
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
Checkbox.defaultProps = {
  checked: false,
  label: ''
};
Checkbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default Checkbox;
