import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';
import {
  TableContainer,
  TableRowHead,
  TableRowBody,
  TableContainerBody,
  BaseTableItem
} from './style';

/**
 * TableItem
 * @component
 *
 */
const TableItem = props => {
  const { left, error, ...rest } = props;
  const styledProps = error ? { color: 'red' } : {};
  if (left) {
    styledProps.className = 'left';
  }
  return <BaseTableItem {...styledProps} {...rest} />;
};
TableItem.defaultProps = { left: false, error: false };
TableItem.propTypes = { left: PropTypes.bool, error: PropTypes.bool };

/**
 * RowHead
 * @component
 *
 */
const RowHead = props => {
  const { themeColors } = useTheme();
  const { colored, ...rest } = props;
  const styledProps = {
    bgcolor: 'transparent',
    bordercolor: 'transparent',
    borderbottomcolor: themeColors.light
  };
  if (colored) {
    styledProps.bgcolor = themeColors.tertiary;
    styledProps.bordercolor = themeColors.activation_inversed;
    styledProps.borderbottomcolor = themeColors.activation_inversed;
  }
  return (
    <TableRowHead
      {...styledProps}
      style={{ borderBottomWidth: '2px' }}
      {...rest}
    />
  );
};
RowHead.defaultProps = { colored: false };
RowHead.propTypes = { colored: PropTypes.bool };

/**
 * RowBody
 * @component
 *
 */
const RowBody = props => {
  const { themeColors } = useTheme();
  const { selected, itemCenter, ...rest } = props;
  const styledProps = {
    bgcolor: 'transparent',
    borderleftcolor: 'transparent',
    bgcolorhover: themeColors.secondary,
    paddingleft: '10%',
    justify: 'flex-start'
  };
  if (selected) {
    styledProps.bgcolor = themeColors.secondary;
    styledProps.borderleftcolor = themeColors.activation_inversed;
  }
  if (itemCenter) {
    styledProps.paddingleft = '0';
    styledProps.justify = 'center';
  }
  return <TableRowBody {...styledProps} {...rest} />;
};
RowBody.defaultProps = { selected: false, itemCenter: false };
RowBody.propTypes = { selected: PropTypes.bool, itemCenter: PropTypes.bool };

/**
 * Result
 * @component
 *
 */
const Result = props => {
  const styledProps = {
    bgcolor: 'transparent',
    bgcolorhover: 'transparent',
    borderbottomcolor: 'transparent',
    paddingleft: '10%',
    justify: 'flex-start'
  };
  return <TableRowHead {...styledProps} {...props} />;
};

/**
 * TableActivatikon
 * Object
 *
 */
const TableActivation = {
  Container: TableContainer,
  Body: TableContainerBody,
  RowHead,
  RowBody,
  Result,
  Item: TableItem
};
export default TableActivation;
