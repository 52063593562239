/**
 * colors
 *
 */
export default {
  dark: {
    primary_header: '#1e1e2f',
    primary: '#1e1e2f',
    secondary: '#383B50',
    tertiary: '#5A5A76',
    funnel: '#009fe3',
    funnel_dark: '#0086bf',
    funnel_baseline1: '#4C4E58',
    funnel_baseline2: '#f2f2f2',
    funnel_baseline3: '#009fe3',
    funnel_baseline4: '#999999',
    activation: '#3EAC7B',
    dark: '#000000',
    light: '#ffffff',
    red: '#FF0000',
    success: '#28A745',
    danger: '#EB5757',
    data: '#e5007d',
    data_result_label: '#e5007d',
    data_result_value: '#fff',
    data_action_text: '#fff',
    data_action_border: '#fff',
    insight: '#ef7d25',
    insight_secondary: '#383B50',
    insight_tertiary: '#5A5A76',
    insight_result_label: '#ef7d25',
    insight_result_value: '#fff',
    insight_action: '#fff',
    insight_action_text: '#fff',
    insight_action_border: '#fff',
    insight_top_border: '#fff',
    data_button_border_bg: '#fff',
    shadow: 'transparent',
    baseline_funnel: '#009fe3',
    baseline_funnel_inversed: '#ffffff',
    keep_light: '#ffffff',
    keep_dark: '#000000',
    icon: '#EEEEEE',
    icon_insight: '#EEEEEE',
    activation_inversed: '#3EAC7B'
  },
  light: {
    primary_header: '#1e1e2f',
    primary: '#eef4f9',
    secondary: '#ffffff',
    tertiary: '#f2f2f2',
    funnel: '#009fe3',
    funnel_dark: '#0086bf',
    funnel_baseline1: '#4C4E58',
    funnel_baseline2: '#f2f2f2',
    funnel_baseline3: '#009fe3',
    funnel_baseline4: '#999999',
    activation: '#3EAC7B',
    dark: '#ffffff',
    light: '#4C4E58',
    red: '#FF0000',
    success: '#28A745',
    danger: '#EB5757',
    data: '#e5007d',
    data_result_label: '#000000',
    data_result_value: '#e5007d',
    data_action_text: '#e5007d',
    data_action_border: '#e5007d',
    insight: '#ef7d25',
    insight_secondary: '#e0e0e0',
    insight_tertiary: '#C8C5C5',
    insight_result_label: '#000000',
    insight_result_value: '#ef7d25',
    insight_action_text: '#ef7d25',
    insight_action_border: '#ef7d25',
    insight_top_border: 'transparent',
    data_button_border_bg: 'transparent',
    shadow: 'lightgrey',
    baseline_funnel: '#ffffff',
    baseline_funnel_inversed: '#009fe3',
    keep_light: '#ffffff',
    keep_dark: '#4C4E58',
    icon: '#4C4E58',
    icon_insight: '#C8C5C5',
    activation_inversed: '#3EAC7B'
  }
};
