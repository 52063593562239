import React from 'react';
import PropTypes from 'prop-types';
import LoggedHeader from './LoggedHeader';

/**
 * @component
 * @desc there is one header LoggedHeader for logged users .
 * @desc this header has two state of display :  onScroll and notScrolled state.
 */
function Header(props) {
  const { isConnected, userLocation, setUserLocation } = props;
  return (
    <React.Fragment>
      {(isConnected && (
        <LoggedHeader
          {...props}
          imageName="/assets/img/logo_ermes_homepage.svg"
          userLocation={userLocation}
          setUserLocation={setUserLocation}
        />
      )) ||
        ''}
    </React.Fragment>
  );
}

Header.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  userLocation: PropTypes.string.isRequired,
  setUserLocation: PropTypes.func.isRequired
};

export default Header;
