import React from 'react';
import PropTypes from 'prop-types';
import ClickableText from './clickableText';
import { ConvTable, ConvRowColumn, SegmentNectarSubTitle } from './style';

function SegmentNectar(props) {
  const { objectif, profiles, offers, visites } = props;

  const tableData = [
    {
      title: `${objectif[0]} > ${objectif[1]}`,
      total: 67,
      nectar: 79
    },
    {
      title: `${objectif[1]} > ${objectif[2]}`,
      total: 47,
      nectar: 50
    },
    { title: 'Total', total: 20, nectar: 29 }
  ];

  return (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        Sur base des 360 critères de Taxonomie Ermes, nous avons identifié un
        Segment Nectar.
      </div>
      <div style={{ textAlign: 'center' }}>
        Ce Segment Nectar que vous pouvez consulter{' '}
        <ClickableText text="ici" href="/insight" /> représente {offers}% des
        offres effectuées pour {visites}% des visites home page.
      </div>
      <br />
      <div style={{ textAlign: 'center' }}>
        Ce segment représente{' '}
        <span style={{ color: '#009FE3' }}>{profiles}</span> profils encore non
        touchés par votre campagne dans la base Ermes.
      </div>
      <div style={{ textAlign: 'center' }}>
        Vous pouvez <ClickableText text="cliquer ici" href="/activation" /> pour
        activer ce segment.
      </div>
      <br />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ConvTable>
          <tr>
            <th />
            <th style={{ color: '#009FE3', fontFamily: 'Roboto' }}>Total</th>
            <th style={{ color: '#009FE3', fontFamily: 'Roboto' }}>Nectar</th>
          </tr>
          <tbody>
            {tableData.map(columns => (
              <tr>
                <ConvRowColumn>{columns.title}</ConvRowColumn>
                <ConvRowColumn style={{ textAlign: 'center' }}>
                  {columns.total}%
                </ConvRowColumn>
                <ConvRowColumn
                  style={{ textAlign: 'center', color: '#3eac7b' }}
                >
                  {columns.nectar}%
                </ConvRowColumn>
              </tr>
            ))}
          </tbody>
        </ConvTable>
      </div>
      <br />
      <div style={{ textAlign: 'center' }}>
        <SegmentNectarSubTitle>
          Les analystes ERMES sont à votre disposition pour toute question ou
          demande complémentaire
        </SegmentNectarSubTitle>
      </div>
    </React.Fragment>
  );
}

SegmentNectar.propTypes = {
  objectif: PropTypes.arrayOf(PropTypes.string).isRequired,
  profiles: PropTypes.string.isRequired,
  offers: PropTypes.number.isRequired,
  visites: PropTypes.number.isRequired
};

export default SegmentNectar;
