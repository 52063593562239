import React from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks';

import Loading from 'src/components/Loading';

import { ACTIVATION } from 'utils/graphQL';
import { useUser } from 'src/contexts/UserContext';
import HistoryActivation, {
  HistoryActivationItem
} from 'Activation/components/HistoryActivation';

/**
 * HistoryActivationContainer
 * @component
 * @see HistoryContainer
 *
 */
function HistoryActivationContainer() {
  const { isAuthenticated } = useUser();
  const response = useQuery(ACTIVATION.GET_ACTIVATIONS, {
    fetchPolicy: 'no-cache',
    skip: !isAuthenticated
  });
  const [deleteActivation] = useMutation(ACTIVATION.DELETE_ACTIVATION);
  if (response.loading) {
    return (
      <Loading
        loading={response.loading}
        src="/assets/img/loader_activation.svg"
      />
    );
  }
  if (response.error) {
    throw response.error;
  }
  const historyActivation = (response.data && response.data.activations) || [];
  return (
    <HistoryActivation>
      {historyActivation
        .sort((a, b) => b.createdAt - a.createdAt)
        .map(activation => (
          <HistoryActivationItem
            key={activation.id}
            activation={activation}
            deleteActivation={deleteActivation}
            refetch={response.refetch}
          />
        ))}
    </HistoryActivation>
  );
}

export default HistoryActivationContainer;
