import React from 'react';
// import PropTypes from 'prop-types';

import { SVG, MainNode } from './style';

/**
 * PlaneMap
 * @component
 *
 */
function PlaneMap(props) {
  return (
    <SVG>
      <MainNode {...props} />
    </SVG>
  );
}
PlaneMap.defaultProps = {};
PlaneMap.propTypes = {};

export default PlaneMap;

export { Path } from './style';
export { default as frenchPaths } from './map';
