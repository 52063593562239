import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/react-hooks';
import configuredGraphqlClient from 'utils/api/graphql';
import { withRouter } from 'react-router-dom';

import ErrorBoundary from '../../ErrorBoundary';
import AppData from './AppData';
import DataProvider from './contexts/DataContext';

/**
 * Data
 * @component
 * @desc :: Manage Data App part
 * @uri :: /data
 */
function DataModule({ match }) {
  const { id } = match.params;
  return (
    <ErrorBoundary>
      <ApolloProvider client={configuredGraphqlClient}>
        <DataProvider filename={id}>
          <AppData />
        </DataProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}
DataModule.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(DataModule);
