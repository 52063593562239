import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  ConnectorContainer,
  doneButton,
  imgStyle,
  linkConnector,
  linkConnectorItem,
  modalHeader,
  nameStyle,
  nameStyleValid
} from './style';

/**
 * ConnectorImg
 * @component
 *
 */
export const ConnectorImg = props => {
  const { id, valid, sources, connected } = props;
  const source = _.find(sources, s => s.id === id);
  return (
    <ConnectorContainer isValid={connected[valid]}>
      <img src={source.src} alt={source.name} style={imgStyle} />
    </ConnectorContainer>
  );
};
ConnectorImg.propTypes = {
  connected: PropTypes.arrayOf(PropTypes.any).isRequired,
  id: PropTypes.number.isRequired,
  valid: PropTypes.number.isRequired,
  sources: PropTypes.arrayOf(PropTypes.any).isRequired
};

/**
 * ConnectorName
 * @component
 *
 */
export const ConnectorName = props => {
  const { id, valid, sources, connected } = props;
  const source = _.find(sources, s => s.id === id);
  if (connected[valid]) {
    return (
      <p style={nameStyleValid} className="connector_title">
        {source.name}
      </p>
    );
  }
  return (
    <p style={nameStyle} className="connector_title">
      {source.name}
    </p>
  );
};
ConnectorName.propTypes = {
  connected: PropTypes.arrayOf(PropTypes.any).isRequired,
  id: PropTypes.number.isRequired,
  valid: PropTypes.number.isRequired,
  sources: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default function ConnectorModalList(props) {
  const { connected, connectors, sources, closeModal, handleConnector } = props;

  return (
    <React.Fragment>
      <div style={modalHeader} className="modal-header">
        <h5
          style={{
            textAlign: 'center',
            fontSize: '20px'
          }}
          className="modal-title align_center"
        >
          Choisir mes connecteurs
        </h5>
      </div>
      <div
        style={linkConnector}
        className="flex_container modal_connector_container connectorGrid"
      >
        {connectors.map((c, i) => (
          <div
            className={`modal_connector ${connected[i] ? 'valid' : ''}`}
            key={c}
            style={linkConnectorItem}
            onClick={() => handleConnector(c)}
            onKeyPress={() => handleConnector(c)}
            role="button"
            tabIndex={i}
          >
            {connected[i] ? (
              <img
                className="valid_icon"
                src="/assets/img/picto_checked.svg"
                alt=""
              />
            ) : (
              ''
            )}
            <ConnectorImg
              id={c}
              valid={i}
              sources={sources}
              connected={connected}
            />
            <ConnectorName
              id={c}
              valid={i}
              sources={sources}
              connected={connected}
            />
          </div>
        ))}
      </div>
      <button
        style={doneButton}
        className="btn"
        onClick={closeModal}
        type="button"
      >
        J’ai fini
      </button>
    </React.Fragment>
  );
}

ConnectorModalList.propTypes = {
  connected: PropTypes.arrayOf(PropTypes.bool).isRequired,
  connectors: PropTypes.arrayOf(PropTypes.number).isRequired,
  sources: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleConnector: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};
