import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React from 'react';
import constants from 'utils/constants';
import moment from 'utils/moment';

import ModalHistoryActivation from 'Activation/components/ModalHistoryActivation';
import DeleteModalContent from './DeleteModalContent';

import TableActivation, {
  Buttons,
  Icons,
  TableRowActivation,
  TableBodyActivation,
  TableItemActivation
} from './style';

const { routes } = constants;
const TABLE_LENGTH = 6;

/**
 * HistoryActivation
 * @component
 * @see HistoryActivationContainer
 *
 */
function HistoryActivation(props) {
  const { children } = props;
  return (
    <TableActivation>
      <TableRowActivation isHead length={TABLE_LENGTH}>
        <TableItemActivation>Nom de la campagne</TableItemActivation>
        <TableItemActivation>Dates de campagne</TableItemActivation>
        <TableItemActivation>Budget</TableItemActivation>
        <TableItemActivation>Objectif</TableItemActivation>
        <TableItemActivation>Nombre de profils</TableItemActivation>
        <TableItemActivation> Actions </TableItemActivation>
      </TableRowActivation>
      <TableBodyActivation>{children}</TableBodyActivation>
    </TableActivation>
  );
}
HistoryActivation.defaultProps = {
  children: undefined
};
HistoryActivation.propTypes = {
  children: PropTypes.node
};

/**
 * BaseHistoryItem
 * @component
 * @see HistoryActivationContainer
 *
 */
const BaseHistoryItem = props => {
  const { activation, refetch, history, deleteActivation } = props;
  const localisation = `${routes.ACTIVATION}/${activation.id}`;
  const [deleteClicked, setDeleteClicked] = React.useState(false);
  const closeModal = () => {
    setDeleteClicked(false);
  };
  const handleClick = () => {
    history.push(localisation);
  };
  const variables = { id: activation.id };
  const onDelete = () => {
    deleteActivation({ variables }).then(() => {
      closeModal();
      refetch();
    });
  };
  return (
    <TableRowActivation length={TABLE_LENGTH}>
      <TableItemActivation handleClick={handleClick}>
        {activation.name}
      </TableItemActivation>
      <TableItemActivation handleClick={handleClick}>
        {moment(activation.startDate).format('L')}-
        {moment(activation.endDate).format('L')}
      </TableItemActivation>
      <TableItemActivation handleClick={handleClick}>
        {activation.budget}
      </TableItemActivation>
      <TableItemActivation handleClick={handleClick}>
        {activation.objective}
      </TableItemActivation>
      <TableItemActivation handleClick={handleClick}>
        {activation.profiles}
      </TableItemActivation>
      <TableItemActivation center>
        <Buttons onClick={() => setDeleteClicked(true)} type="button">
          <Icons icon="trash" />
          Supprimer
        </Buttons>
      </TableItemActivation>
      <ModalHistoryActivation
        isOpen={deleteClicked}
        handleClose={closeModal}
        handleValidation={onDelete}
        validationLabel="Supprimer"
        label="Supprimer activation historique"
      >
        <DeleteModalContent name={activation.name} />
      </ModalHistoryActivation>
    </TableRowActivation>
  );
};
BaseHistoryItem.propTypes = {
  activation: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  refetch: PropTypes.func.isRequired,
  deleteActivation: PropTypes.func.isRequired
};

export const HistoryActivationItem = withRouter(BaseHistoryItem);

export default HistoryActivation;
