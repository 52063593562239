import React from 'react';
import styled from 'styled-components';
import { useTheme } from 'contexts/ThemeContext';
import Dropdown from 'components/Dropdown';
import Grid from 'components/Grid';
import BaseLabel from 'components/Label';

const PurposeWrapper = props => (
  <Grid
    item
    container
    justify="center"
    style={{ textAlign: 'center' }}
    alignItems="center"
    xs={6}
    md={5}
    {...props}
  />
);

const StyledDropdown = styled(Dropdown)``;

export const DropdownPurpose = props => {
  const { themeColors } = useTheme();
  return (
    <StyledDropdown
      color={themeColors.light}
      bgColor={themeColors.primary}
      borderColor={themeColors.funnel}
      width="300px"
      minWidth="300px"
      isMui
      {...props}
    />
  );
};

export const Label = styled(BaseLabel)`
  padding: 8px 0;
`;

export default PurposeWrapper;
