import React from 'react';

const StepContext = React.createContext();

const STEPS_OBJECT = {
  IMPORT: 'import',
  MODELISATION: 'modelisation',
  RESULT: 'result'
};
const STEPS_ARRAY = Object.values(STEPS_OBJECT);

const getPosition = (targetStep, currentStep) => {
  const cursor = STEPS_ARRAY.indexOf(currentStep);
  const target = STEPS_ARRAY.indexOf(targetStep);
  const result = target - cursor;
  return result;
};

/**
 * StepProvider
 * @component
 * @context
 * TODO::: replace "third" by a better name
 *
 */
function StepProvider(props) {
  const [step, setStep] = React.useState(STEPS_ARRAY[0]);
  const [title, setTitle] = React.useState();
  const [canValidate, setCanValidate] = React.useState(true);
  const [validDate, setValidDate] = React.useState(false);
  const [validBudget, setValidBudget] = React.useState(null);
  const [validObjectif, setValidObjectif] = React.useState(false);
  const [validProfiles, setValidProfiles] = React.useState(false);

  const value = {
    positionImport: getPosition(STEPS_OBJECT.IMPORT, step),
    positionModelisation: getPosition(STEPS_OBJECT.MODELISATION, step),
    positionResult: getPosition(STEPS_OBJECT.RESULT, step),
    isImport: step === STEPS_OBJECT.IMPORT,
    isModelisation: step === STEPS_OBJECT.MODELISATION,
    goNext() {
      const nextStep = STEPS_ARRAY.indexOf(step) + 1;
      setStep(STEPS_ARRAY[nextStep]);
    },
    goPrev() {
      const nextStep = STEPS_ARRAY.indexOf(step) - 1;
      setStep(STEPS_ARRAY[nextStep]);
    },
    goFirst() {
      setStep(STEPS_ARRAY[0]);
    },
    title,
    setTitle,
    canValidate,
    setCanValidate,
    validDate,
    setValidDate,
    validBudget,
    setValidBudget,
    validObjectif,
    setValidObjectif,
    validProfiles,
    setValidProfiles
  };

  return <StepContext.Provider {...props} value={value} />;
}

/**
 * useStep
 * @return context
 *
 */
export const useStep = () => {
  const context = React.useContext(StepContext);
  if (!context) throw new Error('StepContext must be called in StepProvider');
  return context;
};
export default StepProvider;
