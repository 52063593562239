import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import { withInsight } from 'Insight/contexts/InsightContext/index';

/**
 * BaseResultLayout
 * @component
 *
 */
const BaseResultLayout = withInsight(
  withTheme(styled.div`
    display: grid;
    grid-template-columns: ${({ isData }) =>
      `${isData ? '274fr ' : ''}551fr 615fr`};
    background-color: ${({ themeColors }) => themeColors.secondary};
    height: fit-content;
    height: 100%;
    border-bottom: 2px solid
      ${({ themeColors }) => themeColors.insight_top_border};
    box-shadow: 0 1.5px 2px 0px ${({ themeColors }) => themeColors.shadow};
  `)
);

export const BaseResultColumn = styled.div`
  align-content: ${({ isResult }) => (isResult ? 'center' : 'flex-start')};
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ isResult }) =>
    isResult ? 'space-between' : 'flex-end'};
  padding-left: 8px;
  text-transform: uppercase;
  flex-flow: ${({ noWrap }) => (noWrap ? 'nowrap' : 'unset')};
  @media (min-width: 1024px) {
    align-content: center;
    justify-content: ${({ isResult }) =>
      isResult ? 'space-evenly' : 'flex-end'};
    padding-left: 0px;
  }
`;

export default BaseResultLayout;
