import React from 'react';

import WrapperBaseBrief from './style';

/**
 * WrapperBrief
 * @component
 *
 */
function WrapperBrief(props) {
  return <WrapperBaseBrief {...props} />;
}
WrapperBrief.defaultProps = {};
WrapperBrief.propTypes = {};

export default WrapperBrief;
