import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';
import Icon from 'utils/icons';

/**
 * IconActivation
 * @component
 *
 */
function IconActivation(props) {
  const { colored, icon, externalLogo, arrowHeader } = props;
  const { themeColors } = useTheme();
  const attrs = {};
  if (colored) {
    attrs.fill = themeColors.activation;
    attrs.stroke = themeColors.activation;
  }
  if (externalLogo) {
    attrs.stroke = themeColors.keep_light;
    attrs.style = {
      backgroundColor: themeColors.keep_light,
      padding: '2px',
      borderRadius: '4px'
    };
    if (arrowHeader) {
      attrs.fill = themeColors.keep_light;
      attrs.stroke = themeColors.keep_light;
    }
  }
  return <Icon icon={icon} attrs={attrs} />;
}
IconActivation.defaultProps = {
  colored: false,
  externalLogo: false,
  arrowHeader: false
};
IconActivation.propTypes = {
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  externalLogo: PropTypes.bool,
  arrowHeader: PropTypes.bool
};

export default IconActivation;
