import React from 'react';
import categoriesInsight from './categoriesInsight';
import data from './data';
import funnelResults from './funnelResults';
import errors from './errors';
import appNexusLogo from './appNexusLogo';
import dv360 from './dv360';
import facebook from './facebook';
import arrowDownHeader from './arrowDown';
import tooltipHelp from './tooltipHelp';

export default {
  ...categoriesInsight,
  ...data,
  ...funnelResults,
  ...errors,
  ...appNexusLogo,
  ...arrowDownHeader,
  ...tooltipHelp,
  ...dv360,
  ...facebook,
  default() {
    return (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      />
    );
  },
  // Main Logo ERMES - blank by default, use 'attrs' to set color
  ermes_logo(options) {
    return (
      <svg viewBox="0 0 341 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M289.01 56.21L297.42 46.13C303.25 50.94 309.35 53.99 316.75 53.99C322.58 53.99 326.09 51.68 326.09 47.89V47.71C326.09 44.1 323.87 42.25 313.05 39.48C300.01 36.15 291.6 32.54 291.6 19.69V19.51C291.6 7.77 301.03 0 314.26 0C323.69 0 331.74 2.96 338.3 8.23L330.9 18.96C325.17 14.98 319.53 12.58 314.07 12.58C308.61 12.58 305.75 15.08 305.75 18.22V18.4C305.75 22.65 308.52 24.04 319.71 26.91C332.84 30.33 340.24 35.05 340.24 46.33V46.51C340.24 59.36 330.44 66.58 316.48 66.58C306.67 66.57 296.77 63.14 289.01 56.21Z"
          fill="white"
        />
        <path
          d="M64.47 1.17004H0V14.03H64.47V1.17004Z"
          fill="#FFFFFF"
          {...options}
        />
        <path
          d="M64.47 26.9801H0V39.8401H64.47V26.9801Z"
          fill="#FFFFFF"
          {...options}
        />
        <path
          d="M64.47 52.78H0V65.64H64.47V52.78Z"
          fill="#FFFFFF"
          {...options}
        />
        <path
          d="M282.32 1.17004H217.85V14.03H282.32V1.17004Z"
          fill="#FFFFFF"
          {...options}
        />
        <path
          d="M282.32 26.98H217.85V39.84H282.32V26.98Z"
          fill="#FFFFFF"
          {...options}
        />
        <path
          d="M282.32 52.78H217.85V65.64H282.32V52.78Z"
          fill="#FFFFFF"
          {...options}
        />
        <path
          d="M116.8 44.89L115.77 43.36C120.4 41.53 124.25 38.18 126.7 33.91C128.54 30.7 129.6 26.99 129.6 23.03C129.6 19.07 128.54 15.35 126.7 12.15C123.12 5.93004 116.57 1.63004 108.97 1.21004C108.56 1.19004 108.15 1.17004 107.74 1.17004C107.33 1.17004 106.92 1.18004 106.51 1.21004H90.19V1.17004H77.34V65.64H80.67H90.19V65.58V44.89H101.28L115.24 65.58H130.75L116.8 44.89ZM107.74 14.06C112.69 14.06 116.7 18.08 116.7 23.03C116.7 27.86 112.87 31.79 108.08 31.98L108.12 32.04H92.61H90.19V28.46V21.33V14.08H107.74V14.06Z"
          fill="white"
        />
        <path
          d="M204.09 1.17004H192.08V1.21004H190.67L172.74 28.27L154.82 1.21004H153.4V1.17004H141.4H140.55V1.21004V1.73004V2.95004V65.64H153.4V22.36L165.03 39.92L172.74 51.56L180.45 39.92L192.08 22.36V65.64H204.94V2.95004V1.73004V1.21004V1.17004H204.09Z"
          fill="white"
        />
      </svg>
    );
  },
  // -----------
  data(options) {
    return (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13 0H0V2.4375H13V0Z" fill="#FFF" {...options} />
        <path d="M13 4.875H0V8.125H13V4.875Z" fill="#FFF" {...options} />
        <path d="M13 10.5625H0V13H13V10.5625Z" fill="#FFF" {...options} />
      </svg>
    );
  },
  // -----------
  insight(options) {
    return (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9484 8.76676L9.81724 7.91385L9.46069 7.7188C11.0585 6.19147 11.3146 3.64262 9.97929 1.80454C9.28246 0.845831 8.26149 0.224324 7.10767 0.0491112C5.9506 -0.126101 4.79677 0.171433 3.8601 0.878894C2.92019 1.58966 2.31093 2.63102 2.13915 3.80791C1.96738 4.98812 2.25907 6.16502 2.95267 7.12043C3.10824 7.33531 3.28321 7.53366 3.47119 7.71549L3.15681 7.88409L2.04839 8.76345C0.742239 10.0131 -0.00323055 11.7751 1.05246e-05 13.6V14H1.94466V13.5735C1.84418 12.3338 2.22665 11.1272 3.01747 10.1784C3.80829 9.22959 4.91345 8.65106 6.12886 8.54858C7.34426 8.4494 8.52726 8.83619 9.45745 9.64283C10.3876 10.4495 10.9549 11.5768 11.0554 12.8165C11.0748 13.0677 11.0748 13.3223 11.0554 13.6V14H13V13.6C13 11.7784 12.2545 10.0164 10.9484 8.76676ZM6.5146 7.28903L6.48216 6.95844C5.15656 6.95844 4.06756 5.85757 4.05135 4.50876C4.04487 3.84758 4.28792 3.21948 4.74167 2.74673C5.19542 2.27399 5.80151 2.00621 6.45296 1.9996C6.46269 1.9996 6.47244 1.9996 6.48216 1.9996C7.80776 1.9996 8.89676 3.09384 8.91297 4.44926C8.91945 5.11044 8.67641 5.73856 8.22266 6.21131C7.76891 6.68405 7.16282 6.95183 6.51136 6.95844L6.5146 7.28903Z"
          fill="#FFF"
          {...options}
        />
      </svg>
    );
  },
  // -----------
  activation(options) {
    return (
      <svg
        width="22"
        height="14"
        viewBox="0 0 22 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8257 0C14.4778 0 13.2328 0.433821 12.2158 1.17031L13.9653 2.91973C14.5141 2.60495 15.1477 2.42134 15.8257 2.42134C17.8959 2.42134 19.5787 4.10618 19.5787 6.17441C19.5787 8.24466 17.8939 9.9275 15.8257 9.9275C15.4382 9.9275 15.065 9.86897 14.7118 9.758L12.8736 11.5962C13.7514 12.0764 14.7562 12.3488 15.8257 12.3488C19.2317 12.3488 22 9.57841 22 6.17441C22 2.77041 19.2317 0 15.8257 0Z"
          fill="#FFF"
          {...options}
        />
        <path
          d="M8.96101 0.231934L7.13692 2.07014L10.4219 5.37527H0V7.96812H10.4219L7.13692 11.2753L8.96101 13.1114L15.3574 6.67271L8.96101 0.231934Z"
          fill="#FFF"
          {...options}
        />
      </svg>
    );
  },
  // -----------
  funnel(options) {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 14L9 14L9 12L5 12L5 14Z" fill="#FFF" {...options} />
        <path d="M2 8L12 8L12 6L2 6L2 8Z" fill="#FFF" {...options} />
        <path d="M0 2L14 2L14 0L0 -6.75251e-10L0 2Z" fill="#FFF" {...options} />
      </svg>
    );
  },
  trash(options) {
    return (
      <svg
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.785714 12.4444C0.785714 13.3 1.49286 14 2.35714 14H8.64286C9.50714 14 10.2143 13.3 10.2143 12.4444V3.11111H0.785714V12.4444ZM11 0.777778H8.25L7.46429 0H3.53571L2.75 0.777778H0V2.33333H11V0.777778Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  },
  add(options) {
    return (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="13" cy="13" r="13" fill="white" />
        <path
          d="M21 14.1429H14.1429V21H11.8571V14.1429H5V11.8571H11.8571V5H14.1429V11.8571H21V14.1429Z"
          fill="grey"
          {...options}
        />
      </svg>
    );
  },
  darkSwitch(options) {
    return (
      <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 7.5C6 4.16667 3.08785 1.79509 0.564905 0.95C1.6776 0.35 2.94436 0 4.29672 0C8.5592 0 12 3.35 12 7.5C12 11.65 8.5592 15 4.29672 15C2.70471 15 1.23252 14.5333 0 13.7333C2.78454 13.0917 6 10.8333 6 7.5Z"
          fill="#C8C5C5"
          {...options}
        />
      </svg>
    );
  },
  lightSwitch(options) {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...options}
      >
        <path
          d="M7.49927 2.44025C4.7095 2.44025 2.43945 4.71012 2.43945 7.50007C2.43945 10.2898 4.7095 12.5599 7.49927 12.5599C10.289 12.5599 12.5591 10.2898 12.5591 7.50007C12.5591 4.71012 10.2889 2.44025 7.49927 2.44025Z"
          fill="white"
        />
        <path
          d="M6.66016 1.77008H8.34201C8.48296 1.77008 8.54078 1.67015 8.46995 1.54817L7.62929 0.0914833C7.55864 -0.0304944 7.44371 -0.0304944 7.37323 0.0914833L6.53222 1.54817C6.46156 1.67015 6.51884 1.77008 6.66016 1.77008Z"
          fill="white"
        />
        <path
          d="M3.90764 2.95814L5.36432 2.11712C5.4863 2.04683 5.4863 1.93136 5.36432 1.86106L3.90764 1.02005C3.78548 0.94957 3.68555 1.00722 3.68555 1.14799V2.8302C3.68573 2.97097 3.78548 3.02861 3.90764 2.95814Z"
          fill="white"
        />
        <path
          d="M1.86097 5.36347C1.93163 5.48527 2.04674 5.48527 2.11704 5.36347L2.95823 3.9066C3.02853 3.78463 2.97106 3.68488 2.83011 3.68488H1.14826C1.00749 3.68488 0.949845 3.78463 1.02032 3.9066L1.86097 5.36347Z"
          fill="white"
        />
        <path
          d="M1.77008 8.3411V6.65925C1.77008 6.5183 1.66997 6.46084 1.54817 6.53113L0.0914833 7.37215C-0.0304944 7.44262 -0.0304944 7.55773 0.0914833 7.62803L1.54817 8.46904C1.66997 8.5397 1.77008 8.48205 1.77008 8.3411Z"
          fill="white"
        />
        <path
          d="M2.11722 9.63662C2.04692 9.51464 1.93181 9.51464 1.86115 9.63662L1.02032 11.0935C0.949845 11.2153 1.00749 11.3152 1.14826 11.3152H2.83047C2.97124 11.3152 3.02907 11.2153 2.95859 11.0935L2.11722 9.63662Z"
          fill="white"
        />
        <path
          d="M5.36414 12.8827L3.90746 12.0417C3.78548 11.9714 3.68555 12.029 3.68555 12.1698V13.8518C3.68555 13.9928 3.78548 14.0504 3.90746 13.9799L5.36414 13.1391C5.4863 13.0683 5.4863 12.953 5.36414 12.8827Z"
          fill="white"
        />
        <path
          d="M8.34008 13.23H6.65824C6.5171 13.23 6.45964 13.3298 6.53011 13.4517L7.37113 14.9084C7.4416 15.0306 7.55653 15.0306 7.62719 14.9084L8.46784 13.4517C8.53886 13.3298 8.48122 13.23 8.34008 13.23Z"
          fill="white"
        />
        <path
          d="M11.0931 12.0418L9.63627 12.8828C9.51447 12.9531 9.51447 13.0682 9.63627 13.1389L11.0931 13.9799C11.2153 14.0503 11.315 13.9925 11.315 13.8517V12.1695C11.3149 12.0289 11.2153 11.9713 11.0931 12.0418Z"
          fill="white"
        />
        <path
          d="M13.1389 9.63662C13.0684 9.51464 12.9531 9.51464 12.8829 9.63662L12.0417 11.0933C11.9714 11.2155 12.0292 11.3152 12.1698 11.3152H13.852C13.9928 11.3152 14.0506 11.2155 13.9801 11.0933L13.1389 9.63662Z"
          fill="white"
        />
        <path
          d="M14.9091 7.37217L13.4522 6.53133C13.3302 6.46067 13.2305 6.51832 13.2305 6.65909V8.34112C13.2305 8.48189 13.3302 8.53954 13.4522 8.46924L14.9091 7.62823C15.031 7.55775 15.031 7.44264 14.9091 7.37217Z"
          fill="white"
        />
        <path
          d="M12.8831 5.36347C12.9534 5.48563 13.0687 5.48563 13.1391 5.36347L13.98 3.90678C14.0506 3.78481 13.9926 3.68488 13.852 3.68488H12.1698C12.029 3.68488 11.9714 3.78481 12.0417 3.90678L12.8831 5.36347Z"
          fill="white"
        />
        <path
          d="M9.63641 2.11712L11.0929 2.95814C11.2152 3.02843 11.315 2.97079 11.315 2.83002V1.14799C11.315 1.00722 11.2152 0.94957 11.0929 1.02005L9.63641 1.86088C9.51443 1.93136 9.51443 2.04665 9.63641 2.11712Z"
          fill="white"
        />
      </svg>
    );
  },
  caretDown(options) {
    return (
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.4955 6.0125L7.9105 6.6105C7.4425 7.0785 7.15 7.475 7.15 8.45H5.85V8.125C5.85 7.4035 6.1425 6.7535 6.6105 6.2855L7.4165 5.4665C7.657 5.2325 7.8 4.9075 7.8 4.55C7.8 3.8285 7.215 3.25 6.5 3.25C6.15522 3.25 5.82456 3.38696 5.58076 3.63076C5.33696 3.87456 5.2 4.20522 5.2 4.55H3.9C3.9 3.86044 4.17393 3.19912 4.66152 2.71152C5.14912 2.22393 5.81044 1.95 6.5 1.95C7.18956 1.95 7.85088 2.22393 8.33848 2.71152C8.82607 3.19912 9.1 3.86044 9.1 4.55C9.1 5.122 8.866 5.6355 8.4955 6.0125ZM7.15 11.05H5.85V9.75H7.15V11.05ZM6.5 0C5.64641 0 4.80117 0.168128 4.01256 0.494783C3.22394 0.821439 2.50739 1.30023 1.90381 1.90381C0.684819 3.12279 0 4.77609 0 6.5C0 8.22391 0.684819 9.87721 1.90381 11.0962C2.50739 11.6998 3.22394 12.1786 4.01256 12.5052C4.80117 12.8319 5.64641 13 6.5 13C8.22391 13 9.87721 12.3152 11.0962 11.0962C12.3152 9.87721 13 8.22391 13 6.5C13 2.9055 10.075 0 6.5 0Z"
          fill="#EBEFF2"
          {...options}
        />
      </svg>
    );
  },
  arrowSimple(options) {
    return (
      <svg
        width="13"
        height="17"
        viewBox="0 0 13 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.784813 0.531998H3.81081L12.3448 8.42L3.81081 16.308H0.784813L9.35281 8.42L0.784813 0.531998Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  },
  arrowDown(options) {
    return (
      <svg width="24" height="18" xmlns="http://www.w3.org/2000/svg">
        <title />
        <g>
          <title>background</title>
          <rect
            fill="none"
            id="canvas_background"
            height="20"
            width="26"
            y="-1"
            x="-1"
          />
        </g>
        <g>
          <title>Layer 1</title>
          <path
            stroke="null"
            fill="#ffffff"
            id="svg_1"
            d="m10.735932,1.683119l0,12.774569l-4.36,-2.923814a1,0.805458 0 0 0 -1.28,1.240406l6,4.027292l0.15,0.072491l0.13,0.056382a1,0.805458 0 0 0 0.36,0.056382l0,0a1,0.805458 0 0 0 0.36,-0.056382l0.13,-0.056382l0.15,-0.072491l6,-4.027292a1,0.805458 0 0 0 0.36,-0.620203a1,0.805458 0 0 0 -0.23,-0.515493a1,0.805458 0 0 0 -1.41,-0.10471l-4.36,2.923814l0,-12.774569a1,0.805458 0 0 0 -2,0z"
            className="cls-1"
            {...options}
          />
          <path
            stroke="null"
            fill="#fcfcfc"
            id="svg_2"
            d="m12.095932,16.930245l0.13,-0.056582l0.15,-0.072749l6,-4.041596a1,0.808319 0 0 0 0.36,-0.622406a1,0.808319 0 0 0 -0.23,-0.517324a1,0.808319 0 0 0 -1.41,-0.105082l-4.36,2.934199l0,-12.819944a1,0.808319 0 0 0 -1,-0.808319l0,16.166385l0,0a1,0.808319 0 0 0 0.36,-0.056582z"
            className="cls-2"
            {...options}
          />
        </g>
      </svg>
    );
  },
  ermes(options, props) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M32.0228 0.579956H0V6.95394H32.0228V0.579956Z" {...options} />
        <path d="M32.0228 13.3726H0V19.7465H32.0228V13.3726Z" {...options} />
        <path d="M32.0228 26.1602H0V32.5341H32.0228V26.1602Z" {...options} />
      </svg>
    );
  },
  checkMark(options, props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <circle cx="10" cy="10" r="10" fill="#28A745" {...options} />
        <path
          d="M17 6.05145L8.08571 15L4 10.8986L5.04743 9.84713L8.08571 12.8896L15.9526 5L17 6.05145Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  },
  arrowRightLarge(options) {
    return (
      <svg
        width="19"
        height="68"
        viewBox="0 0 19 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.4592 32.6444L1.94849 0.566459C1.55975 -0.18882 0.936287 -0.18882 0.547541 0.566459C0.158795 1.32174 0.158795 2.53303 0.547541 3.28831L16.3541 33.9982L0.547541 64.7081C0.158795 65.4634 0.158795 66.6747 0.547541 67.43C0.738247 67.8005 0.994966 68 1.24435 68C1.49373 68 1.75045 67.8147 1.94116 67.43L18.4519 35.352C18.8406 34.611 18.8406 33.3854 18.4592 32.6444Z"
          fill="#3300FF"
          {...options}
        />
      </svg>
    );
  },
  arrowVerticalDouble(options) {
    return (
      <svg
        width="8"
        height="49"
        viewBox="0 0 8 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.35355 0.646416C4.15829 0.451153 3.84171 0.451153 3.64645 0.646416L0.464466 3.8284C0.269204 4.02366 0.269204 4.34024 0.464466 4.5355C0.659728 4.73077 0.976311 4.73077 1.17157 4.5355L4 1.70708L6.82843 4.5355C7.02369 4.73077 7.34027 4.73077 7.53553 4.5355C7.7308 4.34024 7.7308 4.02366 7.53553 3.8284L4.35355 0.646416ZM4.5 47.5251L4.5 0.999969H3.5L3.5 47.5251H4.5Z"
          fill="#4C4E58"
          {...options}
        />
        <path
          d="M3.64645 48.8096C3.84171 49.0049 4.15829 49.0049 4.35355 48.8096L7.53553 45.6277C7.7308 45.4324 7.7308 45.1158 7.53553 44.9206C7.34027 44.7253 7.02369 44.7253 6.82843 44.9206L4 47.749L1.17157 44.9206C0.976311 44.7253 0.659728 44.7253 0.464466 44.9206C0.269204 45.1158 0.269204 45.4324 0.464466 45.6277L3.64645 48.8096ZM3.5 1.93091L3.5 48.4561H4.5L4.5 1.93091H3.5Z"
          fill="#4C4E58"
          {...options}
        />
      </svg>
    );
  },
  arrowRight(options) {
    return (
      <svg
        width="16"
        height="8"
        viewBox="0 0 20 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.3536 4.35355C19.5488 4.15829 19.5488 3.84171 19.3536 3.64645L16.1716 0.464466C15.9763 0.269204 15.6597 0.269204 15.4645 0.464466C15.2692 0.659728 15.2692 0.976311 15.4645 1.17157L18.2929 4L15.4645 6.82843C15.2692 7.02369 15.2692 7.34027 15.4645 7.53553C15.6597 7.7308 15.9763 7.7308 16.1716 7.53553L19.3536 4.35355ZM0 4.5L19 4.5V3.5L0 3.5L0 4.5Z"
          fill="#4C4E58"
          {...options}
        />
      </svg>
    );
  }
};
