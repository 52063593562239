import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * ChartKpiVolumeContainer
 * @component
 *
 */
const ChartKpiVolumeContainer = styled.div`
  padding: 0 15px;
`;

/**
 * linkStyle
 */
export const linkStyle = {
  border: '1px solid steelblue',
  borderRadius: '5px',
  color: 'grey',
  cursor: 'default',
  fontWeight: 600,
  margin: '5px',
  padding: '3px 15px'
};

/**
 * linkStyleActive
 *
 */
export const linkStyleActive = {
  border: '1px solid steelblue',
  borderRadius: '5px',
  color: 'steelblue',
  cursor: 'pointer',
  margin: '5px',
  padding: '3px 15px'
};

export const legendStyle = {
  backgroundColor: 'white',
  borderRadius: '7px',
  color: 'white',
  display: 'flex',
  height: 'fit-content',
  justifyContent: 'center',
  margin: '10px',
  padding: '8px 0',
  width: '200px'
};

export const LegendWrapper = withTheme(styled.div`
  background-color: #fff;
  border-radius: 7px;
  display: flex;
  height: fit-content;
  justify-content: center;
  margin: 10px;
  padding: 8px 0;
  width: 200px;
`);

/**
 * ZoomButtonContainer
 * @component
 *
 */
export const ZoomButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
`;

/**
 * LegendContainer
 * @component
 *
 */
export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
`;

/**
 * Container
 *
 */
export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

/**
 * Description
 * @component
 *
 */
export const Description = withTheme(styled.div`
  display: flex;
  justify-self: flex-start;
  color: ${({ themeColors }) => themeColors.light};
  padding: 45px 11px;
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 11px;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    padding: 0 20px;
  }
`);

export default ChartKpiVolumeContainer;
