import React from 'react';
// import PropTypes from 'prop-types';

import BaseTooltip from './style';

/**
 * TooltipInsight
 * @component
 *
 */
function TooltipInsight(props) {
  return <BaseTooltip {...props} />;
}
TooltipInsight.defaultProps = {};
TooltipInsight.propTypes = {};

export default TooltipInsight;
