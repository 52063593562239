import React from 'react';
// import PropTypes from 'prop-types'

import BaseEvolution from './style';

/**
 * EvolutionFunnel
 * @component
 *
 */
function EvolutionFunnel(props) {
  return <BaseEvolution {...props} />;
}
EvolutionFunnel.defaultProps = {};
EvolutionFunnel.propTypes = {};

export default EvolutionFunnel;
