import React from 'react';
import PropTypes from 'prop-types';
import moment from 'utils/moment';
import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';
import PeriodWrapper, { DatePicker, Label } from './style';

/**
 * PeriodFunnel
 * @component
 *
 */
function PeriodFunnel(props) {
  const { setPeriod, start, end, onClose } = props;
  const { hasPurpose } = useFunnelStep();
  return (
    <PeriodWrapper>
      <Label>
        <span className="span">PÉRIODE ANALYSÉE </span>
      </Label>
      <DatePicker
        onDatesChange={setPeriod}
        startDate={moment(start)}
        endDate={moment(end)}
        disabled={!hasPurpose}
        onClose={onClose}
      />
    </PeriodWrapper>
  );
}
PeriodFunnel.defaultProps = {
  start: undefined,
  end: undefined
};
PeriodFunnel.propTypes = {
  setPeriod: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date)
};

export default PeriodFunnel;
