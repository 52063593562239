import React from 'react';
import styled from 'styled-components';
import BaseTitle from 'components/Title';
import Grid from 'components/Grid';
// import constants from 'utils/constants';
//
// const { colors } = constants;
//

const Title = styled(BaseTitle)`
  margin: 0 auto;
  padding: 8px 0;
  display: inline-block;
`;

/**
 * BaseNameFunnel
 * @component
 *
 */
const BaseNameFunnel = props => (
  <Grid
    item
    direction="column"
    justify="center"
    style={{ textAlign: 'center' }}
    container
    alignItems="center"
    xs={6}
    md={3}
  >
    <Title small {...props} />
  </Grid>
);

export default BaseNameFunnel;
