import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';

const { fontSizes } = constants;

/**
 * BaseLabel
 * @component
 *
 */
const BaseLabel = styled.label`
  color: ${({ themeColors }) => themeColors.light};
  display: ${({ display }) => display || 'inline-flex'};
  font-size: ${fontSizes.small};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  .span {
    margin-right: 8px;
  }
  @media (min-width: 1440px) {
    font-size: ${fontSizes.medium};
  }
`;

export default withTheme(BaseLabel);
