import React from 'react';
import { withRouter } from 'react-router-dom';

import AppInsight from 'Insight/AppInsight';
import InsightProvider from 'Insight/contexts/InsightContext';
import ResultProvider from 'Insight/contexts/ResultContext';
import CategoryProvider from 'Insight/contexts/CategoryContext';
import OperatorProvider from 'Insight/contexts/OperatorContext';
import { getMatching } from 'utils/functions/insight';
import { useData, STEPS } from 'Data/contexts/DataContext';
import ModalTarget from './ModalTarget';

/**
 * Insight
 * @component
 * @desc :: Manage Insight App part
 * @uri :: /insight
 *
 */
function InsightModule() {
  const { currentBase, step, setStep } = useData();

  return (
    <InsightProvider isData dataBase={currentBase}>
      <CategoryProvider>
        <OperatorProvider>
          <ResultProvider>
            <AppInsight />
          </ResultProvider>
        </OperatorProvider>
      </CategoryProvider>
      <ModalTarget
        open={step === STEPS.ENRICHIR_MODAL}
        onClose={() => setStep(STEPS.ENRICHIR_HOME)}
        matchingPercentage={getMatching(currentBase)}
      />
    </InsightProvider>
  );
}

export default withRouter(InsightModule);
