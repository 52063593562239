import React from 'react';

import BaseTemplateimport from './style';

/**
 * TemplateImport
 * @component
 *
 */
function TemplateImport(props) {
  return <BaseTemplateimport {...props} />;
}
TemplateImport.defaultProps = {};
TemplateImport.propTypes = {};

export default TemplateImport;
