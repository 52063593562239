import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useOperator } from 'Insight/contexts/OperatorContext';
import ErrorPage from 'components/ErrorPage';
import isEqual from 'lodash/isEqual';

import { useInsight } from 'Insight/contexts/InsightContext';
import { useCategory } from '../CategoryContext';

const ResultContext = React.createContext();

function getPropSafe(object, prop, fallback = null) {
  return object ? object[prop] || fallback : fallback;
}

const FRAGMENTS = {
  criterias: gql`
    fragment CriteriaProps on Criteria {
      key
      name
      description
      categoryKey
      order
      insight {
        audience {
          all
          insee
        }
      }
    }
  `
};

const QUERY = {
  insightResult: gql`
    query getResults(
      $filters: InsightResultFiltersInput
      $categoryKey: String!
      $importKey: String
    ) {
      insightResult(
        filters: $filters
        categoryKey: $categoryKey
        importKey: $importKey
      ) {
        nbProfiles
        nbCookies
        nbEmails
        category {
          criterias {
            ...CriteriaProps
          }
          subCategories {
            key
            name
            description
            order
            criterias {
              ...CriteriaProps
            }
          }
        }
        tagFunnels {
          id
          levels {
            level
            audience {
              all
              insee
            }
          }
        }
      }
    }
    ${FRAGMENTS.criterias}
  `
};

const MUTATION = {
  insightTargetSave: gql`
    mutation InsightTargetSaveMutation(
      $filters: InsightResultFiltersInput!
      $name: String!
      $frontData: Json
    ) {
      insightTargetSave(filters: $filters, name: $name, frontData: $frontData) {
        id
        name
        email
        filters
        updatedAt
        createdAt
      }
    }
  `
};

/**
 * ResultProvider
 * @provider
 * @desc manage all the result process
 *
 */
export default function ResultProvider(props) {
  const { dataBase } = useInsight();
  const { insightTarget } = props;
  const { formatedFilter, initOperatorsGroups } = useOperator();
  const { currentCategory } = useCategory();
  const [categoryKey, setCategoryKey] = React.useState(currentCategory);
  const [filters, setFilter] = React.useState(formatedFilter);
  const [resultReqFilter, setResultReqFilter] = React.useState(formatedFilter);
  const formatedFilterRef = React.useRef();
  const hasFilter = formatedFilter.value.length;
  const [isUpdated, toggleUpdated] = React.useState(false);
  let resultsLoading = false;
  let resultsError = false;
  let results = {};
  /* ------------ A CHANGER PLUS TARD ------------------------------*/
  /* ----- il faudrait envoyer une requete avec un parametre ------ */
  const socioRes = useQuery(QUERY.insightResult, {
    variables: {
      filters: formatedFilter,
      categoryKey: 'socioDemo',
      // eslint-disable-next-line no-underscore-dangle
      importKey: dataBase ? dataBase._id : null
    },
    skip: !currentCategory || !filters,
    fetchPolicy: 'network-only'
  });
  const typoRes = useQuery(QUERY.insightResult, {
    variables: {
      filters: formatedFilter,
      categoryKey: 'typoAcheteur',
      // eslint-disable-next-line no-underscore-dangle
      importKey: dataBase ? dataBase._id : null
    },
    skip: !currentCategory || !filters,
    fetchPolicy: 'network-only'
  });
  const geoRes = useQuery(QUERY.insightResult, {
    variables: {
      filters: formatedFilter,
      categoryKey: 'geo',
      // eslint-disable-next-line no-underscore-dangle
      importKey: dataBase ? dataBase._id : null
    },
    skip: !currentCategory || !filters,
    fetchPolicy: 'network-only'
  });
  /* ------------------------- */
  const { loading, error, data } = useQuery(QUERY.insightResult, {
    variables: {
      filters,
      categoryKey: currentCategory,
      // eslint-disable-next-line no-underscore-dangle
      importKey: dataBase ? dataBase._id : null
    },
    skip: !currentCategory || !filters,
    fetchPolicy: 'network-only'
  });
  results = getPropSafe(data, 'insightResult', {});
  resultsLoading = loading;
  resultsError = error;

  React.useEffect(() => {
    if (!isEqual(formatedFilterRef.current, formatedFilter)) {
      formatedFilterRef.current = formatedFilter;
      if (insightTarget && !hasFilter && !isUpdated) {
        setFilter(insightTarget.filters);
        const { operatorsGroups } = JSON.parse(insightTarget.frontData);
        initOperatorsGroups(operatorsGroups);
      } else {
        toggleUpdated(true);
        setFilter(formatedFilter);
      }
    }
  }, [
    formatedFilter,
    hasFilter,
    initOperatorsGroups,
    insightTarget,
    isUpdated
  ]);

  const value = {
    categoryKey,
    setCategoryKey,
    resultReqFilter,
    setResultReqFilter,
    socioRes,
    typoRes,
    geoRes,
    nbProfiles: getPropSafe(results, 'nbProfiles', 0),
    nbCookies: getPropSafe(results, 'nbCookies', 0),
    nbEmails: getPropSafe(results, 'nbEmails', 0),
    results,
    resultsLoading,
    resultsError,
    persistFilters: useMutation(MUTATION.insightTargetSave)[0],
    insightTarget
  };
  if (resultsError) {
    return <ErrorPage isServer />;
  }
  return <ResultContext.Provider {...props} value={value} />;
}
ResultProvider.propTypes = {
  insightTarget: PropTypes.objectOf(PropTypes.any).isRequired
};

/**
 * useResult
 * @function
 * @return value defined above (ResultProvider)
 *
 */
export const useResult = () => {
  const context = React.useContext(ResultContext);
  if (!context)
    throw new Error('useResult must be called inside ResultProvider');
  return context;
};
