import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

export const Container = withTheme(styled.div`
  box-shadow: 0px 0 6px 2px ${({ themeColors }) => themeColors.shadow};
  background-color: ${({ themeColors }) => themeColors.keep_light};
  border-radius: 5px;
  margin: auto 10px;
  padding: 5px 20px;
  text-align: center;
  display: flex;
  margin-top: 10px;
  .question_mark {
    height: 50px;
    width: 40px;
    margin-right: 10px;
  }
  .align_center {
    color: ${({ themeColors }) => themeColors.keep_dark};
  }
`);

export const LinkButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const LinkButton = withTheme(styled.div`
  display: inline-flex;
  border: 1px solid ${({ themeColors }) => themeColors.funnel};
  border-radius: 7px;
  margin: 30px 40px;
  min-width: 220px;
  height: 200px;
`);

export const ButtonContent = withTheme(styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ themeColors }) => themeColors.light};
  .icon {
    background-color: ${({ themeColors }) => themeColors.funnel};
    border: 1px solid ${({ themeColors }) => themeColors.funnel};
    color: ${({ themeColors }) => themeColors.lignt};
    border-radius: 7px;
    padding: 5px 10px;
    display: inline-flex;
    width: 60px;
    height: 60px;
  }
  .description {
    margin: 10px 0;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    width: 200px;
    padding: 0 20px;
  }
  .acceder {
    margin: 10px 0;
    background-color: ${({ themeColors }) => themeColors.funnel};
    border: 1px solid ${({ themeColors }) => themeColors.funnel};
    padding: 10px 20px;
    color: ${({ themeColors }) => themeColors.light};
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  .acceder:hover {
    background-color: ${({ themeColors }) => themeColors.light};
    border: 1px solid ${({ themeColors }) => themeColors.light};
    padding: 10px 20px;
    color: ${({ themeColors }) => themeColors.funnel};
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
  }
`);

export const Table = withTheme(styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: ${({ themeColors }) => themeColors.primary};
  border-radius: 0;
`);

export const Titles = withTheme(styled.thead`
  color: ${({ themeColors }) => themeColors.light};
  text-align: left;
  font-weight: bold;
  tr {
    height: 60px;
    border-radius: 0;
  }
  tr th {
    padding-left: 20px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid ${({ themeColors }) => themeColors.light};
    border-radius: 0;
  }
`);

export const TableContent = withTheme(styled.tbody`
  color: ${({ themeColors }) => themeColors.light};
  tr td {
    padding-left: 20px;
    height: 65px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid ${({ themeColors }) => themeColors.light};
    border-radius: 0;
  }
`);

export const ErrorMessage = withTheme(styled.p`
  color: ${({ themeColors }) => themeColors.light};
  font-size: 14px;
  padding: 10px 30px;
`);
