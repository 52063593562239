export function dataOrInsight(
  isData,
  themeColors,
  keyData,
  keyInsight = keyData.replace('data', 'insight')
) {
  return isData ? themeColors[keyData] : themeColors[keyInsight];
}

export function getMatching(base) {
  if (!base || !base.digitizing) return 0;
  const total = base.digitizing.total.nb_records || -1;
  const digitized = base.digitizing.total.nb_digitized;
  return (digitized * 100) / total;
}

export default dataOrInsight;
