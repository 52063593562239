import React from 'react';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import ResultInsight from 'Insight/components/ResultInsight';
import ModalInsight from 'Insight/components/ModalInsight';
import BuyDataModalInsight from 'Insight/components/BuyDataModalInsight';
import ModalBuySegment from 'Insight/components/BuyDataFormModalInsight/ModalBuySegment';
import CautionModalInsight from 'Insight/components/CautionModalInsight';
import SurveyModalInsight from 'Insight/components/SurveyModalInsight';
import ResultSavingInsight from 'Insight/components/ResultSavingInsight';
import ResultInsightLayout, {
  ResultColumn
} from 'Insight/components/ResultInsightLayout';
import ResultTitleInsight from 'Insight/components/ResultTitleInsight';
import { ActionButton } from 'Insight/components/ButtonInsight';

import { useOperator } from 'Insight/contexts/OperatorContext';

import { useResult } from 'Insight/contexts/ResultContext';
import { useInsight } from 'Insight/contexts/InsightContext';
import { useInsightCible } from 'contexts/InsightCibleContext';
import { DataResultColumn } from 'Insight/components/DataResultColumn';
import PropTypes from 'prop-types';
import constants from 'utils/constants';
import { useNotif } from 'contexts/NotifContext';
import { INSIGHT } from 'utils/graphQL/index';

const { routes } = constants;

const getNotifyParams = {
  buyData: {
    title: `Demande d'achat de data envoyée`,
    message: `Votre demande a bien été prise en compte par nos équipes.`,
    variants: 'success'
  },
  survey: {
    title: `Demande de sondage envoyée`,
    message: `Votre demande de sondage a bien été prise en compte par nos équipes.`,
    variants: 'success'
  }
};

/**
 * ResultContainer
 * @component
 *
 */
function ResultContainer({ history }) {
  const { notify } = useNotif();
  const { setId, setNbProfile, setIsSegmentSet } = useInsightCible();
  const {
    resultsError,
    nbProfiles,
    nbCookies,
    nbEmails,
    persistFilters,
    insightTarget
  } = useResult();
  const { isData, dataBase, setSegmentName } = useInsight();
  const { formatedFilter, operatorsGroups, getSelectedList } = useOperator();
  const frontData = { operatorsGroups, nbProfiles };
  const [name, setName] = React.useState(
    insightTarget ? insightTarget.name : ''
  );
  const [currentModal, setCurrentModal] = React.useState(null);
  const closeModal = () => {
    setCurrentModal(null);
  };
  const [sendEmail] = useMutation(INSIGHT.SEND_EMAIL);
  const handlePersist = async cibleButton => {
    const now = moment();
    const saveName = name || `Ìnsight ${now.format('DD/MM/YY HH:mm')}`;

    setSegmentName(name);
    const res = await persistFilters({
      variables: {
        name: saveName,
        filters: formatedFilter,
        frontData: JSON.stringify(frontData)
      }
    });

    if (cibleButton) {
      res.data.insightTargetSave.map(insight => {
        if (insight.name === saveName) {
          setId(insight.id);
          setNbProfile(nbProfiles);
          setIsSegmentSet(false);
        }
        return insight.id;
      });
      closeModal();
    }
  };

  const send = (data, saveId, type) => {
    sendEmail({
      variables: {
        input: {
          type,
          phone: data.phone.trim(),
          description: data.description,
          data: {
            nbContacts: data.nbContacts,
            segmentId: saveId
          }
        }
      },
      skip: !data.phone || !data.description
    });
  };

  const handleSurvey = async data => {
    const saveName = name === '' ? 'segment insight pour sondage' : name;
    const save = await persistFilters({
      variables: {
        name: saveName,
        filters: formatedFilter,
        frontData: JSON.stringify(frontData)
      }
    });
    const lastIdx = save.data.insightTargetSave.length - 1;
    const saveId = save.data.insightTargetSave[lastIdx].id;
    send(data, saveId, 'DATA_START_SURVEY');
    setCurrentModal(null);
    notify(getNotifyParams.survey);
  };
  const handleRecapOrder = () => {};
  const handleCible = () => {
    handlePersist(true).then(() => {
      history.push(routes.ACTIVATION);
    });
  };
  function handleBuyData() {
    setCurrentModal('modal_buy_data_confirm');
  }
  async function handleBuyDataSendMail(data) {
    const saveName = name === '' ? 'segment insight pour achat de data' : name;
    const save = await persistFilters({
      variables: {
        name: saveName,
        filters: formatedFilter,
        frontData: JSON.stringify(frontData)
      }
    });
    const lastIdx = save.data.insightTargetSave.length - 1;
    const saveId = save.data.insightTargetSave[lastIdx].id;
    send(data, saveId, 'DATA_BUY_SEGMENT');
    setCurrentModal(null);
    notify(getNotifyParams.buyData);
  }

  if (resultsError) return <p>Error when loading results!</p>;
  return (
    <React.Fragment>
      <ResultInsightLayout>
        {isData && <DataResultColumn base={dataBase} />}
        <ResultColumn design="results">
          <ResultInsight
            isData={isData}
            name="Contacts (ID ERMES)"
            value={nbProfiles}
          />
          {/* Remettre nbCookies à terme */}
          <ResultInsight
            isData={isData}
            name="Cookies"
            value={isData ? nbProfiles : nbCookies}
          />
          <ResultInsight name="Emails hachés" value={nbEmails} />
        </ResultColumn>
        <ResultColumn noWrap design="actions">
          <ActionButton
            inversed
            onClick={() => setCurrentModal('modal_save_funnel')}
          >
            Sauvegarder la cible
          </ActionButton>
          {!isData && (
            <ActionButton
              inversed
              onClick={() => {
                if (getSelectedList().length > 0) {
                  setCurrentModal('modal_survey_funnel');
                }
              }}
            >
              Sondage AD-HOC
            </ActionButton>
          )}
          <ActionButton onClick={handleCible}> Activer la cible</ActionButton>
          <ActionButton
            onClick={() => {
              if (getSelectedList().length > 0) {
                setCurrentModal('modal_buy_data');
              }
            }}
          >
            Acheter la data
          </ActionButton>
        </ResultColumn>
      </ResultInsightLayout>
      {name && (
        <ResultTitleInsight hasTitle={!!name}>{name}</ResultTitleInsight>
      )}
      <ModalInsight
        isOpen={currentModal === 'modal_save_funnel'}
        handleClose={closeModal}
        handleValidation={handlePersist}
        validationLabel="Sauvegarder"
        label={"Sauvegarder l'insight"}
      >
        <ResultSavingInsight onNameChange={setName} targetName={name} />
      </ModalInsight>
      <SurveyModalInsight
        open={currentModal === 'modal_survey_funnel'}
        onClose={closeModal}
        onNext={handleSurvey}
        contacts={nbProfiles}
      />
      <ModalInsight
        isOpen={currentModal === 'modal_too_small_segment'}
        handleClose={closeModal}
        handleValidation={handleRecapOrder}
        closeLabel="Modifier la sélection"
        validationLabel="Conserver la sélection"
        label="too Small Segment"
      >
        <CautionModalInsight />
      </ModalInsight>
      <ModalInsight
        isOpen={currentModal === 'modal_buy_data'}
        handleClose={closeModal}
        handleValidation={handleBuyData}
        closeLabel="Annuler"
        validationLabel="Acheter la data"
        label="buy Data"
      >
        <BuyDataModalInsight
          operators={operatorsGroups}
          contacts={nbProfiles}
        />
      </ModalInsight>
      <ModalBuySegment
        open={currentModal === 'modal_buy_data_confirm'}
        onClose={closeModal}
        onNext={handleBuyDataSendMail}
        contacts={nbProfiles}
      />
    </React.Fragment>
  );
}

ResultContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(ResultContainer);
