import React from 'react';
import moment from 'utils/moment';
import PropTypes from 'prop-types';

import BaseDatePickerActivation, { DatePicker } from './style';

function isOutsideRange(date) {
  return moment(date).isBefore();
}

/**
 * DatePickerActivation
 * @component
 *
 */
function DatePickerActivation(props) {
  const { setPeriod, startDate, endDate, toTop, onClose, errorInput } = props;
  return (
    <BaseDatePickerActivation>
      {errorInput === 'true' ? (
        <DatePicker
          onDatesChange={setPeriod}
          startDate={startDate}
          endDate={endDate}
          toTop={toTop}
          propsDateRangePicker={{ isOutsideRange }}
          onClose={onClose}
          errorInput
        />
      ) : (
        <DatePicker
          onDatesChange={setPeriod}
          startDate={startDate}
          endDate={endDate}
          toTop={toTop}
          propsDateRangePicker={{ isOutsideRange }}
          onClose={onClose}
        />
      )}
    </BaseDatePickerActivation>
  );
}
DatePickerActivation.defaultProps = {
  startDate: moment(new Date()),
  endDate: moment(new Date()),
  toTop: false,
  errorInput: 'false'
};

DatePickerActivation.propTypes = {
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  setPeriod: PropTypes.func.isRequired,
  toTop: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  errorInput: PropTypes.string
};

export default DatePickerActivation;
