import constants from 'utils/constants';

export default () => null;

/**
 * getPercent
 * @param [number] value from back |  (ex: 0.344)
 * @param [string] field from back | (ex: reference)
 * @return Number
 */
export const getPercent = (value, field) => {
  if (!value || !value[field]) return 0;
  return Math.round(value[field] * 100);
};

export const getDepartement = name => name.split('-')[0].trim();

const { insightCriteriaTypes } = constants;
const { OR, AND } = insightCriteriaTypes;

export const getLabel = type => {
  const typeName = type.NAME.toLowerCase();
  if (typeName === OR.NAME.toLowerCase()) return OR.LABEL;
  if (typeName === AND.NAME.toLowerCase()) return AND.LABEL;
  return '--';
};

export const extractSubGroup = (groups, categoryKEY) => {
  const entry = Object.entries(groups).find(arr => arr[0] === categoryKEY);
  if (!entry) return [];
  const objSubGroups = entry[1];
  return Object.entries(objSubGroups).filter(arr => arr[1].values.length) || [];
};

export const exists = e => {
  return !!e;
};

const shouldKeep = ([key, value]) => {
  if (value && value.values && value.values.length) {
    return [key, value];
  }
  return null;
};

export const getEntries = (key, operators) => {
  return Object.entries(operators[key]).filter(shouldKeep).length
    ? { key }
    : null;
};

export const getSelectedCategoriesForPdf = (
  operatorsGroups,
  getCategoryName
) => {
  const cat = Object.keys(operatorsGroups)
    .map(Key => getEntries(Key, operatorsGroups))
    .filter(exists);
  const reminderedCategories = [];

  cat.map(({ key: categoryKEY }) => {
    const tmp = {
      subGroups: []
    };
    tmp.title = getCategoryName(categoryKEY);
    extractSubGroup(operatorsGroups, categoryKEY).map(([, subGroup]) => {
      const dataTmp = {
        type: getLabel(subGroup.type),
        values: []
      };
      subGroup.values.map(value => {
        dataTmp.values.push({
          type: value.type.LABEL,
          name: value.name
        });
        return tmp;
      });
      tmp.subGroups.push(dataTmp);
      return tmp;
    });
    reminderedCategories.push(tmp);
    return tmp;
  });
  return reminderedCategories;
};
