import React from 'react';
import { useSnackbar } from 'notistack';

const NotifContext = React.createContext();

function NotifProvider(props) {
  const { enqueueSnackbar } = useSnackbar();
  const value = {
    notify({ title, message, variants, time }) {
      let trueTime = 0;
      if (time === undefined) trueTime = 4000;
      else trueTime = time;
      enqueueSnackbar(
        [
          <div key={message}>
            <div style={{ color: 'black' }}>
              <strong>{title}</strong>
              <p>{message}</p>
            </div>
          </div>
        ],
        { variant: variants, autoHideDuration: trueTime }
      );
    }
  };

  return <NotifContext.Provider {...props} value={value} />;
}

export const useNotif = () => {
  const context = React.useContext(NotifContext);
  if (!context)
    throw new Error('NotifContext must be called in Notif Provider');
  return context;
};

export default NotifProvider;
