import styled from 'styled-components';
import Button from 'components/Button';

/**
 * BaseButtonActivation
 * @component
 *
 */
const BaseButtonActivation = styled(Button)`
  && {
    background-color: ${({ bgcolor }) => bgcolor};
    color: ${({ textcolor }) => textcolor};
    border: 2px solid ${({ bordercolor }) => bordercolor};
    width: ${({ width }) => width || '220px'};
    justify-content: space-around;
    border-radius: ${({ borderradius }) => borderradius || '50px'};
    padding: ${({ padding }) => padding || '6px 15px'};
    transition: opacity 0.33s;
  }
  &&:hover {
    background-color: ${({ bgcolor }) => bgcolor};
    opacity: 0.7;
    border: 2px solid ${({ bordercolor }) => bordercolor};
  }
  &&:disabled {
    opacity: 0.5;
    color: ${({ textcolor }) => textcolor};
  }
`;

export default BaseButtonActivation;
