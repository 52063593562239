import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseLinearbarActivation
 * @component
 *
 */
const BaseLinearbarActivation = styled.div`
  border-radius: 7px;
  display: flex;
  background-color: ${({ themeColors }) => themeColors.activation};
  width: ${props => props.value}%;
  height: 30px;
  span {
    margin: auto;
    color: ${({ themeColors }) => themeColors.keep_light} !important;
  }
`;

export default withTheme(BaseLinearbarActivation);
