import React from 'react';
import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

import Title from 'components/Title';
import { withInsight } from 'Insight/contexts/InsightContext';
import { dataOrInsight } from 'utils/functions/insight';

/**
 * @component
 * @see OperatorLayoutInsight/style.js for synchronize height in grid
 */
const BaseCategoriesInsight = styled.div`
  display: grid;
  grid-template-rows: repeat(${({ length }) => `${length}, 1fr`});
`;

const borderRadius = '0 8px 8px 0';

/**
 * BaseCategoriesItem
 * @component
 *
 */
export const BaseCategoriesItem = withInsight(
  withTheme(styled.a`
    text-decoration: none;
    background-color: ${({ active, themeColors }) =>
      active ? themeColors.tertiary : themeColors.secondary};
    display: grid;
    grid-template-columns: 20% 72% 8%;
    min-width: 180px;
    color: ${({ themeColors }) => themeColors.light};
    border-bottom: 1px solid
      ${({ themeColors }) => themeColors.insight_tertiary};
    border-right: 1px solid ${({ themeColors }) => themeColors.insight_tertiary};
    border-radius: ${borderRadius};
    &:first-child {
      border-top: 1px solid ${({ themeColors }) => themeColors.insight_tertiary};
    }
    .logo-insight {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 16px;
      }
    }
    .border {
      background: ${({ active, themeColors, isData }) =>
        active
          ? dataOrInsight(isData, themeColors, 'data')
          : themeColors.secondary};
      border-radius: ${borderRadius};
    }
    .infos-insight {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h3 {
        margin: 0 0 7px 0;
        font-size: 12px;
      }
    }
    .desc-insight {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-weight: normal;
      color: ${({ themeColors }) => themeColors.light};
      opacity: ${({ active }) => (active ? '1' : '0.65')};
      text-transform: none;
    }
    &:hover {
      background-color: ${({ themeColors }) => themeColors.tertiary};
      .desc-insight {
        color: ${({ themeColors }) => themeColors.light};
      }
      .border {
        background: ${({ active, themeColors, isData }) =>
          active
            ? dataOrInsight(isData, themeColors, 'data')
            : themeColors.tertiary};
      }
    }
    @media (min-width: 1200px) {
      .infos-insight {
        h3 {
          font-size: 13px;
        }
      }
      .logo-insight {
        img {
          height: 23px;
        }
      }
    }
    @media (min-width: 1900px) {
      .infos-insight {
        h3 {
          font-size: 16px;
        }
      }
      .logo-insight {
        img {
          height: 24px;
        }
      }
    }
  `)
);

export const CategoriesTitle = props => <Title small {...props} />;
export default BaseCategoriesInsight;
