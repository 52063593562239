import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { useUser } from 'contexts/UserContext';
import { useTheme } from 'contexts/ThemeContext';
import Header from 'components/Header';
import { ButtonConnect } from 'components/Button';

import constants from 'utils/constants';

import BaseLinkMail, { ConnectBlock } from './style';

const { routes } = constants;

/**
 * @component
 * @param {children} param0
 *
 */
function HeaderContainer(props) {
  const { history } = props;
  const { isAuthenticated, userLocation, setUserLocation } = useUser();
  const { toggleTheme, isThemeLight } = useTheme();
  const connectUser = () => {
    history.push(isAuthenticated ? routes.DASHBOARD : routes.CONNECT);
  };
  return (
    <Header
      isConnected={isAuthenticated}
      setUserLocation={setUserLocation}
      toggleTheme={toggleTheme}
      isLightMode={isThemeLight}
      userLocation={userLocation}
    >
      <ConnectBlock>
        <BaseLinkMail href="mailto:contact@ermes.ai" className="test-link">
          Testez ERMES gratuitement pendant 2 mois
        </BaseLinkMail>
        <ButtonConnect onClick={connectUser} className="btn" type="button">
          {isAuthenticated ? 'DASHBOARD' : 'SE CONNECTER'}
        </ButtonConnect>
      </ConnectBlock>
    </Header>
  );
}

HeaderContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(HeaderContainer);
