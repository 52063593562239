import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * Wrapper
 *
 */
const Wrapper = styled.div`
  overflow: hidden;
`;

/**
 * @component
 *
 */
const FirstPage = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  .first-view-bg-colored {
    display: grid;
    grid-template-rows: 9fr 6fr 9fr;
    height: 500px;
    min-height: 72vh;
    margin: 16px;
    border-radius: 3px;
    box-shadow: 0 0 2px 1px ${({ themeColors }) => themeColors.shadow};
    background-color: ${({ themeColors }) => themeColors.secondary};
  }
`);

/**
 * SecondPage
 * @component
 *
 */
const SecondPage = withTheme(styled.div`
  display: grid;
  grid-template-rows: 52px 4fr 52px;
  grid-row-gap: 16px;
`);

/**
 * SecondPageTitle
 * @component
 *
 */
const SecondPageTitle = withTheme(styled.div`
  display: grid;
  grid-template-rows: 52px 40px 4fr 52px;
  grid-row-gap: 16px;
`);

/**
 * ThirdPage
 * @component
 *
 */
const ThirdPage = withTheme(styled.div`
  display: grid;
  grid-template-rows: 52px 1fr 64px;
  grid-row-gap: 24px;
`);

/**
 * ThirdPageTitle
 * @component
 *
 */
const ThirdPageTitle = withTheme(styled.div`
  display: grid;
  grid-template-rows: 52px 30px 1fr 64px;
  grid-row-gap: 24px;
`);

/**
 * getTranslate
 * @param {Number} position (-2,-1,0,1 or 2)
 * @return String
 *
 */
const getTranslate = position => {
  return `translate(${position * 100}%)`;
};

/**
 * getHeight
 * @param {Number} position (-2,-1,0,1 or 2)
 * @return String
 *
 */
const getHeight = position => {
  if (position > 0 || position < 0) return '0';
  return '100%';
};

/**
 * AnimatedSwitch
 * @component
 *
 */
const AnimatedSwitch = styled.div.attrs(({ position }) => ({
  style: {
    transform: getTranslate(position),
    height: getHeight(position),
    opacity: position === 0 ? '1' : '0'
  }
}))`
  position: relative;
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
`;

export default {
  FirstPage,
  SecondPage,
  SecondPageTitle,
  ThirdPage,
  ThirdPageTitle,
  Wrapper,
  AnimatedSwitch
};
