import React from 'react';
import { Waiter, STATUS_BASE_ANALYZED } from 'utils/api/temelioBase';
import { useApi } from 'utils/hooks/useAPI';

// Each blob is 512kB
const BLOB_SIZE = 512 * 1024;

function getUniqueName(file, filename) {
  if (filename) return filename;
  if (!file) return null;

  const splitFilename = file.name.split('.');
  const ext = splitFilename.pop();
  return `${splitFilename
    .join('.')
    // eslint-disable-next-line no-useless-escape
    .replace(/[ \(\)]/g, '')}_${new Date().getTime()}.${ext}`;
}

/**
 * Generates Axios config for next request
 *
 * @param {File} file - Total file to send
 * @param {number} offset - Offset to use for next blob
 * @param {string} Authorization - Token granted by temelio API
 * @returns {AxiosRequestConfig} - Next request config
 */
function getConfig(file, offset, Authorization) {
  if (!file) {
    return null;
  }
  let end = offset + BLOB_SIZE;
  if (end > file.size) {
    end = file.size;
  }

  const blob = file.slice(offset, offset + BLOB_SIZE);
  blob.start = offset;
  blob.name = file.name;
  return {
    method: 'post',
    params: {
      name: file.name,
      size: file.size,
      offset
    },
    data: blob,
    headers: {
      Authorization: `bearer: ${Authorization}`,
      'Content-Type': 'application/json'
    }
  };
}

/**
 * Handles rendering logic of file upload
 *
 * @export
 * @param {string} token - Token granted by Temelio API
 * @returns {Object} hooks rendering computed data
 * progress: number => percentage of completion for file upload
 * setFile: function => function to call with file to upload
 * loading: is the request loading?
 * error: did something bad happen?
 * data: data returned by API
 * cancel: function to cancel the requests
 */
export function useFileUpload(token, filename = null) {
  const [progress, setProgress] = React.useState(0);
  const [file, setFile] = React.useState(null);
  const [offset, setOffset] = React.useState(0);
  const name = file ? file.name : null;
  const size = file ? file.size : null;
  const [config, setConfig] = React.useState(getConfig(file, offset, token));
  const [waiter, setWaiter] = React.useState(null);
  const [status, setStatus] = React.useState('uploading');

  React.useEffect(() => {
    if (file && token) setConfig(getConfig(file, offset, token));
  }, [offset, token, file]);

  React.useEffect(() => {
    if (progress !== 90) {
      return;
    }
    const tmpWaiter = new Waiter(token, name, STATUS_BASE_ANALYZED, base => {
      if (base) setStatus(base.status);
    });
    tmpWaiter.start().then(() => setProgress(100));
    setWaiter(tmpWaiter);
  }, [token, name, progress]);

  const { loading, error, data, cancel } = useApi(
    `${process.env.REACT_APP_TEMELIO_API_URL}/bases`,
    config,
    !config || offset >= size
  );
  if (data && offset < size && data.offset !== offset) {
    setOffset(data.offset);
    setProgress((data.offset / size) * 90);
  }
  return {
    progress,
    file,
    setFile(newFile) {
      setProgress(0);
      if (!filename) setOffset(0);
      if (!filename) setStatus('uploading');
      setFile(new File([newFile], getUniqueName(newFile, filename)));
    },
    loading,
    error,
    data,
    cancel,
    status,
    resetFileUpload() {
      setProgress(0);
      setOffset(0);
      setFile(null);
      if (waiter) waiter.cancel();
      setWaiter(null);
    },
    fromHistory: !!filename,
    setOffset
  };
}

export default useFileUpload;
