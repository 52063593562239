import React from 'react';

export default {
  dataHomeArrow(options) {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.00016 0.333252L5.82516 1.50825L10.4752 6.16658H0.333496V7.83325H10.4752L5.82516 12.4916L7.00016 13.6666L13.6668 6.99992L7.00016 0.333252Z"
          fill="#EEF4F9"
          {...options}
        />
      </svg>
    );
  },
  dataRemoveFile(options) {
    return (
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9706 16.9706C20.0948 13.8464 20.0948 8.78106 16.9706 5.65687C13.8464 2.53267 8.78105 2.53267 5.65685 5.65687C2.53266 8.78106 2.53266 13.8464 5.65685 16.9706C8.78105 20.0948 13.8464 20.0948 16.9706 16.9706ZM12.2026 11.3137L14.8694 8.64692L13.9805 7.75798L11.3137 10.4248L8.6469 7.75798L7.75797 8.64692L10.4248 11.3137L7.75797 13.9805L8.6469 14.8695L11.3137 12.2027L13.9805 14.8695L14.8694 13.9805L12.2026 11.3137Z"
          fill="#4C4E58"
          {...options}
        />
      </svg>
    );
  }
};
