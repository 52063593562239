import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/Button';
import { useTheme, withTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';
import { withInsight, useInsight } from 'Insight/contexts/InsightContext';
import { dataOrInsight } from 'utils/functions/insight';

const { fontSizes } = constants;

/**
 * BaseButtonInsight
 * @component
 *
 */
const BaseButtonInsight = withInsight(styled(Button)`
  && {
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 27px;
    border: 2px solid
      ${({ themeColors, isData }) =>
        dataOrInsight(isData, themeColors, 'data_action_border')};
    color: ${({ color }) => color};
    cursor: pointer;
    display: inline-flex;
    font-size: ${fontSizes.extraSmall};
    font-weight: bold;
    margin: 4px 8px 0px;
    outline: none;
    padding: 3px 5px;
    text-transform: uppercase;
    &&:hover {
      opacity: 0.7;
      color: ${({ themeColors, isData }) =>
        dataOrInsight(isData, themeColors, 'data_action_border')};
    }
    @media (min-width: 1024px) {
      margin: 0 4px;
      padding: 8px 6px;
    }
    @media (min-width: 1440px) {
      font-size: ${fontSizes.small};
      padding: 6px 12px;
      margin: 0 12px;
    }
    @media (min-width: 1800px) {
      font-size: ${fontSizes.medium};
      margin: 0 10px;
    }
  }
`);

/**
 * DefaultButtonInsight
 * @component
 *
 */
export const DefaultButtonInsight = ({ inversed, ...rest }) => {
  const { themeColors } = useTheme();
  const { isData } = useInsight();
  return (
    <BaseButtonInsight
      color={
        inversed
          ? dataOrInsight(isData, themeColors, 'data')
          : themeColors.light
      }
      bgColor={
        inversed ? 'transparent' : dataOrInsight(isData, themeColors, 'data')
      }
      themeColors={themeColors}
      {...rest}
    />
  );
};
DefaultButtonInsight.defaultProps = { inversed: false };
DefaultButtonInsight.propTypes = { inversed: PropTypes.bool };

/**
 * UnColoredButton
 * @component
 *
 */
export const UnColoredButton = ({ inversed, ...rest }) => {
  const { themeColors } = useTheme();
  const { isData } = useInsight();
  return (
    <BaseButtonInsight
      color={
        inversed
          ? dataOrInsight(isData, themeColors, 'data_action_text')
          : themeColors.primary
      }
      bgColor={
        inversed
          ? 'transparent'
          : dataOrInsight(isData, themeColors, 'data_action_border')
      }
      themeColors={themeColors}
      {...rest}
    />
  );
};
UnColoredButton.defaultProps = { inversed: false };
UnColoredButton.propTypes = { inversed: PropTypes.bool };

/**
 * ButtonDeleteInsight
 * @component
 *
 */
export const ButtonDeleteInsight = withTheme(styled(Button)`
  && {
    color: ${({ themeColors }) => themeColors.keep_light};
    background-color: ${({ themeColors }) => themeColors.red};
    border: none;
    border-radius: 4px;
    min-width: 9em;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
  }
`);

export default BaseButtonInsight;
