import React from 'react';
import styled from 'styled-components';

import DrawerUI from '@material-ui/core/Drawer';
import ListItemText from '@material-ui/core/ListItemText';
import Title from 'Funnel/components/TitleFunnel';
import Divider from '@material-ui/core/Divider';
import CloseIconUI from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

import { withTheme } from 'contexts/ThemeContext';
import { withStyles } from '@material-ui/core';

import constants from 'utils/constants';

const { colors, fontFamilies } = constants;

const darkDrawerStyles = () => ({
  paper: {
    backgroundColor: colors.dark.secondary,
    color: colors.dark.light
  }
});
const DarkDrawer = withStyles(darkDrawerStyles)(DrawerUI);

const lightDrawerStyles = () => ({
  paper: {
    backgroundColor: colors.light.secondary,
    color: colors.light.light
  }
});
const LightDrawer = withStyles(lightDrawerStyles)(DrawerUI);

export const Drawer = withTheme(props =>
  props.isThemeLight ? <LightDrawer {...props} /> : <DarkDrawer {...props} />
);

export const TitleDrawer = styled(Title)`
  margin-top: 2rem;
`;

export const FunnelDrawerContainer = withTheme(styled.div`
  width: 500;
  padding-top: 10rem;
  padding-left: 1rem;
  font-family: ${fontFamilies.default};
  hr {
    background-color: ${({ themeColors }) => themeColors.funnel};
  }
`);

export const ListItemDrawerText = withTheme(styled(ListItemText)`
  width: 14rem;
  flex-grow: 0;
  p {
    width: 14rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: ${fontFamilies.default};
    overflow: hidden;
    padding-right: 2rem;
    color: ${({ themeColors }) => themeColors.light};
  }
`);

export const DividerDrawer = styled(Divider)``;

export const DrawerFooter = styled.div`
  margin-top: 3rem;
  margin-left: -1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DrawerFooterTitle = withTheme(styled(Link)`
  color: ${({ themeColors }) => themeColors.funnel};
`);

export const CloseIcon = withTheme(styled(CloseIconUI)`
  color: ${({ themeColors }) => themeColors.funnel};
`);

export const DrawerFooterSubtitle = styled.p``;
