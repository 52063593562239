import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import { TextField } from '@material-ui/core';

/**
 *
 *
 *
 */
export const BaseLinkMail = withTheme(styled.a`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  color: ${({ color, themeColors }) => color || themeColors.secondary};
  padding: ${({ padding }) => padding || 'auto'};
  display: inline-block;
  &.test-link {
    font-size: 1.2em;
    cursor: pointer;
  }
  &:hover {
    text-decoration: underline;
  }
`);

/**
 * Login
 * @component
 *
 */
export const Login = styled.form`
  margin: 30px 30px;
  &.inputForm {
    margin: 100px;
  }
  &.validateButton {
    background-color: linear-gradient(
      90deg,
      #6b5392 0,
      #6b5392 18%,
      #1165b2 60%,
      #00a4d4 100%
    );
    margin: 30px;
  }
`;

/**
 * NavImg
 * @component
 *
 */
export const Logo = styled.img`
  margin: 120px 50px 50px 44vw;
  width: 300px;
  cursor: pointer;
`;

export const LoginInput = withTheme(styled(TextField)`
  margin-top: 0px;
`);

export default {};
