import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseCriteriasGroup
 * @component
 * @see CriteriaContainer
 *
 */
const BaseCriteriasGroup = styled.div`
  overflow-y: auto;
`;

/**
 * BaseCriteriasGroup
 * @component
 * @see CriteriaContainer
 *
 */
export const BaseCriteriasSubGroup = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  .title {
    border-bottom: 1px solid ${({ themeColors }) => themeColors.light};
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 4px;
    margin-bottom: 12px;
  }
  .criteria {
    padding-bottom: 20px;
  }
  @media (min-width: 1800px) {
    font-size: 18px;
    .title {
      font-size: 24px;
      padding-bottom: 8px;
      margin-bottom: 16px;
    }
  }
`);

export default BaseCriteriasGroup;
