import React from 'react';

import { useSegment } from 'Activation/contexts/SegmentContext';
import { useTheme } from 'contexts/ThemeContext';
import { formatNumber } from 'utils/functions';

import WrapperResult from 'Activation/components/WrapperResult';
import IconActivation from 'Activation/components/IconActivation';

import InputActivation from 'Activation/components/InputActivation';
import LabelActivation from 'Activation/components/LabelActivation';
import LinearbarActivation from 'Activation/components/LinearbarActivation';
import SeparatorActivation from 'Activation/components/SeparatorActivation';
import TableActivation from 'Activation/components/TableActivation';
import TitleActivation from 'Activation/components/TitleActivation';
import Tooltip from 'src/components/Tooltip';

const tooltipTitles = {
  'Set Up & Trading':
    '250€ de mise en place de campagne puis 500€ par semaine.',
  'Frais techniques (DSP)':
    'Ceci est le coût qui nous est facturé en moyenne par les DSP. Ermes n’applique aucune marge sur ces coûts. Il peut changer marginalement en fonction de l’évolution de la campagne, le décompte final vous sera transmis en fin de campagne.',
  'Frais Data':
    'Ce sont les coûts d’utilisation de la Data Ermes. Votre contact commercial est disponible pour vous expliquer l’algorithme de valorisation.',
  'Frais Média':
    'Ce sont les coûts qui nous sont facturés par les supports. Aucun écart entre la facture du support et ce qui est réellement dépensé sur la campagne.'
};
const LEVELS_LIST = [1, 2, 3, 4];
const getLevelLabelTitle = level => Object.keys(level)[0];
const getLevelLabelValue = level => Object.values(level)[0];
const getLevelCostTitle = level => Object.keys(level)[1];
const getLevelCostValue = level => Object.values(level)[1];
const getLevelTransition = level => {
  const name = Object.keys(level)[0];
  if (['a', 'e', 'y', 'h', 'u', 'i', 'o'].includes(name[0].toLowerCase())) {
    return `Taux d'${Object.keys(level)[0].toLowerCase()}`;
  }
  return `Taux de ${Object.keys(level)[0].toLowerCase()}`;
};

/**
 * ResultContainer
 * @component
 *
 */
function ResultContainer() {
  const { results, transformationRates } = useSegment();
  const { themeColors } = useTheme();
  const funnel = results.FunnelWithData;
  const costs = results.Costs;
  return (
    <WrapperResult>
      <div className="column-result">
        <TitleActivation small left noMargin>
          <b>RÉPARTITION TRANSPARENTE DES COÛTS</b>
          <SeparatorActivation left />
        </TitleActivation>
        <TableActivation.Container>
          <TableActivation.RowHead length={3} colored isHead>
            <TableActivation.Item />
            <TableActivation.Item>Répartition</TableActivation.Item>
            <TableActivation.Item>Prix</TableActivation.Item>
          </TableActivation.RowHead>
          <TableActivation.Body>
            {Object.entries(costs)
              .filter(([key]) => key !== 'total' && key !== 'CPMTotal')
              .map(([key, { label, percent, price }]) => (
                <TableActivation.RowBody itemCenter key={key} length={3}>
                  <TableActivation.Item left>
                    <Tooltip
                      className="fit-text"
                      label={label}
                      themeColors={themeColors}
                      text={tooltipTitles[label]}
                    />
                    {label}
                  </TableActivation.Item>
                  <TableActivation.Item>
                    {formatNumber(percent, 2)}%
                  </TableActivation.Item>
                  <TableActivation.Item>
                    {formatNumber(price, 2)}€
                  </TableActivation.Item>
                </TableActivation.RowBody>
              ))}
          </TableActivation.Body>
        </TableActivation.Container>
        <TableActivation.Result length={3} key="result">
          <TableActivation.Item left>Total</TableActivation.Item>
          <TableActivation.Item>
            {formatNumber(costs.total.percent, 2)}%
          </TableActivation.Item>
          <TableActivation.Item>
            {formatNumber(costs.total.price, 2)}€
          </TableActivation.Item>
        </TableActivation.Result>
      </div>
      <div className="column-result">
        <TitleActivation small left noMargin>
          <b>FUNNEL PRÉVISIONNEL</b>
          <SeparatorActivation left />
        </TitleActivation>
        <LabelActivation>
          Performances prévisionnelles établies sur base de moyennes de marché.
          Vous pouvez changer les taux de conversions par étape en fonction de
          vos performances attendues.
        </LabelActivation>
        <br />
        {funnel &&
          LEVELS_LIST.map(num => {
            const level = funnel[`level${num}`];
            const txLevel = funnel[`txLevel${num}`];
            const transformationRate = transformationRates[`level${num}`];
            return (
              <React.Fragment key={num}>
                {num > 1 && (
                  <div className="row-result info">
                    <InputActivation
                      type="number"
                      min={txLevel.min}
                      max={txLevel.max}
                      value={
                        formatNumber(transformationRate * 100, 2) ||
                        formatNumber(txLevel.default * 100, 2)
                      }
                      step="any"
                      suffix={<span>%</span>}
                      disabled
                    />

                    <IconActivation icon="arrowVerticalDouble" />
                    <LabelActivation>
                      {getLevelTransition(level)}
                    </LabelActivation>
                  </div>
                )}
                <div className="row-result bar">
                  <LinearbarActivation pourcentage={100 - (num - 1) * 10}>
                    <>
                      {getLevelLabelTitle(level)}:&nbsp;
                      {formatNumber(getLevelLabelValue(level), 0)}
                    </>
                  </LinearbarActivation>
                  <span>
                    <LabelActivation>
                      {getLevelCostTitle(level)}:&nbsp;
                    </LabelActivation>
                    <LabelActivation bold>
                      {formatNumber(getLevelCostValue(level), 1)}€
                    </LabelActivation>
                  </span>
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </WrapperResult>
  );
}

export default ResultContainer;
