import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useUser } from 'contexts/UserContext';
import ExternalRedirect from 'components/ExternalRedirect';
import LoadingAuth from './LoadingAuth';

/**
 * AuthenticatedRoute
 * @desc a Route decorator for authenticated needed routes
 * @return Route component
 *
 */
function AuthenticatedRoute(props) {
  const { isAuthenticated, isLoadingAuth } = useUser();
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={renderProps => (
        <LoadingAuth
          isLoading={isLoadingAuth}
          isAuthenticated={isAuthenticated}
        >
          <Component {...renderProps} />
          <ExternalRedirect />
        </LoadingAuth>
      )}
    />
  );
}
AuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default AuthenticatedRoute;
