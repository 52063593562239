import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Title from 'components/Title';
import Text from 'components/Text';
import Modal from 'Data/components/Modal';
import ButtonFill from 'Data/components/ButtonFill';
import { useTheme } from 'contexts/ThemeContext';
import { formatNumber } from 'utils/functions';
import { StyledSpan } from './style';

/**
 * ModalVerifFile
 * @component
 */
function ModalTarget({ onClose, matchingPercentage, ...props }) {
  const { themeColors } = useTheme();

  return (
    <Modal
      {...props}
      onClose={onClose}
      style={{ width: '450px', backgroundColor: themeColors.primary }}
    >
      <Title small fontSize={24} style={{ color: themeColors.light }}>
        SOUS-CIBLAGE
      </Title>
      <Text style={{ color: themeColors.light }}>
        Suite au matching effectué avec les 25 millions de profils Ermes, et la
        digitalisation de{' '}
        <StyledSpan>{formatNumber(matchingPercentage)}%</StyledSpan> de vos
        profils, découvrez comment votre base de données est répartie selon
        notre taxonomie de 350 critères de segmentation.
      </Text>

      <Grid container justify="center" style={{ marginTop: '22px' }}>
        <ButtonFill width="213px" onClick={onClose}>
          TERMINER
        </ButtonFill>
      </Grid>
    </Modal>
  );
}
ModalTarget.defaultProps = {
  open: true,
  onClose: () => {}
};
ModalTarget.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  matchingPercentage: PropTypes.number.isRequired
};

export default ModalTarget;
