import React from 'react';
// import PropTypes from 'prop-types'

import BaseAnomalyTable from './style';

/**
 * AnomalyTableFunnel
 * @component
 *
 */
function AnomalyTableFunnel(props) {
  return <BaseAnomalyTable {...props} />;
}
AnomalyTableFunnel.defaultProps = {};
AnomalyTableFunnel.propTypes = {};

export default AnomalyTableFunnel;
