import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * @component
 *
 */
const BaseContent = withTheme(styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${({ themeColors }) => themeColors.light};
  align-items: center;

  .description {
    display: flex;
    width: 100%;
    padding: 20px 20px;
    justify-content: flex-start;
    white-space: pre-wrap;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 500;
  }
`);

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 20px;
`;

export default BaseContent;
