import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import Button from '@material-ui/core/Button';
import constants from 'utils/constants';

const { fontSizes } = constants;

/**
 * TableBaseline
 * @component
 *
 */
const TableBaseline = styled.table`
  color: #fff;
  width: 100%;
  padding: 0 30px;
  border-radius: 4px;
  border-spacing: 0px;
  font-weight: normal;
  white-space: pre-wrap;
  padding-top: 8px;
`;

/**
 * RowBaseline
 * @component
 *
 */
export const RowBaseline = styled.tr`
  height: ${({ height }) => height || 'auto'};
`;

/**
 * ColumnBaseline
 * @component
 *
 */
export const ColumnBaseline = withTheme(styled.td`
  font-weight: normal;
  text-align: ${({ textAlign }) => textAlign || 'justify'};
  padding: ${({ padding }) => padding || '30px'} 0;
  background-color: ${({ bgColor, themeColors }) =>
    themeColors[bgColor] || themeColors.secondary};
  color: ${({ color, themeColors }) => themeColors[color] || themeColors.light};
  ${({ paddingLeft }) => (paddingLeft ? `padding-left: ${paddingLeft}px` : '')};
  .div {
    display: inline-flex;
  }
`);

/**
 * TitleBaseline
 * @component
 *
 */
export const TitleBaseline = styled.div`
  height: 55px;
`;

/**
 * DataBaseline
 * @component
 *
 */
export const DataBaseline = styled.div`
  font-size: ${({ light }) => (light ? fontSizes.medium : fontSizes.large)};
  font-weight: bold;
  display: inline-flex;
`;

export const ButtonTab = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
  background-color: transparent;
  float: left;
  left: 47%;
  @media (min-width: 1024px) {
    left: 52%;
  }
  @media (min-width: 1440px) {
    left: 65%;
  }
`;

export const ButtonSwitch = withTheme(styled(Button)`
  && {
    border: 1px solid ${({ themeColors }) => themeColors.light};
    width: 160px;
    line-height: 29px;
    text-transform: none;
    text-align: center;
    border-radius: 4px;
    padding: 0;
    cursor: pointer;
    color: ${({ themeColors }) => themeColors.light};
  }
  &&:hover {
    background-color: ${({ themeColors }) => themeColors.light};
    color: ${({ themeColors }) => themeColors.dark};
    font-weight: bold;
  }
  &&.active {
    background-color: ${({ themeColors }) => themeColors.light};
    color: ${({ themeColors }) => themeColors.dark};
    font-weight: bold;
  }
`);

export const ContentBaseline = styled.div`
  margin: 1em;
`;

export const ContentTab = styled.div`
  height: 25em;
  width: 35em;
  @media (min-width: 1024px) {
    width: 39em;
  }
  @media (min-width: 1440px) {
    width: 59em;
  }
  @media (min-width: 1800px) {
    width: 69em;
  }
`;

export const ContentTabKpi = styled.div`
  height: 25px;
  width: 12em;
`;

export const ErrorMessage = withTheme(styled.p`
  color: ${({ themeColors }) => themeColors.light};
  font-size: 14px;
  padding: 10px 30px;
`);

export default TableBaseline;
