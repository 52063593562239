/**
 * ExternalRedirect
 * @return null to render component
 *
 */

function ExternalRedirect() {
  window.location.href = process.env.REACT_APP_HOME_URL;
  return null;
}

export default ExternalRedirect;
