import React from 'react';
import PropTypes from 'prop-types';

import BaseOperatedCriteria, { OperatedCriteriaRemoveButton } from './style';
import TooltipInsight from '../TooltipInsight';

/**
 * OperatedCriteria
 * @component
 * @see SelectedListContainer
 *
 */
function OperatedCriteriaInsight(props) {
  const {
    title,
    isExcluded,
    toggleExclude,
    isFirst,
    isLast,
    removeCriteria,
    interactive
  } = props;
  const [isHovering, setIsHovering] = React.useState(false);
  const handleClick = e => {
    e.preventDefault();
    toggleExclude();
  };
  return (
    <BaseOperatedCriteria
      href="#"
      inversed={isExcluded}
      isFirst={isFirst}
      isLast={isLast}
      onClick={handleClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {interactive ? (
        <span className="criteria-label" data-tip data-for="exclude">
          {isExcluded ? `Hors ${title}` : title}
        </span>
      ) : (
        <span className="criteria-label">
          {isExcluded ? `Hors ${title}` : title}
        </span>
      )}
      {isHovering && interactive && (
        <>
          <OperatedCriteriaRemoveButton type="button" onClick={removeCriteria}>
            x
          </OperatedCriteriaRemoveButton>
        </>
      )}
      <TooltipInsight
        id="exclude"
        text="Cliquez pour exclure / inclure"
        noIcon
      />
    </BaseOperatedCriteria>
  );
}
OperatedCriteriaInsight.defaultProps = {
  interactive: true,
  toggleExclude: () => {},
  removeCriteria: () => {}
};
OperatedCriteriaInsight.propTypes = {
  isExcluded: PropTypes.bool.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleExclude: PropTypes.func,
  removeCriteria: PropTypes.func,
  interactive: PropTypes.bool
};

export default OperatedCriteriaInsight;
