import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from 'components/ErrorPage';

/**
 * ErrorBoundary
 * @component
 *
 */
class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  /**
   * @param error
   * @param info
   *
   */
  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorPage isServer />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
