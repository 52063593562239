import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * HistoryWrapper
 * @component
 *
 */
const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

/**
 * BaseHistoryTitle
 * @components
 * @deprecated
 */
export const BaseHistoryTitle = withTheme(styled.h1`
  max-width: 730px;
  margin: 0px auto;
  text-align: center;
  font-weight: 500;
  color: ${({ color, themeColors }) => color || themeColors.light};
  font-weight: 300;
  font-weight: bold;
  font-size: 34px;
  margin: 20px auto 0px auto;
  @media (min-width: 1800px) {
    font-size: 40px;
    margin: 50px auto 0px auto;
  }
`);
export default HistoryWrapper;
