import React from 'react';
import PropTypes from 'prop-types';

import BaseResultLayout, { BaseResultColumn } from './style';

/**
 * ResultInsightLayout
 * @component
 * @see ResultContainer
 *
 */
function ResultInsightLayout(props) {
  const { children } = props;
  return <BaseResultLayout>{children}</BaseResultLayout>;
}
ResultInsightLayout.defaultProps = {};
ResultInsightLayout.propTypes = {
  children: PropTypes.node.isRequired
};

/**
 * ResultColumn
 * @component
 *
 */
export const ResultColumn = props => {
  const { design } = props;
  const isResult = design === 'results';
  return <BaseResultColumn {...props} isResult={isResult} />;
};
ResultColumn.defaultProps = {
  noWrap: false
};
ResultColumn.propTypes = {
  design: PropTypes.oneOf(['results', 'actions']).isRequired,
  noWrap: PropTypes.bool
};

export default ResultInsightLayout;
