import React from 'react';
import PropTypes from 'prop-types';

// import { downloadPDFInsight } from 'src/utils/api/pdf';
// import { ActionButton } from 'Insight/components/ButtonInsight';
// import { useNotif } from 'contexts/NotifContext';
// import { useUser } from 'contexts/UserContext';
// import { useInsight } from 'Insight/contexts/InsightContext';
// import { useResult } from 'Insight/contexts/ResultContext';

import CriteriasFormInsight, {
  InsightTitle,
  InsightDesc,
  InsightLegend,
  Header
} from 'Insight/components/CriteriasFormInsight';
import MapInsight from 'Insight/components/MapInsight';
import Tooltip from 'components/Tooltip';
import MapDepartmnentInsight from 'Insight/components/MapDepartmnentInsight';
import CriteriasGroupInsight, {
  CriteriasSubGroupInsight
} from 'Insight/components/CriteriasGroupInsight';
import CriteriaInsight from 'Insight/components/CriteriaInsight';

import {
  getPercent,
  getDepartement
  // getSelectedCategoriesForPdf
} from './functions';

const CRITERIA_DESC =
  'Sélectionner vos critères de ciblage, ajuster vos résultats avec les connecteurs ET/OU, et activer votre audience cible.';
const isMap = subCategory => subCategory.name.toLowerCase() === 'départements';

function CriteriaContainer({
  category,
  tooltip,
  setTooltip,
  categoryKey,
  isValueSelected,
  toggleValueSelected
}) {
  // const { categories, operatorsGroups } = useCategory();
  // const { results /* , socioRes, typoRes, geoRes */ } = useResult();
  /*
    const { notify } = useNotif();
    const { user } = useUser();
    const { name } = useInsight();

    const getCategoryName = categoryKey => {
      const cat = categories.find(b => b.key === categoryKey);
      return cat.name;
    };
  */
  /*
    let insight = {
      category: {
        criteria: [],
        subCategories: []
      }
    };
  */
  const subCategories = category.subCategories.map(subCategory => {
    return {
      ...subCategory,
      name:
        category.subCategories.length === 1 && subCategory.name === 'Autres'
          ? ''
          : subCategory.name
    };
  });
  const hasInsee =
    subCategories
      .map(subCategory => subCategory.criterias)
      .flat()
      .filter(
        criteria =>
          !!criteria.insight.audience && !!criteria.insight.audience.insee
      ).length > 0;

  return (
    <CriteriasFormInsight hasInsee={hasInsee}>
      <Header>
        <InsightTitle>Comprendre sa cible</InsightTitle>
        {/* <ActionButton
            inversed
            height="40px"
            onClick={async () => {
              try {
                insight.category.subCategories = [
                  socioRes.data.insightResult.category.subCategories,
                  typoRes.data.insightResult.category.subCategories,
                  geoRes.data.insightResult.category.subCategories
                ];
                insight = {
                  ...insight,
                  reminderedCategories: getSelectedCategoriesForPdf(
                    operatorsGroups,
                    getCategoryName
                  ),
                  userName: user.attributes['custom:nickname'],
                  name,
                  nbProfiles: results.nbProfiles,
                  nbCookies: results.nbCookies,
                  nbEmails: results.nbEmails
                };
                await downloadPDFInsight(insight, notify, user);
              } catch (e) {
                console.error(e);
              }
            }}
          >
            Export PDF
          </ActionButton>
          */}
      </Header>
      <InsightDesc>{CRITERIA_DESC}</InsightDesc>
      <InsightLegend hasInsee={hasInsee} hasData={subCategories.length !== 0} />
      <CriteriasGroupInsight>
        {subCategories.map(subCategory => (
          <React.Fragment key={subCategory.key}>
            {isMap(subCategory) && (
              <React.Fragment>
                <MapInsight data-tip data-for="tooltip">
                  {subCategory.criterias.map(criteria => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a key={criteria.key} data-tip data-for="criteria">
                      <MapDepartmnentInsight
                        department={getDepartement(criteria.name)}
                        title={criteria.name}
                        checked={isValueSelected(criteria.key)}
                        setTooltip={setTooltip}
                        toggleCheck={() =>
                          toggleValueSelected({
                            criteria,
                            subCategoryKEY: subCategory.key,
                            categoryKEY: categoryKey
                          })
                        }
                      />
                    </a>
                  ))}
                </MapInsight>
                <Tooltip id="criteria" text={tooltip} />
              </React.Fragment>
            )}
            <CriteriasSubGroupInsight
              hideTitle={!subCategories.length}
              title={subCategory.name}
            >
              {[...subCategory.criterias].map(criteria => (
                <CriteriaInsight
                  key={criteria.key}
                  ermes={getPercent(criteria.insight.audience, 'all')}
                  insee={getPercent(criteria.insight.audience, 'insee')}
                  title={criteria.name}
                  selectable
                  description={criteria.description}
                  checked={isValueSelected(criteria.key)}
                  hasInsee={hasInsee}
                  toggleCheck={() =>
                    toggleValueSelected({
                      criteria,
                      subCategoryKEY: subCategory.key,
                      categoryKEY: categoryKey
                    })
                  }
                />
              ))}
            </CriteriasSubGroupInsight>
          </React.Fragment>
        ))}
      </CriteriasGroupInsight>
    </CriteriasFormInsight>
  );
}

CriteriaContainer.defaultProps = {};
CriteriaContainer.propTypes = {
  category: PropTypes.shape().isRequired,
  tooltip: PropTypes.string.isRequired,
  setTooltip: PropTypes.func.isRequired,
  categoryKey: PropTypes.string.isRequired,
  isValueSelected: PropTypes.bool.isRequired,
  toggleValueSelected: PropTypes.func.isRequired
};

export default CriteriaContainer;
