import React from 'react';
import Footer from 'components/Footer';

/**
 * @component
 * @param {children} param0
 */
function FooterContainer() {
  return (
    <Footer>
      <pre className="copyright">© 2019 Ermes | </pre>
      <div>
        <a href="mailto:contact@ermes.ai">contact@ermes.ai</a>
      </div>
    </Footer>
  );
}

export default FooterContainer;
