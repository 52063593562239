import React from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { FUNNEL } from 'utils/graphQL';
import { useUser } from 'contexts/UserContext';

import Loading from 'components/Loading';
import HistoryFunnel, {
  HistoryFunnelItem
} from 'Funnel/components/HistoryFunnel';

/**
 * HistoryFunnelContainer
 * @component
 * @see HistoryContainer
 * TODO:: creer les composants Loading, remove panel
 *
 *
 */
function HistoryFunnelContainer() {
  const { isAuthenticated } = useUser();
  const variables = { limit: 100 };
  const response = useQuery(FUNNEL.LIST_FUNNEL, {
    variables,
    skip: !isAuthenticated
  });
  const [deleteFunnelFn] = useMutation(FUNNEL.DELETE_FUNNEL);
  if (response.loading) {
    return <Loading loading={response.loading} />;
  }
  if (response.error) {
    throw response.error;
  }
  const historyFunnel = response.data.funnels || [];
  return (
    <HistoryFunnel>
      {historyFunnel.map(oneFunnel => (
        <HistoryFunnelItem
          key={oneFunnel.id}
          funnel={oneFunnel}
          deleteFunnel={deleteFunnelFn}
          refetch={response.refetch}
        />
      ))}
    </HistoryFunnel>
  );
}

export default HistoryFunnelContainer;
