import moment from 'moment';

function postProcessing(response, format = 'DD/MM/YYYY', mergeFromApi = true) {
  /**
   * Convert timeseries dates before sending back response
   * mergeFromApi is usefull when the data come from a Funnel saved in DB
   * So we don't need to merge with old funnel (in modules/Funnel/index.js)
   */
  if (typeof response.data === 'string') {
    const regex = /Infinity/gi;
    response.data = JSON.parse(response.data.replace(regex, '0'));
  }
  const momentFormat = format
    ? date => moment(date, format, 'fr', true)
    : date => moment(date);
  const timeserieKeys = [
    'anom',
    'budgets',
    'dayoff',
    'kpi1',
    'kpi2',
    'kpi3',
    'kpi4',
    'volume1',
    'volume2',
    'volume3',
    'volume4',
    'cr1_2',
    'cr2_3',
    'cr3_4'
  ];
  function convertDateInArray(array) {
    return array
      .map(dataPoint => [momentFormat(dataPoint[0]), dataPoint[1]])
      .sort(
        (dataPoint1, dataPoint2) =>
          dataPoint1[0].valueOf() - dataPoint2[0].valueOf()
      );
  }
  if (response.data.timeseries) {
    timeserieKeys.forEach(key => {
      response.data.timeseries[key] = convertDateInArray(
        response.data.timeseries[key]
      );
      /*
       * This block converts date in 'Sun 25 04 2019' format to '25/04/2018'.
       * This is needed because JSON.stringify wrecks montent format.
       * It checks if precedent formatting failed and tries to format a second
       * time following hard-coded format.
       * See src/utils/api/ermes/index.js for more context.
       */
      if (key === 'anom') {
        response.data.timeseries[key].map(elem => {
          if (!elem[0].isValid()) {
            // eslint-disable-next-line no-param-reassign
            elem[0] = moment(
              // eslint-disable-next-line no-underscore-dangle
              moment(elem[0]._i).format('DD/MM/YYYY'),
              'DD/MM/YYYY',
              'fr'
            );
          }
          return elem;
        });
      }
    });
  }
  if (response.data.forecast) {
    response.data.forecast.fkpi4 = convertDateInArray(
      response.data.forecast.fkpi4
    );
    response.data.forecast.fakpi4 = convertDateInArray(
      response.data.forecast.fakpi4
    );
  }
  if (response.data.anomalies) {
    response.data.anomalies = response.data.anomalies
      .map(dataPoint => ({
        ...dataPoint,
        date: momentFormat(dataPoint.date)
      }))
      .sort((a, b) => a.date.valueOf() - b.date.valueOf());
  }

  if (mergeFromApi) {
    const funnelUpdatedFromAPi = response.data.oldFunnel;
    response.data.oldFunnel = undefined;
    funnelUpdatedFromAPi.funnelData = {
      ...funnelUpdatedFromAPi.funnelData,
      ...response.data
    };
    return funnelUpdatedFromAPi;
  }
  return response.data;
}

export default postProcessing;
