import React from 'react';
import { frenchPaths } from 'components/PlaneMap';
import PropTypes from 'prop-types';

import BaseDepartment from './style';

/**
 * MapDepartmnentInsight
 * @component
 *
 */
function MapDepartmnentInsight(props) {
  const { department, toggleCheck, checked, title, setTooltip } = props;
  const id = department < 10 ? `${department}` : department;
  const currentPath = frenchPaths.find(({ dataId }) => dataId === id);
  const handleHover = () => {
    setTooltip(title);
  };
  React.useEffect(() => {}, [checked]);
  return (
    <BaseDepartment
      checked={checked}
      onClick={toggleCheck}
      title={title}
      id={id}
      onMouseOver={handleHover}
      onFocus={handleHover}
      {...currentPath}
    />
  );
}
MapDepartmnentInsight.propTypes = {
  department: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  toggleCheck: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setTooltip: PropTypes.func.isRequired
};
export default MapDepartmnentInsight;
