import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import Title from 'components/Title';

/**
 * BaseTitleActivaion
 * @component
 *
 */
const BaseTitleActivation = styled(Title)`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || 'center'};
  color: ${({ themeColors }) => themeColors.light};
  margin-top: 4px;
  font-size: ${({ fontsize }) => fontsize || 'inherit'};
  font-weight: ${({ fontWeight }) => fontWeight || 'inherit'};
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '20px')};
`;

export default withTheme(BaseTitleActivation);
