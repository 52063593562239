import styled from 'styled-components';
import Loading from 'components/Loading';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseLoadingActivation
 * @component
 *
 */
const BaseLoadingActivation = styled(Loading)``;

export default withTheme(BaseLoadingActivation);
