function preProcessing(funnel) {
  /**
   * Convert timeseries dates before sending back payload
   */
  const payload = { ...funnel };
  payload.objectifed = payload.objectifed.filter(o => o.value !== 'NaN');
  const refData = payload.funnelData;
  const timeserieKeys = [
    'budgets',
    'kpi1',
    'kpi2',
    'kpi3',
    'kpi4',
    'volume1',
    'volume2',
    'volume3',
    'volume4',
    'cr1_2',
    'cr2_3',
    'cr3_4'
  ];
  function convertDateInArray(array) {
    return array.map(dataPoint => {
      if (typeof dataPoint[0] === 'string') {
        return [dataPoint[0], dataPoint[1]];
      }
      return [dataPoint[0].format('DD/MM/YYYY'), dataPoint[1]];
    });
  }
  if (refData.timeseries) {
    timeserieKeys.forEach(key => {
      refData.timeseries = {
        ...refData.timeseries,
        [key]: convertDateInArray(refData.timeseries[key])
      };
    });
  }
  if (refData.forecast && refData.forecast.fkpi4) {
    refData.forecast = {
      ...refData.forecast,
      fkpi4: convertDateInArray(refData.forecast.fkpi4),
      fakpi4: convertDateInArray(refData.forecast.fakpi4)
    };
  }
  if (refData.anomalies) {
    refData.anomalies = refData.anomalies.map(dataPoint => {
      if (typeof dataPoint.date === 'string') {
        return {
          ...dataPoint,
          date: dataPoint.date
        };
      }
      return {
        ...dataPoint,
        date: dataPoint.date.format('DD/MM/YYYY')
      };
    });
  }
  return Promise.resolve(payload);
}

export default preProcessing;
