import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'components/Grid';
import { withTheme } from 'contexts/ThemeContext';

/**
 * ConnectorForm
 * @component
 *
 */
const ConnectorForm = rest => (
  <Grid
    item
    container
    direction="row"
    justify="space-around"
    alignItems="center"
    xs={12}
    {...rest}
  />
);

ConnectorForm.propTypes = {
  themeColors: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withTheme(ConnectorForm);
