import React from 'react';

export default {
  tooltipHelp(options) {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9.9 15.3H8.1V13.5H9.9V15.3ZM11.763 8.325L10.953 9.153C10.305 9.81 9.9 10.35 9.9 11.7H8.1V11.25C8.1 10.26 8.505 9.36 9.153 8.703L10.269 7.569C10.602 7.245 10.8 6.795 10.8 6.3C10.8 5.31 9.99 4.5 9 4.5C8.01 4.5 7.2 5.31 7.2 6.3H5.4C5.4 4.311 7.011 2.7 9 2.7C10.989 2.7 12.6 4.311 12.6 6.3C12.6 7.092 12.276 7.812 11.763 8.325Z"
          {...options}
        />
      </svg>
    );
  }
};
