import React from 'react';
import PropTypes from 'prop-types';
import KpisTab from './KpisTab';
import GraphTab from './GraphTab';
import { ButtonTab, ButtonSwitch, ContentBaseline } from './style';

/**
 * BaselineFunnel
 * @component
 *
 */
function BaselineFunnel(props) {
  const [activeButton, setActiveButton] = React.useState('kpis');
  const isButtonActive = tab => {
    if (activeButton === tab) return 'active';
    return '';
  };
  const { funnel } = props;

  const activePanel = () => {
    return (
      <React.Fragment>
        <ContentBaseline>
          {activeButton === 'kpis' && <KpisTab funnel={funnel} />}
          {activeButton === 'graph' && <GraphTab funnel={funnel} />}
        </ContentBaseline>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ButtonTab>
        <ButtonSwitch
          className={isButtonActive('kpis')}
          onClick={() => {
            setActiveButton('kpis');
          }}
        >
          KPIs
        </ButtonSwitch>
        <ButtonSwitch
          className={isButtonActive('graph')}
          onClick={() => {
            setActiveButton('graph');
          }}
        >
          Graphique
        </ButtonSwitch>
      </ButtonTab>
      {activePanel()}
    </React.Fragment>
  );
}

// @TODO Fix this with better proptypes
/* eslint-disable react/forbid-prop-types */
BaselineFunnel.propTypes = {
  funnel: PropTypes.shape({
    newFormat: PropTypes.shape({
      synthesis: PropTypes.shape({
        kpi4: PropTypes.shape({
          volume: PropTypes.number,
          real: PropTypes.number
        }),
        kpi4HB: PropTypes.shape({
          volume: PropTypes.number,
          real: PropTypes.number
        })
      }),
      timeseries: PropTypes.shape({
        volume4: PropTypes.arrayOf(PropTypes.any),
        baseline: PropTypes.arrayOf(PropTypes.any),
        remanence: PropTypes.arrayOf(PropTypes.any)
      })
    }),
    measure: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired
};

export default BaselineFunnel;
