import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * @component
 *
 */
const Body = withTheme(styled.div`
  display: grid;
  background-color: ${({ themeColors }) => themeColors.primary};
  grid-template-rows: 74px auto;
  grid-row-gap: 2px;
  @media (min-width: 1800px) {
    grid-template-rows: 64px auto;
    grid-row-gap: 4px;
  }
  @media (min-width: 1024px) {
    grid-template-rows: 64px auto;
  }
`);

/**
 * @component
 *
 */
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 540px;
  @media (min-width: 1400px) {
    height: 630px;
  }
  @media (min-width: 1800px) {
    height: 710px;
  }
`;

/**
 * @component
 *
 */
const ColumnLeft = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.5fr;
  padding-top: 6px;
  width: 50%;
  min-width: 400px;
`;

/**
 * @component
 *
 */
const ColumnRight = styled.div`
  display: flex;
  width: 50%;
  padding-right: 16px;
  padding-top: 4px;
`;

export default {
  Body,
  Row,
  ColumnLeft,
  ColumnRight
};
