import React from 'react';
import styled from 'styled-components';
import Grid from 'components/Grid';
import Button from 'components/Button';
import Text from 'components/Text';

const ActionWrapper = props => (
  <Grid container direction="row" justify="left" alignItems="left" {...props} />
);

export const ActionButtonY = styled(Button)`
  && {
    background-color: rgb(255, 140, 0);
    color: #fff;
    border-radius: 10px;

    &:hover {
      background-color: #ffcf9e;
    }
  }
`;

export const ActionButtonB = styled(Button)`
  && {
    background-color: rgba(49, 39, 130, 0.8);
    color: #fff;
    border-radius: 10px;

    &:hover {
      background-color: rgba(0, 159, 227, 0.8);
    }
  }
`;

export const ActionText = styled(Text)`
  font-weight: bold;
  font-size: 12px;
`;

export const ActionTextTitle = styled(Text)`
  font-weight: bold;
  font-size: 16px;
`;

export const ActionSVGpro = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1062 8.14056L9.06207 7.34858L8.73295 7.16745C10.2079 5.74922 10.4443 3.38243 9.21166 1.67565C8.56843 0.785415 7.62599 0.208301 6.56092 0.0456032C5.49286 -0.117094 4.42779 0.159187 3.56317 0.816116C2.69556 1.47612 2.13317 2.44309 1.9746 3.53592C1.81604 4.63183 2.0853 5.72467 2.72554 6.61183C2.86914 6.81136 3.03065 6.99554 3.20418 7.16438L2.91398 7.32094L1.89082 8.13749C0.685144 9.29786 -0.00298205 10.934 9.71499e-06 12.6286V13H1.79507V12.604C1.70232 11.4528 2.05537 10.3324 2.78536 9.45136C3.51535 8.57034 4.53549 8.03313 5.65741 7.93797C6.77932 7.84587 7.87132 8.20504 8.72995 8.95406C9.58859 9.70308 10.1122 10.7499 10.2049 11.901C10.2229 12.1343 10.2229 12.3707 10.2049 12.6286V13H12V12.6286C12 10.9371 11.3119 9.30093 10.1062 8.14056ZM6.01348 6.76839L5.98353 6.46141C4.7599 6.46141 3.75467 5.43917 3.73971 4.18671C3.73373 3.57276 3.95808 2.98952 4.37692 2.55054C4.79577 2.11156 5.35524 1.86291 5.95658 1.85677C5.96556 1.85677 5.97456 1.85677 5.98353 1.85677C7.20716 1.85677 8.2124 2.87285 8.22736 4.13145C8.23334 4.74541 8.00899 5.32867 7.59015 5.76764C7.1713 6.20662 6.61183 6.45527 6.01049 6.46141L6.01348 6.76839Z"
      fill="white"
    />
  </svg>
);

export const ActionSVGarrow = () => (
  <svg
    width="22"
    height="22"
    viewBox="5 -5 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8257 0C14.4778 0 13.2328 0.433821 12.2158 1.17031L13.9653 2.91973C14.5141 2.60495 15.1477 2.42134 15.8257 2.42134C17.8959 2.42134 19.5787 4.10618 19.5787 6.17441C19.5787 8.24466 17.8939 9.9275 15.8257 9.9275C15.4382 9.9275 15.065 9.86897 14.7118 9.758L12.8736 11.5962C13.7514 12.0764 14.7562 12.3488 15.8257 12.3488C19.2317 12.3488 22 9.57841 22 6.17441C22 2.77041 19.2317 0 15.8257 0Z"
      fill="white"
    />
    <path
      d="M8.96101 0.231949L7.13692 2.07016L10.4219 5.37529H0V7.96814H10.4219L7.13692 11.2753L8.96101 13.1115L15.3574 6.67272L8.96101 0.231949Z"
      fill="white"
    />
  </svg>
);

export const ActionSVGarrowBis = () => (
  <svg
    width="22"
    height="22"
    viewBox="5 -5 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8257 0C14.4778 0 13.2328 0.433821 12.2158 1.17031L13.9653 2.91973C14.5141 2.60495 15.1477 2.42134 15.8257 2.42134C17.8959 2.42134 19.5787 4.10618 19.5787 6.17441C19.5787 8.24466 17.8939 9.9275 15.8257 9.9275C15.4382 9.9275 15.065 9.86897 14.7118 9.758L12.8736 11.5962C13.7514 12.0764 14.7562 12.3488 15.8257 12.3488C19.2317 12.3488 22 9.57841 22 6.17441C22 2.77041 19.2317 0 15.8257 0Z"
      fill="rgba(49, 39, 130, 0.8)"
    />
    <path
      d="M8.96101 0.231949L7.13692 2.07016L10.4219 5.37529H0V7.96814H10.4219L7.13692 11.2753L8.96101 13.1115L15.3574 6.67272L8.96101 0.231949Z"
      fill="rgba(49, 39, 130, 0.8)"
    />
  </svg>
);

export default ActionWrapper;
