import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseBriefWrapper
 * @component
 *
 */
const BaseBriefWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .row-brief {
    display: grid;
    margin-bottom: 12px;
    grid-template-columns: 180px 360px 170px;
  }
  .row-error {
    display: grid;
    margin-bottom: 12px;
    font-size: 14px;
    margin-top: -8px;
    color: #eb5757;
    grid-template-columns: 180px 530px;
  }
`;

export default withTheme(BaseBriefWrapper);
