import React from 'react';
import PropTypes from 'prop-types';
import { useStep } from 'Activation/contexts/StepContext';

import DataProvider from 'src/modules/Data/contexts/DataContext';

import AudiencesChoiceContainer from 'Activation/containers/AudiencesChoiceContainer';
import BriefContainer from 'Activation/containers/BriefContainer';
import ImportDataContainer from 'Activation/containers/ImportDataContainer';
import ImportInsightContainer from 'Activation/containers/ImportInsightContainer';
import IndicatorContainer from 'Activation/containers/IndicatorContainer';
import LayoutActivation from 'Activation/components/LayoutActivation';
import MatchingVisualizationContainer from 'Activation/containers/MatchingVisualizationContainer';
import ModalSwitchContainer from 'Activation/containers/ModalSwitchContainer';
import ModelisationContainer from 'Activation/containers/ModelisationContainer';
import ResumeContainer from 'Activation/containers/ResumeContainer';
import ResultContainer from 'Activation/containers/ResultContainer';
import ValidateContainer from 'Activation/containers/ValidateContainer';
import TitleActivation from './components/TitleActivation';

/**
 * AppActivation
 * @component
 *
 */
function AppActivation(props) {
  const { isFromHistory } = props;
  const {
    positionImport,
    positionModelisation,
    positionResult,
    isImport,
    goNext,
    title,
    setTitle
  } = useStep();

  if (isFromHistory && isImport) {
    setTitle(isFromHistory.name);
    goNext();
  }
  return (
    <LayoutActivation.Wrapper>
      <LayoutActivation.AnimatedSwitch position={positionImport}>
        <LayoutActivation.FirstPage>
          <div className="first-view-bg-colored">
            <AudiencesChoiceContainer />
            <MatchingVisualizationContainer />
            <BriefContainer />
          </div>
          <ValidateContainer />
        </LayoutActivation.FirstPage>
      </LayoutActivation.AnimatedSwitch>
      <LayoutActivation.AnimatedSwitch position={positionModelisation}>
        {title ? (
          <LayoutActivation.SecondPageTitle>
            <IndicatorContainer />
            <TitleActivation medium bold>
              {title}
            </TitleActivation>
            <ModelisationContainer hasDataErmes />
            <ResumeContainer />
            <ModelisationContainer />
            <ValidateContainer secondPage />
          </LayoutActivation.SecondPageTitle>
        ) : (
          <LayoutActivation.SecondPage>
            <IndicatorContainer />
            <ModelisationContainer hasDataErmes />
            <ResumeContainer />
            <ModelisationContainer />
            <ValidateContainer secondPage />
          </LayoutActivation.SecondPage>
        )}
      </LayoutActivation.AnimatedSwitch>
      <LayoutActivation.AnimatedSwitch position={positionResult}>
        {title ? (
          <LayoutActivation.ThirdPageTitle>
            <IndicatorContainer thirdContainer />
            <TitleActivation medium bold>
              {title}
            </TitleActivation>
            <ResultContainer />
            <ResumeContainer />
            <ValidateContainer thirdPage />
          </LayoutActivation.ThirdPageTitle>
        ) : (
          <LayoutActivation.ThirdPage>
            <IndicatorContainer thirdContainer />
            <ResultContainer />
            <ResumeContainer />
            <ValidateContainer thirdPage />
          </LayoutActivation.ThirdPage>
        )}
      </LayoutActivation.AnimatedSwitch>
      <ModalSwitchContainer>
        <ImportInsightContainer />
        <DataProvider refetchInterval={1}>
          <ImportDataContainer />
        </DataProvider>
      </ModalSwitchContainer>
    </LayoutActivation.Wrapper>
  );
}
AppActivation.propTypes = {
  isFromHistory: PropTypes.bool.isRequired
};

export default AppActivation;
