import React from 'react';
import PropTypes from 'prop-types';

import HiderFunnel from 'Funnel/components/HiderFunnel';
import { CSVDownloadFunnel } from 'Funnel/components/PDFDownloadFunnel';
import { useFunnel } from 'Funnel/contexts/FunnelContext';

import moment from 'utils/moment';

import PersistWrapper, {
  PersistButton,
  PersistModal,
  ModalContent,
  PersistTextField,
  modalStyle,
  PersistDButton,
  PersistButtonCSV,
  CloseButton
} from './style';

/**
 * PersistFunnel
 * @component
 *
 */
function PersistFunnel(props) {
  const { name, saveFunnel, downloadPDF } = props;
  const [currentName, updateCurrentName] = React.useState(name);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { funnel } = useFunnel();
  const handlePersist = () => {
    saveFunnel(currentName);
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    updateCurrentName(name);
  }, [name]);

  const filter = data => {
    return moment(data.date).isBetween(
      moment(funnel.start)
        .startOf('day')
        .format(),
      moment(funnel.end)
        .startOf('day')
        .format(),
      null,
      '[]'
    );
  };

  function downloadCsv() {
    CSVDownloadFunnel.dl(
      funnel.connected,
      funnel.measure.funnels.map(({ id }) => id),
      filter,
      funnel,
      funnel.name,
      funnel.objectifed,
      []
    );
  }

  return (
    <HiderFunnel hide={funnel.connected.length <= 4}>
      <PersistWrapper
        style={{ position: 'relative', bottom: '7%', marginBottom: '1%' }}
      >
        <PersistModal
          aria-labelledby="Sauvegarde du funnel"
          aria-describedby="simple-modal-title"
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ModalContent>
            <CloseButton onClick={() => setIsModalOpen(false)} />
            <h1 style={modalStyle.title}>Donner un nom à votre funnel</h1>
            <PersistTextField
              label="Nom du funnel"
              style={modalStyle.form}
              placeholder="Nom du funel"
              fullWidth
              margin="normal"
              value={currentName}
              onChange={({ target }) => updateCurrentName(target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
            <PersistDButton onClick={handlePersist}>SAUVEGARDER</PersistDButton>
          </ModalContent>
        </PersistModal>
        <PersistButtonCSV
          style={{ backgroundColor: '#383b50' }}
          onClick={() => downloadPDF()}
        >
          Rapport PDF
        </PersistButtonCSV>
        <PersistButtonCSV
          style={{ backgroundColor: '#383b50', display: 'none' }}
          onClick={() => downloadCsv()}
        >
          Rapport CSV
        </PersistButtonCSV>
        <PersistButton onClick={() => setIsModalOpen(true)}>
          Sauvegarder le FUNNEL
        </PersistButton>
      </PersistWrapper>
    </HiderFunnel>
  );
}

PersistFunnel.propTypes = {
  name: PropTypes.string.isRequired,
  saveFunnel: PropTypes.func.isRequired,
  downloadPDF: PropTypes.func.isRequired
};

export default PersistFunnel;
