import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import constants from 'utils/constants';
import { useUser } from 'contexts/UserContext';

import Icon from 'utils/icons';
import ProfilNav from './ProfilNav';
import {
  FlexCenter,
  HeaderErmes,
  NavTab,
  NavTabItem,
  NavImg,
  BaseHeaderLink
} from './styles';

const { panels, colors } = constants;

/**
 * useLocationPanel
 * return current panel (regarding to the url)
 *
 */
const useLocationPanel = location => {
  const [currentPanel, setCurrentPanel] = React.useState(panels.funnel);
  const { pathname } = location;
  React.useEffect(() => {
    const nextPanel = pathname
      .split('/')
      .find(onePath => Object.values(panels).includes(onePath));
    setCurrentPanel(nextPanel);
  }, [pathname]);
  return currentPanel;
};

/**
 * LoggedHeader
 * @component
 *
 */
function LoggedHeader(props) {
  const { user } = useUser();
  const role = user && user.attributes ? user.attributes['custom:role'] : '';
  const { setUserLocation, location, toggleTheme, isLightMode } = props;
  const currentPanel = useLocationPanel(location);
  return (
    <HeaderErmes className="header" color={currentPanel || ''}>
      <FlexCenter>
        <BaseHeaderLink
          to="/dashboard"
          onClick={() => {
            setUserLocation('/dashboard');
          }}
        >
          <NavImg>
            <Icon
              className="logo"
              icon="ermes_logo"
              attrs={{ fill: colors.dark[currentPanel || panels.funnel] }}
            />
          </NavImg>
        </BaseHeaderLink>

        <NavTab>
          {Object.values(panels).map(panel => (
            <NavTabItem
              id={panel}
              key={panel}
              isFocused={panel === currentPanel}
              onClick={() => {
                setUserLocation(`/${panel}`);
              }}
            >
              <BaseHeaderLink
                to={
                  panel === 'activation' && role === 'AGENCY'
                    ? `/${currentPanel}`
                    : `/${panel}`
                }
                className="nav-tab-link"
                style={
                  panel === 'activation' && role === 'AGENCY'
                    ? {
                        backgroundColor: colors.dark.secondary,
                        cursor: 'default'
                      }
                    : null
                }
                color={colors.dark[panel]}
                isActive={panel === currentPanel}
              >
                <Icon icon={panel} />
                <div className="nav-title">{panel}</div>
              </BaseHeaderLink>
            </NavTabItem>
          ))}
        </NavTab>
      </FlexCenter>
      <ProfilNav toggleTheme={toggleTheme} isLightMode={isLightMode} />
    </HeaderErmes>
  );
}

LoggedHeader.propTypes = {
  setUserLocation: PropTypes.func.isRequired,
  toggleTheme: PropTypes.func.isRequired,
  isLightMode: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.shape({})
  }).isRequired
};

export default withRouter(LoggedHeader);
