import React from 'react';
import {
  Waiter,
  STATUS_BASE_ANALYZED,
  STATUS_BASE_DIGITIZED,
  STATUS_BASE_DIGITIZING
} from './index';

/**
 * useTemelioBase hook
 *
 * @export
 * @param {string} token
 * @param {string} filename
 * @param {string} status - base status to wait for
 * @returns
 */
export function useTemelioBase(token, filename, status) {
  const [base, setBase] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [applyMiddleware, setApplyMiddleware] = React.useState(() => {});
  const [progress, setProgress] = React.useState(0);
  const [waiter, setWaiter] = React.useState(null);

  React.useEffect(() => {
    if (status === STATUS_BASE_ANALYZED) {
      setApplyMiddleware(() => () => !filename);
    } else if (status === STATUS_BASE_DIGITIZED) {
      setApplyMiddleware(() => newBase => {
        if (!filename) return true;
        if (newBase && newBase.progress) {
          const newProgress =
            newBase.status === STATUS_BASE_DIGITIZING
              ? newBase.progress / 2 + 50
              : newBase.progress / 2;
          setProgress(
            newBase.status === STATUS_BASE_DIGITIZED ? 100 : newProgress
          );
        }
        return false;
      });
    }
  }, [filename, status]);

  React.useEffect(() => {
    if (!token || !status || !filename) return;
    setLoading(true);
    setError(null);
    setBase(null);
    const tmpWaiter = new Waiter(token, filename, status, applyMiddleware);
    tmpWaiter
      .start()
      .then(b => {
        setBase(b);
        setLoading(false);
        setError(null);
      })
      .catch(e => {
        setBase(null);
        setLoading(false);
        setError(e);
      });
    setWaiter(tmpWaiter);
  }, [filename, applyMiddleware, status, token]);

  return {
    base,
    progress,
    error,
    loading,
    resetBase() {
      setBase(null);
      setLoading(true);
      setError(null);
      setApplyMiddleware(() => () => {});
      setProgress(0);
      if (waiter) waiter.cancel();
      setWaiter(null);
    }
  };
}

export default useTemelioBase;
