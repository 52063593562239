import React from 'react';
import Axios from 'axios';

export function useApi(url, config, skip = false) {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [cancel, setCancel] = React.useState(null);

  React.useEffect(() => {
    const source = Axios.CancelToken.source();
    setCancel(() => source.cancel);
    setLoading(true);
    setData(null);
    setError(null);
    if (skip) return () => {};
    Axios(url, {
      ...config,
      cancelToken: source.token
    })
      .then(response => {
        setLoading(false);
        setData(response.data);
        setError(null);
        return response.data;
      })
      .catch(e => {
        setLoading(false);
        setData(null);
        setError(e);
      });

    return () => {
      source.cancel('Component unmounted.');
    };
  }, [url, config, skip]);

  return {
    error,
    data,
    loading,
    cancel: (message = 'Request canceled') => cancel(message)
  };
}

export default useApi;
