import React from 'react';
import BaseWrapperResumeSentence from './style';

/**
 * WrapperResumeSentence
 * @component
 *
 */
function WrapperResumeSentence(props) {
  return <BaseWrapperResumeSentence {...props} />;
}

WrapperResumeSentence.defaultProps = {};
WrapperResumeSentence.propTypes = {};

export default WrapperResumeSentence;
