import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';
import { withInsight } from 'Insight/contexts/InsightContext';

const { fontSizes } = constants;

const BaseResult = withInsight(styled.div`
  align-items: center;
  color: ${({ themeColors, isData }) =>
    themeColors[`${isData ? 'data' : 'insight'}_result_label`]};
  display: flex;
  flex-direction: row;
  justify-content: center;
  .title {
    font-size: ${fontSizes.medium};
    margin: 0 4px 0;
  }
  .value-result-insight {
    color: ${({ themeColors, isData }) =>
      themeColors[`${isData ? 'data' : 'insight'}_result_value`]};
    font-size: ${fontSizes.mediumLarge};
    font-weight: bold;
  }
  @media (min-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;
    .title {
      font-size: ${fontSizes.medium};
      margin: 0;
    }
    .value-result-insight {
      font-size: ${fontSizes.large};
    }
  }
  @media (min-width: 1800px) {
    .value-result-insight {
      font-size: 24px;
    }
    .title {
      font-size: ${fontSizes.medium};
    }
  }
`);

export default withTheme(BaseResult);
