import React, { useState, useEffect } from 'react';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import moment from 'utils/moment';
import {
  dateDays,
  dateMonth,
  dateWeeks,
  listForMonths,
  listOfMonths,
  listOfWeeks
} from './getData';
import { formatNumber } from '../../../../utils/functions/index';
import {
  Alert,
  ArrowAnalyse,
  ButtonAnalyses,
  ButtonDrop,
  ButtonTab,
  Container,
  DropdownAnalyse,
  ErrorMessage,
  IconArrowAnalyse,
  Success,
  TableAnalyses,
  TableBodyAnalyses,
  TableCellAnalyses,
  TableHeadAnalyses,
  TableRowAnalyses
} from './style';

function AnalysesTemporelleContainer() {
  const { funnel, isLoading } = useFunnel();
  const { objectifed } = funnel;
  if (
    isLoading ||
    !funnel.start ||
    !funnel.end ||
    !moment(funnel.start).isValid() ||
    !moment(funnel.end).isValid() ||
    moment(funnel.start).isAfter(moment(funnel.end))
  ) {
    return (
      <div className="kpi_table_description align_center">
        <ErrorMessage>
          {
            'Vos données ne nous permettent pas d’afficher l’analyse temporelle de vos campagnes'
          }
        </ErrorMessage>
      </div>
    );
  }
  const dateEnd = moment(funnel.end);
  const minForMonth = 3.9;
  const minForWeek = 0.24;
  let list = [];
  let dropList = [];
  const duration = moment.duration(dateEnd.diff(moment(funnel.start)));
  const month = duration.asMonths();
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [activeButton, setActiveButton] = useState('week');

  if (month >= minForMonth) {
    list = dateMonth(funnel, month);
    dropList = listForMonths(funnel, month);
  } else if (month >= minForWeek) {
    list = dateWeeks(funnel);
    dropList = listOfMonths(funnel, month);
  } else {
    list = dateDays(funnel);
    dropList = listOfWeeks(funnel, list[list.length - 1]);
  }

  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [dateList, dateListSet] = useState(list);
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [dropDownList, dropDownListSet] = useState(dropList);
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [dateListSelected, dateListSelectedSet] = useState(
    dateList[0].type === 'day'
      ? dateList.slice(0, 7).reverse()
      : dateList.slice(0, 4).reverse()
  );

  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (activeButton === 'day') {
      dateListSelectedSet(dateList.slice(0, 7).reverse());
    } else dateListSelectedSet(dateList.slice(0, 4).reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateList]);

  const option = dropDownList.map(e => (
    <ButtonDrop
      onClick={() => {
        if (e.type === 'day') {
          let end = dateList.findIndex(a => a.date === e.prevDate);
          let start = end - 6;
          if (start < 0) {
            start = 0;
            end = 6;
          }
          dateListSelectedSet(dateList.slice(start, end + 1).reverse());
        } else if (e.type === 'week') {
          let end = dateList.findIndex(a => a.month === e.date);
          let start = end - 3;
          if (start < 0) {
            start = 0;
            end = 3;
          } else if (start + 2 < dateList.length) {
            start += 2;
            end += 2;
          }
          dateListSelectedSet(dateList.slice(start, end + 1).reverse());
        } else {
          let end = dateList.findIndex(a => a.date === e.date);
          let start = end - 3;
          if (start < 0) {
            start = 0;
            end = 3;
          } else if (start + 3 < dateList.length) {
            start += 3;
            end += 3;
          }
          dateListSelectedSet(dateList.slice(start, end + 1).reverse());
        }
      }}
    >
      {e.date}
    </ButtonDrop>
  ));

  const coloredCellKpi3 = e => {
    if (!objectifed[3]) return <strong>{formatNumber(e.KPI4)}</strong>;
    return e.KPI4 > objectifed[3].value ? (
      <Alert>
        <strong>{formatNumber(e.KPI4)}</strong>
      </Alert>
    ) : (
      <Success>
        <strong>{formatNumber(e.KPI4)}</strong>
      </Success>
    );
  };

  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [dropValue, dropValueSet] = useState(
    `${dateListSelected[0].datePreview} - ${dateListSelected[dateListSelected.length - 1].datePreviewBis}`
  );

  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const firstMonth = dateListSelected[0].date;
    const lastMonth = dateListSelected[dateListSelected.length - 1].date;
    if (dateListSelected[0].type === 'month')
      dropValueSet(`${firstMonth} - ${lastMonth}`);
    else
      dropValueSet(
        `${dateListSelected[0].datePreview} - ${dateListSelected[dateListSelected.length - 1].datePreviewBis}`
      );
    if (activeButton === 'day')
      dropDownListSet(
        listOfWeeks(funnel, dateListSelected[dateListSelected.length - 1])
      );
    if (activeButton === 'week') dropDownListSet(listOfMonths(funnel, month));
    if (activeButton === 'month') dropDownListSet(listForMonths(funnel, month));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateListSelected]);

  if (objectifed.length === 0)
    return (
      <div className="kpi_table_description align_center">
        <ErrorMessage>
          veuillez rentrer les objectifs afin d&#39;avoir les analyses
        </ErrorMessage>
      </div>
    );
  return (
    <React.Fragment>
      <div className="pb-data">
        <Container>
          <img
            src="/assets/img/info.svg"
            alt="? icon"
            className="question_mark"
          />
          <div className="kpi_table_description align_center">
            {`Comparez les indicateurs de performance de votre
             funnel période par période`}
          </div>
        </Container>
      </div>
      <ButtonTab>
        <ArrowAnalyse
          className="left"
          icon="arrowSimple"
          isLighter={
            dateListSelected[0].prevDate ===
            moment(funnel.start).format('DD/MM/YYYY')
          }
          onClick={() => {
            const len = dateListSelected[dateListSelected.length - 1];
            const end = dateList.findIndex(
              e => e.date === dateListSelected[0].date
            );
            const start = dateList.findIndex(e => e.date === len.date);
            if (end !== dateList.length - 1) {
              dateListSelectedSet(dateList.slice(start + 1, end + 2).reverse());
            }
          }}
        >
          <IconArrowAnalyse icon="arrowSimple" />
        </ArrowAnalyse>
        <DropdownAnalyse
          minWidth="120px"
          marginRight="15px"
          fix
          defaultValue={dropValue}
          options={option}
        />
        <ArrowAnalyse
          className="right"
          icon="arrowSimple"
          isLighter={
            dateListSelected[dateListSelected.length - 1].nextDate ===
            moment(dateEnd).format('')
          }
          onClick={() => {
            const len = dateListSelected[dateListSelected.length - 1];
            const end = dateList.findIndex(
              e => e.date === dateListSelected[0].date
            );
            const start = dateList.findIndex(e => e.date === len.date);
            if (start !== 0) {
              dateListSelectedSet(dateList.slice(start - 1, end).reverse());
            }
          }}
        >
          <IconArrowAnalyse icon="arrowSimple" />
        </ArrowAnalyse>
      </ButtonTab>
      <ButtonTab>
        <ButtonAnalyses
          type="button"
          value="mois"
          className={activeButton === 'month' ? 'active' : ''}
          onClick={() => {
            dateListSet(dateMonth(funnel, month, dateEnd));
            dateListSelectedSet(dateList.slice(0, 4).reverse());
            setActiveButton('month');
          }}
        >
          Mois
        </ButtonAnalyses>
        <ButtonAnalyses
          type="button"
          value="week"
          className={activeButton === 'week' ? 'active' : ''}
          onClick={() => {
            dateListSet(dateWeeks(funnel, moment(funnel.start), dateEnd));
            dateListSelectedSet(dateList.slice(0, 4).reverse());
            setActiveButton('week');
          }}
        >
          Semaine
        </ButtonAnalyses>
        <ButtonAnalyses
          type="button"
          value="day"
          className={activeButton === 'day' ? 'active' : ''}
          onClick={() => {
            dateListSet(dateDays(funnel, moment(funnel.start), dateEnd));
            dateListSelectedSet(dateList.slice(0, 7).reverse());
            setActiveButton('day');
          }}
        >
          Jours
        </ButtonAnalyses>
      </ButtonTab>
      {funnel === undefined ? (
        <ErrorMessage>
          {
            "Il semblerait que les données que vous nous avez transmis pour ce funnel ne nous permettent pas d'analyser la distribution de vos budgets médias."
          }
        </ErrorMessage>
      ) : (
        <TableAnalyses>
          <TableHeadAnalyses>
            <TableRowAnalyses>
              <TableCellAnalyses className="b-headerCell" />
              <TableCellAnalyses className="b-headerCell">
                <strong>Objectif</strong>
              </TableCellAnalyses>
              {dateListSelected.map(e => (
                <TableCellAnalyses className="b-headerCell">
                  <strong>{e.date}</strong>
                </TableCellAnalyses>
              ))}
            </TableRowAnalyses>
          </TableHeadAnalyses>
          <TableBodyAnalyses>
            <TableRowAnalyses>
              <TableCellAnalyses className="b-tableCell">
                Budget (K€)
              </TableCellAnalyses>
              <TableCellAnalyses className="b-tableCell">
                <strong>N/A</strong>
              </TableCellAnalyses>
              {dateListSelected.map(e => (
                <TableCellAnalyses className="b-tableCell">
                  <strong>{(e.BUDGET / 1000).toFixed(2)}</strong>
                </TableCellAnalyses>
              ))}
            </TableRowAnalyses>
            <TableRowAnalyses>
              <TableCellAnalyses className="b-tableCell">
                {funnel.measure.funnels[0].id} (€)
              </TableCellAnalyses>
              <TableCellAnalyses className="b-tableCell">
                <strong>{objectifed[0] ? objectifed[0].value : 'N/A'}</strong>
              </TableCellAnalyses>
              {dateListSelected.map(e => (
                <TableCellAnalyses className="b-tableCell">
                  <strong>{e.KPI1.toFixed(2)}</strong>
                </TableCellAnalyses>
              ))}
            </TableRowAnalyses>
            <TableRowAnalyses>
              <TableCellAnalyses className="b-tableCell">
                {funnel.measure.funnels[1].id} (€)
              </TableCellAnalyses>
              <TableCellAnalyses className="b-tableCell">
                <strong>{objectifed[1] ? objectifed[1].value : 'N/A'}</strong>
              </TableCellAnalyses>
              {dateListSelected.map(e => (
                <TableCellAnalyses className="b-tableCell">
                  <strong>{e.KPI2.toFixed(2)}</strong>
                </TableCellAnalyses>
              ))}
            </TableRowAnalyses>
            <TableRowAnalyses>
              <TableCellAnalyses className="b-tableCell">
                {funnel.measure.funnels[2].id} (€)
              </TableCellAnalyses>
              <TableCellAnalyses className="b-tableCell">
                <strong>{objectifed[2] ? objectifed[2].value : 'N/A'}</strong>
              </TableCellAnalyses>
              {dateListSelected.map(e => (
                <TableCellAnalyses className="b-tableCell">
                  <strong>{e.KPI3.toFixed(2)}</strong>
                </TableCellAnalyses>
              ))}
            </TableRowAnalyses>
            <TableRowAnalyses>
              <TableCellAnalyses className="b-tableCell-final">
                {funnel.measure.funnels[3].id} (€)
              </TableCellAnalyses>
              <TableCellAnalyses className="b-tableCell-final">
                <strong>{objectifed[3] ? objectifed[3].value : 'N/A'}</strong>
              </TableCellAnalyses>
              {dateListSelected.map(e => (
                <TableCellAnalyses className="b-tableCell-final">
                  {coloredCellKpi3(e)}
                </TableCellAnalyses>
              ))}
            </TableRowAnalyses>
            <TableRowAnalyses>
              <TableCellAnalyses className="b-tableCell">
                {`${funnel.measure.funnels[1].title} (%)`}
              </TableCellAnalyses>
              <TableCellAnalyses className="b-tableCell">
                <strong>{funnel.funnelData.leverage[1].goal.toFixed(2)}</strong>
              </TableCellAnalyses>
              {dateListSelected.map(e => (
                <TableCellAnalyses className="b-tableCell">
                  <strong>{((e.KPI1 / 1000 / e.KPI2) * 100).toFixed(2)}</strong>
                </TableCellAnalyses>
              ))}
            </TableRowAnalyses>
            <TableRowAnalyses>
              <TableCellAnalyses className="b-tableCell">
                {`${funnel.measure.funnels[2].title} (%)`}
              </TableCellAnalyses>
              <TableCellAnalyses className="b-tableCell">
                <strong>{funnel.funnelData.leverage[2].goal.toFixed(2)}</strong>
              </TableCellAnalyses>
              {dateListSelected.map(e => (
                <TableCellAnalyses className="b-tableCell">
                  <strong>{((e.KPI2 / e.KPI3) * 100).toFixed(2)}</strong>
                </TableCellAnalyses>
              ))}
            </TableRowAnalyses>
            <TableRowAnalyses>
              <TableCellAnalyses className="b-tableCell">
                {`${funnel.measure.funnels[3].title} (%)`}
              </TableCellAnalyses>
              <TableCellAnalyses className="b-tableCell">
                <strong>{funnel.funnelData.leverage[3].goal.toFixed(2)}</strong>
              </TableCellAnalyses>
              {dateListSelected.map(e => (
                <TableCellAnalyses className="b-tableCell">
                  <strong>{((e.KPI3 / e.KPI4) * 100).toFixed(2)}</strong>
                </TableCellAnalyses>
              ))}
            </TableRowAnalyses>
          </TableBodyAnalyses>
        </TableAnalyses>
      )}
    </React.Fragment>
  );
}

export default AnalysesTemporelleContainer;
