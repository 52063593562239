import React from 'react';
import PropTypes from 'prop-types';

import BaseOperatorsLayout from './style';

/**
 * OperatorLayoutInsight
 * @component
 * @see OperatorContainer
 * @desc operator layout
 * @param {number} length :: categories count for CSS grid template rows
 *
 */
function OperatorLayoutInsight(props) {
  const { length } = props;
  return <BaseOperatorsLayout length={length} {...props} />;
}
OperatorLayoutInsight.efaultProps = {};
OperatorLayoutInsight.propTypes = {
  length: PropTypes.number.isRequired
};
export default OperatorLayoutInsight;
