import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'components/ErrorPage';
import LoadingInsight from 'Insight/components/LoadingInsight';
import { INSIGHT_SUR_MESURE_KEY } from 'Insight/constant';
import { INSIGHT } from 'src/utils/graphQL';

const CategoryContext = React.createContext();

/**
 * CategoryProvider
 * @provider
 * @desc manage all the insight process
 *
 */
export default function CategoryProvider(props) {
  const [currentCategory, setCurrentCategory] = React.useState(null);
  const { data: dataFromTagFunnel } = useQuery(INSIGHT.GET_TAG_FUNNELS);
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data
  } = useQuery(gql`
    {
      insightCategories {
        key
        name
        description
      }
    }
  `);

  // Init the first category founded in db
  React.useEffect(() => {
    setCurrentCategory(
      data &&
        data.insightCategories &&
        data.insightCategories[0] &&
        data.insightCategories[0].key
    );
  }, [data]);

  if (!data) {
    return <LoadingInsight />;
  }
  const { insightCategories: categories } = data;
  const tagFunnels =
    (dataFromTagFunnel && dataFromTagFunnel.tagFunnels) || null;
  if (
    categories &&
    !categories.find(e => e.key === INSIGHT_SUR_MESURE_KEY) &&
    (tagFunnels && tagFunnels.length > 0)
  ) {
    categories.push({ key: INSIGHT_SUR_MESURE_KEY, name: 'Sur-mesure' });
  }
  const value = {
    tagFunnels,
    currentCategory,
    setCurrentCategory,
    categories,
    categoriesLoading,
    categoriesError
  };
  if (categoriesError) {
    return <ErrorPage isServer />;
  }
  return <CategoryContext.Provider {...props} value={value} />;
}

/**
 * useCategory
 * @function
 * @return value defined above (CategoryProvider)
 *
 */
export const useCategory = () => {
  const context = React.useContext(CategoryContext);
  if (!context)
    throw new Error('useCategory must be called inside CategoryProvider');
  return context;
};
