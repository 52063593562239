import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Title from 'src/components/Title';
import Text from 'src/components/Text';
import Modal from 'Data/components/Modal';
import ButtonFill from 'Data/components/ButtonFill';
import { useTheme } from 'src/contexts/ThemeContext';
import { ModalBuyProfilsForm } from './ModalBuyProfilsForm';

/**
 * ModalBuyProfils
 * @component
 */
function ModalBuyProfils({ onClose, onNext, open, ...props }) {
  const [step, setStep] = React.useState(0);
  const { themeColors } = useTheme();

  useEffect(() => {
    setStep(0);
  }, [open]);

  return (
    <Modal
      {...props}
      onClose={onClose}
      open={open}
      style={{ width: '500px', backgroundColor: themeColors.primary }}
    >
      <Title small fontSize={24} style={{ color: themeColors.light }}>
        ACHETER LES PROFILS DIGITALISÉS
      </Title>
      {!step ? (
        <ModalBuyProfilsForm
          onClose={onClose}
          onNext={() => setStep(1)}
          {...props}
          style={{ backgroundColor: themeColors.primary }}
        />
      ) : (
        <>
          <Text style={{ marginBottom: '24px', color: themeColors.light }}>
            Vous serez rappelé sous 24h par l’équipe ERMES afin de finaliser
            votre commande et vous informer des prochaines étapes.
          </Text>
          <Grid container justify="center">
            <ButtonFill width="180px" onClick={onNext}>
              TERMINER
            </ButtonFill>
          </Grid>
        </>
      )}
    </Modal>
  );
}
ModalBuyProfils.defaultProps = {
  open: false,
  onClose: () => {},
  onNext: () => {}
};
ModalBuyProfils.propTypes = {
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  open: PropTypes.bool
};

export default ModalBuyProfils;
