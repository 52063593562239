import React from 'react';
import Grid from 'components/Grid';
import { useTheme } from 'contexts/ThemeContext';
import BaseDatePicker from 'components/DateRangePicker';
import BaseLabel from 'components/Label';
import styled from 'styled-components';

/**
 * PeriodWrapper
 * @component
 *
 */
const PeriodWrapper = props => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    item
    style={{ textAlign: 'center' }}
    xs={6}
    md={4}
    {...props}
  />
);

export const DatePicker = props => {
  const { themeColors } = useTheme();
  return (
    <BaseDatePicker
      bgColor={themeColors.primary}
      color={themeColors.light}
      borderColor={themeColors.funnel}
      {...props}
    />
  );
};
export const Label = styled(BaseLabel)`
  padding: 8px 0;
`;

export default PeriodWrapper;
