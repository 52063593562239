import React from 'react';
import PropTypes from 'prop-types';

import BaseWrapperValidation from './style';

/**
 * WrapperValidation
 * @component
 *
 */
function WrapperValidation(props) {
  const { children } = props;
  return <BaseWrapperValidation>{children}</BaseWrapperValidation>;
}
WrapperValidation.defaultProps = {
  children: undefined
};
WrapperValidation.propTypes = {
  children: PropTypes.node
};

export default WrapperValidation;
