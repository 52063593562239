import React from 'react';

export default {
  synthesis(options) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.30005 19.7064H13.8V15.7779H6.30005L6.30005 19.7064Z"
          fill="white"
          {...options}
        />
        <path
          d="M3.4427 11.8494L16.657 11.8494V7.92079L3.4427 7.92079V11.8494Z"
          fill="white"
          {...options}
        />
        <path
          d="M0.228479 3.99219L19.8713 3.99219V0.0636168L0.228479 0.0636168V3.99219Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  },
  evolution(options) {
    return (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.2892 18.9661H1.42165V0.710824C1.42165 0.323102 1.09855 0 0.710824 0C0.323102 0 0 0.323102 0 0.710824V19.6769C0 20.0646 0.323102 20.3877 0.710824 20.3877H19.2892C19.6769 20.3877 20 20.0646 20 19.6769C20 19.2892 19.6769 18.9661 19.2892 18.9661Z"
          fill="white"
          {...options}
        />
        <path
          d="M18.6753 7.91597L18.7722 5.10498L16.2843 6.4297L17.0275 6.88204L14.3134 10.6946C14.1519 10.63 13.958 10.5977 13.7641 10.5977C13.3441 10.5977 12.9564 10.7593 12.6979 11.0177L8.94993 8.30369C8.98224 8.17445 9.01455 8.0129 9.01455 7.85135C9.01455 6.97897 8.30372 6.30046 7.46366 6.30046C6.59128 6.30046 5.91277 7.01128 5.91277 7.85135C5.91277 8.14214 6.0097 8.43293 6.13894 8.6591L3.29565 12.2132L2.8433 12.7948C2.64944 13.021 2.71406 13.3441 2.94023 13.5379C3.16641 13.7318 3.48951 13.6672 3.68337 13.4733L4.13571 12.924L7.01132 9.33761C7.17287 9.40223 7.33442 9.43454 7.52828 9.43454C7.85138 9.43454 8.17448 9.33761 8.40065 9.17606L12.2779 11.987C12.2779 12.0517 12.2779 12.1163 12.2779 12.1809C12.2779 13.0533 12.9887 13.7318 13.8288 13.7318C14.7011 13.7318 15.3797 13.021 15.3797 12.1809C15.3797 11.8901 15.2827 11.5993 15.1535 11.3732L17.9645 7.46362L18.6753 7.91597ZM6.75284 7.91597C6.75284 7.56055 7.01132 7.26976 7.33442 7.20514C7.36673 7.20514 7.36673 7.20514 7.39904 7.20514C7.43135 7.20514 7.43135 7.20514 7.46366 7.20514C7.78676 7.20514 8.07755 7.43131 8.14217 7.75441C8.14217 7.81904 8.17448 7.85135 8.17448 7.91597C8.17448 8.30369 7.85138 8.59448 7.49597 8.59448C7.07594 8.56217 6.78515 8.27138 6.75284 7.91597ZM14.0872 12.7948C13.9903 12.8594 13.8611 12.8917 13.7641 12.8917C13.3764 12.8917 13.0856 12.5686 13.0856 12.2132C13.0856 11.9547 13.2149 11.7286 13.441 11.5993C13.538 11.5347 13.6349 11.5024 13.7641 11.5024H13.7965C14.1842 11.5024 14.475 11.8255 14.475 12.1809C14.475 12.2132 14.475 12.2455 14.475 12.3101C14.4103 12.504 14.2811 12.6979 14.0872 12.7948Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  },
  analysis(options) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1667 20H0.833333C0.333333 20 0 19.6667 0 19.1667V0.833333C0 0.333333 0.333333 0 0.833333 0C1.33333 0 1.66667 0.333333 1.66667 0.833333V18.3333H19.1667C19.6667 18.3333 20 18.6667 20 19.1667C20 19.6667 19.5833 20 19.1667 20Z"
          fill="white"
          {...options}
        />
        <path
          d="M17.8336 16.75C17.3336 16.75 17.0002 16.4166 17.0002 15.9166V5.49996C17.0002 4.99996 17.3336 4.66663 17.8336 4.66663C18.3336 4.66663 18.6669 4.99996 18.6669 5.49996V15.9166C18.6669 16.3333 18.3336 16.75 17.8336 16.75Z"
          fill="white"
          {...options}
        />
        <path
          d="M14.3333 16.7499C13.8333 16.7499 13.5 16.4166 13.5 15.9166V8.41659C13.5 7.91659 13.8333 7.58325 14.3333 7.58325C14.8333 7.58325 15.1667 7.91659 15.1667 8.41659V15.9166C15.1667 16.3333 14.8333 16.7499 14.3333 16.7499Z"
          fill="white"
          {...options}
        />
        <path
          d="M10.8333 16.75C10.3333 16.75 10 16.4166 10 15.9166V10.25C10 9.74996 10.3333 9.41663 10.8333 9.41663C11.3333 9.41663 11.6667 9.74996 11.6667 10.25V15.9166C11.6667 16.3333 11.3333 16.75 10.8333 16.75Z"
          fill="white"
          {...options}
        />
        <path
          d="M7.33333 16.7499C6.83333 16.7499 6.5 16.4166 6.5 15.9166V12.1666C6.5 11.6666 6.83333 11.3333 7.33333 11.3333C7.83333 11.3333 8.16667 11.6666 8.16667 12.1666V15.9166C8.16667 16.3333 7.83333 16.7499 7.33333 16.7499Z"
          fill="white"
          {...options}
        />
        <path
          d="M3.83333 16.75C3.33333 16.75 3 16.4167 3 15.9167V14.0833C3 13.5833 3.33333 13.25 3.83333 13.25C4.33333 13.25 4.66667 13.5833 4.66667 14.0833V15.9167C4.66667 16.3333 4.33333 16.75 3.83333 16.75Z"
          fill="white"
          {...options}
        />
        <path
          d="M4.41667 10.0834C4.16667 10.0834 3.91667 9.91672 3.75 9.66672C3.5 9.25005 3.58333 8.75005 4 8.50005L15.5 1.08338C15.8333 0.833383 16.4167 1.00005 16.6667 1.33338C16.9167 1.75005 16.8333 2.25005 16.4167 2.50005L4.91667 9.91672C4.75 10 4.58333 10.0834 4.41667 10.0834Z"
          fill="white"
          {...options}
        />
        <path
          d="M15.3332 6.33338C15.2499 6.33338 15.1666 6.33338 15.1666 6.33338C14.7499 6.25005 14.4166 5.75005 14.5832 5.33338L15.2499 2.33338L12.3332 1.66671C11.9166 1.58338 11.5832 1.08338 11.7499 0.666714C11.8332 0.250048 12.3332 -0.0832858 12.7499 0.0833808L16.4999 0.916714C16.7499 1.00005 16.9166 1.08338 16.9999 1.25005C17.0832 1.41671 17.1666 1.66671 17.0832 1.91671L16.1666 5.75005C16.0832 6.08338 15.7499 6.33338 15.3332 6.33338Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  },
  baseline(options) {
    return (
      <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3415 19.3293H2.52979V3.62646V19.3293H18.3415Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.3999 17.0863H2.52958H1.3999Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.3999 14.8435H2.52958H1.3999Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.3999 12.6002H2.52958H1.3999Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.3999 10.3569H2.52958H1.3999Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.3999 8.1134H2.52958H1.3999Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.3999 5.87H2.52958H1.3999Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.7876 20.451V19.3296V20.451Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.0459 20.451V19.3296V20.451Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.30518 20.451V19.3296V20.451Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.564 20.451V19.3296V20.451Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8228 20.451V19.3296V20.451Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.082 20.451V19.3296V20.451Z"
          stroke={options.dataStroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.65902 3.62649L2.52958 1.38342L1.3999 3.62649H3.65902Z"
          fill="white"
          stroke="white"
          {...options}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3413 20.4511L20.5999 19.3294L18.3413 18.208V20.4511Z"
          fill="white"
          stroke="white"
          {...options}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5648 10.3562H9.30566V19.3294H11.5648V10.3562Z"
          stroke="white"
          {...options}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5648 3.62695H9.30566V10.3566H11.5648V3.62695Z"
          fill="white"
          stroke="white"
          {...options}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.0472 14.8428H4.78809V19.3294H7.0472V14.8428Z"
          stroke="white"
          {...options}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.0472 10.3562H4.78809V14.8428H7.0472V10.3562Z"
          fill="white"
          stroke="white"
          {...options}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0826 14.8428H13.8232V19.3294H16.0826V14.8428Z"
          stroke="white"
          {...options}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0826 8.11316H13.8232V14.8426H16.0826V8.11316Z"
          fill="white"
          stroke="white"
          {...options}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
  anomalies(options) {
    return (
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0041 0C9.20597 0 8.51551 0.409862 8.10565 1.07858L0.318351 14.0865C-0.0915107 14.7768 -0.112998 15.6178 0.296864 16.3081C0.685154 16.9984 1.39719 17.4301 2.19534 17.4301H17.7914C18.5896 17.4301 19.3231 17.02 19.7114 16.3081C20.0997 15.6178 20.0998 14.7768 19.6899 14.0865L11.9026 1.07858C11.4927 0.409862 10.8023 0 10.0041 0ZM10.0041 1.2943C10.3277 1.2943 10.608 1.44522 10.7806 1.74722L18.5898 14.7551C18.7624 15.0356 18.7623 15.3809 18.6113 15.6829C18.4387 15.9633 18.1581 16.1358 17.8129 16.1358H2.19534C1.87177 16.1358 1.56951 15.9633 1.39694 15.6829C1.22436 15.4024 1.24585 15.0356 1.41842 14.7551L9.22763 1.74722C9.4002 1.46679 9.68055 1.2943 10.0041 1.2943ZM10.0041 5.54418C9.63741 5.54418 9.35697 5.82461 9.35697 6.19133V10.7214C9.35697 11.0881 9.63741 11.3685 10.0041 11.3685C10.3708 11.3685 10.6513 11.0881 10.6513 10.7214V6.19133C10.6513 5.82461 10.3708 5.54418 10.0041 5.54418ZM10.0041 12.2744C9.83249 12.2744 9.66788 12.3426 9.54652 12.4639C9.42515 12.5853 9.35697 12.7499 9.35697 12.9215C9.35697 13.0932 9.42515 13.2578 9.54652 13.3791C9.66788 13.5005 9.83249 13.5687 10.0041 13.5687C10.1758 13.5687 10.3404 13.5005 10.4617 13.3791C10.5831 13.2578 10.6513 13.0932 10.6513 12.9215C10.6513 12.7499 10.5831 12.5853 10.4617 12.4639C10.3404 12.3426 10.1758 12.2744 10.0041 12.2744Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  },
  previsions(options) {
    return (
      <svg
        width="20"
        height="25"
        viewBox="0 0 20 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0625 7.38459H16.5625V7.99997H18.1994L13.3547 11.975L6.31656 10.4686C6.27125 10.4594 6.22375 10.4594 6.17875 10.4698L4.46219 10.8677L4.29406 10.5363L4.10125 10.631L4.21562 10.4003L3.6875 10.1464L4.01437 9.98551L3.73469 9.43505L3.17562 9.71043L3.45531 10.2609L3.63281 10.1735L3.37969 10.6837L3.76375 10.8683L3.83687 11.0126L1.25 11.6114V5.84213L1.77781 5.58213L1.52938 5.0929L1.25 5.23074V0.615356H0.625V18.7692C0.625 18.9394 0.765 19.0769 0.9375 19.0769H19.375V18.4615H1.25V16.3784L1.4725 15.9298L1.25 15.823V15.1384L1.74688 15.3772L2.02094 14.8243L1.45938 14.5544L1.25 14.9769V12.244L2.95469 11.8489L3.39219 12.0597L3.56719 11.707L6.2525 11.0849L13.3709 12.6086C13.3931 12.6129 13.4153 12.6154 13.4375 12.6154C13.51 12.6154 13.5809 12.5904 13.6375 12.5443L18.75 8.3492V10.1538H19.375V7.69228C19.375 7.52213 19.235 7.38459 19.0625 7.38459Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M16.5625 0.615356C15.0119 0.615356 13.75 1.85751 13.75 3.38459C13.75 4.91166 15.0119 6.15382 16.5625 6.15382C18.1131 6.15382 19.375 4.91166 19.375 3.38459C19.375 1.85751 18.1131 0.615356 16.5625 0.615356ZM16.5625 5.53843C15.3563 5.53843 14.375 4.57197 14.375 3.38459C14.375 2.1972 15.3563 1.23074 16.5625 1.23074C17.7688 1.23074 18.75 2.1972 18.75 3.38459C18.75 4.57197 17.7688 5.53843 16.5625 5.53843Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M16.5625 1.84619C16.0456 1.84619 15.625 2.26035 15.625 2.76927H16.25C16.25 2.59942 16.3903 2.46158 16.5625 2.46158C16.7347 2.46158 16.875 2.59942 16.875 2.76927C16.875 2.93911 16.7347 3.07696 16.5625 3.07696C16.39 3.07696 16.25 3.2145 16.25 3.38465V4.00004H16.875V3.63973C17.2387 3.51265 17.5 3.1705 17.5 2.76927C17.5 2.26035 17.0794 1.84619 16.5625 1.84619Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M16.875 4.30774H16.25V4.92312H16.875V4.30774Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M9.375 2.76929H8.75V3.38467H9.375V2.76929Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M10.625 2.76929H10V3.38467H10.625V2.76929Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M11.875 2.76929H11.25V3.38467H11.875V2.76929Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M13.125 2.76929H12.5V3.38467H13.125V2.76929Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M2.00826 13.4481L1.73413 14.0011L2.29554 14.2709L2.56966 13.7178L2.00826 13.4481Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M4.2026 9.02447L3.92798 9.57727L4.48942 9.84767L4.76404 9.29487L4.2026 9.02447Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M5.29953 6.81243L5.0249 7.36523L5.58634 7.63563L5.86096 7.08283L5.29953 6.81243Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M6.94516 3.49477L6.6709 4.0481L7.2323 4.31787L7.50657 3.76455L6.94516 3.49477Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M2.5566 12.3423L2.28247 12.8954L2.84388 13.1652L3.118 12.6121L2.5566 12.3423Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M4.75107 7.91836L4.47681 8.47168L5.03821 8.74145L5.31248 8.18813L4.75107 7.91836Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M8.125 2.76929H7.5V3.38467H8.125V2.76929Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M5.84799 5.70669L5.57373 6.26001L6.13514 6.52978L6.4094 5.97646L5.84799 5.70669Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M6.3967 4.60089L6.12207 5.15369L6.68351 5.42409L6.95813 4.87129L6.3967 4.60089Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M4.85197 11.6365L4.29297 11.9117L4.57247 12.4621L5.13147 12.1869L4.85197 11.6365Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M5.4113 12.7374L4.85229 13.0126L5.1318 13.563L5.6908 13.2878L5.4113 12.7374Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M5.96989 13.8381L5.41089 14.1133L5.69039 14.6637L6.24939 14.3885L5.96989 13.8381Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M6.875 14.7693H6.25V15.3847H6.875V14.7693Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M2.61613 7.23322L2.05713 7.50842L2.33663 8.05882L2.89563 7.78362L2.61613 7.23322Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M2.05705 6.13227L1.49805 6.40747L1.77755 6.95787L2.33655 6.68267L2.05705 6.13227Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M3.17521 8.33442L2.61621 8.60962L2.89557 9.15974L3.45457 8.88454L3.17521 8.33442Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M16.5625 12.3077C15.0119 12.3077 13.75 13.5499 13.75 15.077C13.75 16.604 15.0119 17.8462 16.5625 17.8462C18.1131 17.8462 19.375 16.604 19.375 15.077C19.375 13.5499 18.1131 12.3077 16.5625 12.3077ZM16.5625 17.2308C15.3563 17.2308 14.375 16.2644 14.375 15.077C14.375 13.8896 15.3563 12.9231 16.5625 12.9231C17.7688 12.9231 18.75 13.8896 18.75 15.077C18.75 16.2644 17.7688 17.2308 16.5625 17.2308Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M16.5625 13.5385C16.0456 13.5385 15.625 13.9526 15.625 14.4615H16.25C16.25 14.2917 16.3903 14.1538 16.5625 14.1538C16.7347 14.1538 16.875 14.2917 16.875 14.4615C16.875 14.6314 16.7347 14.7692 16.5625 14.7692C16.39 14.7692 16.25 14.9068 16.25 15.0769V15.6923H16.875V15.332C17.2387 15.2049 17.5 14.8628 17.5 14.4615C17.5 13.9526 17.0794 13.5385 16.5625 13.5385Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M16.875 16H16.25V16.6154H16.875V16Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M13.125 14.7693H12.5V15.3847H13.125V14.7693Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M11.875 14.7693H11.25V15.3847H11.875V14.7693Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M10.625 14.7693H10V15.3847H10.625V14.7693Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M9.375 14.7693H8.75V15.3847H9.375V14.7693Z"
          fill="white"
          stroke="white"
          {...options}
        />
        <path
          d="M8.125 14.7693H7.5V15.3847H8.125V14.7693Z"
          fill="white"
          stroke="white"
          {...options}
        />
      </svg>
    );
  },
  distribution(options) {
    return (
      <svg
        width="19"
        height="21"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 16.2629H1.16327V14.3497H14.3469V11.7986H1.16327V9.56643H4.26531V7.01538H1.16327V4.78322H10.0816V2.23217H1.16327V0H0.496714H0V20.7273H0.496714H1.16327V18.814H19V16.2629Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  },
  temporal(options) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0978 1.62805H16.9462V2.2225V3.20872C16.9462 4.00961 16.2969 4.65894 15.496 4.65894H15.4016C14.6007 4.65894 13.9513 4.00961 13.9513 3.20872V2.2225V1.62805H6.08844V2.2225V3.20872C6.088 {...options}44 4.00961 5.43911 4.65894 4.63822 4.65894H4.544C3.74289 4.65894 3.09356 4.00961 3.09356 3.2085V2.2225V1.62805H1.90244C0.851778 1.62805 0 2.47983 0 3.5305V18.0589C0 19.1096 0.851778 19.9614 1.90244 19.9614H15.59C15.8147 19.9614 16.0289 19.8678 16.1818 19.7032L19.7847 15.8194C19.9231 15.6703 20 15.4741 20 15.2705V3.53027C20 2.47983 19.1482 1.62805 18.0978 1.62805ZM15.9922 18.4492V16.3874C15.9922 15.9949 16.3118 15.6756 16.7042 15.6756H18.6273L15.9922 18.4492ZM18.8096 14.4852H16.7042C15.6551 14.4852 14.8018 15.3385 14.8018 16.3874V18.7707H1.90244C1.51 18.7707 1.19044 18.4514 1.19044 18.0587V6.72716H18.8096V14.4852Z"
          fill="white"
          {...options}
        />
        <path
          d="M4.06757 11.8768H6.27735C6.75468 11.8768 7.14179 11.3515 7.14179 10.7037V9.48683C7.14179 8.83905 6.75468 8.31372 6.27735 8.31372H4.06757C3.59024 8.31372 3.20312 8.83905 3.20312 9.48683V10.7037C3.20313 11.3515 3.59001 11.8768 4.06757 11.8768Z"
          fill="white"
          {...options}
        />
        <path
          d="M8.91523 11.8768H11.1248C11.6023 11.8768 11.9892 11.3515 11.9892 10.7037V9.48683C11.9892 8.83905 11.6023 8.31372 11.1248 8.31372H8.91523C8.43767 8.31372 8.05078 8.83905 8.05078 9.48683V10.7037C8.05078 11.3515 8.43767 11.8768 8.91523 11.8768Z"
          fill="white"
          {...options}
        />
        <path
          d="M13.7626 11.8768H15.9724C16.4497 11.8768 16.8369 11.3515 16.8369 10.7037V9.48683C16.8369 8.83905 16.4497 8.31372 15.9724 8.31372H13.7626C13.2853 8.31372 12.8982 8.83905 12.8982 9.48683V10.7037C12.8982 11.3515 13.2851 11.8768 13.7626 11.8768Z"
          fill="white"
          {...options}
        />
        <path
          d="M4.06757 16.6736H6.27735C6.75468 16.6736 7.14179 16.1485 7.14179 15.5005V14.2836C7.14179 13.6356 6.75468 13.1105 6.27735 13.1105H4.06757C3.59024 13.1105 3.20312 13.6356 3.20312 14.2836V15.5005C3.20313 16.1483 3.59001 16.6736 4.06757 16.6736Z"
          fill="white"
          {...options}
        />
        <path
          d="M8.91523 16.6736H11.1248C11.6023 16.6736 11.9892 16.1485 11.9892 15.5005V14.2836C11.9892 13.6356 11.6023 13.1105 11.1248 13.1105H8.91523C8.43767 13.1105 8.05078 13.6356 8.05078 14.2836V15.5005C8.05078 16.1483 8.43767 16.6736 8.91523 16.6736Z"
          fill="white"
          {...options}
        />
        <path
          d="M4.54389 4.036H4.63811C5.09433 4.036 5.46522 3.66489 5.46522 3.20867V0.827333C5.46522 0.371111 5.09433 0 4.63811 0H4.54389C4.08766 0 3.71655 0.371111 3.71655 0.827333V3.20867C3.71655 3.66489 4.08766 4.036 4.54389 4.036Z"
          fill="white"
          {...options}
        />
        <path
          d="M15.4016 4.036H15.496C15.9522 4.036 16.3231 3.66489 16.3231 3.20867V0.827333C16.3231 0.371111 15.9522 0 15.496 0H15.4016C14.9454 0 14.5745 0.371111 14.5745 0.827333V3.20867C14.5745 3.66489 14.9454 4.036 15.4016 4.036Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  },
  audience(options) {
    return (
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.82452 6.5116C2.09359 6.5116 1.42882 7.17709 1.4292 7.90801C1.4296 8.63838 2.09415 9.30252 2.82452 9.30252C3.55489 9.30252 4.21928 8.63838 4.21984 7.90801C4.2204 7.1769 3.55545 6.5116 2.82452 6.5116Z"
          fill="white"
          {...options}
        />
        <path
          d="M9.99981 6.5116C9.26889 6.5116 8.6041 7.17709 8.60449 7.90801C8.60489 8.63838 9.26945 9.30252 9.99981 9.30252C10.7302 9.30252 11.3948 8.63838 11.3954 7.90801C11.396 7.1769 10.7307 6.5116 9.99981 6.5116Z"
          fill="white"
          {...options}
        />
        <path
          d="M17.1756 6.5116C16.4447 6.5116 15.7799 7.17709 15.7803 7.90801C15.7807 8.63838 16.4452 9.30252 17.1756 9.30252C17.906 9.30252 18.5703 8.63838 18.5709 7.90801C18.5715 7.1769 17.9065 6.5116 17.1756 6.5116Z"
          fill="white"
          {...options}
        />
        <path
          d="M2.82446 9.65234C0.746812 9.65234 0.313029 11.9799 0.048594 12.9784C-0.215841 13.9769 0.583595 14.1486 2.82446 14.1486C5.06533 14.1486 5.86477 13.9769 5.60033 12.9784C5.3359 11.9799 4.90211 9.65234 2.82446 9.65234Z"
          fill="white"
          {...options}
        />
        <path
          d="M9.99976 9.65234C7.92211 9.65234 7.48832 11.9799 7.22389 12.9784C6.95945 13.9769 7.75889 14.1486 9.99976 14.1486C12.2406 14.1486 13.0403 13.9769 12.7759 12.9784C12.5115 11.9799 12.0774 9.65234 9.99976 9.65234Z"
          fill="white"
          {...options}
        />
        <path
          d="M17.1755 9.65234C15.0979 9.65234 14.6641 11.9799 14.3997 12.9784C14.1352 13.9769 14.9347 14.1486 17.1755 14.1486C19.4164 14.1486 20.2158 13.9769 19.9514 12.9784C19.687 11.9799 19.2532 9.65234 17.1755 9.65234Z"
          fill="white"
          {...options}
        />
        <path
          d="M4.70616 0C4.19799 0 3.7358 0.462668 3.73608 0.970834C3.73637 1.47861 4.19838 1.94033 4.70616 1.94033C5.21393 1.94033 5.67584 1.47861 5.67623 0.970833C5.67663 0.462537 5.21432 0 4.70616 0Z"
          fill="white"
          {...options}
        />
        <path
          d="M10 0C9.49188 0 9.02951 0.462668 9.02979 0.970834C9.03007 1.47861 9.49227 1.94033 10 1.94033C10.5078 1.94033 10.9697 1.47861 10.9701 0.970833C10.9705 0.462537 10.5082 0 10 0Z"
          fill="white"
          {...options}
        />
        <path
          d="M15.294 0C14.7859 0 14.3237 0.462668 14.324 0.970834C14.3242 1.47861 14.7863 1.94033 15.294 1.94033C15.8018 1.94033 16.2637 1.47861 16.2641 0.970833C16.2645 0.462537 15.8022 0 15.294 0Z"
          fill="white"
          {...options}
        />
        <path
          d="M4.70609 2.18347C3.26164 2.18347 2.96006 3.80169 2.77622 4.49586C2.59237 5.19002 3.14816 5.30957 4.70609 5.30957C6.26401 5.30957 6.8198 5.19002 6.63596 4.49586C6.45211 3.80169 6.15053 2.18347 4.70609 2.18347Z"
          fill="white"
          {...options}
        />
        <path
          d="M9.99997 2.18347C8.55552 2.18347 8.25375 3.80169 8.06991 4.49586C7.88607 5.19002 8.44205 5.30957 9.99997 5.30957C11.5579 5.30957 12.1137 5.19002 11.9298 4.49586C11.746 3.80169 11.4444 2.18347 9.99997 2.18347Z"
          fill="white"
          {...options}
        />
        <path
          d="M15.294 2.18347C13.8495 2.18347 13.5479 3.80169 13.3641 4.49586C13.1803 5.19002 13.7361 5.30957 15.294 5.30957C16.8519 5.30957 17.4077 5.19002 17.2238 4.49586C17.04 3.80169 16.7384 2.18347 15.294 2.18347Z"
          fill="white"
          {...options}
        />
      </svg>
    );
  }
};
