import React from 'react';

import BaseWrapperResult from './style';

/**
 * WrapperResult
 * @component
 *
 */
function WrapperResult(props) {
  return <BaseWrapperResult {...props} />;
}
WrapperResult.defaultProps = {};
WrapperResult.propTypes = {};

export default WrapperResult;
