import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';
import BaseTable, { TableItem, TableLine } from './style';

/**
 * Table
 * @component
 * @see HistoryFunnelContainer
 *
 */
function Table(props) {
  const { children, ...rest } = props;
  return <BaseTable {...rest}>{children}</BaseTable>;
}
Table.defaultProps = {
  children: undefined
};
Table.propTypes = {
  children: PropTypes.node
};

/**
 * TableRow
 * @component
 * @see HistoryFunnelContainer
 *
 */
export const TableRow = props => {
  const { length, isHead, children, ...rest } = props;
  return (
    <TableLine length={length} isHead={isHead} {...rest}>
      {children}
    </TableLine>
  );
};
TableRow.defaultProps = {
  isHead: false
};
TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  isHead: PropTypes.bool,
  length: PropTypes.number.isRequired
};

/**
 * TableRowItem
 * @component
 *
 */
export const TableRowItem = props => {
  const { themeColors } = useTheme();
  const { children, center, handleClick, ...rest } = props;
  const handler = handleClick ? { onClick: handleClick } : {};
  return (
    <TableItem center={center} {...handler} themeColors={themeColors} {...rest}>
      {children}
    </TableItem>
  );
};
TableRowItem.defaultProps = {
  center: false,
  handleClick: null
};
TableRowItem.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
  handleClick: PropTypes.func
};

export { TableBody } from './style';
export default Table;
