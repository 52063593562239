import React from 'react';
import { SectionDashboard } from 'components/Section';
import Dashboard from 'components/Dashboard';

import ErrorBoundary from 'ErrorBoundary';

/**
 * @component
 * DashboardContainer
 *
 */
function DashboardContainer(props) {
  return (
    <ErrorBoundary>
      <SectionDashboard {...props}>
        <Dashboard />
      </SectionDashboard>
    </ErrorBoundary>
  );
}

export default DashboardContainer;
