import React from 'react';
import { formatNumber } from 'utils/functions';
import { useSegment } from 'Activation/contexts/SegmentContext';

import LabelActivation from 'Activation/components/LabelActivation';
import WrapperResumeSentence from 'Activation/components/WrapperResumeSentence';

const getLevelLabelTitle = (level, field) => Object.keys(level)[field];
const getLevelLabelValue = (level, field) => Object.values(level)[field];

const addPreposition = word => {
  const vowels = ['a', 'e', 'i', 'o', 'u', 'y'];
  if (vowels.includes(word[0])) {
    return "d'".concat(word);
  }
  return 'de '.concat(word);
};
/**
 * ResumeSentence
 * @component
 *
 */
function ResumeSentenceContainer() {
  const { results } = useSegment();
  const engagementsWithData = getLevelLabelValue(
    results.FunnelWithData.level4,
    0
  );
  const diff =
    engagementsWithData -
    getLevelLabelValue(results.FunnelWithoutData.level4, 0);
  const percentage = (diff * 100) / engagementsWithData;
  const cpeWithoutData = getLevelLabelValue(
    results.FunnelWithoutData.level4,
    1
  );
  const resWithData = getLevelLabelValue(results.FunnelWithData.level4, 1);
  const fieldName = getLevelLabelTitle(results.FunnelWithData.level4, 1);
  const fieldLabel = getLevelLabelTitle(
    results.FunnelWithData.level4,
    0
  ).toLowerCase();

  return (
    <WrapperResumeSentence>
      <LabelActivation>
        {'A budget égal, une campagne sans data Ermes générerait'}&nbsp;
        <b>
          {`${formatNumber(percentage, 2)}% (${formatNumber(diff, 0)})`}&nbsp;
        </b>
        {`${addPreposition(fieldLabel)} en moins, passant le ${fieldName} de`}
        &nbsp;
        <b>{formatNumber(resWithData, 2)}€</b>
        &nbsp;à&nbsp;
        <b>{formatNumber(cpeWithoutData, 2)}€.</b>
      </LabelActivation>
    </WrapperResumeSentence>
  );
}
ResumeSentenceContainer.defaultProps = {};
ResumeSentenceContainer.propTypes = {};

export default ResumeSentenceContainer;
