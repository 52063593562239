import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'utils/functions';
import 'antd.css';
import { InputNumber } from 'antd';
import Slider from '@material-ui/core/Slider';
import { styled } from '@material-ui/core/styles';
import constants from 'utils/constants';

import { useSegment } from 'Activation/contexts/SegmentContext';

import IconActivation from 'Activation/components/IconActivation';
import InputActivation from 'Activation/components/InputActivation';
import LabelActivation from 'Activation/components/LabelActivation';
import LinearbarActivation from 'Activation/components/LinearbarActivation';
import SeparatorActivation from 'Activation/components/SeparatorActivation';
import TableActivation from 'Activation/components/TableActivation';
import TitleActivation from 'Activation/components/TitleActivation';
import WrapperModelisation from 'Activation/components/WrapperModelisation';
import kpiList from 'utils/constants/kpiList';
import { useStep } from 'Activation/contexts/StepContext';

const { colors } = constants;

const SliderActivation = styled(Slider)({
  color: colors.dark.activation
});

function strToElem(str) {
  const elems = str.split('\n');
  return (
    <span style={{ textAlign: 'center' }}>
      {elems.map((elem, i) => {
        if (i + 1 !== elems.length) {
          return (
            <>
              {elem}
              <br />
            </>
          );
        }
        return elem;
      })}
    </span>
  );
}

const RepartitionTitle = strToElem('Répartition\nsouhaitée');

const TITLES_ERMES = [
  { title: 'Types de médias', target: '' },
  { title: RepartitionTitle, target: 'repartition' },
  { title: 'Impressions', target: 'impressions' },
  { title: strToElem('ISC\navec Data'), target: 'iscData' },
  { title: strToElem('CPM sur cible\nhors Data'), target: 'cpmNoData' },
  { title: strToElem('CPM\nsur cible'), target: 'cpm' },
  { title: strToElem('Full CPM'), target: 'FullCPMCible' }
];
const TITLE_NO_ERMES = [
  { title: 'Types de médias', target: '' },
  { title: RepartitionTitle, target: 'repartition' },
  { title: 'Impressions', target: 'impressions' },
  { title: 'ISC estimé hors Data', target: 'iscNoData' }
];

const LEVELS_LIST = [1, 2, 3, 4];

function getRoiItems(roi, hasDataErmes, results, key) {
  if (hasDataErmes) {
    return [
      roi.title,
      roi.pourcentage * 100,
      formatNumber(roi.Impressions, 0),
      <span>
        {'100% '}
        <IconActivation icon="arrowRight" colored />
        {` ${formatNumber(roi.Impressions, 0)}`}
      </span>,
      `${formatNumber(results.ROI[key].CPM)}€`,
      `${formatNumber(roi.CPM)}€`,
      `${formatNumber(results.ROI[key].fullCPM)}€`
    ];
  }
  return [
    roi.title,
    roi.pourcentage * 100,
    formatNumber(roi.Impressions, 0),
    <span>
      {`${formatNumber(results.Brief.IscHorsData * 100, 0)}% `}
      <IconActivation icon="arrowRight" colored />
      {` ${formatNumber(roi.ISC, 0)}`}
    </span>
  ];
}

function getTotal(roi, hasDataErmes) {
  const { total } = roi || { total: {} };
  const totalResult = new Array(hasDataErmes ? 5 : 4);
  let result = total || {};
  // compute again total result, investigate why the result.Impressions is wrong
  try {
    result.Impressions =
      roi.banniere.Impressions +
      roi.banniere_impact.Impressions +
      roi.video.Impressions +
      roi.video_premium.Impressions;
  } catch (e) {
    result = total || {};
  }
  const ISC = hasDataErmes ? result.Impressions : result.ISC;
  if (!result.pourcentage) return [];
  totalResult[0] = 'Total Campagne';
  totalResult[1] = `${formatNumber(result.pourcentage * 100, 2)}%`;
  totalResult[2] = formatNumber(result.Impressions, 0);
  totalResult[3] = formatNumber(ISC, 0);
  if (hasDataErmes) {
    totalResult[4] = `${formatNumber(result.CPMMedia, 2)}€`;
    totalResult[5] = `${formatNumber(result.fullCPMCible, 2)}€`;
    totalResult[6] = `${formatNumber(result.fullCPMTotal, 2)}€`;
  }
  return totalResult;
}

const getLevelLabelTitle = level => Object.keys(level)[0];
const getLevelLabelValue = level => Object.values(level)[0];
const getLevelCostTitle = level => Object.keys(level)[1];
const getLevelCostValue = level => Object.values(level)[1];
const getLevelTransition = level => {
  const name = Object.keys(level)[0];
  const { nameTaux } =
    Object.values(kpiList).find(value => value.name === name) || {};
  if (nameTaux) return nameTaux;
  if (['a', 'e', 'y', 'h', 'u', 'i', 'o'].includes(name[0].toLowerCase())) {
    return `Taux d'${name.toLowerCase()}`;
  }
  return `Taux de ${name.toLowerCase()}`;
};

/**
 *
 * ModelisationContainer
 * @container
 *
 * @desc::  The second and third part at the second page
 *
 */
function ModelisationContainer(props) {
  const step = useStep();
  const { hasDataErmes } = props;
  const {
    objective,
    results,
    setTansformationRate,
    setCampaignRatios,
    transformationRates
  } = useSegment();
  const [roi, setRoi] = React.useState({});
  const [funnel, setFunnel] = React.useState(null);
  const headTitles = hasDataErmes ? TITLES_ERMES : TITLE_NO_ERMES;
  const changeRate = (rate, num) => {
    setTansformationRate({ num, rate: parseFloat(rate) / 100 });
  };
  const changeRepartion = (key, e) => {
    const value = !Number.isNaN(parseFloat(e.target.value))
      ? parseFloat(e.target.value)
      : parseFloat(0);
    setCampaignRatios(key, value / 100);
  };
  const handleRepartitionError = (value, index) => {
    if (index !== 1) return false;
    const isError = value !== '100.00%';
    if (isError === step.canValidate) {
      step.setCanValidate(!isError);
    }
    return isError;
  };
  React.useEffect(() => {
    if (results.ROI) {
      setRoi(hasDataErmes ? results.WithDataSummary : results.ROI);
      setFunnel(
        hasDataErmes ? results.FunnelWithData : results.FunnelWithoutData
      );
    }
  }, [hasDataErmes, results, results.ROI]);
  const total = getTotal(roi, hasDataErmes);
  return (
    <WrapperModelisation>
      <div className="column-modelisation left-part">
        <TitleActivation small left noMargin>
          <b>MODÉLISATION {hasDataErmes ? 'AVEC' : 'HORS'} DATA ERMES</b>
          <SeparatorActivation left />
        </TitleActivation>
        <LabelActivation>
          Retrouvez ci-dessous la modélisation de votre campagne {objective}
          {hasDataErmes
            ? ' avec la Data Ermes'
            : " si vous n'aviez pas utilisé la Data Ermes"}
          . Vous pouvez changer les types de médias, en veillant bien que la
          somme reste égale à 100%
        </LabelActivation>
        <TableActivation.Container>
          <TableActivation.RowHead length={headTitles.length} colored isHead>
            {headTitles.map(({ title: headTitle }, index) => (
              <TableActivation.Item key={headTitle} left={index === 0}>
                {headTitle}
              </TableActivation.Item>
            ))}
          </TableActivation.RowHead>
          <TableActivation.Body>
            {Object.entries(roi)
              .filter(([key]) => key !== 'total')
              .map(([key, roiItem]) => {
                const roiItems = getRoiItems(
                  roiItem,
                  hasDataErmes,
                  results,
                  key
                );
                return (
                  <TableActivation.RowBody length={headTitles.length}>
                    {roiItems.map((item, i) => {
                      const isRepartition = i === 1;
                      const mut = !(isRepartition && !hasDataErmes);
                      const Elem = isRepartition
                        ? `${formatNumber(item, 2)}%`
                        : item;
                      return (
                        <TableActivation.Item left={i === 0} mutable={mut}>
                          {isRepartition && mut ? (
                            <InputNumber
                              min={0}
                              max={100}
                              step={0.01}
                              formatter={value => `${value}%`}
                              parser={value => value.replace('%', '')}
                              defaultValue={formatNumber(item, 2)}
                              onBlur={value => changeRepartion(key, value)}
                            />
                          ) : (
                            Elem
                          )}
                        </TableActivation.Item>
                      );
                    })}
                  </TableActivation.RowBody>
                );
              })}
          </TableActivation.Body>
        </TableActivation.Container>
        <TableActivation.Result length={total.length}>
          {total.map((value, index) => (
            <TableActivation.Item
              left={index === 0}
              error={handleRepartitionError(value, index)}
            >
              {value}
            </TableActivation.Item>
          ))}
        </TableActivation.Result>
        {step.canValidate === false ? (
          <span style={{ color: 'red', textAlign: 'center' }}>
            Le total des répartitions doit être égal à 100%
          </span>
        ) : (
          <div />
        )}
        <div style={{ textAlign: 'center' }}>
          <LabelActivation>
            {`Votre répétition moyenne sur cible sera de
             ${formatNumber(results.WithDataSummary.total.repetition, 2)}.`}
          </LabelActivation>
        </div>
      </div>
      <div className="column-modelisation center">
        <IconActivation icon="arrowRightLarge" colored />
      </div>
      <div className="column-modelisation right-part">
        <TitleActivation small left noMargin>
          <b>FUNNEL PRÉVISIONNEL</b>
          <SeparatorActivation left />
        </TitleActivation>
        <LabelActivation>
          Performances prévisionnelles établies sur base de moyennes de marché.
          Vous pouvez changer les taux de conversions par étape en fonction de
          vos performances attendues.
        </LabelActivation>
        {funnel &&
          transformationRates &&
          LEVELS_LIST.map(num => {
            const level = funnel[`level${num}`];
            const txLevel = funnel[`txLevel${num}`];
            const transformationRate = transformationRates[`level${num}`];
            const rateValue =
              (transformationRate &&
                formatNumber(transformationRate * 100, 2)) ||
              (txLevel && formatNumber(txLevel.default * 100, 2));
            const min = num > 1 && formatNumber(txLevel.min * 100, 2);
            const max = num > 1 && formatNumber(txLevel.max * 100, 2);
            const unitSlider = formatNumber((max - min) / 100, 4);
            const sliderValue =
              num > 1 &&
              formatNumber(parseFloat(rateValue - min) / unitSlider, 0);
            return (
              <React.Fragment>
                {num > 1 && (
                  <div className="row-modelisation info">
                    {hasDataErmes ? (
                      <div className="row-modelisation infoSub">
                        <InputNumber
                          min={parseFloat(min)}
                          max={parseFloat(max)}
                          step={0.01}
                          value={rateValue}
                          formatter={value => `${value}%`}
                          parser={value => value.replace('%', '')}
                          onChange={value => changeRate(value, num)}
                          disabled={!hasDataErmes}
                        />
                        <SliderActivation
                          style={{
                            'margin-left': '10px',
                            width: '70px',
                            'padding-top': '7px'
                          }}
                          track={false}
                          aria-labelledby="track-false-slider"
                          getAriaValueText={val => `${val}%`}
                          value={sliderValue}
                          marks={[
                            {
                              value: 0
                            },
                            {
                              value: 100
                            }
                          ]}
                          onChange={(e, val) => {
                            const newRate = formatNumber(
                              parseFloat(min) + parseFloat(unitSlider * val),
                              2
                            );
                            changeRate(newRate, num);
                          }}
                        />
                      </div>
                    ) : (
                      <InputActivation
                        style={{
                          width: '86px'
                        }}
                        type="number"
                        min={min}
                        max={max}
                        step={0.01}
                        value={rateValue}
                        disabled={!hasDataErmes}
                      />
                    )}
                    <IconActivation icon="arrowVerticalDouble" />
                    <LabelActivation bold>
                      {getLevelTransition(level)}
                    </LabelActivation>
                  </div>
                )}
                <div className="row-modelisation bar">
                  <LinearbarActivation pourcentage={100 - (num - 1) * 10}>
                    <b>
                      {getLevelLabelTitle(level)}:&nbsp;
                      {formatNumber(getLevelLabelValue(level), 0)}
                    </b>
                  </LinearbarActivation>
                  <span>
                    <LabelActivation>
                      {getLevelCostTitle(level)}:&nbsp;
                    </LabelActivation>
                    <LabelActivation bold>
                      {formatNumber(getLevelCostValue(level), 1)}€
                    </LabelActivation>
                  </span>
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </WrapperModelisation>
  );
}
ModelisationContainer.defaultProps = {
  hasDataErmes: false
};
ModelisationContainer.propTypes = {
  hasDataErmes: PropTypes.bool
};

export default ModelisationContainer;
