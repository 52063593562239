export const sexeCriteria = [
  [
    {
      label: 'H',
      color: '#2E4053',
      percent: 36,
      percentColor: '#4C4E58'
    },
    {
      label: 'F',
      color: '#85929E',
      percent: 64,
      percentColor: '#3EAC7B'
    }
  ],
  [
    {
      label: 'H',
      color: '#2E4053',
      percent: 40,
      percentColor: '#4C4E58'
    },
    {
      label: 'F',
      color: '#85929E',
      percent: 60,
      percentColor: '#3EAC7B'
    }
  ],
  [
    {
      label: 'H',
      color: '#2E4053',
      percent: 45,
      percentColor: '#4C4E58'
    },
    {
      label: 'F',
      color: '#85929E',
      percent: 55,
      percentColor: '#3EAC7B'
    }
  ],
  [
    {
      label: 'H',
      color: '#2E4053',
      percent: 47,
      percentColor: '#4C4E58'
    },
    {
      label: 'F',
      color: '#85929E',
      percent: 53,
      percentColor: '#3EAC7B'
    }
  ]
];

export const ageCriteria = [
  [
    {
      label: '<25',
      color: '#7FB3D5',
      percent: 3,
      percentColor: '#4C4E58'
    },
    {
      label: '35',
      color: '#7FB3D5',
      percent: 10,
      percentColor: '#4C4E58'
    },
    {
      label: '45',
      color: '#7FB3D5',
      percent: 20,
      percentColor: '#4C4E58'
    },
    {
      label: '55',
      color: '#174A6C',
      percent: 27,
      percentColor: '#4C4E58'
    },
    {
      label: '65',
      color: '#174A6C',
      percent: 22,
      percentColor: '#4C4E58'
    },
    {
      label: '>65',
      color: '#7FB3D5',
      percent: 18,
      percentColor: '#4C4E58'
    }
  ],
  [
    {
      label: '<25',
      color: '#7FB3D5',
      percent: 1,
      percentColor: '#4C4E58'
    },
    {
      label: '35',
      color: '#7FB3D5',
      percent: 15,
      percentColor: '#4C4E58'
    },
    {
      label: '45',
      color: '#7FB3D5',
      percent: 18,
      percentColor: '#4C4E58'
    },
    {
      label: '55',
      color: '#174A6C',
      percent: 33,
      percentColor: '#4C4E58'
    },
    {
      label: '65',
      color: '#174A6C',
      percent: 25,
      percentColor: '#4C4E58'
    },
    {
      label: '>65',
      color: '#7FB3D5',
      percent: 8,
      percentColor: '#4C4E58'
    }
  ],
  [
    {
      label: '<25',
      color: '#7FB3D5',
      percent: 0,
      percentColor: '#4C4E58'
    },
    {
      label: '35',
      color: '#7FB3D5',
      percent: 13,
      percentColor: '#4C4E58'
    },
    {
      label: '45',
      color: '#7FB3D5',
      percent: 15,
      percentColor: '#4C4E58'
    },
    {
      label: '55',
      color: '#174A6C',
      percent: 36,
      percentColor: '#4C4E58'
    },
    {
      label: '65',
      color: '#174A6C',
      percent: 29,
      percentColor: '#4C4E58'
    },
    {
      label: '>65',
      color: '#7FB3D5',
      percent: 7,
      percentColor: '#4C4E58'
    }
  ],
  [
    {
      label: '<25',
      color: '#7FB3D5',
      percent: 0,
      percentColor: '#4C4E58'
    },
    {
      label: '35',
      color: '#7FB3D5',
      percent: 13,
      percentColor: '#4C4E58'
    },
    {
      label: '45',
      color: '#7FB3D5',
      percent: 15,
      percentColor: '#4C4E58'
    },
    {
      label: '55',
      color: '#174A6C',
      percent: 40,
      percentColor: '#4C4E58'
    },
    {
      label: '65',
      color: '#174A6C',
      percent: 30,
      percentColor: '#4C4E58'
    },
    {
      label: '>65',
      color: '#7FB3D5',
      percent: 2,
      percentColor: '#4C4E58'
    }
  ]
];

export const cspCriteria = [
  [
    {
      label: '-',
      color: '#138D75',
      percent: 70,
      percentColor: '#3EAC7B'
    },
    {
      label: '+',
      color: '#73C6B6',
      percent: 30,
      percentColor: '#4C4E58'
    }
  ],
  [
    {
      label: '-',
      color: '#138D75',
      percent: 60,
      percentColor: '#3EAC7B'
    },
    {
      label: '+',
      color: '#73C6B6',
      percent: 40,
      percentColor: '#4C4E58'
    }
  ],
  [
    {
      label: '-',
      color: '#138D75',
      percent: 57,
      percentColor: '#3EAC7B'
    },
    {
      label: '+',
      color: '#73C6B6',
      percent: 43,
      percentColor: '#4C4E58'
    }
  ],
  [
    {
      label: '-',
      color: '#138D75',
      percent: 57,
      percentColor: '#3EAC7B'
    },
    {
      label: '+',
      color: '#73C6B6',
      percent: 43,
      percentColor: '#4C4E58'
    }
  ]
];

export const zoneCriteria = [
  [
    {
      label: 'C',
      color: '#5B2C6F',
      percent: 29,
      percentColor: '#4C4E58'
    },
    {
      label: 'P',
      color: '#8E44AD',
      percent: 29,
      percentColor: '#4C4E58'
    },
    {
      label: 'R',
      color: '#BB8FCE',
      percent: 42,
      percentColor: '#3EAC7B'
    }
  ],
  [
    {
      label: 'C',
      color: '#5B2C6F',
      percent: 28,
      percentColor: '#4C4E58'
    },
    {
      label: 'P',
      color: '#8E44AD',
      percent: 28,
      percentColor: '#4C4E58'
    },
    {
      label: 'R',
      color: '#BB8FCE',
      percent: 44,
      percentColor: '#3EAC7B'
    }
  ],
  [
    {
      label: 'C',
      color: '#5B2C6F',
      percent: 28,
      percentColor: '#4C4E58'
    },
    {
      label: 'P',
      color: '#8E44AD',
      percent: 28,
      percentColor: '#4C4E58'
    },
    {
      label: 'R',
      color: '#BB8FCE',
      percent: 44,
      percentColor: '#3EAC7B'
    }
  ],
  [
    {
      label: 'C',
      color: '#5B2C6F',
      percent: 32,
      percentColor: '#4C4E58'
    },
    {
      label: 'P',
      color: '#8E44AD',
      percent: 33,
      percentColor: '#4C4E58'
    },
    {
      label: 'R',
      color: '#BB8FCE',
      percent: 35,
      percentColor: '#3EAC7B'
    }
  ]
];
