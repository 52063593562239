import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Title from 'src/components/Title';
import Text from 'src/components/Text';
import Modal from 'Data/components/Modal';
import ButtonBorder from 'Data/components/ButtonBorder';
import { useTheme } from 'src/contexts/ThemeContext';
import ColorLinearProgress from '../../../components/ColorLinearProgress';

/**
 * ModalVerifFile
 * @component
 */
function ModalMatchingProgress({ onClose, progress, status, ...props }) {
  const { themeColors } = useTheme();

  return (
    <Modal
      {...props}
      onClose={onClose}
      style={{ width: '450px', backgroundColor: themeColors.primary }}
    >
      <Title small fontSize={24} style={{ color: themeColors.light }}>
        MATCHING DES PROFILS
      </Title>
      <Text style={{ color: themeColors.light }}>
        Le matching de votre base de données est en cours.
      </Text>
      <Text style={{ color: themeColors.light }}>
        Dans quelques minutes vous aurez les résultats du matching sur les 30
        millions de profils Ermes.
      </Text>

      <Grid container direction="column" alignItems="center">
        <Text style={{ color: themeColors.data }} textAlign="center">
          {progress}%
        </Text>
        <ColorLinearProgress
          variant="determinate"
          value={progress}
          style={{ width: '50%', height: 10 }}
        />
        <Text
          style={{ color: themeColors.data, fontStyle: 'italic' }}
          textAlign="center"
        >
          {status}
        </Text>
      </Grid>

      <Grid container justify="center" style={{ marginTop: '22px' }}>
        <ButtonBorder style={{ width: '213px', margin: 0 }} onClick={onClose}>
          ANNULER
        </ButtonBorder>
      </Grid>
    </Modal>
  );
}
ModalMatchingProgress.defaultProps = {
  open: false,
  onClose: () => {},
  status: 'Matching en cours'
};
ModalMatchingProgress.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  progress: PropTypes.number.isRequired,
  status: PropTypes.string
};

export default ModalMatchingProgress;
