import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { withTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';

const { colors } = constants;

export const TableDistrib = withTheme(styled(Table)`
  && {
    width: 96%;
    color: ${({ themeColors }) => themeColors.light};
    position: relative;
    left: 1%;
    p,
    div,
    span,
    th,
    td {
      color: ${({ themeColors }) => themeColors.light};
      max-height: 47px;
    }
  }
`);

export const TableAgregated = withTheme(styled(Table)`
  && {
    width: 35%;
    color: ${({ themeColors }) => themeColors.light};
    position: relative;
    float: left;
    right: 2%;
    p,
    div,
    span,
    th,
    td {
      color: ${({ themeColors }) => themeColors.light};
    }
    @media (max-width: 1280px) {
      width: 100%;
    }
  }
`);

export const TableBodyDistrib = styled(TableBody)``;

export const TableHeadDistrib = styled(TableHead)`
  text-align: center;
  padding: 0;

  th {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 0;
  }
`;

export const TableCellDistrib = withTheme(styled(TableCell)`
  .label {
    text-align: right;
    padding-bottom: 27px;
    padding-top: 27px;
  }
  img {
    width: 20px;
    padding-left: 5px;
  }
  font-weight: ${({ fontWeight }) =>
    fontWeight ? 'bold' : 'normal'} !important;
  && {
    border: none;
    width: 24%;
    border-radius: 0;
  }
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &&.action_td {
    border: none;
  }
  &&.b-all {
    border: 1px solid #7e7ead;
  }
  &&.b-left {
    border-top: none;
    border-right: none;
    border-bottom: none;
    padding-bottom: 0px;
  }
  &&.b-left-final {
    border-top: none;
    border-right: none;
    border-bottom: none;
    padding-bottom: 0px;
    padding-top: 39px;
  }
  &&.b-right {
    border-top: none;
    border-left: none;
    border-bottom: none;
    background-color: ${({ themeColors }) => `${themeColors.tertiary}bb`};
  }
  &&.b-right-final {
    border-top: none;
    border-left: none;
    border-bottom: none;
    background-color: ${({ themeColors }) => `${themeColors.tertiary}bb`};
    padding-top: 39px;
  }
  &&.b-right-head {
    height: 10%;
    border-radius: 0;
    background-color: ${({ themeColors }) => themeColors.funnel};
  }
  &&.b-top {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #7e7ead;
  }
  &&.b-bottom {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid ${({ themeColors }) => themeColors.light};
  }
`);

export const TableCellAgregated = withTheme(styled(TableCell)`
  .label {
    text-align: right;
    padding-bottom: 27px;
    padding-top: 27px;
  }
  img {
    width: 20px;
    padding-left: 5px;
  }
  font-weight: ${({ fontWeight }) =>
    fontWeight ? 'bold' : 'normal'} !important;
  && {
    height: 50px;
    border: none;
    border-radius: 0;
    text-align: center;
  }
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &&.action_td {
    border: none;
  }
  &&.b-all {
    border: 1px solid #7e7ead;
  }
  &&.b-left {
    border-top: none;
    border-right: none;
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 0px;
  }
  &&.b-right {
    border-top: none;
    border-left: none;
    border-bottom: none;
    background-color: ${({ themeColors }) => themeColors.tertiary};
  }
  &&.b-right-head {
    height: 10%;
    border-radius: 0;
    background-color: ${({ themeColors }) => themeColors.funnel};
  }
  &&.b-top {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #7e7ead;
  }
  &&.b-bottom {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid ${({ themeColors }) => themeColors.light};
  }
`);

export const TableRowDistrib = styled(TableRow)``;

export const InputDistrib = withTheme(styled.input`
  width: 3.6rem;
  border: 1px solid ${({ themeColors }) => themeColors.funnel};
  border-radius: 0.5rem;
  height: 1.8rem;
  text-align: center;
  overflow: hidden;
  margin: 0.4rem;
  background-color: ${({ themeColors }) => themeColors.primary};
  color: ${({ themeColors }) => themeColors.light};
`);

export const Image = styled.div``;

export const Container = withTheme(styled.div`
  background-color: ${({ themeColors }) => themeColors.light};
  border-radius: 5px;
  margin: auto 10px;
  padding: 5px 20px;
  text-align: center;
  display: flex;
  .question_mark {
    height: 50px;
    width: 40px;
    margin-right: 10px;
  }
  .align_center {
    color: ${({ themeColors }) => themeColors.dark};
  }
`);

export const ErrorMessage = withTheme(styled.p`
  color: ${({ themeColors }) => themeColors.light};
  font-size: 14px;
  padding: 10px 40px;
`);

export const DateMessage = withTheme(styled.p`
  color: ${({ themeColors }) => themeColors.light};
  font-size: 25px;
  /* padding: 5px 40px; */
  position: relative;
  text-align: center;
  top: 20px;
`);

export const LabelStyle = {
  fontSize: '14px',
  fill: colors.light
};

export const ButtonTab = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
  background-color: transparent;
  /* padding: 10px 25px; */
  float: left;
  width: 100%;
`;

export const ButtonSwitch = withTheme(styled(Button)`
  && {
    border: 1px solid ${({ themeColors }) => themeColors.light};
    width: 160px;
    line-height: 29px;
    text-transform: none;
    text-align: center;
    border-radius: 4px;
    padding: 0;
    cursor: pointer;
    color: ${({ themeColors }) => themeColors.light};
  }
  &&:hover {
    background-color: ${({ themeColors }) => themeColors.light};
    color: ${({ themeColors }) => themeColors.dark};
    font-weight: bold;
  }
  &&.active {
    background-color: ${({ themeColors }) => themeColors.light};
    color: ${({ themeColors }) => themeColors.dark};
    font-weight: bold;
  }
`);

export const ColorCpm = withTheme(styled.div`
  && {
    align-content: center;
    margin: 0 auto;
    border-radius: 7px;
  }
  &&.success {
    background-color: #28a745;
    color: ${({ themeColors }) => themeColors.keep_light};
  }
  &&.alert {
    background-color: #ff0000;
    color: ${({ themeColors }) => themeColors.keep_light};
  }
`);

export const ContentTabGraph = styled.div`
  float: left;
  width: 38%;
  position: relative;
  bottom: 10%;
  margin-top: 3%;
  height: 80%;
  @media (min-width: 1024px) {
    width: 44%;
  }
  @media (min-width: 1280px) {
    width: 26%;
  }
  @media (min-width: 1440px) {
    width: 27%;
  }
  @media (min-width: 1800px) {
    width: 26%;
  }
  &&.withLegend {
    width: 58%;
    @media (min-width: 1024px) {
      width: 55%;
    }
    @media (min-width: 1280px) {
      width: 34%;
    }
    @media (min-width: 1440px) {
      width: 33%;
    }
    @media (min-width: 1800px) {
      width: 32%;
    }
  }
`;
