import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseGrid
 * @component
 *
 */
const BaseGrid = withTheme(styled(Grid)`
  && {
    color: ${({ color, themeColors }) => color || themeColors.light};
    background-color: ${({ bgColor }) => bgColor || 'transparent'};
    padding: ${({ padding }) => padding || '0'};
    width: 100%;
  }
`);

export default BaseGrid;
