import React from 'react';
import PropTypes from 'prop-types';

import CriteriasFormInsight, {
  InsightTitle,
  InsightDesc,
  InsightLegend
} from 'Insight/components/CriteriasFormInsight';
import CriteriasGroupInsight, {
  CriteriasSubGroupInsight
} from 'Insight/components/CriteriasGroupInsight';
import CriteriaInsight from 'Insight/components/CriteriaInsight';
import { INSIGHT_SUR_MESURE_KEY } from 'Insight/constant';

import { useResult } from 'Insight/contexts/ResultContext';
import { getPercent } from 'Insight/containers/CriteriaContainer/functions';

const CRITERIA_DESC =
  'Sélectionner vos critères de ciblage, ajuster vos résultats avec les connecteurs ET/OU, et activer votre audience cible.';

/**
 *
 * @param tagFunnels: the result from elasticSearch
 * @param tagFunnelId: id of the current tagFunnel
 * @param tagFunnelLevel: level of the current tagFunnelLevels
 */
const getAudience = (tagFunnels, tagFunnelId, funnelLevel) => {
  const audience = tagFunnels
    .filter(o => o && o.id === tagFunnelId)
    .flatMap(o => o && o.levels)
    .filter(o => o && o.level === funnelLevel)
    .map(o => o && o.audience);

  return audience && audience.length > 0 ? audience.reduce(o => o) : {};
};

function FunnelContainer({ tagFunnels, isValueSelected, toggleValueSelected }) {
  const { results } = useResult();
  const { tagFunnels: tagFunnelsResult } = results;
  return (
    <CriteriasFormInsight hasInsee={false}>
      <InsightTitle>Comprendre sa cible</InsightTitle>
      <InsightDesc>{CRITERIA_DESC}</InsightDesc>
      <InsightLegend hasInsee={false} hasData={tagFunnels.length !== 0} />
      <CriteriasGroupInsight>
        {tagFunnelsResult &&
          tagFunnels.map(tagFunnel => (
            <React.Fragment key={tagFunnel.id}>
              <CriteriasSubGroupInsight
                hideTitle={false}
                title={tagFunnel.name}
              >
                {tagFunnel.funnelLevels.map(funnelLevel => {
                  const audience = getAudience(
                    tagFunnelsResult,
                    tagFunnel.id,
                    funnelLevel.level
                  );
                  return (
                    <CriteriaInsight
                      key={tagFunnel.id + funnelLevel.level}
                      ermes={getPercent((audience.all && audience) || 0, 'all')}
                      insee={getPercent(
                        (audience.insee && audience) || 0,
                        'insee'
                      )}
                      title={funnelLevel.label}
                      selectable
                      description="DESC"
                      checked={isValueSelected(
                        tagFunnel.id + funnelLevel.level
                      )}
                      hasInsee={audience.insee}
                      toggleCheck={() =>
                        toggleValueSelected({
                          tagFunnel: {
                            key: tagFunnel.id + funnelLevel.level,
                            name: funnelLevel.label,
                            id: tagFunnel.id,
                            level: funnelLevel.level
                          },
                          subCategoryKEY: tagFunnel.name,
                          categoryKEY: INSIGHT_SUR_MESURE_KEY
                        })
                      }
                    />
                  );
                })}
              </CriteriasSubGroupInsight>
            </React.Fragment>
          ))}
      </CriteriasGroupInsight>
    </CriteriasFormInsight>
  );
}

FunnelContainer.defaultProps = {};
FunnelContainer.propTypes = {
  tagFunnels: PropTypes.arrayOf().isRequired,
  isValueSelected: PropTypes.bool.isRequired,
  toggleValueSelected: PropTypes.func.isRequired
};

export default FunnelContainer;
