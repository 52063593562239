import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Loading from 'components/Loading';
import { useTheme } from 'contexts/ThemeContext';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { useUser } from 'contexts/UserContext';

import constants from 'utils/constants';
import { Logo, Login } from './style';

const { routes, colors } = constants;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    paddingTop: 50,
    height: 500,
    width: 500,
    boxShadow: '10px 10px 100px -51px rgba(0,0,0,0.46)'
  },
  control: {
    padding: theme.spacing(2)
  },
  head: {
    marginBottom: 20
  },
  text: {
    width: 500,
    borderRadius: 20,
    margin: 15
  },
  button: {
    width: 500,
    margin: 15,
    background:
      'linear-gradient(90deg,#6b5392 0,#6b5392 18%,#1165b2 60%,#00a4d4 100%)',
    borderRadius: 20,
    '&:hover, &:focus': {
      background:
        'linear-gradient(90deg,#6b4392 0,#6b4392 18%,#1155b2 60%,#0094d4 100%)'
    }
  }
}));

/**
 * Login
 * @component
 * @desc
 *   it must listen on document location pathname becaucse two url renders the
 *   same component (/connect and /) for handler modal behavior
 */
function LoginContainer({ history }) {
  const classes = useStyles();
  const { isThemeLight } = useTheme();
  const redirectToDashboard = () => history.push(routes.DASHBOARD);
  const { signIn, isAuthenticated } = useUser();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isErrorMsg, setIsErrorMsg] = React.useState(false);
  const [isErrorEmail, setIsErrorEmail] = React.useState(false);
  const [isErrorPwd, setIsErrorPwd] = React.useState(false);
  const handleSubmit = event => {
    setIsLoading(true);
    event.preventDefault();
    signIn({ email, password })
      .then(hasAccount => {
        setIsLoading(false);
        if (hasAccount) {
          redirectToDashboard();
        } else {
          setIsErrorMsg(true);
          setIsErrorEmail(true);
          setIsErrorPwd(true);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setIsErrorMsg(true);
        setIsErrorEmail(true);
        setIsErrorPwd(true);
        console.error('errorAuthServ: ', err);
      });
  };
  const logoOnClick = () => {
    window.location.href = process.env.REACT_APP_HOME_URL;
  };
  React.useEffect(() => {
    if (isAuthenticated) redirectToDashboard();
  });

  return (
    <Login>
      <form onSubmit={handleSubmit}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid container direction="column">
                <Grid item xs={7}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    {isThemeLight ? (
                      <Logo
                        src="/assets/img/logo_ermes_homepage_dark.svg"
                        alt="ERMES"
                        onClick={logoOnClick}
                      />
                    ) : (
                      <Logo
                        src="/assets/img/logo_ermes_homepage.svg"
                        alt="ERMES"
                        onClick={logoOnClick}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Paper className={classes.paper}>
                <Grid
                  container
                  direction="column"
                  style={{ marginLeft: '16.5%' }}
                >
                  <Grid item xs={8}>
                    <Grid container justify="center">
                      <Typography variant="h4" className={classes.head}>
                        Connexion
                      </Typography>
                      <TextField
                        id="email"
                        type="text"
                        className={classes.text}
                        label="Email"
                        margin="normal"
                        variant="outlined"
                        onChange={e => setEmail(e.target.value)}
                        onClick={() => setIsErrorEmail(false)}
                        error={isErrorEmail}
                        required
                      />
                      <TextField
                        id="psw"
                        type="password"
                        className={classes.text}
                        label="Mot de passe"
                        margin="normal"
                        variant="outlined"
                        onChange={e => setPassword(e.target.value)}
                        onClick={() => setIsErrorPwd(false)}
                        error={isErrorPwd}
                        required
                      />
                      {isErrorMsg && !isLoading && (
                        <Typography style={{ color: colors.dark.red }}>
                          {'Vos identifiants sont incorrects.'}
                        </Typography>
                      )}
                      <Loading loading={isLoading}>
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.button}
                        >
                          Se connecter
                        </Button>
                        <Typography>
                          <a href="https://www.ermes.ai/contact">
                            {"S'inscrire"}
                          </a>
                          {" si vous n'avez pas de compte"}
                        </Typography>
                      </Loading>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Login>
  );
}

LoginContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(LoginContainer);
