import { gql } from 'apollo-boost';

export default {
  GET_ACTIVATION: gql`
    query activation($id: ID!) {
      activation(id: $id) {
        id
        name
        startDate
        endDate
        budget
        objective
        nexusSize
        profiles
        campaignRatios
        transformationRates
        filters
      }
    }
  `,
  GET_ACTIVATIONS: gql`
    query activations($limit: Int) {
      activations(limit: $limit) {
        id
        name
        startDate
        endDate
        budget
        objective
        profiles
        createdAt
      }
    }
  `,
  CREATE_ACTIVATION: gql`
    mutation createActivation(
      $userId: ID!
      $name: String!
      $startDate: DateTime!
      $endDate: DateTime!
      $budget: Float!
      $objective: String!
      $nexusSize: Float!
      $profiles: Int!
      $campaignRatios: Json!
      $transformationRates: Json!
      $filters: Json!
    ) {
      createActivation(
        input: {
          userId: $userId
          name: $name
          startDate: $startDate
          endDate: $endDate
          budget: $budget
          objective: $objective
          nexusSize: $nexusSize
          profiles: $profiles
          campaignRatios: $campaignRatios
          transformationRates: $transformationRates
          filters: $filters
        }
      ) {
        id
        userId
        name
        startDate
        endDate
        budget
        objective
        nexusSize
        profiles
        campaignRatios
        transformationRates
        filters
        createdAt
        updatedAt
      }
    }
  `,
  UPDATE_ACTIVATION: gql`
    mutation updateActivation(
      $id: ID!
      $userId: ID!
      $name: String!
      $startDate: DateTime!
      $endDate: DateTime!
      $budget: Float!
      $objective: String!
      $profiles: Int!
      $campaignRatios: Json!
      $transformationRates: Json!
      $filters: Json!
    ) {
      updateActivation(
        input: {
          id: $id
          userId: $userId
          name: $name
          startDate: $startDate
          endDate: $endDate
          budget: $budget
          objective: $objective
          profiles: $profiles
          campaignRatios: $campaignRatios
          transformationRates: $transformationRates
          filters: $filters
        }
      ) {
        id
        userId
        name
        startDate
        endDate
        budget
        objective
        profiles
        campaignRatios
        transformationRates
        filters
        createdAt
        updatedAt
      }
    }
  `,
  DELETE_ACTIVATION: gql`
    mutation deleteActivation($id: ID!) {
      deleteActivation(id: $id)
    }
  `,
  SEND_EMAIL: gql`
    mutation sendEmail($input: SendEmailInput!) {
      sendEmail(input: $input)
    }
  `
};
