import React from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { FUNNEL } from 'utils/graphQL';
import { useUser } from 'contexts/UserContext';

import HistoryDrawerFunnel from 'Funnel/components/HistoryDrawerFunnel';
import { useFunnelStep } from '../../contexts/FunnelStepContext';

/**
 * HistoryFunnelContainer
 * @component
 * @see HistoryContainer
 * TODO:: creer les composants Loading, remove panel
 *
 *
 */
function HistoryFunnelContainer() {
  const { isAuthenticated } = useUser();
  const { isDrawerOpen, setIsDrawerOpen } = useFunnelStep();
  const variables = { limit: 100 };
  const response = useQuery(FUNNEL.LIST_FUNNEL, {
    variables,
    skip: !isAuthenticated
  });
  const [deleteFunnelFn] = useMutation(FUNNEL.DELETE_FUNNEL);
  const historyFunnel = (response.data && response.data.funnels) || [];
  const isDataLoaded = !!(response.data && response.data.funnels);

  return (
    <HistoryDrawerFunnel
      isOpen={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      historyItems={historyFunnel}
      deleteFunnel={deleteFunnelFn}
      isDataLoaded={isDataLoaded}
    />
  );
}

export default HistoryFunnelContainer;
