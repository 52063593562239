import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'contexts/ThemeContext';
import styled from 'styled-components';

/** * @component */
const BaseLayoutFunnel = styled.div``;

/**
 * LayoutFunnel
 *
 */
function LayoutFunnel(props) {
  return <BaseLayoutFunnel {...props} />;
}

/**
 * GridFunnel
 * @desc contains the two columns (left and right)
 * @component
 */
const GridFunnel = styled.div`
  display: grid;
  grid-template-columns: 6fr 24fr;
  padding: 0 16px 0 2px;
  grid-column-gap: 16px;
`;

/**
 * BaseColumnFunnel
 * @desc the style of one ColumnFunnel
 * @components
 *
 */
const BaseColumnFunnel = styled.div`
  .verticalySpaced {
    display: grid;
    grid-row-gap: 16px;
  }
  .one-block {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

/**
 * ColumnFunnel
 * @desc adapt children wrapper for the ColumnFunnel
 * @components
 */
const ColumnFunnel = props => {
  const { verticalySpaced, children } = props;
  return (
    <BaseColumnFunnel>
      {verticalySpaced ? (
        <div className="verticalySpaced">{children}</div>
      ) : (
        <div className="one-block">{children}</div>
      )}
    </BaseColumnFunnel>
  );
};
ColumnFunnel.defaultProps = {
  verticalySpaced: false
};
ColumnFunnel.propTypes = {
  children: PropTypes.node.isRequired,
  verticalySpaced: PropTypes.bool
};

/**
 * Switch
 * @component
 *
 */
const Switch = withTheme(styled.section`
  background-color: ${({ themeColors }) => themeColors.secondary};
  border-radius: 4px;
  padding: 15px 0px;
  overflow-y: auto;
  width: 100%;
  height: 567px;
  .title_blue_line {
    margin-top: 0;
  }
  .table {
    background-color: ${({ themeColors }) => themeColors.primary};
    color: ${({ themeColors }) => themeColors.light};
    width: 100%;
    // border: 1px solid ${({ themeColors }) => themeColors.light};
    padding: 8px;
    // border-radius: 4px;
    margin-bottom: 16px;
  }
  tbody {
    background-color: ${({ themeColors }) => themeColors.secondary};
  }
  td {
    // border: 2px solid ${({ themeColors }) => themeColors.primary};
    padding: 4px;
    margin: 4px;
    border-radius: 4px;
  }
  .btn_plug {
    background-color: transparent;
    margin: 4px;
    border-color: transparent;
  }
  .modal-content {
    width: 565px;
  }
  .btn-funnel-save {
    color: ${({ themeColors }) => themeColors.light};
    background-color: ${({ themeColors }) => themeColors.primary};
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    padding: 16px 24px;
    margin: 0 8px;
    border: 1px solid ${({ themeColors }) => themeColors.light};
    text-decoration: none;
  }
  input[type='text'] {
    background-color: ${({ themeColors }) => themeColors.primary};
    color: ${({ themeColors }) => themeColors.light};
    padding: 8px;
  }
  .DateInput input[type='text'] {
    cursor: pointer;
  }
  .input-row {
    border-color: ${({ themeColors }) => themeColors.funnel};
    margin: 0 4px 0 4px;
    width: 100px;
  }
  .action_td_container a {
    color: ${({ themeColors }) => themeColors.light};
  }
  .kpi_title {
    font-weight: 600;
  }
  .buying_num_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .download_link {
    display: grid;
    grid-template-columns: 210px 100px 100px;
    margin-left: 15%;
    img {
      height: 60px;
    }
  }
  .filter-headers {
    display: inline-flex;
    font-size:14px;
    grid-column-gap: 4px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    padding: 8px;
    .btn {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid ${({ themeColors }) => themeColors.light};
      cursor: pointer;
      margin: 0 5px;
      text-align: center;
    }
    .btn:hover {
      border-color: ${({ themeColors }) => themeColors.funnel};
      color: ${({ themeColors }) => themeColors.funnel};
    }
    @media(min-width:1800px){
      font-size: 16px;
    }
  }
  .filter-headers.bis {
    grid-template-columns: 170px 170px 170px 170px;
  }
  .graph_container {
  }
  .kpi_table_description {
    color: ${({ themeColors }) => themeColors.light};
    text-align: center;
    @media(max-width: 1024px){
       font-size: 14px;
    }
  }
  .kpi_table_result {
    color: ${({ themeColors }) => themeColors.light};
    margin: 12px 0;
    text-align: center;
  }
  .pb-data {
    overflow-y: auto;
    @media (max-width: 1024px) {
      max-width:68vw;
    }
  }
  .anom_success {
    background-color: green;
  }
  .anom_alert {
    background-color: red;
  }
  @media (max-width: 1024px) {
    max-width: 63vw;
  }
  @media (max-width: 768px) {
    max-width: 61vw;
  }
`);

export default {
  Wrapper: LayoutFunnel,
  Grid: GridFunnel,
  Column: ColumnFunnel,
  Switch
};
