import styled from 'styled-components';
import Icon from 'utils/icons';

/**
 * Logo
 * @component
 *
 */
export const FileUploaded = styled(Icon)`
  margin: 0 0 0 7px;
  vertical-align: middle;
  border-style: none;
`;

export default FileUploaded;
