import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import LayoutFunnel from 'Funnel/components/LayoutFunnel';
import VisibilitiesFunnel from 'Funnel/components/VisibilitiesFunnel';

import ConnectorsContainer from 'Funnel/containers/ConnectorsContainer';
import GraphContainer from 'Funnel/containers/GraphContainer';
import IndicatorsContainer from 'Funnel/containers/IndicatorsContainer';
import InsightSummaryContainer from 'Funnel/containers/InsightSummaryContainer';
import InsightDetailsContainer from 'Funnel/containers/InsightDetailsContainer';
import OverviewContainer from 'Funnel/containers/OverviewContainer';
import PersistContainer from 'Funnel/containers/PersistContainer';
import SynthesisContainer from 'Funnel/containers/SynthesisContainer';
import HistoryDrawerContainer from 'Funnel/containers/HistoryDrawerContainer';

import { useUser } from 'contexts/UserContext';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';
import { useFunnelGraph } from 'Funnel/contexts/FunnelGraphContext';

import ModalDatePicker from './containers/ModalDatePicker';

/**
 * AppFunnel
 * @component
 * @desc :::
 *   AppFunnel is the main funnel files manager
 *   LayoutFunnel (Grid, Wrapper, Column, Switch, ...)  is the main architectural component that places funnel containers
 *   IndicatorsContainer contains the period, name and purpose of a Funnel
 *   InsightSummaryContainer contains the main list-nav that allow to switch between differents graph views
 *   ConnectorsContainer manage the blue buttons that allow to upload/add/connect data to the current funnel
 *   Inside Layout.Switch we can find various details of the calculation result of the current funnel
 *
 */
function AppFunnel(props) {
  const { recoveredFunnel } = props;
  const { isFinalStep, setHasGetFunnel } = useFunnelStep();
  const { graphTab } = useFunnelGraph();
  const { isAuthenticated } = useUser();
  const { setFunnel, parseFunnel, isDatePickerModal } = useFunnel();
  React.useEffect(() => {
    if (recoveredFunnel) {
      const newFunnel = parseFunnel(recoveredFunnel);
      setFunnel(newFunnel);
      setHasGetFunnel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, recoveredFunnel]);
  return (
    <LayoutFunnel.Wrapper>
      <IndicatorsContainer isRecoveryMode={!!recoveredFunnel} />
      <HistoryDrawerContainer />
      <LayoutFunnel.Grid>
        <LayoutFunnel.Column>
          <InsightSummaryContainer />
        </LayoutFunnel.Column>
        <LayoutFunnel.Column verticalySpaced>
          <ConnectorsContainer />
          <VisibilitiesFunnel isSecond={isFinalStep}>
            <OverviewContainer />
            <LayoutFunnel.Switch>
              <div style={{ display: graphTab !== 0 && 'none' }}>
                <SynthesisContainer />
              </div>
              {graphTab === 1 && <GraphContainer />}
              {graphTab >= 2 && <InsightDetailsContainer />}
            </LayoutFunnel.Switch>
          </VisibilitiesFunnel>
        </LayoutFunnel.Column>
      </LayoutFunnel.Grid>
      <PersistContainer />
      <ModalDatePicker open={isDatePickerModal} disableBackdropClick />
    </LayoutFunnel.Wrapper>
  );
}
AppFunnel.defaultProps = {
  recoveredFunnel: null
};
AppFunnel.propTypes = {
  recoveredFunnel: PropTypes.objectOf(PropTypes.any)
};

export default withRouter(AppFunnel);
