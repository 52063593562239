import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 *
 *BaseLinkMail
 *
 */
const BaseLinkMail = withTheme(styled.a`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  color: ${({ color, themeColors }) => color || themeColors.secondary};
  padding: ${({ padding }) => padding || 'auto'};
  display: inline-block;
  font-size: 1.2em;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`);

export const ConnectBlock = styled.div`
  padding: 12px 0;
`;

export default withTheme(BaseLinkMail);
