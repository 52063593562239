import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';

/**
 * LoadingAuth
 * wait for reconnect
 * @desc waiting for reconnection on first loading app
 * @return Authenticated needed component or redirection
 *
 */
const LoadingAuth = props => {
  const { children, isLoading, isAuthenticated } = props;
  const [ComponentChild, RedirectAuth] = React.Children.toArray(children);
  React.useEffect(() => {}, [isLoading]);
  return (
    <Loading loading={isLoading} data={{ component: true }}>
      {isAuthenticated && ComponentChild}
      {!isAuthenticated && RedirectAuth}
    </Loading>
  );
};
LoadingAuth.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

export default LoadingAuth;
