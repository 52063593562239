import React from 'react';
import PropTypes from 'prop-types';

import Block from './Block';

/**
 * LaunchCampaign
 * @component
 */
function BlockLaunchCampaign({ onClick, disabled }) {
  return (
    <Block
      icon="launchCampaign"
      onClick={onClick}
      disabled={disabled}
      title="Lancer une campagne"
      text="Déléguez vos campagnes programmatiques à notre équipe d’Ad Traders
      s’appuyant sur la technologie de ciblage Ermes et atteignez votre
      audience sur tous les supports digitaux . Un processus de configuration
      efficient depuis la définition de votre cible jusqu’à l’upload de vos
      créations."
    />
  );
}
BlockLaunchCampaign.defaultProps = {
  disabled: false,
  onClick: () => {}
};
BlockLaunchCampaign.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default BlockLaunchCampaign;
