import React from 'react';
import PropTypes from 'prop-types';
import BaseDropdownInsight, { ArrowDown } from './style';

/**
 * @component
 *
 */
function DropdownInsight(props) {
  const { options, onChange, defaultValue, label, fix } = props;
  const [selected, setSelected] = React.useState(defaultValue);
  const onClick = (e, value) => {
    e.preventDefault();
    onChange(value);
    setSelected(value);
  };

  return (
    <BaseDropdownInsight {...props}>
      <span className="label">{label}</span>
      <ArrowDown
        className="custom_select_arrow"
        src="/assets/img/arrow_down_no_outline.svg"
        alt="fleche"
      />
      <button className="dropbtn" type="button">
        {fix ? defaultValue : selected || defaultValue}
      </button>
      <div className="dropdown-content">
        {options.map((option, i) => (
          <a
            // @TODO Fix this with better logic
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            value={option}
            className="options"
            href="#"
            onClick={e => onClick(e, option)}
          >
            {option}
          </a>
        ))}
      </div>
    </BaseDropdownInsight>
  );
}

DropdownInsight.defaultProps = {
  defaultValue: 'Sélectionner une option',
  onChange: () => null,
  label: '',
  options: [],
  fix: false
};

DropdownInsight.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  fix: PropTypes.bool
};

export default DropdownInsight;
