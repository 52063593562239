import React from 'react';

export default {
  dv360() {
    return (
      <svg
        width="129"
        height="30"
        viewBox="0 0 575 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M105.8 61.8V22H118C124.1 22 129 23.8 132.7 27.5C136.4 31.2 138.2 36 138.2 41.9C138.2 47.9 136.4 52.7 132.7 56.3C129 59.9 124.1 61.8 118 61.8H105.8V61.8ZM110.9 26.8V56.8H118C122.6 56.8 126.3 55.5 129 52.8C131.7 50.2 133 46.5 133 41.8C133 37.1 131.7 33.5 129 30.8C126.3 28.1 122.7 26.8 118 26.8H110.9Z"
          fill="#606469"
        />
        <path
          d="M148.9 25.1002C148.9 26.1002 148.5 27.0002 147.8 27.7002C147.1 28.4002 146.2 28.8001 145.2 28.8001C144.2 28.8001 143.3 28.5002 142.6 27.7002C141.9 27.0002 141.5 26.1002 141.5 25.1002C141.5 24.1002 141.9 23.2002 142.6 22.5002C143.3 21.8002 144.2 21.4001 145.2 21.4001C146.2 21.4001 147.1 21.8002 147.8 22.5002C148.6 23.3002 148.9 24.1002 148.9 25.1002ZM147.8 34.5002V61.8001H142.7V34.5002H147.8Z"
          fill="#606469"
        />
        <path
          d="M174.9 54.2001C174.9 56.6001 173.9 58.6001 171.8 60.2001C169.7 61.8001 167.1 62.6001 164 62.6001C161.3 62.6001 158.8 61.9001 156.8 60.5001C154.7 59.1001 153.2 57.2001 152.4 54.9001L157 53.0001C157.7 54.6001 158.6 55.9001 159.9 56.8001C161.2 57.7001 162.6 58.2001 164.1 58.2001C165.7 58.2001 167.1 57.8001 168.2 57.1001C169.3 56.4001 169.8 55.6001 169.8 54.6001C169.8 52.9001 168.5 51.6001 165.8 50.8001L161.1 49.6001C155.8 48.3001 153.1 45.7001 153.1 41.9001C153.1 39.4001 154.1 37.4001 156.1 35.9001C158.1 34.4001 160.7 33.6001 163.9 33.6001C166.3 33.6001 168.5 34.2001 170.4 35.3001C172.3 36.5001 173.7 38.0001 174.5 39.9001L169.9 41.8001C169.4 40.7001 168.5 39.8001 167.4 39.1001C166.2 38.5001 164.9 38.1001 163.5 38.1001C162.2 38.1001 161 38.4001 159.9 39.1001C158.8 39.8001 158.3 40.6001 158.3 41.5001C158.3 43.1001 159.8 44.2001 162.7 44.8001L166.8 45.9001C172.2 47.4001 174.9 50.1001 174.9 54.2001Z"
          fill="#606469"
        />
        <path
          d="M193.5 62.7001C191.5 62.7001 189.7 62.3001 188 61.4001C186.4 60.5001 185.1 59.4001 184.2 58.0001H184L184.2 61.8001V73.8001H179.1V34.5001H184V38.3001H184.2C185.1 36.9001 186.4 35.8001 188 34.9001C189.7 34.0001 191.5 33.6001 193.5 33.6001C197.1 33.6001 200.1 35.0001 202.6 37.8001C205.2 40.7001 206.4 44.1001 206.4 48.1001C206.4 52.1001 205.1 55.6001 202.6 58.4001C200.1 61.3001 197.1 62.7001 193.5 62.7001ZM192.7 58.0001C195.1 58.0001 197.2 57.1001 198.9 55.2001C200.6 53.4001 201.4 51.0001 201.4 48.1001C201.4 45.2001 200.6 42.9001 198.9 41.0001C197.2 39.1001 195.2 38.2001 192.7 38.2001C190.2 38.2001 188.1 39.1001 186.5 41.0001C184.9 42.9001 184.1 45.2001 184.1 48.1001C184.1 51.0001 184.9 53.4001 186.5 55.2001C188.1 57.1001 190.2 58.0001 192.7 58.0001Z"
          fill="#606469"
        />
        <path d="M216.1 22V61.8H211V22H216.1Z" fill="#606469" />
        <path
          d="M232.5 33.6001C236.3 33.6001 239.3 34.6001 241.5 36.6001C243.7 38.6001 244.8 41.4001 244.8 44.9001V61.7001H239.9V58.0001H239.7C237.6 61.1001 234.8 62.7001 231.2 62.7001C228.2 62.7001 225.7 61.8001 223.7 60.0001C221.7 58.2001 220.7 56.0001 220.7 53.3001C220.7 50.5001 221.8 48.2001 223.9 46.6001C226 44.9001 228.9 44.1001 232.4 44.1001C235.4 44.1001 237.9 44.7001 239.9 45.8001V44.6001C239.9 42.8001 239.2 41.3001 237.8 40.1001C236.4 38.9001 234.7 38.2001 232.9 38.2001C230 38.2001 227.8 39.4001 226.1 41.8001L221.6 39.0001C224 35.4001 227.6 33.6001 232.5 33.6001ZM225.9 53.4001C225.9 54.7001 226.5 55.8001 227.6 56.7001C228.7 57.6001 230.1 58.0001 231.6 58.0001C233.7 58.0001 235.7 57.2001 237.4 55.6001C239.1 54.0001 239.9 52.1001 239.9 50.0001C238.3 48.7001 236.1 48.1001 233.2 48.1001C231.1 48.1001 229.4 48.6001 228 49.6001C226.6 50.6001 225.9 51.9001 225.9 53.4001Z"
          fill="#606469"
        />
        <path
          d="M273.6 34.5L256.5 73.8H251.2L257.5 60.1L246.3 34.6H251.9L260 54.2H260.1L268 34.6H273.6V34.5Z"
          fill="#606469"
        />
        <path
          d="M314.6 54.9001L318.3 59.8001L314.5 62.7001L311.8 59.1001C309.1 61.5001 306 62.7001 302.6 62.7001C298.6 62.7001 295.1 61.4001 292.3 58.6001C289.5 55.9001 288.1 52.6001 288.1 48.6001C288.1 46.3001 288.7 44.0001 290 42.0001C291.2 39.9001 292.9 38.2001 295 36.9001C293.1 34.4001 292.1 32.2001 292.1 30.3001C292.1 27.8001 293 25.6001 294.9 23.8001C296.8 22.0001 299.1 21.1001 301.7 21.1001C303.8 21.1001 305.6 21.7001 307.2 22.9001C308.8 24.1001 309.9 25.7001 310.6 27.8001L306.2 30.1001C305.2 27.3001 303.7 25.9001 301.7 25.9001C300.4 25.9001 299.3 26.3001 298.5 27.1001C297.7 27.9001 297.3 28.9001 297.3 30.2001C297.3 31.5001 297.7 32.6001 298.6 33.8001L311.8 51.0001L315.4 45.0001L319.4 47.4001L314.6 54.9001ZM292.7 48.6001C292.7 51.3001 293.6 53.5001 295.5 55.3001C297.4 57.1001 299.7 58.0001 302.5 58.0001C305.1 58.0001 307.3 57.1001 308.9 55.3001L297.7 40.6001C296.1 41.5001 294.9 42.6001 294 44.0001C293.1 45.5001 292.7 47.0001 292.7 48.6001Z"
          fill="#606469"
        />
        <path
          d="M351 61.8H345.7L331.7 22H337.4L348.3 54.3H348.5L359.8 22H365.5L351 61.8Z"
          fill="#606469"
        />
        <path
          d="M374.7 25.1002C374.7 26.1002 374.3 27.0002 373.6 27.7002C372.9 28.4002 372 28.8001 371 28.8001C370 28.8001 369.1 28.5002 368.4 27.7002C367.7 27.0002 367.3 26.1002 367.3 25.1002C367.3 24.1002 367.7 23.2002 368.4 22.5002C369.1 21.8002 370 21.4001 371 21.4001C372 21.4001 372.9 21.8002 373.6 22.5002C374.4 23.3002 374.7 24.1002 374.7 25.1002ZM373.7 34.5002V61.8001H368.6V34.5002H373.7Z"
          fill="#606469"
        />
        <path
          d="M391.3 62.7C387.7 62.7 384.7 61.3 382.1 58.5C379.6 55.6 378.3 52.2 378.3 48.2C378.3 44.2 379.6 40.8 382.1 37.9C384.7 35.1 387.7 33.7 391.3 33.7C393.3 33.7 395.1 34.1 396.8 35C398.4 35.9 399.7 37 400.6 38.4H400.8L400.6 34.6V22H405.7V61.8H400.8V58H400.6C399.7 59.4 398.4 60.5 396.8 61.4C395.1 62.2 393.3 62.7 391.3 62.7ZM392.1 58C394.6 58 396.7 57.1 398.3 55.3C400 53.5 400.8 51.1 400.8 48.2C400.8 45.3 400 43 398.3 41.1C396.7 39.2 394.6 38.3 392.1 38.3C389.7 38.3 387.6 39.2 385.9 41.1C384.2 43 383.4 45.3 383.4 48.2C383.4 51.1 384.2 53.4 385.9 55.3C387.6 57.1 389.7 58 392.1 58Z"
          fill="#606469"
        />
        <path
          d="M424.1 62.7002C420.1 62.7002 416.8 61.3002 414.2 58.6002C411.6 55.9002 410.3 52.4002 410.3 48.2002C410.3 44.0002 411.6 40.6002 414.1 37.8002C416.6 35.0002 419.8 33.7002 423.8 33.7002C427.8 33.7002 431.1 35.0002 433.4 37.6002C435.8 40.2002 437 43.9002 437 48.6002L436.9 49.2002H415.5C415.6 51.9002 416.5 54.0002 418.2 55.7002C419.9 57.3002 421.9 58.1002 424.3 58.1002C427.6 58.1002 430.1 56.5002 432 53.2002L436.6 55.4002C435.4 57.7002 433.7 59.5002 431.5 60.8002C429.3 62.0002 426.8 62.7002 424.1 62.7002ZM415.9 44.9002H431.5C431.3 43.0002 430.6 41.4002 429.2 40.2002C427.8 39.0002 425.9 38.3002 423.6 38.3002C421.7 38.3002 420 38.9002 418.6 40.1002C417.3 41.3002 416.3 42.9002 415.9 44.9002Z"
          fill="#606469"
        />
        <path
          d="M440.1 48.1001C440.1 43.9001 441.4 40.4001 444 37.7001C446.7 35.0001 450 33.6001 454.1 33.6001C458.1 33.6001 461.5 35.0001 464.1 37.7001C466.8 40.4001 468.1 43.9001 468.1 48.1001C468.1 52.3001 466.8 55.8001 464.1 58.5001C461.5 61.2001 458.1 62.6001 454.1 62.6001C450.1 62.6001 446.7 61.2001 444 58.5001C441.4 55.8001 440.1 52.3001 440.1 48.1001ZM445.2 48.1001C445.2 51.0001 446.1 53.4001 447.8 55.2001C449.5 57.0001 451.6 57.9001 454.1 57.9001C456.6 57.9001 458.7 57.0001 460.4 55.2001C462.1 53.4001 463 51.0001 463 48.1001C463 45.2001 462.1 42.9001 460.4 41.0001C458.7 39.1001 456.5 38.2001 454.1 38.2001C451.6 38.2001 449.5 39.1001 447.8 41.0001C446 42.9001 445.2 45.3001 445.2 48.1001Z"
          fill="#606469"
        />
        <path
          d="M509.2 51.0002C509.2 54.3002 508 57.1002 505.5 59.3002C503.1 61.5002 500 62.6002 496.4 62.6002C493.5 62.6002 491 61.7002 488.7 60.0002C486.4 58.2002 484.8 55.9002 484 53.0002L488.7 51.1002C489.3 53.2002 490.3 54.8002 491.6 56.0002C493 57.1002 494.6 57.7002 496.5 57.7002C498.6 57.7002 500.4 57.1002 501.9 55.8002C503.4 54.5002 504.1 52.9002 504.1 51.0002C504.1 49.1002 503.3 47.5002 501.8 46.2002C500.3 44.9002 498.4 44.3002 496.3 44.3002H493V39.5002H495.9C497.9 39.5002 499.6 38.9002 500.9 37.8002C502.2 36.7002 502.8 35.2002 502.8 33.4002C502.8 31.7002 502.2 30.4002 501 29.3002C499.8 28.3002 498.2 27.7002 496.2 27.7002C493 27.7002 490.8 29.4002 489.6 32.6002L484.9 30.6002C485.8 28.3002 487.2 26.4002 489.2 24.9002C491.2 23.5002 493.6 22.7002 496.3 22.7002C499.7 22.7002 502.5 23.7002 504.8 25.7002C507.1 27.6002 508.2 30.1002 508.2 33.2002C508.2 36.9002 506.5 39.6002 503.1 41.4002V41.6002C505.1 42.5002 506.7 43.7002 507.8 45.3002C508.6 47.1002 509.2 48.9002 509.2 51.0002Z"
          fill="#606469"
        />
        <path
          d="M527.6 37.6001C530.7 37.6001 533.4 38.8 535.8 41.3C538.2 43.7 539.5 46.6001 539.5 49.9001C539.5 53.3001 538.2 56.3001 535.6 58.9001C533 61.4001 529.9 62.7001 526.5 62.7001C522.8 62.7001 519.7 61.4001 517.2 58.9001C514.7 56.4001 513.4 53.4001 513.4 49.9001C513.4 46.8001 514.6 43.3001 517.1 39.4001L528.2 22.8L532.3 25.6001L522.9 38.6001L523 38.7001C524.2 38.0001 525.7 37.6001 527.6 37.6001ZM518.6 50.0001C518.6 52.2001 519.4 54.0001 520.9 55.6001C522.4 57.1001 524.3 57.8 526.5 57.8C528.7 57.8 530.6 57.1001 532.1 55.6001C533.6 54.1001 534.4 52.2001 534.4 50.0001C534.4 47.9001 533.6 46.0001 532.1 44.5001C530.6 43.0001 528.7 42.2001 526.6 42.2001C524.5 42.2001 522.6 43.0001 521 44.5001C519.4 46.0001 518.6 47.8001 518.6 50.0001Z"
          fill="#606469"
        />
        <path
          d="M543.7 42.8001C543.7 37.0001 545.1 32.3002 548 28.5002C550.9 24.8002 554.5 22.9001 559 22.9001C563.5 22.9001 567.2 24.8002 570 28.6002C572.9 32.3002 574.3 37.1001 574.3 42.8001C574.3 48.5001 572.9 53.3002 570 57.1002C567.1 60.8002 563.5 62.7002 559 62.7002C554.5 62.7002 550.8 60.8002 548 57.1002C545.1 53.2002 543.7 48.5001 543.7 42.8001ZM569 42.8001C569 38.4001 568.1 34.8002 566.2 32.0002C564.4 29.2002 562 27.8001 558.9 27.8001C555.8 27.8001 553.4 29.2002 551.6 32.0002C549.7 34.8002 548.8 38.4001 548.8 42.8001C548.8 47.2001 549.7 50.8002 551.6 53.6002C553.4 56.4002 555.8 57.8001 558.9 57.8001C562 57.8001 564.4 56.4002 566.2 53.6002C568.1 50.7002 569 47.1001 569 42.8001Z"
          fill="#606469"
        />
        <path
          d="M57.0001 31.8001C61.7001 29.1001 67.7001 30.7001 70.4001 35.4001C73.1001 40.1001 71.5001 46.1001 66.8001 48.8001L15.8001 78.2001C11.1001 80.9001 5.10007 79.3001 2.40007 74.6001C-0.299932 69.9001 1.30007 63.9001 6.00007 61.2001L57.0001 31.8001Z"
          fill="#82C393"
        />
        <path
          d="M20.4 10.8001C20.4 4.70005 14.9 -0.0999215 8.60004 1.20008C4.00004 2.10008 0.900024 6.50008 0.900024 11.2001V69.6001L20.4 69.9001V10.8001Z"
          fill="#31A753"
        />
        <path
          d="M57.3001 48.7999C62.0001 51.4999 68.3001 49.3 70.7001 45.2C73.4001 40.5 71.9001 34.5 67.2001 31.8L15.8001 2.39996C10.7001 -0.500043 5.1001 1.29996 2.3001 5.99996C-0.399904 10.7 1.20009 16.7 5.90009 19.4L57.3001 48.7999Z"
          fill="#5EB573"
        />
        <path
          d="M66.8 48.8001L20.4 74.7001V52.4001L57.3 31.8001C62.6 28.8001 69.6 31.2001 71.7 37.2001C73.1 41.7001 70.9 46.5001 66.8 48.8001Z"
          fill="#82C393"
        />
        <path
          d="M10.6 79.7001C15.9572 79.7001 20.3 75.3572 20.3 70.0001C20.3 64.6429 15.9572 60.3 10.6 60.3C5.24287 60.3 0.900024 64.6429 0.900024 70.0001C0.900024 75.3572 5.24287 79.7001 10.6 79.7001Z"
          fill="#1F8E3E"
        />
      </svg>
    );
  }
};
