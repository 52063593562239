import React from 'react';
// import PropTypes from 'prop-types'

import BaseErrorMessage from './style';

/**
 * ErrorMessageFunnel
 * @component
 *
 */
function ErrorMessageFunnel(props) {
  return <BaseErrorMessage {...props} />;
}
ErrorMessageFunnel.defaultProps = {};
ErrorMessageFunnel.propTypes = {};

export default ErrorMessageFunnel;
