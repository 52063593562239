// @TODO Fix this with better object definition
/* eslint-disable no-underscore-dangle */
import { TimeRange, TimeSeries } from 'pondjs';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ChartContainer,
  ChartRow,
  Charts,
  EventMarker,
  Legend,
  LineChart,
  Resizable,
  ScatterChart,
  styler,
  YAxis
} from 'react-timeseries-charts';
import moment from 'utils/moment';

import ChartKpiVolumeContainer, {
  Container,
  Description,
  LegendContainer,
  LegendWrapper,
  linkStyle,
  linkStyleActive,
  ZoomButtonContainer
} from './style';

const style = styler([
  { key: 'CPM', color: '#2F80ED', width: '2px' },
  { key: 'CPV', color: '#009FE3', width: '2px' },
  { key: 'CPD', color: '#80B0F5', width: '2px' },
  { key: 'CPI', color: '#cdf0ff', width: '2px' },
  { key: 'ANOM', color: '#CA4040', symbolType: 'dot' },
  { key: 'DAYOFF', color: '#009FE3', symbolType: 'dot' }
]);

/**
 * ChartKpiVolume
 * @component
 *
 */
function ChartKpiVolume(props) {
  const {
    allkpi,
    allname,
    funnel,
    graphId,
    series,
    seriesanom,
    seriesBegin,
    seriescpd,
    seriescpi,
    seriescpv,
    seriesdayoff,
    seriesZoom,
    start,
    end
  } = props;
  const [mode, setMode] = React.useState('zoom');

  const maxSet = () => {
    const e = series.max('CPM');
    const d = seriescpd.max('CPD');
    const i = seriescpi.max('CPI');
    const v = seriescpv.max('CPV');
    let total;
    if (graphId !== 3) total = Math.max(e, d, i, v);
    else total = Math.max(e, d, i);
    return total;
  };

  const [max, setMax] = React.useState(maxSet());
  const [timerange, setTimerange] = React.useState(
    new TimeRange([moment(start).add(1, 'day'), moment(end)])
  );
  const [tracker, setTracker] = React.useState(null);
  const [trackerEvent, setTrackerEvent] = React.useState(null);
  const [trackerValue, setTrackerValue] = React.useState('');
  const [trackerValuecpv, setTrackerValuecpv] = React.useState('');
  const [trackerValuecpd, setTrackerValuecpd] = React.useState('');
  const [trackerValuecpi, setTrackerValuecpi] = React.useState('');
  const min = seriesBegin;
  const [showCPM, setShowCPM] = React.useState(false);
  const [showCPD, setShowCPD] = React.useState(false);
  const [showCPI, setShowCPI] = React.useState(false);
  const [showCPV, setShowCPV] = React.useState(false);
  const [showANOM, setShowANOM] = React.useState(false);
  const [showDAYOFF, setShowDAYOFF] = React.useState(false);
  const markerMode = 'flag';
  const zoom = seriesZoom;
  const getMotif = (currentKpi, time) => {
    if (funnel.anom) {
      const kpis = funnel.anom.filter(e => e.funnelId === currentKpi);
      if (kpis.length > 0) {
        const f = kpis.filter(i => i.date === time.format('DD-MM-YYYY'));
        if (f.length > 0) {
          return `(anomalie : ${f[f.length - 1].motifanom})`;
        }
      }
    }
    return '';
  };

  const handleTrackerChanged = t => {
    let d;
    let v;
    let i;
    let eventValue;

    if (t) {
      const e = series.atTime(t);
      eventValue = e.get('CPM');
      const m = `${eventValue.toFixed(2)}${getMotif(allkpi[0], moment(t))}`;
      if (seriescpd._collection._eventList.size !== 0) {
        const ed = seriescpd.atTime(t);
        const eventValuedd = ed.get('CPD');
        d = `${eventValuedd.toFixed(2)}${getMotif(allkpi[1], moment(t))}`;
      }
      if (seriescpv._collection._eventList.size !== 0) {
        const ev = seriescpv.atTime(t);
        const eventValuedv = ev.get('CPV');
        v = `${eventValuedv.toFixed(2)}${getMotif(allkpi[2], moment(t))}`;
      }
      if (seriescpi._collection._eventList.size !== 0) {
        const ei = seriescpi.atTime(t);
        const eventValuedi = ei.get('CPI');
        i = `${eventValuedi.toFixed(2)}${getMotif(allkpi[3], moment(t))}`;
      }
      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );
      setTracker(eventTime);
      setTrackerValue(m);
      setTrackerValuecpd(d);
      setTrackerValuecpv(v);
      setTrackerValuecpi(i);
      setTrackerEvent(e);
    } else {
      setTracker(null);
      setTrackerValue(null);
      setTrackerEvent(null);
    }
  };

  function getEventMarkerInfo() {
    switch (graphId) {
      case 1:
        return [
          {
            label: `${allkpi[0]}`,
            value: `${trackerValue} €`
          },
          {
            label: allkpi[1],
            value: `${trackerValuecpv} €`
          },
          {
            label: allkpi[2],
            value: `${trackerValuecpd} €`
          },
          {
            label: allkpi[3],
            value: `${trackerValuecpi} €`
          }
        ];
      case 2:
        return [
          {
            label: allname[0],
            value: parseInt(trackerValue, 10)
          },
          {
            label: allname[1],
            value: parseInt(trackerValuecpv, 10)
          },
          {
            label: allname[2],
            value: parseInt(trackerValuecpd, 10)
          },
          {
            label: allname[3],
            value: parseInt(trackerValuecpi, 10)
          }
        ];
      default:
        return [
          {
            label: `${allname[1].split(' ')[0]} / ${allname[0].split(' ')[0]}`,
            value: `${trackerValuecpi} %`
          },
          {
            label: `${allname[2].split(' ')[0]} / ${allname[1].split(' ')[0]}`,
            value: `${trackerValuecpd} %`
          },
          {
            label: `${allname[3].split(' ')[0]} / ${allname[2].split(' ')[0]}`,
            value: `${trackerValue} %`
          }
        ];
    }
  }

  const renderMarker = () => {
    if (!tracker) {
      return () => <g />;
    }
    if (markerMode === 'flag') {
      return (
        <EventMarker
          type="flag"
          axis="y"
          event={trackerEvent}
          info={getEventMarkerInfo()}
          infoHeight={65}
          infoStyle={{ fill: 'white', stroke: 'black' }}
          infoTimeFormat="%d/%m/%y"
          infoWidth={200}
          markerRadius={3}
          markerStyle={{ fill: 'white' }}
        />
      );
    }
    return (
      <EventMarker
        axis="axis"
        event={trackerEvent}
        infoStyle={{ fill: 'white', stroke: 'black' }}
        markerLabel={trackerValue}
        markerLabelAlign="left"
        markerLabelStyle={{ fill: 'white', stroke: 'black' }}
        markerRadius={3}
        markerStyle={{ fill: 'white' }}
        type="point"
      />
    );
  };

  const handleTimeRangeChange = newTimerange => setTimerange(newTimerange);

  const timeZoom = () => {
    const newmax = max * (1 - 20 / 100);
    if (newmax < zoom) {
      return 0;
    }
    setMode('zoom');
    setMax(newmax);
    return newmax;
  };

  const timedeZoom = () => {
    const newmax = max * (1 + 20 / 100);

    setMode('dezoom');
    setMax(newmax);
    return newmax;
  };

  const handleActiveChange = channelName => {
    let e = series.max('CPM');
    let d = seriescpd.max('CPD');
    let i = seriescpi.max('CPI');
    let v = seriescpv.max('CPV');
    if (channelName === 'CPM') {
      e = 0;
      setShowCPM(prev => !prev);
    }
    if (channelName === 'CPD') {
      d = 0;
      setShowCPD(prev => !prev);
    }
    if (channelName === 'CPI') {
      i = 0;
      setShowCPI(prev => !prev);
    }
    if (channelName === 'CPV') {
      v = 0;
      setShowCPV(prev => !prev);
    }
    if (channelName === 'ANOM') {
      setShowANOM(prev => !prev);
      return;
    }
    if (channelName === 'DAYOFF') {
      setShowDAYOFF(prev => !prev);
      return;
    }
    let total;
    if (graphId !== 3) total = Math.max(e, d, i, v);
    else total = Math.max(e, d, i);
    setMax(total);
  };

  const renderChart = () => {
    const croppedSeries = series.crop(timerange);
    return (
      <ChartContainer
        paddingLeft={25}
        paddingRight={20}
        timeRange={timerange}
        onTimeRangeChanged={handleTimeRangeChange}
        onTrackerChanged={handleTrackerChanged}
        format="%d/%m"
        trackerPosition={tracker}
        onBackgroundClick={() => {
          setShowCPM(false);
          setShowCPD(false);
          setShowCPI(false);
          setShowCPV(false);
          setShowANOM(false);
          setShowDAYOFF(false);
        }}
        onSelectionChange={handleActiveChange}
        onContextMenu={handleActiveChange}
      >
        <ChartRow height="300">
          <YAxis
            id="y"
            min={min}
            max={max}
            format=",.2f"
            width={40}
            height={10}
            style={style}
            type="linear"
          />
          <Charts>
            <LineChart
              axis="y"
              style={style}
              columns={['CPM']}
              series={croppedSeries}
              visible={!showCPM}
            />
            <LineChart
              axis="y"
              style={style}
              columns={['CPV']}
              series={seriescpv}
              visible={!showCPV}
            />
            <LineChart
              axis="y"
              style={style}
              columns={['CPD']}
              series={seriescpd}
              visible={!showCPD}
            />
            <LineChart
              axis="y"
              style={style}
              columns={['CPI']}
              series={seriescpi}
              visible={!showCPI}
            />
            <ScatterChart
              axis="y"
              style={style}
              columns={['ANOM']}
              series={seriesanom}
              radius={5}
              visible={!showANOM}
            />
            <ScatterChart
              axis="y"
              style={style}
              columns={['DAYOFF']}
              series={seriesdayoff}
              radius={5}
              visible={!showDAYOFF}
            />
            {renderMarker()}
          </Charts>
        </ChartRow>
      </ChartContainer>
    );
  };

  const renderLegend = () => {
    if (graphId === 1)
      return (
        <LegendWrapper>
          <Legend
            type="line"
            align="left"
            stack
            style={style}
            categories={[
              {
                key: 'CPM',
                label: allkpi[0],
                disabled: showCPM
              },
              { key: 'CPV', label: allkpi[1], disabled: showCPV },
              { key: 'CPD', label: allkpi[2], disabled: showCPD },
              { key: 'CPI', label: allkpi[3], disabled: showCPI },
              {
                key: 'ANOM',
                label: 'Anomalies',
                symbolType: 'dot',
                disabled: showANOM
              },
              {
                key: 'DAYOFF',
                label: 'Dimanche/Jours fériés',
                symbolType: 'dot',
                disabled: showDAYOFF
              }
            ]}
            select=""
            onSelectionChange={handleActiveChange}
          />
        </LegendWrapper>
      );
    if (graphId === 2)
      return (
        <LegendWrapper>
          <Legend
            type="line"
            align="right"
            stack
            style={style}
            categories={[
              {
                key: 'CPM',
                label: allname[0].split(' ')[0],
                disabled: showCPM
              },
              {
                key: 'CPV',
                label: allname[1].split(' ')[0],
                disabled: showCPV
              },
              {
                key: 'CPD',
                label: allname[2].split(' ')[0],
                disabled: showCPD
              },
              {
                key: 'CPI',
                label: allname[3].split(' ')[0],
                disabled: showCPI
              },
              { key: 'ANOM', label: 'Anomalies', symbolType: 'dot' },
              {
                key: 'DAYOFF',
                label: 'Dimanche/Jours fériés',
                symbolType: 'dot'
              }
            ]}
            onSelectionChange={handleActiveChange}
          />
        </LegendWrapper>
      );
    if (graphId === 3)
      return (
        <LegendWrapper>
          <Legend
            type="line"
            align="left"
            stack
            style={style}
            categories={[
              {
                key: 'CPI',
                label: `${allname[1].split(' ')[0]}/${
                  allname[0].split(' ')[0]
                }`,
                disabled: showCPI
              },
              {
                key: 'CPD',
                label: `${allname[2].split(' ')[0]}/${
                  allname[1].split(' ')[0]
                }`,
                disabled: showCPD
              },
              {
                key: 'CPM',
                label: `${allname[3].split(' ')[0]}/${
                  allname[2].split(' ')[0]
                }`,
                disabled: showCPM
              },
              { key: 'ANOM', label: 'Anomalies', symbolType: 'dot' },
              {
                key: 'DAYOFF',
                label: 'Dimanche/Jours fériés',
                symbolType: 'dot'
              }
            ]}
            onSelectionChange={handleActiveChange}
          />
        </LegendWrapper>
      );
    return null;
  };

  React.useEffect(() => {}, [graphId]);
  return (
    <ChartKpiVolumeContainer>
      <Container>
        <ZoomButtonContainer>
          <span
            className="zoom_buttonplus"
            style={mode === 'zoom' ? linkStyleActive : linkStyle}
            onClick={timeZoom}
            onKeyPress={timeZoom}
            role="button"
            tabIndex="0"
          >
            +
          </span>
          <span
            className="zoom_buttonmoins"
            style={mode === 'dezoom' ? linkStyleActive : linkStyle}
            onClick={timedeZoom}
            onKeyPress={timedeZoom}
            role="button"
            tabIndex="-1"
          >
            -
          </span>
        </ZoomButtonContainer>
        <Description>
          Cliquez sur la légende pour sélectionner les courbes à afficher et
          remettre à l{`'`}échelle.
        </Description>
        <LegendContainer className="legend_container">
          {renderLegend()}
        </LegendContainer>
      </Container>
      <Resizable>{renderChart()}</Resizable>
    </ChartKpiVolumeContainer>
  );
}

ChartKpiVolume.propTypes = {
  allkpi: PropTypes.arrayOf(PropTypes.string).isRequired,
  allname: PropTypes.arrayOf(PropTypes.string).isRequired,
  end: PropTypes.instanceOf(moment).isRequired,
  funnel: PropTypes.shape({
    anom: PropTypes.arrayOf(PropTypes.shape({ funnelId: PropTypes.string }))
  }).isRequired,
  graphId: PropTypes.number.isRequired,
  series: PropTypes.instanceOf(TimeSeries).isRequired,
  seriesBegin: PropTypes.number.isRequired,
  seriesZoom: PropTypes.number.isRequired,
  seriesanom: PropTypes.instanceOf(TimeSeries).isRequired,
  seriescpd: PropTypes.instanceOf(TimeSeries).isRequired,
  seriescpi: PropTypes.instanceOf(TimeSeries).isRequired,
  seriescpv: PropTypes.instanceOf(TimeSeries).isRequired,
  seriesdayoff: PropTypes.instanceOf(TimeSeries).isRequired,
  start: PropTypes.instanceOf(moment).isRequired
};

export default ChartKpiVolume;
