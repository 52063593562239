import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import _ from 'lodash';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import api from 'utils/api/ermes';
import Loading from 'components/Loading';
import AccountChoiceFormContent from './AccountChoiceFormContent';
import ConnectorModalList from './ConnectorModalList';
import UploadCSVFormContent from './UploadCSVFormContent';

import { ModalContent } from './style';

/**
 *
 * ConnectorModalFunnel
 * @component
 *
 *
 */
function ConnectorModalFunnel(props) {
  const {
    // childId, nécessaire pour futurs connecteurs
    connectors,
    funnelId,
    notify,
    onClose,
    setConnector,
    sources,
    handleCloseModal,
    modalIsOpen
  } = props;
  const [localConnector, setLocalConnector] = React.useState(null);
  const [connected, setConnected] = React.useState(connectors.map(() => false)); // TODO remove this if not usefull

  const [accounts, setAccounts] = React.useState([]);
  const { funnel, setFunnel, setIsLoading, isLoading } = useFunnel();

  const addConnector = (accessArguments, funnelToUpdate) => {
    const funnelUpdated = {
      ...funnelToUpdate,
      funnelData: {
        ...funnelToUpdate.funnelData,
        connectorStates: [
          ...(funnelToUpdate.funnelData.connectorStates || []),
          {
            connectorType: localConnector.name,
            connectorStep: funnelId,
            args: accessArguments
          }
        ]
      }
    };
    return funnelUpdated;
  };

  const markAsConnected = () => {
    setLocalConnector(null);
    const nextConnected = connected.map(
      (c, i) =>
        c || i === _.findIndex(connectors, co => co === localConnector.id)
    );
    setConnected(nextConnected);
  };

  const handleSso = () => {
    markAsConnected();
    notify({
      title: 'Connecteur ajouté',
      message: `Votre connecteur ${localConnector.name} a bien été ajouté à votre funnel.`,
      variants: 'success'
    });
  };

  const loadData = async (
    apiLoadFunction,
    data,
    funnelUpdatedFromConnectors
  ) => {
    try {
      let funnelUpdated = await apiLoadFunction(
        data,
        funnelUpdatedFromConnectors
      );
      // Rajoute le connecteur CSV avec son Id dans connectorStates
      if (
        funnelUpdated &&
        funnelUpdated.funnelData &&
        funnelUpdated.funnelData.csvId
      ) {
        funnelUpdated = addConnector(
          [funnelUpdated.funnelData.csvId],
          funnelUpdated
        );
        funnelUpdated.funnelData.csvId = undefined;
      }
      setFunnel(currentFunnel => ({
        ...currentFunnel,
        funnelData: funnelUpdated.funnelData
      }));

      ['kpi1', 'kpi2', 'kpi3', 'kpi4'].forEach((kpi, kpiIdx) => {
        if (
          funnelUpdated.funnelData.synthesis &&
          funnelUpdated.funnelData.synthesis[kpi] &&
          funnelUpdated.funnelData.synthesis[kpi].volume !== undefined
        ) {
          const {
            id: currFunnelId,
            childId: currChildId
          } = funnel.measure.funnels[kpiIdx];
          setConnector(currFunnelId, sources[5], {}, currChildId);
          if (currChildId) {
            if (Array.isArray(currChildId)) {
              for (let i = 0; i < currChildId.length; i += 1) {
                setConnector(currChildId[i], sources[5], {}, null);
              }
            } else {
              setConnector(currChildId, sources[5], {}, null);
            }
          }
        }
      });
      return funnelUpdated;
    } catch (err) {
      notify({
        title: 'Erreur',
        message: `Une erreur est survenue lors de la connexion de votre ${localConnector.name}.`,
        variants: 'error'
      });
      return funnel;
    }
  };

  const handleConnector = id => {
    const nextConnector = _.find(sources, s => s.id === id);
    setLocalConnector(nextConnector);
    /**
     * If the current connector has been defined as using Oauth
     * We can open a new window for authentication
     * And wait for the third party to callback on our OauthContainer
     * So that we can gather data from the third third party
     * */
    if (nextConnector.oauthUrl) {
      let childWindow = null;
      // Registering a global function
      // to get callback data from child window
      window.setCredentialsAfterOauth = data => {
        if (childWindow) childWindow.close();
        setAccounts(data.data);
      };
      api.fetchSession();
      childWindow = window.open(nextConnector.oauthUrl);
    }
  };

  const validateConnector = () => {
    setIsLoading(true);
    loadData(
      api.loadFacebookData,
      funnel.funnelData.connectorStates.filter(
        c => c.connectorType === 'Facebook Ads'
      ),
      funnel
    );
    setIsLoading(false);
    handleSso();
  };

  const selectCampaign = accessArguments => {
    setFunnel(addConnector(accessArguments, funnel));
  };

  const unselectCampaign = accessArguments => {
    setFunnel(currentFunnel => ({
      ...currentFunnel,
      funnelData: {
        ...currentFunnel.funnelData,
        connectorStates: currentFunnel.funnelData.connectorStates.filter(
          f => f.args[1] !== accessArguments[1]
        )
      }
    }));
  };

  const campaignIsChosen = (connectorName, campaignId) => {
    if (funnel.funnelData && funnel.funnelData.connectorStates) {
      return funnel.funnelData.connectorStates.filter(conn => {
        return (
          conn.connectorType === connectorName &&
          conn.args[conn.args.length - 1] === campaignId
        );
      }).length;
    }
    return 0;
  };

  const cancel = () => setLocalConnector(null);

  const handleCsv = async d => {
    setIsLoading(true);
    await loadData(api.uploadFunnelCSV, d.target.files, funnel);
    setIsLoading(false);
    handleSso();
  };

  const closeModal = () => {
    cancel();
    onClose();
    handleCloseModal();
  };
  return (
    <Modal
      aria-labelledby="Connecteur Funnel"
      aria-describedby="Ajouter un connecteur au funel"
      open={modalIsOpen}
      onClose={closeModal}
    >
      <ModalContent>
        {!localConnector ? (
          <ConnectorModalList
            connected={connected}
            connectors={connectors}
            sources={sources}
            closeModal={closeModal}
            handleConnector={handleConnector}
          />
        ) : (
          ''
        )}

        {localConnector && localConnector.sso ? (
          <AccountChoiceFormContent
            connectorName={localConnector.name}
            cancel={cancel}
            validate={validateConnector}
            elementTree={accounts.map(o => ({
              type: 'comptes',
              id: o.account.id,
              name: o.account.name,
              children: o.campaigns.map(c => ({
                type: 'campagne',
                id: c.id,
                name: c.name,
                checked: campaignIsChosen(localConnector.name, c.id)
              }))
            }))}
            setAccessArguments={selectCampaign}
            unsetAccessArguments={unselectCampaign}
          />
        ) : (
          ''
        )}

        {localConnector && localConnector.name === 'CSV' ? (
          <Loading loading={isLoading}>
            <UploadCSVFormContent
              cancel={cancel}
              closeModal={closeModal}
              handleCsv={handleCsv}
            />
          </Loading>
        ) : (
          ''
        )}
      </ModalContent>
    </Modal>
  );
}
ConnectorModalFunnel.defaultProps = {
  connectors: []
};

ConnectorModalFunnel.propTypes = {
  connectors: PropTypes.arrayOf(PropTypes.number),
  funnelId: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  notify: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setConnector: PropTypes.func.isRequired,
  sources: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default ConnectorModalFunnel;
