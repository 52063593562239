import React from 'react';

import moment from 'moment';
import AnomalyRowFunnel from 'Funnel/components/AnomalyRowFunnel';
import BaselineFunnel from 'Funnel/components/BaselineFunnel';
import ForecastChartFunnel from 'Funnel/components/ForecastChartFunnel';
import ObjectifRowFunnel from 'Funnel/components/ObjectifRowFunnel';
import DistributionMediaContainer from 'Funnel/containers/DistributionMediaContainer';
import AnalysesTemporelleContainer from 'Funnel/containers/AnalyseTemporelleContainer';
import AnomalyTableFunnel from 'Funnel/components/AnomalyTableFunnel';
import AnalysesAudienceContainer from 'Funnel/containers/AnalyseAudience/index';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useFunnelGraph } from 'Funnel/contexts/FunnelGraphContext';
import {
  Container,
  LinkButtonContainer,
  LinkButton,
  ButtonContent,
  Table,
  Titles,
  TableContent,
  ErrorMessage
} from './style';

/**
 *
 * InsightDetailsContainer
 * @component
 *
 */
function InsightDetailsContainer() {
  const {
    graphSubTab,
    setSubTab,
    worstlevierCPI,
    setWorsLevierCPI
  } = useFunnelGraph();
  const array = [1.4, 50.9, 74.5];
  const [savet3, setSavet3] = React.useState(0);
  const { funnel, setFunnel } = useFunnel();
  if (
    graphSubTab === 'tabAnalyse' &&
    (!funnel.start ||
      !funnel.end ||
      !moment(funnel.start).isValid() ||
      !moment(funnel.end).isValid() ||
      moment(funnel.start).isAfter(moment(funnel.end)))
  ) {
    return (
      <div className="kpi_table_description align_center">
        <ErrorMessage>
          {
            'Vos données ne nous permettent pas d’afficher l’analyse de votre funnel.'
          }
        </ErrorMessage>
      </div>
    );
  }
  const { connected, objectifed, measure, forecast } = funnel;
  const setAnom = anom => {
    const indexAnom = funnel.anom.findIndex(
      ({ funnelId, date }) => anom.date === date && anom.funnelId === funnelId
    );
    const newAnom = [...funnel.anom];
    if (indexAnom === -1) newAnom.push(anom);
    else newAnom.splice(indexAnom, 1, anom);
    setFunnel({ ...funnel, anom: newAnom });
  };

  const allkpi = measure.funnels.map(({ id }) => id);

  const newt3 = (item, name) => {
    setSavet3(item);
    setWorsLevierCPI(name);
  };

  const filter = data => {
    return moment(data.date).isBetween(
      moment(funnel.start).startOf('day'),
      moment(funnel.end).startOf('day'),
      null,
      '[]'
    );
  };

  function checkBaseline() {
    if (
      !funnel ||
      !funnel.funnelData ||
      !funnel.funnelData.synthesis ||
      !funnel.funnelData.synthesis.kpi4HB ||
      !funnel.funnelData.synthesis.kpi4HB.volume
    ) {
      return (
        <td className="kpi_table_description">
          <ErrorMessage>
            {
              "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
            }
          </ErrorMessage>
        </td>
      );
    }

    return (
      <div className="pb-data">
        <Container>
          <img
            src="/assets/img/info.svg"
            alt="? icon"
            className="question_mark"
          />
          <div className="kpi_table_description align_center">
            {`La Baseline correspond au traffic que vous auriez obtenu sans aucun investissement média,
             elle est déterminée en fonction de l'historique de vos campagnes.`}
          </div>
        </Container>
        <div className="table_blue_container">
          {!allkpi[4] ? (
            <td className="kpi_table_description"> {'missing data'}</td>
          ) : (
            <BaselineFunnel
              id={allkpi[4]}
              connected={connected}
              filter={filter}
              kpi={allkpi[3]}
              funnel={funnel}
            />
          )}
        </div>
      </div>
    );
  }

  const checkAnomalie = () => {
    if (
      !funnel ||
      !funnel.funnelData ||
      !funnel.funnelData.anomalies ||
      !funnel.funnelData.anomalies.length
    ) {
      return (
        <ErrorMessage className="kpi_table_description error">
          {
            "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
          }
        </ErrorMessage>
      );
    }
    return (
      <div>
        <Container>
          <img
            src="/assets/img/info.svg"
            alt="? icon"
            className="question_mark"
          />
          <div className="kpi_table_description align_center">
            {`Le tableau d’anomalies fait ressortir rapidement et simplement les dates où certains KPIs
             ont des valeurs significativement éloignées, positivement ou négativement, de la tendance des 5 jours précédents.`}
          </div>
        </Container>
        <AnomalyTableFunnel className="table table_blue">
          <tr>
            <th className="uppercase">Date</th>
            <th>KPI</th>
            <th>Valeur KPI</th>
            <th> Moyenne 5 jours précedents</th>
            <th> Ecart KPI/5jours précedents</th>
            <th className="uppercase">Piste explication anomalies</th>
          </tr>
          <AnomalyRowFunnel
            connected={connected}
            allkpi={allkpi}
            funnel={funnel}
            setAnom={setAnom}
          />
        </AnomalyTableFunnel>
      </div>
    );
  };

  const checkPrevision = () => {
    if (
      !funnel ||
      !funnel.funnelData ||
      !funnel.funnelData.forecast ||
      !funnel.funnelData.forecast.fkpi4 ||
      !funnel.funnelData.forecast.fkpi4.length ||
      !funnel.funnelData.forecast.fakpi4 ||
      !funnel.funnelData.forecast.fakpi4.length
    ) {
      return (
        <td className="kpi_table_description">
          <ErrorMessage>
            {
              "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
            }
          </ErrorMessage>
        </td>
      );
    }
    return (
      <div className="pb-data">
        <Container>
          <img
            src="/assets/img/info.svg"
            alt="? icon"
            className="question_mark"
          />
          <div className="kpi_table_description align_center">
            {`Vos performances futures sont déterminées suite à l’analyse de vos campagnes historiques et 
            prennent en compte un budget identique à celui dépensé sur la période actuellement analysée.`}
          </div>
        </Container>
        <div className="graph_container">
          {!allkpi[3] || !allkpi[4] ? (
            <td className="kpi_table_description"> {'missing data'}</td>
          ) : (
            <ForecastChartFunnel
              funnel={funnel}
              forecast={forecast}
              kpi={allkpi[3]}
              kpiForecast={allkpi[4]}
              connected={connected}
            />
          )}
        </div>
      </div>
    );
  };

  // ------------------ render:
  return (
    <React.Fragment>
      {graphSubTab === 'default' ? (
        <div>
          <LinkButtonContainer>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon analyse"
                  src="/assets/img/icons/icon_analyse.svg"
                  className="icon"
                />
                <div className="description">
                  Analyse du Funnel et impact sur objectif
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('tabAnalyse');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon baseline"
                  src="/assets/img/icons/icon_baseline.svg"
                  className="icon"
                />
                <div className="description">
                  Impact de la campagne sur la Baseline
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('tabBaseline');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon anomalies"
                  src="/assets/img/icons/icon_anomalies.svg"
                  className="icon"
                />
                <div className="description">
                  Anomalies détéctées pendant la campagne
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('tabAnomalies');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon prevision"
                  src="/assets/img/icons/icon_prevision.svg"
                  className="icon"
                />
                <div className="description">
                  Prévisions des performances futures
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('graphPrevision');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
            <LinkButton>
              <ButtonContent>
                <img
                  alt="icon distribution media"
                  src="/assets/img/icons/icon_distribution_media.svg"
                  className="icon"
                />
                <div className="description">
                  Distribution des budgets et des impressions
                </div>
                <button
                  type="button"
                  className="acceder"
                  onClick={() => {
                    setSubTab('tabDistribMedia');
                  }}
                >
                  Accéder
                </button>
              </ButtonContent>
            </LinkButton>
          </LinkButtonContainer>
        </div>
      ) : null}
      {graphSubTab === 'tabAnalyse' && connected.length >= 4 ? (
        <div>
          {objectifed.length > 0 ? (
            <div className="kpi_table_container">
              <div className="pb-data">
                <Container>
                  <img
                    src="/assets/img/info.svg"
                    alt="? icon"
                    className="question_mark"
                  />
                  <div className="kpi_table_description align_center">
                    {`Retrouvez ici l’impact de vos différents KPI sur votre objectif final et identifiez 
                    immédiatement le niveau du funnel sur lequel vous devez agir pour améliorer vos performances.`}
                  </div>
                </Container>
                <div className="kpi_table_result align_center">
                  {`Votre principal levier d'amélioration du ${
                    allkpi[3]
                  } est le ${worstlevierCPI}.`}
                </div>
                <Table>
                  <Titles>
                    <tr>
                      <th className="uppercase">KPI</th>
                      <th>Objectif</th>
                      <th>Réel</th>
                      <th className="uppercase">Impact {allkpi[3]}</th>
                    </tr>
                  </Titles>
                  <TableContent>
                    {measure
                      ? measure.funnels
                          .filter(
                            f =>
                              Object.values(objectifed).findIndex(
                                oneObjectifed => oneObjectifed.funnelId === f.id
                              ) >= 0
                          )
                          .map((f, i) => (
                            <ObjectifRowFunnel
                              key={f.name}
                              {...f}
                              funnelIndex={i}
                              connected={connected}
                              objectifed={objectifed}
                              filter={filter}
                              funnel={funnel}
                              array={array}
                              savet3={savet3}
                              newt3={newt3}
                              worstlevierCPI={worstlevierCPI}
                            />
                          ))
                      : null}
                  </TableContent>
                </Table>
              </div>
            </div>
          ) : (
            <div className="kpi_table_description align_center">
              <ErrorMessage>
                veuillez rentrer les objectifs afin d&#39;avoir les analyses
              </ErrorMessage>
            </div>
          )}
        </div>
      ) : null}

      {graphSubTab === 'tabBaseline' && connected.length >= 4 ? (
        <div>{checkBaseline()}</div>
      ) : null}

      {graphSubTab === 'tabAnomalies' && connected.length >= 4 ? (
        <div className="graph-section scroll">{checkAnomalie()}</div>
      ) : null}

      {graphSubTab === 'graphPrevision' && connected.length >= 4 ? (
        <div>{checkPrevision()}</div>
      ) : null}
      {graphSubTab === 'tabDistribMedia' && connected.length >= 4 ? (
        <DistributionMediaContainer />
      ) : null}
      {graphSubTab === 'tabAnalysesTemp' && connected.length >= 4 ? (
        <AnalysesTemporelleContainer />
      ) : null}
      {graphSubTab === 'tabAnalysesAudience' && connected.length >= 4 ? (
        <AnalysesAudienceContainer />
      ) : null}
    </React.Fragment>
  );
}

InsightDetailsContainer.defaultProps = {};

InsightDetailsContainer.propTypes = {};

export default InsightDetailsContainer;
