import axios from 'axios';
import { Auth } from 'aws-amplify';
import postProcessing from './postProcessing';
import preProcessing from './preProcessing';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

api.interceptors.request.use(
  async config => {
    const currentUser = await Auth.currentSession();
    const newConfig = {
      ...config,
      headers: {
        ...config.headers,
        authorization: currentUser.idToken.jwtToken
      }
    };
    return newConfig;
  },
  error => Promise.reject(error)
);

const config = {
  getCampaigns() {
    return api.get('/auth/FacebookAds/check-status');
  },
  fetchSession() {
    return api.get('/auth/fetch-user');
  },
  loadFacebookData(facebookConnectorStates, funnel) {
    return preProcessing(funnel)
      .then(data => {
        return api.post('/funnel/load-facebook', {
          campaigns: facebookConnectorStates.map(c => c.args[1]),
          funnel: data
        });
      })
      .then(postProcessing)
      .then(newFunnel => ({
        ...newFunnel,
        funnelData: {
          ...newFunnel.funnelData,
          connectorStates: funnel.funnelData.connectorStates
        }
      }));
  },
  uploadFunnelCSV(csvData, funnel) {
    const formData = new FormData();
    formData.append('csv', csvData[0]);
    formData.append('funnel', JSON.stringify(funnel));
    return api
      .post('/funnel/upload-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(postProcessing);
  },
  updateFunnelAnalysis(funnel) {
    return preProcessing(funnel)
      .then(data => {
        /*
         * This is needed because JSON.stringify wrecks moment object, causing
         * bugs in front-end. Date value is put in String format and reconverted
         * in postProcessing.
         * See src/utils/api/ermes/postProcessing.js for more information
         */
        // eslint-disable-next-line no-restricted-syntax
        for (const value of data.funnelData.timeseries.anom) {
          value[0] = value[0].toString();
        }
        const formData = new FormData();
        formData.append('funnel', JSON.stringify(data));
        return api.post('/funnel/update-funnel-analysis', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      })
      .then(postProcessing);
  },
  updateFunnel(funnel) {
    return preProcessing(funnel)
      .then(data => {
        const formData = new FormData();
        formData.append('funnel', JSON.stringify(data));
        return api.post('/funnel/update-funnel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      })
      .then(postProcessing)
      .then(newFunnel => ({
        ...newFunnel,
        funnelData: {
          ...newFunnel.funnelData,
          connectorStates: funnel.funnelData.connectorStates
        }
      }));
  }
};

export default config;
