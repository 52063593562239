import styled from 'styled-components';
import Checkbox from 'components/Checkbox';
import { withTheme } from 'contexts/ThemeContext';
import { withInsight } from 'Insight/contexts/InsightContext';
import { dataOrInsight } from 'utils/functions/insight';

/**
 * BaseCriteria
 * @component
 *
 */
const BaseCriteriaInsight = withInsight(
  withTheme(styled.div`
    display: grid;
    grid-template-columns: ${({ hasInsee }) =>
      hasInsee ? '4fr 2fr 2fr' : '3fr 2fr'};
    margin: 0 4px 4px 0;
    .label {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .checkbox-title {
        font-size: 14px;
        font-weight: 500;
      }
      margin-right: 6px;
    }
    .item-value {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      a,
      a:active {
        text-decoration: none;
        line-height: 1px;
      }
    }
    .description-icon {
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
    .graph {
      width: 100%;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ themeColors }) => themeColors.light};
      }
    }
    .graph.left {
      justify-content: flex-end;
      flex-direction: ${({ hasInsee }) => (hasInsee ? 'row' : 'row-reverse')};
      span {
        font-weight: 600;
        color: ${({ themeColors, isData }) =>
          dataOrInsight(isData, themeColors, 'data')};
      }
    }
    .gauge {
      height: 16px;
    }
    .gauge.ermes {
      background-color: ${({ themeColors, isData }) =>
        dataOrInsight(isData, themeColors, 'data')};
      width: ${({ ermesPercent }) => ermesPercent}%;
      border-radius: ${({ hasInsee }) =>
        hasInsee ? '8px 0 0 8px' : '0 8px 8px 0'};
      margin-left: 8px;
      margin-right: 5px;
    }
    .gauge.insee {
      background-color: #e0e0e0;
      width: ${({ inseePercent }) => inseePercent}%;
      border-radius: 0 8px 8px 0;
      margin-right: 8px;
      margin-left: 5px;
    }
    @media (min-width: 1024px) {
      grid-template-columns: ${({ hasInsee }) =>
        hasInsee ? '3fr 3fr 3fr' : '2fr 3fr'};
      .gauge {
        height: 20px;
      }
    }
    @media (min-width: 1440px) {
      grid-template-columns: ${({ hasInsee }) =>
        hasInsee ? '270px 3fr 3fr' : '3fr 3fr'};
      .gauge {
        height: 20px;
      }
    }

    @media (min-width: 1800px) {
      grid-template-columns: ${({ hasInsee }) =>
        hasInsee ? '2fr 3fr 3fr' : '2fr 3fr'};
      margin: 0 8px 8px 0;
      .gauge {
        height: 24px;
      }
      .label {
        .checkbox-title {
          font-size: 18px;
        }
      }
    }
  `)
);

export const CriteriaCheckbox = styled(Checkbox)``;

export default BaseCriteriaInsight;
