import React from 'react';
import PropTypes from 'prop-types';

import Block from './Block';

/**
 * BuySegments
 * @component
 */
function BlockBuySegments({ onClick, disabled }) {
  return (
    <Block
      icon="buySegments"
      onClick={onClick}
      disabled={disabled}
      title="Acheter des segments de données"
      text="Accédez à nos 25 millions de profils segmentés selon 350 critères
      établis et intentionnistes, et jouer avec notre taxonomie dynamique
      permettant de définir votre coeur de cible. Acheter ces profils
      digitalisés et intégrez-les dans votre DSP/DMP afin de maximiser les
      résultats de vos campagnes on-line."
    />
  );
}
BlockBuySegments.defaultProps = {
  disabled: false,
  onClick: () => {}
};
BlockBuySegments.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default BlockBuySegments;
