import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { StepLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

function Icon(props) {
  const { active } = props;
  return (
    <span
      style={{
        height: '25px',
        width: '25px',
        border: active ? '' : '3px solid #C8C5C5',
        backgroundColor: active ? '#009FE3' : 'white',
        borderRadius: '50%',
        display: 'inline-block'
      }}
    />
  );
}

Icon.propTypes = {
  active: PropTypes.bool.isRequired
};

function getSteps() {
  return [
    'Audience générale et analyse de conversion',
    "Insight par niveau d'engagement",
    'Segment Nectar Best Performer'
  ];
}

function AudienceSteper({ onChangeStep }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleStep = step => () => {
    setActiveStep(step);
    onChangeStep(step);
  };

  return (
    <div>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepButton onClick={handleStep(index)} {...buttonProps}>
                <StepLabel
                  StepIconProps={{
                    icon: <Icon active={activeStep === index} />
                  }}
                >
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

AudienceSteper.propTypes = {
  onChangeStep: PropTypes.func.isRequired
};

export default AudienceSteper;
