import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { useStep } from 'Activation/contexts/StepContext';
import { useSegment } from 'Activation/contexts/SegmentContext';

import ModalSaveActivation from 'Activation/components/ModalSaveActivation';
import ButtonActivation from 'Activation/components/ButtonActivation';
import LabelActivation from 'Activation/components/LabelActivation';
import WrapperValidation from 'Activation/components/WrapperValidation';
import SavingActivation from 'Activation/components/SavingActivation';
import { ACTIVATION } from 'src/utils/graphQL';
import { useUser } from 'src/contexts/UserContext';
import { useNotif } from 'src/contexts/NotifContext';
import ModalLaunchCampaign from 'Activation/components/LaunchCampaignFormModal/ModalLaunchCampaign';
import downloadPdfActivation from 'Activation/containers/ValidateContainer/functions';

const lodash = require('lodash');

/**
 * getNotifyParams
 * @param {String} name
 * @return {title, message, variants}
 *
 */
const getNotifyParams = {
  success: name => ({
    title: 'Campagne enregistrée',
    message: `Votre campagne ${name} a bien été enregistrée,\nsuite à votre demande.`,
    variants: 'success'
  }),
  error: name => ({
    title: 'Erreur lors de l’enregistrement',
    message: `Nous n’avons pas pu enregistrer votre campagne ${name}”.\n Merci de bien vouloir réessayer.`,
    variants: 'error'
  })
};

/**
 * ValidateContainer
 * @component
 *
 */
function ValidateContainer(props) {
  const { notify } = useNotif();
  const { secondPage, thirdPage } = props;
  const {
    goNext,
    goPrev,
    goFirst,
    setTitle,
    canValidate,
    setValidDate,
    setValidBudget,
    setValidObjectif,
    setValidProfiles
  } = useStep();
  const { user } = useUser();
  const { results, activation, setActivation, objective } = useSegment();
  const hasSetDates = activation.endDate.diff(activation.startDate, 'day') >= 1;
  const [savingName, setSavingName] = React.useState(activation.name);
  const [openSaveModal, setSaveModal] = React.useState(false);
  const [openLaunchModal, setLaunchModal] = React.useState(false);
  const [createActivation] = useMutation(ACTIVATION.CREATE_ACTIVATION);
  const [updateActivation] = useMutation(ACTIVATION.UPDATE_ACTIVATION);
  const currentSaveFunc = activation.id ? updateActivation : createActivation;
  const variables = {
    userId: user.username,
    name: savingName,
    startDate: activation.startDate,
    endDate: activation.endDate,
    budget: activation.budget,
    objective: activation.objective,
    campaignRatios: activation.campaignRatios,
    nexusSize: activation.nexusSize,
    profiles: activation.profiles,
    transformationRates: activation.transformationRates,
    filters: activation.filters
  };
  const [sendEmail] = useMutation(ACTIVATION.SEND_EMAIL);
  const send = (data, saveId, type) => {
    sendEmail({
      variables: {
        input: {
          type,
          phone: data.phone.trim(),
          description: data.description,
          data: {
            objectif: data.objectif,
            activationId: saveId,
            dates: data.dates,
            budget: data.budget,
            nbProfilsAppnexus: data.nbProfilsAppnexus,
            videoPercentage: data.videoPercentage,
            videoPremiumPercentage: data.videoPremiumPercentage,
            bannerImpactPercentage: data.bannerImpactPercentage,
            bannerPercentage: data.bannerPercentage,
            objectiveOneName: data.objectiveOneName,
            objectiveOnePercentage: data.objectiveOnePercentage,
            objectiveTwoName: data.objectiveTwoName,
            objectiveTwoPercentage: data.objectiveTwoPercentage,
            objectiveThreeName: data.objectiveThreeName,
            objectiveThreePercentage: data.objectiveThreePercentage
          }
        }
      },
      skip: !data.phone
    });
  };
  const convertDateToEmailData = (startDate, endDate) => {
    const start = moment(startDate).format('DD/MM/YYYY');
    const end = moment(endDate).format('DD/MM/YYYY');
    const weeks = endDate.week() - startDate.week();
    return `${start} - ${end} soit ${weeks} semaines.`;
  };
  const getEmailData = () => {
    return {
      objectif: activation.objective,
      activationId: JSON.stringify(activation.id),
      dates: convertDateToEmailData(activation.startDate, activation.endDate),
      budget: JSON.stringify(activation.budget),
      nbProfilsAppnexus: JSON.stringify(activation.nexusSize),
      videoPercentage: JSON.stringify(
        (activation.campaignRatios.video * 100).toFixed(2)
      ),
      videoPremiumPercentage: JSON.stringify(
        (activation.campaignRatios.video_premium * 100).toFixed(2)
      ),
      bannerImpactPercentage: JSON.stringify(
        (activation.campaignRatios.banniere_impact * 100).toFixed(2)
      ),
      bannerPercentage: JSON.stringify(
        (activation.campaignRatios.banniere * 100).toFixed(2)
      ),
      objectiveOneName: JSON.stringify(activation.funnelRates[0].shortLabel),
      objectiveOnePercentage: JSON.stringify(
        (activation.funnelRates[0].default * 100).toFixed(2)
      ),
      objectiveTwoName: JSON.stringify(activation.funnelRates[1].shortLabel),
      objectiveTwoPercentage: JSON.stringify(
        (activation.funnelRates[1].default * 100).toFixed(2)
      ),
      objectiveThreeName: JSON.stringify(activation.funnelRates[2].shortLabel),
      objectiveThreePercentage: JSON.stringify(
        (activation.funnelRates[2].default * 100).toFixed(2)
      )
    };
  };
  const closeSaveModal = () => {
    setSaveModal(false);
  };

  const closeLaunchModal = () => {
    setLaunchModal(false);
  };
  const handlePersist = () => {
    variables.name = savingName;
    setTitle(savingName);
    currentSaveFunc({
      variables: activation.id ? { id: activation.id, ...variables } : variables
    })
      .then(({ data }) => {
        const responseId = data.createActivation && data.createActivation.id;
        if (!activation.id) {
          setActivation({ ...activation, id: responseId, name: savingName });
        } else {
          setActivation({ ...activation, name: savingName });
        }
        notify(getNotifyParams.success(savingName));
        closeSaveModal();
      })
      .catch(() => {
        getNotifyParams.error(savingName);
        closeSaveModal();
      });
  };
  const handleLauchActivationCampaign = async data => {
    handlePersist();
    const saveId = activation.id;
    send(data, saveId, 'DATA_LAUNCH_ACTIVATION_CAMPAIGN');
    closeLaunchModal();
  };
  const handleValidationBref = () => {
    setValidDate(false);
    setValidObjectif(false);
    setValidBudget(null);
    setValidProfiles(false);
    if (
      activation.budget > 0 &&
      activation.objective !== null &&
      hasSetDates &&
      activation.profiles > 0
    ) {
      goNext();
    } else {
      if (!hasSetDates) {
        setValidDate(true);
      }
      if (activation.budget === '') {
        setValidBudget('Vous devez saisir un budget pour votre campagne');
      }
      if (lodash.isNaN(activation.budget)) {
        setValidBudget('Votre budget doit être exprimé en chiffres');
      }
      if (!lodash.isNaN(activation.budget) && activation.budget < 1) {
        setValidBudget('Votre budget doit être supérieur à 0€');
      }

      if (!activation.objective) {
        setValidObjectif(true);
      }
      if (!activation.profiles) {
        setValidProfiles(true);
      }
    }
  };
  const handleDownload = async () => {
    try {
      await downloadPdfActivation(
        results,
        notify,
        user,
        objective,
        activation.name
      );
    } catch (e) {
      console.error(e);
    }
  };

  if (thirdPage) {
    return (
      <WrapperValidation>
        <div className="row-validation">
          <LabelActivation>
            <span>
              Si vous validez ce brief de campagne, celle-ci ne démarre pas
              immédiatement, l’équipe Ermes prendra contact avec vous dans
              l’heure pour finaliser avec vous le plan et lancer la campagne.
            </span>
          </LabelActivation>
        </div>
        <div className="row-validation">
          <ButtonActivation onClick={goPrev} inversed>
            REVENIR EN ARRIÈRE
          </ButtonActivation>
          <ModalSaveActivation
            isOpen={openSaveModal}
            handleClose={closeSaveModal}
            handleValidation={handlePersist}
            validationLabel="Sauvegarder"
            label="Sauvegarder la campagne"
            isInputEmpty={savingName && savingName.length > 0}
          >
            <SavingActivation
              onNameChange={setSavingName}
              targetName={savingName}
            />
          </ModalSaveActivation>
          <ButtonActivation
            onClick={() => {
              if (savingName !== activation.name) {
                setSavingName(activation.name);
              }
              setSaveModal(true);
            }}
            inverse
          >
            SAUVEGARDER
          </ButtonActivation>
          <ModalLaunchCampaign
            open={openLaunchModal}
            onClose={closeLaunchModal}
            onNext={handleLauchActivationCampaign}
            disableBackdropClick
            data={getEmailData()}
          />
          <ButtonActivation onClick={handleDownload} inversed>
            EXPORTER LE PDF
          </ButtonActivation>
          <ButtonActivation onClick={() => setLaunchModal(true)}>
            LANCER LA CAMPAGNE
          </ButtonActivation>
        </div>
      </WrapperValidation>
    );
  }

  if (secondPage || activation.id) {
    return (
      <WrapperValidation>
        <div className="row-validation">
          <LabelActivation>
            La data ERMES permet un meilleur ciblage et influe directement sur
            vos performances de campagne.
          </LabelActivation>
        </div>
        <div className="row-validation">
          <ButtonActivation onClick={goFirst} inversed>
            ANNULER
          </ButtonActivation>
          <ModalSaveActivation
            isOpen={openSaveModal}
            handleClose={closeSaveModal}
            handleValidation={handlePersist}
            validationLabel="Sauvegarder"
            label="Sauvegarder la campagne"
            isInputEmpty={savingName && savingName.length > 0}
          >
            <SavingActivation
              onNameChange={setSavingName}
              targetName={savingName}
            />
          </ModalSaveActivation>
          <ButtonActivation
            onClick={() => {
              if (savingName !== activation.name) {
                setSavingName(activation.name);
              }
              setSaveModal(true);
            }}
            inverse
          >
            SAUVEGARDER
          </ButtonActivation>
          <ButtonActivation onClick={goNext} disabled={!canValidate}>
            VALIDER LE PLAN
          </ButtonActivation>
        </div>
      </WrapperValidation>
    );
  }
  if (!activation.id) {
    return (
      <WrapperValidation>
        <div className="row-validation">
          <ButtonActivation onClick={() => handleValidationBref()}>
            VALIDER LE BRIEF
          </ButtonActivation>
        </div>
      </WrapperValidation>
    );
  }
}
ValidateContainer.defaultProps = {
  secondPage: false,
  thirdPage: false
};
ValidateContainer.propTypes = {
  secondPage: PropTypes.bool,
  thirdPage: PropTypes.bool
};

export default ValidateContainer;
