import styled from 'styled-components';
import Dropdown from 'components/Dropdown';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseDropdownActivation
 * @component
 *
 */
const BaseDropdownActivation = styled(Dropdown)`
  float: initial;
  .dropSelect {
    background-color: ${({ themeColors }) => themeColors.primary};
    border: 1px solid
      ${({ themeColors, errorInput }) => {
        if (errorInput) return '#eb5757';
        return themeColors.activation;
      }}};
    :after {
      border-bottom-color: ${({ themeColors }) => themeColors.activation};
    }
  }
`;

export default withTheme(BaseDropdownActivation);
