import React from 'react';
import { useTheme } from 'contexts/ThemeContext';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import constants from 'utils/constants';

const { fontFamilies } = constants;

const OriginBaseCheckbox = styled(Checkbox)`
  && {
    color: ${({ themeColors }) => themeColors.light};
    &&:checked {
      color: ${({ themeColors }) => themeColors.light};
    }
    margin: 0px 4px 0 8px;
    font-family: ${fontFamilies.default};
    padding: '4px 4px';
  }
`;
/**
 * BaseCheckbox
 * @component
 *
 */
const BaseCheckbox = props => {
  const { themeColors } = useTheme();
  return (
    <OriginBaseCheckbox color="default" {...props} themeColors={themeColors} />
  );
};

export default BaseCheckbox;
