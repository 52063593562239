import React from 'react';
import PropTypes from 'prop-types';
import { ClickableRedirect } from './style';

function clickableText(props) {
  const { text, href } = props;
  return (
    <ClickableRedirect href={href} style={{ color: '#009FE3' }}>
      {text}
    </ClickableRedirect>
  );
}

clickableText.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};

export default clickableText;
