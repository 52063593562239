import React from 'react';
import PropTypes from 'prop-types';

import BaseCriteriasForm from './style';

/**
 * CriteriaInsight
 * @component
 * @see CriteriaContainer
 *
 */
function CriteriasFormInsight(props) {
  const { hasInsee } = props;
  const displayRightBlock = hasInsee;
  return <BaseCriteriasForm displayRightBlock={displayRightBlock} {...props} />;
}

CriteriasFormInsight.defaultProps = {};
CriteriasFormInsight.propTypes = {
  hasInsee: PropTypes.bool.isRequired
};

export const InsightLegend = ({ hasInsee, hasData }) => (
  <>
    {hasInsee && (
      <div className="legend">
        <div />
        {hasData && <div className="ermes">Données ermes</div>}
        {hasInsee && <div className="insee">Données INSEE</div>}
      </div>
    )}
  </>
);
InsightLegend.propTypes = {
  hasInsee: PropTypes.bool.isRequired,
  hasData: PropTypes.bool.isRequired
};
export { InsightTitle, InsightDesc, Header } from './style';
export default CriteriasFormInsight;
