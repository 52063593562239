import React from 'react';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import moment from 'utils/moment';
import {
  TableDistrib,
  TableBodyDistrib,
  TableCellDistrib,
  TableHeadDistrib,
  TableRowDistrib,
  TableAgregated,
  TableCellAgregated,
  ButtonTab,
  ButtonSwitch,
  ErrorMessage,
  DateMessage
} from './style';
import Distrib, { Agregated, Stacked } from './Distrib';

/**
 * @component
 *
 */

const cpmPosition = data => {
  const cpmList = [...data];
  const { length } = data;
  const temp = cpmList
    .sort((a, b) => {
      if (parseFloat(a.CPM) - parseFloat(b.CPM))
        return parseFloat(a.CPM) - parseFloat(b.CPM);
      return a.name < b.name ? -1 : 1;
    })
    .map(o => o.name);
  const indiceStack = data.map((kpi, idx) => ({
    indice: !length ? 0 : (length - temp.indexOf(kpi.name)) / length,
    position: idx
  }));
  return indiceStack;
};

function DistributionMediaContainer() {
  const { funnel } = useFunnel();
  const [activeButton, setActiveButton] = React.useState('agregated');

  const media = funnel && funnel.funnelData && funnel.funnelData.mediaDistrib;
  const synthesis = funnel && funnel.funnelData && funnel.funnelData.synthesis;

  const sumBudget = media.reduce((acc, m) => acc + m.budget, 0);
  const sumImpression = media.reduce((acc, m) => acc + m.impression, 0);

  const max = {
    name: 'TOTAL',
    budget: sumBudget,
    impression: sumImpression,
    CPM: synthesis.kpi1 && synthesis.kpi1.real
  };
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    media.sort((a, b) => {
      if (parseFloat(a.CPM) - parseFloat(b.CPM))
        return parseFloat(a.CPM) - parseFloat(b.CPM);
      return a.name < b.name ? -1 : 1;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = React.useState(media);
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [croissantCPM, setCroissantCPM] = React.useState(false);
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [croissantBudget, setCroissantBudget] = React.useState(false);
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [croissantImpression, setCroissantImpression] = React.useState(false);
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [activeImpression, setActiveImpression] = React.useState(false);
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [activeCPM, setActiveCPM] = React.useState(false);
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [activeBudget, setActiveBudget] = React.useState(false);

  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [cpmPos, setCpmPos] = React.useState(cpmPosition(data));
  // @TODO FIX THIS IT IS DANGEROUS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    setCpmPos(cpmPosition(data));
    setData(media);
  }, [data, media]);
  const [bestCPM, worstCPM] = (() => {
    const cpms = media.map(oneMedia => oneMedia.CPM);
    cpms.sort((a, b) => a - b);
    return [cpms[0], cpms[cpms.length - 1]];
  })();

  if (
    !funnel.start ||
    !funnel.end ||
    !moment(funnel.start).isValid() ||
    !moment(funnel.end).isValid() ||
    moment(funnel.start).isAfter(moment(funnel.end))
  ) {
    return (
      <div className="kpi_table_description align_center">
        <ErrorMessage>
          {
            'Vos données ne nous permettent pas d’afficher votre plan média réel.'
          }
        </ErrorMessage>
      </div>
    );
  }

  const activePanel = () => {
    return (
      <React.Fragment>
        {activeButton === 'agregated' ? (
          <React.Fragment>
            <Stacked data={media} sumB={max.budget} sumI={max.impression} />
            <TableAgregated>
              <TableHeadDistrib>
                <TableRowDistrib>
                  <TableCellAgregated>Média</TableCellAgregated>
                  <TableCellAgregated
                    fontWeight={activeBudget ? 'true' : undefined}
                    onClick={() => {
                      setActiveImpression(false);
                      setActiveBudget(true);
                      setActiveCPM(false);
                      if (croissantBudget) {
                        setData(data.sort((a, b) => a.budget - b.budget));
                        setCroissantBudget(false);
                        setCpmPos(cpmPosition(data));
                      } else {
                        setData(data.sort((a, b) => b.budget - a.budget));
                        setCroissantBudget(true);
                        setCpmPos(cpmPosition(data));
                      }
                    }}
                  >
                    Budget (k€)
                    <img
                      src={
                        activeBudget && croissantBudget
                          ? '/assets/img/dropup.svg'
                          : '/assets/img/dropdown.svg'
                      }
                      alt="drop-down-arrow"
                    />
                  </TableCellAgregated>
                  <TableCellAgregated
                    fontWeight={activeImpression ? 'true' : undefined}
                    onClick={() => {
                      setActiveImpression(true);
                      setActiveBudget(false);
                      setActiveCPM(false);
                      if (croissantImpression) {
                        setData(
                          data.sort((a, b) => a.impression - b.impression)
                        );
                        setCroissantImpression(false);
                        setCpmPos(cpmPosition(data));
                      } else {
                        setData(
                          data.sort((a, b) => b.impression - a.impression)
                        );
                        setCroissantImpression(true);
                        setCpmPos(cpmPosition(data));
                      }
                    }}
                  >
                    Impression (M)
                    <img
                      src={
                        activeImpression && croissantImpression
                          ? '/assets/img/dropup.svg'
                          : '/assets/img/dropdown.svg'
                      }
                      alt="drop-down-arrow"
                    />
                  </TableCellAgregated>
                  <TableCellAgregated
                    style={{ padding: 0 }}
                    fontWeight={activeCPM ? 'true' : undefined}
                    onClick={() => {
                      setActiveImpression(false);
                      setActiveBudget(false);
                      setActiveCPM(true);
                      if (croissantCPM) {
                        setData(data.sort((a, b) => a.CPM - b.CPM));
                        setCroissantCPM(false);
                        setCpmPos(cpmPosition(data));
                      } else {
                        setData(data.sort((a, b) => b.CPM - a.CPM));
                        setCroissantCPM(true);
                        setCpmPos(cpmPosition(data));
                      }
                    }}
                  >
                    CPM
                    <img
                      src={
                        activeCPM && croissantCPM
                          ? '/assets/img/dropup.svg'
                          : '/assets/img/dropdown.svg'
                      }
                      alt="drop-down-arrow"
                    />
                  </TableCellAgregated>
                </TableRowDistrib>
              </TableHeadDistrib>
              <TableBodyDistrib>
                {data.map((e, index) => (
                  <Agregated
                    tmpData={e}
                    bestCPM={bestCPM}
                    worstCPM={worstCPM}
                    indiceCPM={cpmPos[index].position}
                    length={data.length}
                    // @TODO: find a better key
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  />
                ))}
              </TableBodyDistrib>
            </TableAgregated>
          </React.Fragment>
        ) : (
          <TableDistrib>
            <TableHeadDistrib>
              <TableRowDistrib>
                <TableCellDistrib>Média</TableCellDistrib>
                <TableCellDistrib style={{ width: '2%' }} />
                <TableCellDistrib
                  className="b-bottom"
                  fontWeight={activeBudget ? 'true' : undefined}
                  onClick={() => {
                    setActiveImpression(false);
                    setActiveBudget(true);
                    setActiveCPM(false);
                    if (croissantBudget) {
                      setData(data.sort((a, b) => a.budget - b.budget));
                      setCroissantBudget(false);
                      setCpmPos(cpmPosition(data));
                    } else {
                      setData(data.sort((a, b) => b.budget - a.budget));
                      setCroissantBudget(true);
                      setCpmPos(cpmPosition(data));
                    }
                  }}
                >
                  Budget (k€)
                  <img
                    src={
                      activeBudget && croissantBudget
                        ? '/assets/img/dropup.svg'
                        : '/assets/img/dropdown.svg'
                    }
                    alt="drop-down-arrow"
                  />
                </TableCellDistrib>
                <TableCellDistrib style={{ width: '2%' }} />
                <TableCellDistrib
                  className="b-bottom"
                  style={{ padding: 0 }}
                  fontWeight={activeCPM ? 'true' : undefined}
                  onClick={() => {
                    setActiveImpression(false);
                    setActiveBudget(false);
                    setActiveCPM(true);
                    if (croissantCPM) {
                      setData(data.sort((a, b) => a.CPM - b.CPM));
                      setCroissantCPM(false);
                      setCpmPos(cpmPosition(data));
                    } else {
                      setData(data.sort((a, b) => b.CPM - a.CPM));
                      setCroissantCPM(true);
                      setCpmPos(cpmPosition(data));
                    }
                  }}
                >
                  CPM
                  <img
                    src={
                      activeCPM && croissantCPM
                        ? '/assets/img/dropup.svg'
                        : '/assets/img/dropdown.svg'
                    }
                    alt="drop-down-arrow"
                  />
                </TableCellDistrib>
                <TableCellDistrib style={{ width: '2%' }} />
                <TableCellDistrib
                  className="b-bottom"
                  fontWeight={activeImpression ? 'true' : undefined}
                  onClick={() => {
                    setActiveImpression(true);
                    setActiveBudget(false);
                    setActiveCPM(false);
                    if (croissantImpression) {
                      setData(data.sort((a, b) => a.impression - b.impression));
                      setCroissantImpression(false);
                      setCpmPos(cpmPosition(data));
                    } else {
                      setData(data.sort((a, b) => b.impression - a.impression));
                      setCroissantImpression(true);
                      setCpmPos(cpmPosition(data));
                    }
                  }}
                >
                  Impression (M)
                  <img
                    src={
                      activeImpression && croissantImpression
                        ? '/assets/img/dropup.svg'
                        : '/assets/img/dropdown.svg'
                    }
                    alt="drop-down-arrow"
                  />
                </TableCellDistrib>
              </TableRowDistrib>
            </TableHeadDistrib>
            <TableBodyDistrib>
              {data.map((e, index) => (
                <Distrib
                  // @TODO Fix this with id in compute
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  number={index}
                  name={e.name}
                  tmpData={e}
                  maxBudget={sumBudget}
                  maxImpression={sumImpression}
                  indiceCPM={cpmPos[index].indice}
                />
              ))}
              <TableRowDistrib />
              <Distrib
                number={data.length}
                name={max.name}
                tmpData={max}
                maxBudget={sumBudget}
                maxImpression={sumImpression}
                indiceCPM={0.5}
              />
            </TableBodyDistrib>
          </TableDistrib>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ButtonTab>
        <ButtonSwitch
          className={activeButton === 'agregated' ? 'active' : ''}
          onClick={() => {
            setActiveButton('agregated');
          }}
        >
          Plan Média Agrégé
        </ButtonSwitch>
        <ButtonSwitch
          className={activeButton === 'detailled' ? 'active' : ''}
          onClick={() => {
            setActiveButton('detailled');
          }}
        >
          Plan Média Détaillé
        </ButtonSwitch>
      </ButtonTab>

      <DateMessage>
        {`Période du ${moment(funnel.start).format('DD/MM/YYYY')} au ${moment(
          funnel.end
        ).format('DD/MM/YYYY')}`}
      </DateMessage>

      {media.length === 0 ||
      moment(funnel.start).isAfter(moment(funnel.end)) ? (
        <ErrorMessage>
          {
            "Il semblerait que les données que vous nous avez transmis pour ce funnel ne nous permettent pas d'analyser la distribution de vos budgets médias."
          }
        </ErrorMessage>
      ) : (
        activePanel()
      )}
    </React.Fragment>
  );
}

DistributionMediaContainer.defaultProps = {};

DistributionMediaContainer.propTypes = {};

export default DistributionMediaContainer;
