import React from 'react';
import { useTheme } from 'contexts/ThemeContext';

import Section from './index';

/**
 *
 *
 * SectionLanding
 * @param {Object} props
 *
 *
 *
 */
function SectionDashboard(props) {
  const { themeColors } = useTheme();
  return (
    <Section
      {...props}
      width="100%"
      color={themeColors.light}
      justifyContent="center"
      alignItems="center"
      minHeight="auto"
      containerProps={{ maxWidth: 'lg' }}
    />
  );
}

export default SectionDashboard;
