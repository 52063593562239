import styled from 'styled-components';
import { withTheme } from 'src/contexts/ThemeContext';
import Text from 'src/components/Text';

const StyledResultColumn = withTheme(styled.div``);

export const StyledSpan = withTheme(styled.span`
  display: inline;
  color: ${({ themeColors, number }) =>
    number ? themeColors.light : themeColors.data};
  font-weight: ${({ number }) => (number ? '600' : '500')};
  margin: 0px;
  font-size: ${({ fontSize }) => fontSize || '14px'};
`);

export const StyledText = withTheme(styled(Text)`
  margin: 4px;
  line-height: 1.2em;
`);

export default StyledResultColumn;
