import React from 'react';
import PropTypes from 'prop-types';
import {
  STATUS_BASE_ANALYZED,
  STATUS_BASE_DIGITIZED
} from 'utils/api/temelioBase';
import { useLogin } from 'Data/hooks/useLogin';
import { useFileUpload } from 'Data/hooks/useFileUpload';
import { useTemelioBase } from 'Data/hooks/useTemelioBase';
import { useTemelioBases } from 'Data/hooks/useTemelioBases';

const DataContext = React.createContext();

export const STEPS = {
  INIT: 'INIT',
  IMPORT_FILE: 'IMPORT_FILE',
  CHANGE_HEADERS: 'CHANGE_HEADERS',
  MATCHING_PROGRESS: 'MATCHING_PROGRESS',
  MATCHING_END: 'MATCHING_END',
  DONE: 'DONE',
  ENRICHIR_MODAL: 'ENRICHIR_MODAL',
  ENRICHIR_HOME: 'ENRICHIR_HOME'
};

const STEPS_BY_STATUS = {
  uploading: STEPS.IMPORT_FILE,
  uploaded: STEPS.IMPORT_FILE,
  analyzing: STEPS.IMPORT_FILE,
  analyzed: STEPS.CHANGE_HEADERS,
  headers_checked: STEPS.MATCHING_PROGRESS,
  database_creating: STEPS.MATCHING_PROGRESS,
  database_created: STEPS.MATCHING_PROGRESS,
  digitizing: STEPS.MATCHING_PROGRESS,
  digitized: STEPS.DONE
};

function getFilename(file, filename) {
  if (file) {
    return file.name;
  }
  if (filename) {
    return filename;
  }
  return null;
}

/**
 * DataProvider
 * @context
 * @component
 * @param {number} refetchInterval Interval for refetch in second
 */
export default function DataProvider({ filename, refetchInterval, ...props }) {
  const [status, setStatus] = React.useState(null);
  const filenameRef = React.useRef();
  const [step, setStep] = React.useState(STEPS.INIT);
  const { token, login: loginLoading, error: loginError } = useLogin();
  const {
    progress: uploadProgress,
    file,
    setFile,
    loading: uploadLoading,
    error: uploadError,
    cancel: uploadCancel,
    status: uploadStatus,
    resetFileUpload,
    data: uploadData,
    setOffset: setFileOffset,
    fromHistory
  } = useFileUpload(token, filename);
  const {
    base: currentBase,
    progress: matchingProgress,
    loading: baseLoading,
    error: baseError,
    resetBase
  } = useTemelioBase(token, getFilename(file, filename), status);
  const {
    bases,
    total,
    loading: basesLoading,
    error: basesError
  } = useTemelioBases(token, refetchInterval);

  React.useEffect(() => {
    if (!filename && filenameRef.current !== filename) {
      setStep(STEPS.INIT);
      filenameRef.current = filename;
    }
  }, [filename]);

  React.useEffect(() => {
    if (!status && bases) {
      const base = bases.find(b => b.name === filename);
      if (base) {
        setStatus(base.status);
        setStep(STEPS_BY_STATUS[base.status]);
        setFileOffset(base.offset || 0);
      }
    }
  }, [bases, filename, setFileOffset, status]);

  React.useEffect(() => {
    if (matchingProgress === 100 && step === STEPS.MATCHING_PROGRESS) {
      setStep(STEPS.MATCHING_END);
    }
  }, [matchingProgress, step]);

  React.useEffect(() => {
    if (step === STEPS.CHANGE_HEADERS) setStatus(STATUS_BASE_ANALYZED);
    else if (step === STEPS.MATCHING_PROGRESS) {
      setStatus(STATUS_BASE_DIGITIZED);
    }
  }, [resetBase, resetFileUpload, step]);

  React.useEffect(() => {
    if (currentBase && currentBase.digityzing) {
      setStep(STEPS.MATCHING_END);
    }
  }, [currentBase]);

  const value = {
    // variable
    step,
    status,

    token,
    loginLoading,
    loginError,

    uploadProgress,
    file,
    uploadLoading,
    uploadError,
    uploadData,
    uploadStatus,

    currentBase,
    matchingProgress,
    baseLoading,
    baseError,

    bases,
    total,
    basesLoading,
    basesError,

    filename: getFilename(file, filename),
    fromHistory,
    historyName: filename,

    // function
    setStep,

    setFile,
    uploadCancel,

    reset() {
      setStatus(null);
      setStep(STEPS.INIT);
      resetFileUpload();
      resetBase();
    }
  };
  return <DataContext.Provider {...props} value={value} />;
}
DataProvider.defaultProps = {
  filename: null,
  refetchInterval: 30
};
DataProvider.propTypes = {
  filename: PropTypes.string,
  refetchInterval: PropTypes.number
};

/**
 * useFunel
 * @function
 */
export const useData = () => {
  const context = React.useContext(DataContext);
  if (!context)
    throw new Error('DataContext must be used in DataProvider(DataModule)');
  return context;
};
