import React from 'react';
import constants from 'utils/constants';
import ConnectorButtonFunnel from 'Funnel/components/ConnectorButtonFunnel';
import ConnectorFormFunnel from 'Funnel/components/ConnectorFormFunnel';
import ConnectorModalFunnel from 'Funnel/components/ConnectorModalFunnel';

import HiderFunnel from 'Funnel/components/HiderFunnel';

import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useNotif } from 'contexts/NotifContext';

const { connectors: sources } = constants;

/**
 * ConnectorsContainer
 * @container
 *
 */
function ConnectorsContainer() {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { canSeeConnector, setHasConnector } = useFunnelStep();
  const [funnelRef, setFunnelRef] = React.useState({});
  const { notify } = useNotif();
  const { setFunnel, funnel } = useFunnel();
  const setConnector = (funnelId, connector, data, childId) => {
    const connectorContent = { funnelId, connector, data };
    if (childId) connectorContent.childId = childId;
    setFunnel(currentFunnel => ({
      ...currentFunnel,
      connected: [
        ...currentFunnel.connected.filter(co => co.funnelId !== funnelId),
        connectorContent
      ]
    }));
  };
  const setFetched = () => {
    const newConnected = funnel.connected.map(c => ({ ...c, fetched: true }));
    setFunnel(currentFunnel => ({ ...currentFunnel, connected: newConnected }));
  };
  const handleClick = (e, f) => {
    setFunnelRef(f);
    setModalIsOpen(true);
  };
  const setForecast = forecast => {
    setFunnel(currentFunnel => ({ ...currentFunnel, forecast }));
  };

  React.useEffect(() => {
    if (funnel.connected.length === funnel.measure.funnels.length) {
      setHasConnector();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnel.connected]);
  return (
    <>
      <HiderFunnel hide={!canSeeConnector}>
        <ConnectorFormFunnel>
          {funnel.measure &&
            funnel.measure.funnels
              .filter(f => f.connectors.length)
              .map(f => (
                <ConnectorButtonFunnel
                  key={f.id}
                  handleClick={e => handleClick(e, f)}
                  name={f.name}
                />
              ))}
        </ConnectorFormFunnel>
      </HiderFunnel>
      <ConnectorModalFunnel
        {...funnelRef}
        childId={funnelRef.childId}
        connectors={funnelRef.connectors}
        forecast={funnelRef.forecast}
        funnel={funnel}
        funnelId={funnelRef.id}
        handleCloseModal={() => setModalIsOpen(false)}
        modalIsOpen={modalIsOpen}
        notify={notify}
        onClose={setFetched}
        setConnector={setConnector}
        setForecast={setForecast}
        sources={sources}
        target={funnelRef.target}
      />
    </>
  );
}

export default ConnectorsContainer;
