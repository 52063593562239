const connectors = [
  {
    id: 1,
    name: 'Facebook Ads',
    src: '/assets/img/connector/Facebook-Ads-Icon.png',
    oauthUrl: `${process.env.REACT_APP_API_URL}/auth/FacebookAds/oauth`,
    sso: true
  },
  {
    id: 2,
    name: 'Google Ads',
    src: '/assets/img/connector/Google_a_logo.png',
    sso: true
  },
  {
    id: 3,
    name: 'Display & Video 360',
    src: '/assets/img/connector/dv360.png',
    sso: true
  },
  {
    id: 4,
    name: 'App Nexus',
    src: '/assets/img/connector/logo_appnexus.png',
    sso: true
  },
  {
    id: 5,
    name: 'CSV',
    src: '/assets/img/connector/CSV_Icon.png',
    upload: true
  },
  {
    id: 6,
    name: 'Google Play',
    src: '/assets/img/connector/google_play.png',
    sso: true
  },
  {
    id: 7,
    name: 'App Store',
    src: '/assets/img/connector/Logo_App_Store.png',
    sso: true
  },
  {
    id: 8,
    name: 'Firebase',
    src: '/assets/img/connector/firebase.png',
    sso: true
  },
  {
    id: 9,
    name: 'Logz.io',
    src: '/assets/img/connector/logzio.png',
    sso: true
  },
  {
    id: 10,
    name: 'Mongo DB',
    src: '/assets/img/connector/mongodb.png'
  },
  {
    id: 11,
    name: 'Google Analytics',
    src: '/assets/img/connector/Google_a_logo.png',
    sso: true
  },
  {
    id: 12,
    name: 'Critéo',
    src: '/assets/img/connector/criteo.png',
    sso: true
  },
  {
    id: 13,
    name: 'Commanders Act',
    src: '/assets/img/connector/commanders_act.png',
    sso: true
  },
  {
    id: 14,
    name: 'Kairos',
    src: '/assets/img/connector/kairos.png',
    sso: true
  },
  {
    id: 15,
    name: 'Salesforce',
    src: '/assets/img/connector/salesforce.png',
    sso: true
  },
  {
    id: 16,
    name: 'SAP',
    src: '/assets/img/connector/sap.png',
    sso: true
  },
  {
    id: 17,
    name: 'Oracle',
    src: '/assets/img/connector/oracle.png',
    sso: true
  },
  {
    id: 18,
    name: 'Hubspot',
    src: '/assets/img/connector/hubspot.png',
    sso: true
  },
  {
    id: 19,
    name: 'Pipedrive',
    src: '/assets/img/connector/pipedrive.png',
    sso: true
  },
  {
    id: 20,
    name: 'Yougov',
    src: '/assets/img/connector/yougov.png',
    sso: true
  },
  {
    id: 21,
    name: 'Amazon Apps',
    src: '/assets/img/connector/amazon_apps.png',
    sso: true
  },
  {
    id: 22,
    name: 'Linkedin',
    src: '/assets/img/connector/linkedin.png',
    sso: true
  },
  {
    id: 23,
    name: 'youtube',
    src: '/assets/img/connector/youtube.png',
    sso: true
  }
];

export default connectors;
