import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'src/contexts/ThemeContext';

const FIELD_TYPE = {
  adr: 'Adresse',
  cp: 'Code postal',
  contact: 'Nom + Prénom',
  cpville: 'Code postal + Ville',
  email: 'Email',
  id: 'ID CRM',
  num: 'Ne pas importer',
  md5: 'MD5 Email',
  nom: 'Nom',
  pr: 'Prénom',
  pays: 'Pays',
  segment: 'Segment',
  tel: 'Téléphone fixe',
  mob: 'Téléphone mobile',
  loc: 'Ville',
  unknown: 'Non reconnu',
  civ: 'Civilité'
};
const FIELD_TYPE_KEY = Object.keys(FIELD_TYPE);

function SelectFileFieldType({ selectedKey, onChange }) {
  const handleChange = event => onChange(event.target.value);
  const { themeColors } = useTheme();

  return (
    <select
      style={{
        border: 'none',
        background: 'no-repeat',
        fontSize: '1.1em',
        color: themeColors.light
      }}
      onChange={handleChange}
      defaultValue={selectedKey}
    >
      {FIELD_TYPE_KEY.map(key => (
        <option key={key} value={key}>
          {FIELD_TYPE[key]}
        </option>
      ))}
    </select>
  );
}
SelectFileFieldType.defaultProps = {
  selectedKey: null
};
SelectFileFieldType.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedKey: PropTypes.string
};

export default SelectFileFieldType;
