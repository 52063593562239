export default {
  OR: {
    NAME: 'OR',
    LABEL: 'OU'
  },
  AND: {
    NAME: 'AND',
    LABEL: 'ET'
  },
  SIMPLE: {
    NAME: 'IS',
    LABEL: ''
  },
  EXCLUDE: {
    NAME: 'NOT',
    LABEL: ''
  }
};
