import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';
import { withRouter } from 'react-router-dom';

import constants from 'utils/constants';

import { ApolloProvider } from '@apollo/react-hooks';
import configuredGraphqlClient from 'utils/api/graphql';
import HistoryFunnelContainer from 'Funnel/containers/HistoryFunnelContainer';
import HistoryInsightContainer from 'Insight/containers/HistoryInsightContainer';
import HistoryDataContainer from 'Data/containers/HistoryDataContainer/index';
import HistoryActivationContainer from 'Activation/containers/HistoryActivationContainer';
import ErrorPage from 'components/ErrorPage';
import History from 'components/History';
import Sidebar, { SideItem } from 'components/Sidebar';
import DataProvider from 'src/modules/Data/contexts/DataContext';

const { panels } = constants;

const DISPLAYED_PANELS = [
  {
    title: "Mes DATA's",
    name: panels.data
  },
  {
    title: "Mes INSIGHT's",
    name: panels.insight
  },
  {
    title: "Mes ACTIVATION's",
    name: panels.activation
  },
  {
    title: "Mes FUNNEL's",
    name: panels.funnel
  }
];

/**
 * HistoryContainer
 * @component
 *
 */
function HistoryContainer(props) {
  const { match, history } = props;
  const { themeColors } = useTheme();
  const { panel } = match.params;
  if (!Object.values(panels).includes(panel)) {
    return <ErrorPage />;
  }
  const handleCLick = selected => {
    history.push(`/history/${selected}`);
  };
  return (
    <ApolloProvider client={configuredGraphqlClient}>
      <History panel={panel}>
        <Sidebar width="16%" panel={panel}>
          {DISPLAYED_PANELS.map(panelsItem => (
            <SideItem
              key={panelsItem.name}
              active={panel === panelsItem.name}
              handleCLick={() => handleCLick(panelsItem.name)}
              hasActiveClass={panel === panelsItem.name}
              svg={panelsItem.name}
              svgAttrs={{ fill: themeColors.icon }}
              title={panelsItem.title}
            />
          ))}
        </Sidebar>
        {panel === panels.data && (
          <DataProvider refetchInterval={1}>
            <HistoryDataContainer />
          </DataProvider>
        )}
        {panel === panels.insight && <HistoryInsightContainer />}
        {panel === panels.activation && <HistoryActivationContainer />}
        {panel === panels.funnel && <HistoryFunnelContainer />}
      </History>
    </ApolloProvider>
  );
}
HistoryContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(HistoryContainer);
