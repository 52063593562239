import React from 'react';
import PropTypes from 'prop-types';

const InsightContext = React.createContext();

/**
 * InsightProvider
 * @provider
 * @desc manage all the insight process
 *
 */
export default function InsightProvider({ isData, dataBase, ...props }) {
  const [name, setSegmentName] = React.useState('Mon segment');
  const value = {
    name,
    setSegmentName,
    isData,
    dataBase
  };
  return <InsightContext.Provider {...props} value={value} />;
}
InsightProvider.defaultProps = {
  dataBase: null,
  isData: false
};
InsightProvider.propTypes = {
  dataBase: PropTypes.shape({}),
  isData: PropTypes.bool
};

/**
 * useInsight
 * @function
 * @return value defined above (InsightProvider)
 *
 */
export const useInsight = () => {
  const context = React.useContext(InsightContext);
  if (!context) {
    return {
      isData: false,
      dataBase: null
    };
  }
  return context;
};

/**
 * withInsight
 * @HOC
 * Hight order component
 *
 */
export const withInsight = Component => props => {
  const { isData, dataBase } = useInsight();
  return <Component isData={isData} dataBase={dataBase} {...props} />;
};
