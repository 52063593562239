import styled from 'styled-components';
/*
import constants from 'utils/constants';

const { colors } = constants;
*/

/**
 * BaseConnector
 * @component
 *
 */
const BaseOperatedGroup = styled.div`
  flex-wrap: wrap;
  padding: 0;
  display: inline-flex;
  width: auto;
`;
export default BaseOperatedGroup;
