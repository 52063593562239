import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { useUser } from 'contexts/UserContext';
import Icon from 'utils/icons';
import constants from 'utils/constants';
import { useTheme } from 'contexts/ThemeContext';
import {
  DropdownHeader,
  Logo,
  LogoImg,
  FunnelLink,
  FlexCenterProfile,
  ButtonSwitchLight
} from './styles';

const { routes, colors } = constants;

/**
 * ProfilNav
 * @component
 *
 */
function ProfilNav(props) {
  const { toggleTheme, isLightMode } = props;
  const { logOut, user, isAuthenticated, setUserLocation } = useUser();
  const { themeColors } = useTheme();
  const handleLogout = async event => {
    event.preventDefault();
    await logOut();
    window.location.href = process.env.REACT_APP_HOME_URL;
  };
  const handleLightSwitch = () => {
    toggleTheme();
  };
  return (
    <React.Fragment>
      <FlexCenterProfile>
        <DropdownHeader
          minWidth="140px"
          marginRight="15px"
          fix
          defaultValue="Historique"
          themeColors={colors.dark}
          options={[
            <FunnelLink
              to={`${routes.HISTORY}${routes.DATA}`}
              title=""
              onClick={() => {
                setUserLocation(`${routes.HISTORY}${routes.DATA}`);
              }}
            >
              <Logo icon="ermes" attrs={{ fill: themeColors.data }} />
              <span>Data</span>
            </FunnelLink>,
            <FunnelLink
              to={`${routes.HISTORY}${routes.INSIGHT}`}
              title=""
              onClick={() => {
                setUserLocation(`${routes.HISTORY}${routes.INSIGHT}`);
              }}
            >
              <Logo icon="ermes" attrs={{ fill: themeColors.insight }} />
              <span>Insight</span>
            </FunnelLink>,
            <FunnelLink
              to={`${routes.HISTORY}${routes.ACTIVATION}`}
              title=""
              onClick={() => {
                setUserLocation(`${routes.HISTORY}${routes.ACTIVATION}`);
              }}
            >
              <Logo icon="ermes" attrs={{ fill: themeColors.activation }} />
              <span>Activation</span>
            </FunnelLink>,
            <FunnelLink
              to={`${routes.HISTORY}${routes.FUNNEL}`}
              title=""
              onClick={() => {
                setUserLocation(`${routes.HISTORY}${routes.FUNNEL}`);
              }}
            >
              <Logo icon="ermes" attrs={{ fill: themeColors.funnel }} />
              <span>Funnel</span>
            </FunnelLink>
          ]}
        />
        {isAuthenticated && (
          <DropdownHeader
            fix
            options={[
              <a
                href="#"
                onClick={() => {}}
                onKeyUp={() => {}}
                tabIndex={0}
                title=""
                role="button"
              >
                <LogoImg
                  src="/assets/img/picto_profil.svg"
                  alt="Icone mon compte"
                />
                Mon compte
              </a>,
              <a
                onClick={handleLogout}
                onKeyUp={handleLogout}
                role="button"
                tabIndex={0}
                title=""
              >
                <LogoImg
                  src="/assets/img/icons/logout.svg"
                  alt="Icone se deconnecter"
                />
                Se déconnecter
              </a>
            ]}
            themeColors={colors.dark}
            defaultValue={
              user.attributes ? user.attributes['custom:nickname'] : ''
            }
          />
        )}
        <ButtonSwitchLight type="button" onClick={handleLightSwitch}>
          <Icon icon={isLightMode ? 'darkSwitch' : 'lightSwitch'} />
        </ButtonSwitchLight>
      </FlexCenterProfile>
    </React.Fragment>
  );
}

ProfilNav.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
  isLightMode: PropTypes.bool.isRequired
};

export default withRouter(ProfilNav);
