import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

const ErrorMessage = styled.p`
  color: ${({ themeColors }) => themeColors.light};
  font-size: 14px;
  padding: 10px 40px;
`;

export default withTheme(ErrorMessage);
