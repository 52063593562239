import axios from 'axios';

export default () => null;

/**
 * Get PDF name from funnel
 *
 * @export
 * @param {Object} funnel
 * @param {string} funnel.name
 * @returns
 */
export function getFunnelName(funnel) {
  if (funnel.name) {
    return funnel.name.replace(/\s/g, '_');
  }
  return `${new Date().toLocaleDateString()}_funnel`;
}

/**
 * getFunnelPDF
 * @param {Object} funnel
 * @return {Promise}
 *
 */
export const getFunnelPDF = funnel => {
  const payload = JSON.stringify(funnel);
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_PDF_API_URL}/funnel-pdf/export`,
          {
            funnel: payload
          },
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf'
            }
          }
        )
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * getinsightPDF
 * @param {Object} insight
 * @return {Promise}
 *
 */
export const getInsightPDF = insight => {
  const payload = JSON.stringify(insight);
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_PDF_API_URL}/insight/export-pdf`,
          {
            insight: payload
          },
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf'
            }
          }
        )
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * getNotifyParams
 * @param {String} name
 * @return {title, message, variants}
 *
 */
const getNotifyParams = {
  pdfPending: () => ({
    title: 'Génération du PDF',
    message: `Le PDF est en cours de création`,
    variants: 'info'
  }),
  pdfSuccess: name => ({
    title: 'Génération du PDF',
    message: `La génération du PDF ${name} est terminée.`,
    variants: 'success'
  }),
  pdfError: () => ({
    title: 'Génération du PDF',
    message: `La génération du PDF a échoué`,
    variants: 'error'
  })
};

export const downloadPDFInsight = (insight, notify, user) => {
  notify(getNotifyParams.pdfPending());
  getInsightPDF({ ...insight, username: user.attributes['custom:nickname'] })
    .then(response => {
      // force local PDF download
      const nameInsight = `${insight.name}.pdf`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nameInsight);
      document.body.appendChild(link);
      link.click();
      notify(getNotifyParams.pdfSuccess(nameInsight));
    })
    .catch(e => {
      console.error(e);
      notify(getNotifyParams.pdfError());
    });
};
