import React from 'react';
import styled from 'styled-components';
import Title from 'components/Title';
import constants from 'utils/constants';
import { withTheme } from 'contexts/ThemeContext';
import { dataOrInsight } from 'utils/functions/insight';
import { withInsight } from 'Insight/contexts/InsightContext';

const { fontSizes } = constants;

const BaseInsightTitle = props => <Title big {...props} />;
/**
 * CriteriaTitle
 * @component
 *
 */
export const InsightTitle = styled(BaseInsightTitle)`
  font-size: 26px;
  text-align: left;
  margin: 4px 0;
  @media (min-width: 1200px) {
    font-size: 36px;
    margin: 8px 0;
  }
`;

/**
 * BaseInsightInsight
 * @component
 *
 */
const BaseCriteriasForm = withInsight(
  withTheme(styled.div`
    display: flex;
    width: auto;
    flex-direction: column;
    background-color: ${({ themeColors }) => themeColors.secondary};
    color: ${({ themeColors }) => themeColors.light};
    padding: 2px 16px;
    border-radius: 8px;
    overflow-x: hidden;
    .legend {
      display: grid;
      font-size: ${fontSizes.lower};
      grid-template-columns: ${({ displayRightBlock }) =>
        displayRightBlock ? '5fr 5fr 4fr' : '8fr 5fr'};
      div {
        text-transform: uppercase;
      }
      .ermes {
        color: ${({ themeColors, isData }) =>
          dataOrInsight(isData, themeColors, 'data')};
        text-align: ${({ displayRightBlock }) =>
          displayRightBlock ? 'right' : 'left'};
        margin-right: 15px;
      }
      .insee {
        color: ${({ themeColors }) => themeColors.light};
        opacity: 0.6;
        margin-left: 15px;
      }
    }
    .InsightTitle {
      font-size: 36px;
      font-weight: bold;
      text-align: left;
      margin: 3px 0;
    }
    @media (min-width: 1024px) {
      .legend {
        font-size: ${fontSizes.small};
        grid-template-columns: ${({ displayRightBlock }) =>
          displayRightBlock ? '10fr 9fr 10fr' : '2fr 3fr'};
      }
      font-size: ${fontSizes.small};
    }
    @media (min-width: 1440px) {
      .legend {
        grid-template-columns: ${({ displayRightBlock }) =>
          displayRightBlock ? '278px 9fr 10fr' : '3fr 3fr'};
      }
    }
    @media (min-width: 1800px) {
      padding: 8px 16px;
      .legend {
        grid-template-columns: ${({ displayRightBlock }) =>
          displayRightBlock ? '3fr 5fr 5fr' : '4fr 6fr'};
        font-size: 18px;
      }
    }
  `)
);

/**
 * InsightDesc
 * @component
 *
 */
export const InsightDesc = withTheme(styled.p`
  color: ${({ themeColors }) => themeColors.light};
  font-size: 14px;
  margin: 5px 0 10px 0;
  @media (min-width: 1800px) {
    font-size: 18px;
  }
`);

/**
 * Header (container for title and pdf button)
 * @component
 *
 */
export const Header = withTheme(styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`);

export default BaseCriteriasForm;
