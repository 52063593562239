import React from 'react';
import PropTypes from 'prop-types';

import Block from './Block';
import ModalBuyProfils from './Modals/ModalBuyProfils';

/**
 * BuyProfile
 * @component
 */
function BlockBuyProfile({ disabled }) {
  const [modalOpenned, setModalOpenned] = React.useState(false);

  function onClick() {
    setModalOpenned(true);
  }

  return (
    <>
      <Block
        icon="buyProfile"
        onClick={onClick}
        disabled={disabled}
        title="Acheter les profils digitalisés"
        text="Suite au matching effectué avec les 25 millions de profils Ermes,
    rappatrier vos profils digitalisés sur votre CRM ou DMP ou DSP
    partenaire afin d’atteindre votre audience sur tous les supports
    digitaux grâce à vos campagnes."
      />
      <ModalBuyProfils
        open={modalOpenned}
        onClose={() => setModalOpenned(false)}
        onNext={() => setModalOpenned(false)}
      />
    </>
  );
}
BlockBuyProfile.defaultProps = {
  disabled: false
};
BlockBuyProfile.propTypes = {
  disabled: PropTypes.bool
};

export default BlockBuyProfile;
