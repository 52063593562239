import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import { dataOrInsight } from 'utils/functions/insight';
import { withInsight } from 'Insight/contexts/InsightContext/index';

const getBorderRadius = ({ isFirst, isLast }) => {
  if (isFirst && isLast) return '15px';
  if (isFirst) return '15px 0 0 15px';
  if (isLast) return '0 15px 15px 0';
  return 0;
};
/**
 * ConnectorCriteria
 * @component
 *
 */
const OperatedCriteria = withInsight(styled.a`
  background-color: ${({ inversed, themeColors, isData }) =>
    inversed ? 'transparent' : dataOrInsight(isData, themeColors, 'data')};
  color: ${({ inversed, themeColors, isData }) =>
    inversed
      ? dataOrInsight(isData, themeColors, 'data')
      : themeColors.keep_light};
  border: 1px solid
    ${({ themeColors, isData }) => dataOrInsight(isData, themeColors, 'data')};
  border-radius: ${({ isFirst, isLast }) =>
    getBorderRadius({ isFirst, isLast })};
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  padding: 1px 5px;
  transition: all 0.3s ease-out;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 1px 3px;
  font-size: 11px;
  .criteria-label {
    text-transform: uppercase;
    white-space: nowrap;
  }
  @media (min-width: 1800px) {
    padding: 2px 6px;
    font-size: 14.5px;
  }
`);

export const OperatedCriteriaRemoveButton = withTheme(styled.button`
  background-color: ${({ themeColors }) => themeColors.primary};
  color: ${({ themeColors }) => themeColors.light};
  border: 1px solid ${({ themeColors }) => themeColors.primary};
  border-radius: 50%;
  font-size: 10px;
  font-weight: 300;
  position: relative;
  right: -7px;
  top: -7px;
  width: 14px;
  height: 14px;
  padding: 2px;
  line-height: 4px;
  cursor: pointer;
  @media (min-width: 1800px) {
    padding: 2px;
    font-size: 12px;
  }
`);

export default withTheme(OperatedCriteria);
