import React from 'react';
import PropTypes from 'prop-types';
import BaseInputActivation, { InputWrapper } from './style';

/**
 * InputActivation
 * @component
 *
 */
function InputActivation(props) {
  const { suffix } = props;
  const Suffix = () => (suffix ? <>{suffix}</> : null);

  return (
    <InputWrapper>
      <BaseInputActivation {...props} />
      {Suffix && <Suffix />}
    </InputWrapper>
  );
}
InputActivation.defaultProps = {
  suffix: undefined
};

InputActivation.propTypes = {
  suffix: PropTypes.node
};

export default InputActivation;
