import React from 'react';
// import PropTypes from 'prop-types'

import BaseNameFunnel from './style';

/**
 * NameFunnel
 * @component
 *
 */
function NameFunnel(props) {
  return <BaseNameFunnel {...props} />;
}
NameFunnel.defaultProps = {};
NameFunnel.propTypes = {};

export default NameFunnel;
