import colors from './colors';
import connectors from './connectors';
import errorPage from './errorPage';
import exampleCategories from './exampleCategories';
import featureContent from './featureContent';
import fontFamilies from './fontFamilies';
import fontSizes from './fontSizes';
import insightCriteriaTypes from './insightCriteriaTypes';
import kpiList from './kpiList';
import landingRoles from './landingRoles';
import mapList from './mapList';
import measures from './measures';
import panels from './panels';
import policies from './policies';
import routes from './routes';
import themes from './themes';
import thresolds from './thresolds';

export default {
  colors,
  connectors,
  errorPage,
  exampleCategories,
  featureContent,
  fontFamilies,
  fontSizes,
  insightCriteriaTypes,
  kpiList,
  landingRoles,
  mapList,
  measures,
  panels,
  policies,
  routes,
  themes,
  thresolds
};
