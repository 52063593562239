import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';

const { fontSizes } = constants;

/**
 * BaseWrapperIndicator
 * @component
 *
 */
const BaseWrapperIndicator = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${({ themeColors }) => themeColors.secondary};
  box-shadow: 1px 0px 3px 1px ${({ themeColors }) => themeColors.shadow};
  .item-indicator {
    display: flex;
    > * {
      margin: 0;
      margin-right: 4px;
    }
    label {
      font-size: ${fontSizes.small};
    }
  }
`;

export default withTheme(BaseWrapperIndicator);
