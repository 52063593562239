import React from 'react';
import PropTypes from 'prop-types';

import CriteriasGroupInsight, { BaseCriteriasSubGroup } from './style';

/**
 * CriteriasSubGroupInsight
 * @component
 * @see CriteriaContainer
 *
 */
export function CriteriasSubGroupInsight(props) {
  const { title, children, hideTitle } = props;
  return (
    <BaseCriteriasSubGroup>
      {hideTitle ? <div /> : <div className="title">{title}</div>}
      <div className="criteria">{children}</div>
    </BaseCriteriasSubGroup>
  );
}
CriteriasSubGroupInsight.defaultProps = {
  hideTitle: false
};
CriteriasSubGroupInsight.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideTitle: PropTypes.bool
};

export default CriteriasGroupInsight;
