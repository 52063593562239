import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { withTheme } from 'contexts/ThemeContext';
import Icon from 'utils/icons';
import Table, { TableBody, TableRow, TableRowItem } from 'components/Table';

/**
 * TableActivation
 * @component
 *
 */
const TableActivation = withTheme(styled(Table)`
  border-bottom-color: ${({ themeColors }) => themeColors.activation};
`);

/**
 * TableBodyActivation
 * @component
 *
 */
export const TableBodyActivation = styled(TableBody)``;

/**
 * TableRowActivation
 * @component
 *
 */
export const TableRowActivation = withTheme(styled(TableRow)`
  color: ${({ themeColors }) => themeColors.activation};
  border-bottom-color: ${({ isHead, themeColors }) =>
    isHead ? themeColors.activation : themeColors.light};
`);

/**
 * TableItemActivation
 * @component
 *
 */
export const TableItemActivation = styled(TableRowItem)``;

/**
 * Buttons
 * @component
 *
 */
export const Buttons = withTheme(styled(Button)`
&& {
  background-color: ${({ bgcolor, themeColors }) => bgcolor || themeColors.red};
  color: ${({ color, themeColors }) => color || themeColors.keep_light};
  width: ${({ width }) => width || 'auto'};
  text-align : left;
  padding: 10px;
  svg {
    margin-right: 10px;
  }
}
&&:hover {
  background-color: ${({ bgcolor, disableHover, themeColors }) =>
    disableHover && (bgcolor || themeColors.secondary)};
}
white-space: nowrap;
  }
`);

/**
 * Icons
 *
 */
export const Icons = styled(Icon)`
  height:32px;
  padding:8px;
}
`;

/**
 * @component In Modal
 *
 */
export const BaseContent = withTheme(styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${({ themeColors }) => themeColors.light};
  align-items: center;

  .description {
    display: flex;
    width: 100%;
    padding: 20px 20px;
    justify-content: flex-start;
    white-space: pre-wrap;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 500;
  }
`);

/**
 * Title
 * @component
 *
 */
export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 20px;
`;

export default TableActivation;
