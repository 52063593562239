import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/* The dropdown container */
const BaseDropdownInsight = withTheme(styled.div`
  .dropbtn {
    outline: none;
    cursor: pointer;
    border: none;
    text-decoration: none;
    background-color: ${({ themeColors }) => themeColors.keep_light};
    color: ${({ themeColors }) => themeColors.keep_dark};
    align-items: center;
    font-weight: 600;
    padding: 0 3px 1px 3px;
    font-size: 11.5px;
    @media (min-width: 1800px) {
      padding: 2px 6px;
      font-size: 14.5px;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    ${({ maxWidth }) => `max-width: ${maxWidth}` || 'max-width: auto'};
    ${({ minWidth }) => `min-width: ${minWidth}` || 'min-width: auto'};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    float: none;
    color: ${({ themeColors }) => themeColors.keep_dark};
    padding: 6px 8px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  /* Add a grey background color to dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #ddd;
  }

  /* Show the dropdown menu on hover */
  &:hover .dropdown-content {
    display: block;
  }
`);

export const ArrowDown = styled.img`
  position: relative;
  float: right;
  top: 2px;
  width: 10px;
  height: 10px;
`;

export default BaseDropdownInsight;
