import styled from 'styled-components';
import Sidebar, { SideItem } from 'components/Sidebar';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseSidebarFunnel
 * @component
 *
 */
const BaseSidebarFunnel = withTheme(styled(Sidebar)`
  margin-top: 8px;
  background-color: ${({ themeColors }) => themeColors.secondary};
`);

export default BaseSidebarFunnel;

export const SideItemFunnel = styled(SideItem)`
  height: 56px;
  p {
    width: 220px;
  }
`;

/**
 * ModalContent
 * @component
 *
 */
export const ModalContent = withTheme(styled.div`
  align-items: center;
  background-color: ${({ themeColors }) => themeColors.tertiary};
  border-color: ${({ themeColors }) => themeColors.funnel};
  border-radius: 4px;
  bottom: auto;
  color: ${({ themeColors }) => themeColors.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -50%;
  margin: auto;
  max-width: 33%;
  outline: none;
  padding: 10px 10px 12px;
  position: relative;
  top: 20%;
`);

/**
 * Separator
 * @component
 *
 */
export const Separator = styled.div`
  border-top: 3px solid white;
  margin: 12px 3%;
`;
