import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import constants from 'utils/constants';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import { PropTypes } from 'prop-types';
import Icon from 'utils/icons';

const { colors, fontSizes } = constants;

/**
 * FlexCenter
 * @component
 *
 */
export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * HeaderErmes
 * @component
 *
 */
export const HeaderErmes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px 0 10px;
  position: relative;
  z-index: 1600;
  border-bottom: 8px solid;
  border-color: ${({ color }) => colors.dark[color] || colors.dark.funnel};
  font-size: ${fontSizes.extraSmall};
  background-color: ${colors.dark.primary};
  @media (min-width: 1024px) {
    padding: 4px 40px 0 40px;
    font-size: ${fontSizes.small};
  }
  @media (min-width: 1800px) {
    font-size: ${fontSizes.medium};
  }
`;

/**
 * NavTab
 * @component
 *
 */
export const NavTab = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 3px 0 0 40px;
  a {
    display: flex;
    align-items: center;
    color: ${colors.dark.light};
    flex-direction: column;
  }
  a.selected {
    background-color: ${colors.dark.funnel};
    color:${colors.dark.light};
    font-weight: 700;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    &:after {
      content:"";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 8px solid #009fe3;
    }
  }
  }
`;

/**
 * NavTabItem
 * @component
 *
 */
export const NavTabItem = styled.li`
  min-height: 50px;
  display: flex;
  text-transform: uppercase;
  align-items: flex-end;
  .nav-tab-link {
    padding: 12px 20px;
    div {
      margin-top: 4px;
    }
  }
  background-color: ${props =>
    props.isFocused ? colors.dark.funnel : 'inherit'};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

/**
 * NavImg
 * @component
 *
 */
export const NavImg = styled.div`
  margin-top: 5px;
  width: 110px;
  @media (min-width: 1800px) {
    width: 130px;
  }
`;

/**
 * BaseDefaultHeader
 * @component
 *
 */
export const BaseDefaultHeader = styled.div`
  position: fixed;
  box-sizing: border-box;
  background-color: ${({ isOnScroll }) =>
    isOnScroll ? colors.dark.light : 'transparent'};
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  .test-link {
    color: ${({ isOnScroll }) =>
      isOnScroll ? colors.dark.dark : colors.dark.light};
    margin-right: 20px;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    p a {
      display: block;
      text-align: center;
      margin-right: auto;
      margin: 0 auto 15px auto;
    }

    button {
      display: block;
      margin: 0 auto;
    }
  }
`;

const LinkWrapper = ({ isActive, ...rest }) => {
  return <Link {...rest} />;
};
LinkWrapper.defaultProps = {
  isActive: false
};
LinkWrapper.propTypes = {
  isActive: PropTypes.bool
};

/**
 * @params isActive
 * @params nav
 *
 */
export const BaseHeaderLink = styled(LinkWrapper)`
  background-color: ${({ isActive, color, nav }) =>
    isActive ? color : colors.dark[nav]};
  color: ${colors.dark.secondary};
  text-decoration: none;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : '')};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
`;

/**
 * StyledDropdown
 * @component
 *
 */
const StyledDropdown = styled(Dropdown)`
  width: auto;
  display: flex;
  color: ${colors.dark.light};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  min-height: 42px;

  .dropdown-content,
  .dropdown-content a {
    top: 42px;
    width: auto;
    min-width: ${({ minWidth }) => minWidth || '200px'};
    right: 0;
    cursor: pointer;
    outline: none;
    color: ${colors.dark.light};
    background-color: ${colors.dark.primary};
  }
  .dropdown-content a:hover {
    width: auto;
    background-color: ${colors.dark.tertiary};
    font-weight: bold;
    a {
      background-color: ${colors.dark.tertiary};
    }
  }
`;

/**
 * DropdownHeader
 * @component
 *
 */
export const DropdownHeader = props => (
  <StyledDropdown
    color={colors.dark.light}
    bgColor={colors.dark.primary}
    maxWidth="300px"
    {...props}
  />
);

/**
 * Logo
 * @component
 *
 */
export const Logo = styled(Icon)`
  margin: 0 7px 0 0;
  vertical-align: middle;
  border-style: none;
`;

/**
 * LogoImg
 * @component
 *
 */
export const LogoImg = styled.img`
  width: 20px;
  margin: 0 7px 0 0;
  vertical-align: middle;
  border-style: none;
`;

/**
 * FunnelLink
 * @component
 *
 */
export const FunnelLink = styled(Link)`
  text-decoration: none;
  display: block;
`;

/**
 * FlexCenterProfile
 * @component
 *
 */
export const FlexCenterProfile = styled.ul`
  list-style-type: none;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  list-style: none;
  padding: 0;
`;

export const ButtonSwitchLight = styled(Button)`
  && {
    padding: 4px;
    width: 38px;
    min-width: 0;
    min-height: 0;
    height: 38px;
    border-radius: 12px;
    margin-left: 14px;
    background-color: ${colors.dark.secondary};
  }
`;
export default {};
