import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';
import Tooltip from 'components/Tooltip';
import BaseCriteriaInsight, { CriteriaCheckbox } from './style';

/**
 * CriteriaInsight
 * @see CriteriasListContainer
 * @desc:: one criteria (eq one right line)
 *
 */
function CriteriaInsight(props) {
  const { themeColors } = useTheme();
  const {
    checked,
    ermes,
    insee,
    title,
    description,
    hasInsee,
    toggleCheck
  } = props;

  const handleCheck = () => {
    toggleCheck();
  };
  const id = `criteria-${title}`;
  return (
    <React.Fragment>
      <BaseCriteriaInsight
        inseePercent={insee}
        ermesPercent={ermes}
        hasInsee={hasInsee}
      >
        <div className="item-value">
          <CriteriaCheckbox
            id={id}
            className="checkbox"
            checked={checked}
            onChange={handleCheck}
            label={title}
          />
          {description && (
            <Tooltip
              className="fit-text"
              label={title}
              themeColors={themeColors}
              text={description}
            />
          )}
        </div>
        <div className="graph left" value={ermes}>
          <span>{ermes}%</span>
          <div className="gauge ermes" />
        </div>
        {hasInsee ? (
          <div className="graph" value={ermes}>
            <div className="gauge insee" />
            <span>{insee}%</span>
          </div>
        ) : null}
      </BaseCriteriaInsight>
    </React.Fragment>
  );
}
CriteriaInsight.defaultProps = {
  insee: 0
};
CriteriaInsight.propTypes = {
  checked: PropTypes.bool.isRequired,
  ermes: PropTypes.number.isRequired,
  hasInsee: PropTypes.bool.isRequired,
  insee: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  toggleCheck: PropTypes.func.isRequired
};

export default CriteriaInsight;
