import React from 'react';
import PropTypes from 'prop-types';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { useFunnelStep } from 'Funnel/contexts/FunnelStepContext';
import moment from 'moment';
import DrawerOpener from 'Funnel/components/DrawerOpener';
import IndicatorsFunnel from 'Funnel/components/IndicatorsFunnel';
import NameFunnel from 'Funnel/components/NameFunnel';
import PurposeFunnel from 'Funnel/components/PurposeFunnel';
import PeriodFunnel from 'Funnel/components/PeriodFunnel';
import api from 'utils/api/ermes';

import constants from 'utils/constants';

const { measures } = constants;

function getDateFromData(data) {
  if (!data) return data;
  if (typeof data === 'string') return new Date(data);
  if (data.toDate) return data.toDate();
  return data;
}

/**
 * IndicatorsContainer (Funnel Module)
 * @component
 *
 *
 */
function IndicatorsContainer(props) {
  const { isRecoveryMode } = props;
  const {
    setHasPurpose,
    setHasPeriod,
    isDrawerOpen,
    setIsDrawerOpen
  } = useFunnelStep();
  const {
    setFunnel,
    funnel,
    toggleChangePurpose,
    setIsDatePickerModal
  } = useFunnel();
  const setMeasure = measure => {
    setHasPurpose();
    setIsDrawerOpen(false);
    setFunnel({ ...funnel, measure });
  };
  const setPeriod = async ({ startDate, endDate }) => {
    setFunnel({
      ...funnel,
      start: moment(startDate)
        .startOf('day')
        .add(2, 'hours'),
      end: moment(endDate).endOf('day')
    });
  };
  const setPeriodClose = async ({ startDate, endDate }) => {
    let funnelToUpdate = funnel;
    if (
      (moment(funnel.start).format() !== moment(startDate).format() &&
        moment(funnel.end).isSame(endDate, 'day')) !== true &&
      moment(startDate).isBefore(endDate)
    ) {
      setHasPeriod();
      // Pop DatePickerModal, keeping user from changing date again
      setIsDatePickerModal(true);
      if (funnel.funnelData && funnel.funnelData.synthesis) {
        funnelToUpdate = await api.updateFunnel({
          ...funnel,
          start: moment(startDate)
            .startOf('day')
            .add(2, 'hours'),
          end: moment(endDate).endOf('day')
        });
      }
      setFunnel({
        ...funnelToUpdate,
        start: moment(startDate)
          .startOf('day')
          .add(2, 'hours'),
        end: moment(endDate).endOf('day')
      });
      setIsDatePickerModal(false);
    }
  };
  const { start, end } = funnel;
  return (
    <IndicatorsFunnel>
      {!isDrawerOpen && <DrawerOpener onClick={() => setIsDrawerOpen(true)} />}
      {funnel.name && <NameFunnel>{funnel.name}</NameFunnel>}
      <PurposeFunnel
        funnelMeasure={funnel.measure}
        measures={measures}
        setMeasure={setMeasure}
        isFrozen={isRecoveryMode}
        toggleChangePurpose={toggleChangePurpose}
      />
      <PeriodFunnel
        start={getDateFromData(start)}
        end={getDateFromData(end)}
        setPeriod={setPeriod}
        onClose={setPeriodClose}
      />
    </IndicatorsFunnel>
  );
}
IndicatorsContainer.propTypes = {
  isRecoveryMode: PropTypes.bool.isRequired
};

export default IndicatorsContainer;
