import React from 'react';
import PropTypes from 'prop-types';
import BaseResultSaving, { ModalInput, Title } from './style';

/**
 * ResultSavingInsight
 * @component
 *
 */

function ResultSavingInsight({ onNameChange, targetName }) {
  return (
    <BaseResultSaving>
      <Title>Donner un nom à votre Cible</Title>
      <ModalInput
        id="outlined-bare"
        placeholder="Nom de la cible"
        margin="normal"
        variant="outlined"
        value={targetName}
        onChange={({ target }) => onNameChange(target.value)}
      />
    </BaseResultSaving>
  );
}
ResultSavingInsight.defaultProps = {};
ResultSavingInsight.propTypes = {
  onNameChange: PropTypes.func.isRequired,
  targetName: PropTypes.string.isRequired
};

export default ResultSavingInsight;
