import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from './style';

/**
 * ModalFunnel
 * @component
 *
 */
function ModalHistoryFunnel(props) {
  const {
    children,
    label,
    handleValidation,
    validationLabel,
    handleClose,
    isOpen,
    hasButton,
    closeLabel
  } = props;
  React.useEffect(() => {}, [isOpen]);
  return (
    <BaseModal isOpen={isOpen} contentLabel={label} onClose={handleClose}>
      <div className="children">{children}</div>
      {hasButton ? (
        <div className="buttons-group">
          <button type="button" className="cancel" onClick={handleClose}>
            {closeLabel}
          </button>
          <button type="button" className="save" onClick={handleValidation}>
            {validationLabel}
          </button>
        </div>
      ) : null}{' '}
    </BaseModal>
  );
}
ModalHistoryFunnel.defaultProps = {
  isOpen: false,
  hasButton: true,
  closeLabel: 'Annuler',
  validationLabel: 'Valider'
};
ModalHistoryFunnel.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  hasButton: PropTypes.bool,
  label: PropTypes.string.isRequired,
  closeLabel: PropTypes.string,
  validationLabel: PropTypes.string
};

export default ModalHistoryFunnel;
