import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import { Link } from 'react-router-dom';

/**
 * @params isActive
 * @params nav
 *
 */
const BaseLink = styled(Link)`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  color: ${({ color, themeColors }) => color || themeColors.secondary};
  padding: ${({ padding }) => padding || 'auto'};
  display: inline-block;
  &.arrow-home {
    background-color: ${({ themeColors }) => themeColors.funnel};
    display: block;
    text-align: center;
    padding-bottom: 5px;
  }

  &.arrow-home.arrow-home--white {
    background-color: ${({ themeColors }) => themeColors.light};
  }

  &.arrow-home img {
    height: 25px;
  }
  &.test-link {
    font-size: 1.2em;
    cursor: pointer;
  }
  &:hover {
    text-decoration: underline;
  }
`;

/**
 * HrefLink
 *
 */
export const HrefLink = withTheme(styled.a`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  color: ${({ color, themeColors }) => color || themeColors.secondary};
  padding: ${({ padding }) => padding || 'auto'};
  display: inline-block;
  &.arrow-home {
    background-color: ${({ themeColors }) => themeColors.funnel};
    display: block;
    text-align: center;
    padding-bottom: 5px;
  }

  &.arrow-home.arrow-home--white {
    background-color: ${({ themeColors }) => themeColors.keep_light};
  }

  &.arrow-home img {
    height: 25px;
  }
  &.test-link {
    font-size: 1.2em;
    cursor: pointer;
  }
  &:hover {
    text-decoration: underline;
  }
`);

export default withTheme(BaseLink);
