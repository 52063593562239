import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Title from 'src/components/Title';
import { formatNumber } from 'src/utils/functions';
import Text from 'src/components/Text';
import Modal from 'Data/components/Modal';
import ButtonFill from 'Data/components/ButtonFill';
import { useTheme } from 'src/contexts/ThemeContext';

/**
 * ModalVerifFile
 * @component
 */
function ModalMatchingEnd({
  onNext,
  matchingPercentage,
  nbDigitized,
  nbRecords,
  ...props
}) {
  const { themeColors } = useTheme();

  return (
    <Modal
      {...props}
      onClose={onNext}
      style={{ width: '450px', backgroundColor: themeColors.primary }}
    >
      <Title small fontSize={24} style={{ color: themeColors.light }}>
        MATCHING DES PROFILS
      </Title>
      <Text style={{ color: themeColors.light }}>
        Suite à l’analyse de votre base de données, nous avons obtenu :
      </Text>
      <Text textAlign="center" style={{ color: themeColors.light }}>
        Un{' '}
        <span style={{ color: themeColors.data }}>
          taux de matching de{' '}
          <strong style={{ fontSize: '1.2em' }}>
            {formatNumber(matchingPercentage, 0)}%
          </strong>
        </span>{' '}
        sur les 25 millions de profils ERMES.
        <br />
        <br />
        Soit{' '}
        <strong style={{ color: themeColors.data, fontSize: '1.2em' }}>
          {formatNumber(nbDigitized, 0)} profils digitalisés
        </strong>{' '}
        sur {formatNumber(nbRecords, 0)} profils uploadés.
      </Text>
      <Text style={{ color: themeColors.light }}>
        Note : L’analyse se finissant en arrière-plan, ce taux est susceptible
        de varier de +/-3%.
      </Text>

      <Grid container justify="center" style={{ marginTop: '22px' }}>
        <ButtonFill width="213px" onClick={onNext}>
          TERMINER
        </ButtonFill>
      </Grid>
    </Modal>
  );
}
ModalMatchingEnd.defaultProps = {
  open: false,
  onClose: () => {},
  onNext: () => {}
};
ModalMatchingEnd.propTypes = {
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  open: PropTypes.bool,
  matchingPercentage: PropTypes.number.isRequired,
  nbDigitized: PropTypes.number.isRequired,
  nbRecords: PropTypes.number.isRequired
};

export default ModalMatchingEnd;
