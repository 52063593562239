import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Title from 'src/components/Title';
import Text from 'src/components/Text';
import Modal from 'Data/components/Modal';
import ButtonBorder from 'Data/components/ButtonBorder';
import ButtonFill from 'Data/components/ButtonFill';
import Table, { Tr, Td, Th } from 'Data/components/Table';
import SelectFileFieldType from 'Data/components/SelectFileFieldType';
import ColorLine from 'Data/components/ColorLine';
import { useData } from 'Data/contexts/DataContext';
import { updateHeaders } from 'Data/hooks/baseControl';
import { useTheme } from 'src/contexts/ThemeContext';
import Loading from 'src/components/Loading';

function Legend() {
  const { themeColors } = useTheme();
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
  return (
    <Grid container justify="flex-end">
      <div style={{ marginRight: '24px' }}>
        <div style={style}>
          <ColorLine type="success" />
          <Text style={{ marginLeft: '12px', color: themeColors.light }}>
            Champs identitiés
          </Text>
        </div>
      </div>
      <div style={{ marginRight: '24px' }}>
        <div style={style}>
          <ColorLine type="danger" />
          <Text style={{ marginLeft: '12px', color: themeColors.light }}>
            Champs à vérifier
          </Text>
        </div>
      </div>
    </Grid>
  );
}

/**
 * ModalVerifFile
 * @component
 */
function ModalVerifFile({ onClose, onNext, open, ...props }) {
  const {
    token,
    filename,
    currentBase: base,
    baseError: error,
    baseLoading: loading
  } = useData();
  const [newHeaders, setNewHeaders] = React.useState([]);
  const { themeColors } = useTheme();
  const onSelect = col => keySelected => {
    setNewHeaders(previousHeaders => {
      return previousHeaders.map(header => {
        if (col.column_id !== header.column_id) return header;
        return {
          ...header,
          column_name: keySelected
        };
      });
    });
  };

  function onNextWrapper() {
    updateHeaders(token, filename, newHeaders).then(onNext);
  }

  if (error) {
    return (
      <Modal
        open={open}
        {...props}
        onClose={onClose}
        style={{
          backgroundColor: themeColors.primary,
          color: themeColors.light
        }}
      >
        {error.message}
      </Modal>
    );
  }
  if (loading || !base.headers) {
    return (
      <Modal
        open={open}
        {...props}
        onClose={onClose}
        style={{
          backgroundColor: themeColors.primary,
          color: themeColors.light
        }}
      >
        <Loading loading src="/assets/img/loader_data.svg" />
      </Modal>
    );
  }
  const { headers, extract } = base;
  if (!newHeaders.length) setNewHeaders(headers);
  return (
    <Modal
      open={open}
      {...props}
      onClose={onClose}
      style={{ backgroundColor: themeColors.primary }}
    >
      <Title small fontSize={24} style={{ color: themeColors.light }}>
        VÉRIFIER VOTRE BASE DE DONNÉES
      </Title>
      <Text style={{ color: themeColors.light }}>
        Associez vos données pour les importer : certains champs ont déjà été
        identifiés, d’autres non.
        <br />
        Merci de bien vouloir vous assurer de la correspondance.
      </Text>
      <Text style={{ color: themeColors.light }}>
        Vos données seront hachées avant d’être importées.
      </Text>
      <Legend />
      <div style={{ maxWidth: '80vw', maxHeight: '45vh', overflow: 'scroll' }}>
        <Table
          style={{ color: themeColors.light, borderColor: themeColors.light }}
        >
          <thead>
            <Tr>
              {headers.map(header => (
                <Th
                  key={header.column_id}
                  type={header.column_score >= 50 ? 'success' : 'danger'}
                >
                  <SelectFileFieldType
                    selectedKey={header.column_name}
                    onChange={onSelect(header)}
                  />
                </Th>
              ))}
            </Tr>
          </thead>
          <tbody>
            {extract.map((col, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Tr key={idx}>
                {col.map((row, id) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Td key={id}>{row}</Td>
                ))}
              </Tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Grid container justify="space-around" style={{ marginTop: '22px' }}>
        <ButtonBorder style={{ width: '213px', margin: 0 }} onClick={onClose}>
          ANNULER
        </ButtonBorder>
        <ButtonFill width="213px" onClick={onNextWrapper}>
          SUIVANT
        </ButtonFill>
      </Grid>
    </Modal>
  );
}
ModalVerifFile.defaultProps = {
  open: false,
  onClose: () => {},
  onNext: () => {}
};
ModalVerifFile.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onNext: PropTypes.func
};

export default ModalVerifFile;
