import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import { withInsight } from 'Insight/contexts/InsightContext';
import { dataOrInsight } from 'src/utils/functions/insight';

/**
 * @component
 *
 */
const BaseContent = withTheme(styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${({ themeColors }) => themeColors.light};
  align-items: center;
  padding: 32px 32px 8px 32px;
`);

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 18px;
  font-weight: bold;
`;

export const CommandRecap = styled.div`
  width: 100%;
  margin: 8px 0px 8px 0px;
`;

export const CommandTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 16px;
`;

export const ContactRecap = styled.div`
  width: 100%;
`;

export const ContactLabel = withInsight(
  withTheme(styled.span`
    margin-left: 10%;
    font-size: 18px;
    font-weight: bold;
    color: ${({ themeColors, isData }) =>
      dataOrInsight(isData, themeColors, 'data')};
  `)
);

export const IDLabel = withInsight(
  withTheme(styled.span`
    font-size: 14px;
    font-weight: bold;
    color: ${({ themeColors, isData }) =>
      dataOrInsight(isData, themeColors, 'data')};
  `)
);

export const ContactNumber = styled.span`
  float: right;
  margin-right: 32px;
  font-size: 18px;
  font-weight: 900;
`;

export const CommandCriterias = withInsight(
  withTheme(styled.div`
    padding: 8px 32px;
    width: 100%;
    border-bottom: solid 4px
      ${({ themeColors, isData }) => dataOrInsight(isData, themeColors, 'data')};
    text-align: left;
  `)
);

export const OperatorLabel = withInsight(
  withTheme(styled.div`
    color: ${({ themeColors, isData }) =>
      dataOrInsight(isData, themeColors, 'data')};
    font-size: 18px;
    font-weight: 500;
    margin-left: 8px;
  `)
);

export default BaseContent;
