/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSegment } from 'Activation/contexts/SegmentContext';

import moment from 'utils/moment';
import constants from 'utils/constants';
import { useData } from 'Data/contexts/DataContext';

import Icon from 'utils/icons';
import { useModalActivation } from 'Activation/contexts/ModalActivationContext';

import TemplateImport from 'Activation/components/TemplateImport';
import TitleActivation from 'Activation/components/TitleActivation';
import LabelActivation from 'Activation/components/LabelActivation';
import TableActivation from 'Activation/components/TableActivation';
import ButtonActivation from 'Activation/components/ButtonActivation';
import SeparatorActivation from 'Activation/components/SeparatorActivation';

const HEADS = ['Nom du segment', 'Profils digitalisés', 'Date d’upload'];
const { routes } = constants;

const extractDigitilizing = base => {
  return (
    (base &&
      base.digitizing &&
      base.digitizing.total &&
      base.digitizing.total.nb_digitized) ||
    0
  );
};

/**
 *
 * ImportDataContainer
 *
 * @container
 *
 * @desc ::
 *      Dislayed in a modal called at the first page, allow to import Data
 *
 * Called by @see Activation/containers/AudiencesChoiceContainer
 * Displayed in @see Activation/containers/ModalSwitchContainer
 *
 * TODO:: Ne fonctionne pas, s'insipirer du fonctionnement de Data/HistoryDataContainer
 *
 * @return {null}
 */
function ImportDataContainer({ history }) {
  const { isTemplateData, closeModal } = useModalActivation();
  const { bases: temelioBases } = useData();
  const { setSegmentProfiles } = useSegment();
  const [selected, setSelected] = React.useState('');
  const handleSelect = base => setSelected(base);
  const handleAdd = () => history.push(routes.DATA);
  const handleNext = () => {
    const profiles = extractDigitilizing(selected);
    setSegmentProfiles(profiles);
    closeModal();
  };
  return !isTemplateData ? null : (
    <TemplateImport>
      <TitleActivation medium left bold>
        IMPORTEZ UN SEGMENT SAUVEGARDÉ À PARTIR DE VOS DONNÉES PROPRIÉTAIRES
      </TitleActivation>
      <LabelActivation>
        Sélectionnez un segment existant dans la liste ci-dessous :
      </LabelActivation>
      <TableActivation.Container>
        <TableActivation.RowHead length={HEADS.length} isHead>
          {HEADS.map(headTitle => (
            <TableActivation.Item key={headTitle} left>
              {headTitle}
            </TableActivation.Item>
          ))}
        </TableActivation.RowHead>
        <TableActivation.Body>
          {temelioBases.length === 0 && (
            <LabelActivation>
              <span>Aucun historique actuellement enregistré.</span>
            </LabelActivation>
          )}
          {temelioBases.length > 0 &&
            temelioBases.map(temelioBase => (
              <TableActivation.RowBody
                key={temelioBase._id}
                length={HEADS.length}
                selected={selected._id === temelioBase._id}
              >
                <TableActivation.Item
                  onClick={() => handleSelect(temelioBase)}
                  left
                >
                  {temelioBase.name}
                </TableActivation.Item>
                <TableActivation.Item
                  onClick={() => handleSelect(temelioBase)}
                  left
                >
                  {extractDigitilizing(temelioBase)}
                </TableActivation.Item>
                <TableActivation.Item
                  onClick={() => handleSelect(temelioBase)}
                  left
                >
                  {moment.unix(temelioBase.date_update).format('DD/MM/YYYY')}
                </TableActivation.Item>
              </TableActivation.RowBody>
            ))}
        </TableActivation.Body>
      </TableActivation.Container>
      <div className="row-import">
        <SeparatorActivation>OU</SeparatorActivation>
      </div>
      <div className="row-import">
        <ButtonActivation onClick={handleAdd}>
          <Icon icon="add" />
          <span>Nouveau segment</span>
        </ButtonActivation>
      </div>
      <div className="row-import">
        <small>
          <i>Vous serez redirigé vers le module DATA</i>
        </small>
      </div>
      <div className="row-import bottom">
        <ButtonActivation onClick={closeModal} inversed>
          <span>ANNULER</span>
        </ButtonActivation>
        <ButtonActivation onClick={handleNext} disabled={!selected._id}>
          <span>SUIVANT</span>
        </ButtonActivation>
      </div>
    </TemplateImport>
  );
}
ImportDataContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(ImportDataContainer);
