import Axios from 'axios';
import { sleep } from 'src/utils/functions';

export const STATUS_BASE_ANALYZED = 'analyzed';
export const STATUS_BASE_DIGITIZING = 'digitizing';
export const STATUS_BASE_DIGITIZED = 'digitized';

/**
 * Fetch a base by filename
 *
 * @param {string} token
 * @param {string} filename
 * @returns
 */
async function checkBase(token, filename) {
  return Axios.get(
    `${process.env.REACT_APP_TEMELIO_API_URL}/bases/${filename}`,
    {
      headers: {
        Authorization: `bearer: ${token}`
      }
    }
  )
    .then(response => response.data.base)
    .catch(() => null);
}

/**
 * Waits for a file to be ready
 *
 * @param {string} token
 * @param {string} filename
 * @param {string} status - base status that is considered ready
 * @returns
 *
 * @description
 *   No await in loop is suppressed because of this :
 *   https://eslint.org/docs/rules/no-await-in-loop
 */
export class Waiter {
  continue = true;

  constructor(token, filename, status, middleware = () => {}) {
    this.token = token;
    this.filename = filename;
    this.status = status;
    this.middleware = middleware;
  }

  start() {
    return new Promise(async resolve => {
      let nbTry = 1;
      let base = await checkBase(this.token, this.filename);
      let shouldStop = this.middleware(base, nbTry);
      if (shouldStop) return resolve(null);
      while (this.continue && (!base || base.status !== this.status)) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(3000);
        // eslint-disable-next-line no-await-in-loop
        base = await checkBase(this.token, this.filename);
        nbTry += 1;
        shouldStop = this.middleware(base, nbTry);
        if (shouldStop) return resolve(null);
      }
      return resolve(base);
    });
  }

  stop() {
    this.continue = false;
  }

  cancel() {
    this.continue = false;
  }
}

/**
 * Header in PUT request to change file headers
 *
 * @typedef Header
 * @property {number} column_id
 * @property {string} column_name
 * @property {number} column_score
 */

/**
 * Put header update for a given base
 *
 * @export
 * @param {string} token
 * @param {string} filename
 * @param {Header[]} headers
 * @returns {Promise<any>}
 */
export function updateHeaders(token, filename, headers) {
  return Axios.put(
    `${process.env.REACT_APP_TEMELIO_API_URL}/bases/${filename}`,
    { headers },
    {
      headers: {
        Authorization: `bearer: ${token}`
      }
    }
  )
    .then(response => response.data)
    .catch(() => null);
}

export { default as useTemelioBase } from './useTemelioBase';
