import styled from 'styled-components';
import constants from 'utils/constants';
import { withTheme } from 'contexts/ThemeContext';

const { fontSizes } = constants;

/**
 * BaseFooter
 * @component
 *
 */
const BaseFooter = withTheme(styled.footer`
  font-family: Poppins;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  background-color: ${({ color }) => color || 'transparent'};
  border-top: 2px solid ${({ themeColors }) => themeColors.secondary};
  a,
  pre,
  div {
    font-family: Poppins;
  }
  a,
  pre {
    opacity: 0.4;
    color: ${({ themeColors }) => themeColors.light};
    text-decoration: none;
  }
  font-size: ${fontSizes.small};
  @media (min-width: 1600px) {
    font-size: ${fontSizes.medium};
  }
`);
export default BaseFooter;
