import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

/**
 * Return type of useLogin hook
 *
 * @typedef UseLoginReturn
 * @property {boolean} loading
 * @property {Object} error
 * @property {string | null} token
 */

/**
 * Hook to handle login
 *
 * @export
 * @returns {UseLoginReturn}
 */
export function useLogin() {
  const { loading, error, data } = useQuery(gql`
    query getTokens {
      getTemelioToken {
        token
        refresh_token
        ttl
        role
      }
    }
  `);

  return {
    loading,
    error,
    token: data && data.getTemelioToken ? data.getTemelioToken.token : null
  };
}

export default useLogin;
