import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';

import { withTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';

const { fontFamilies } = constants;

export const TableAudience = withTheme(styled(Table)`
  && {
    margin-left: 2rem;
    width: calc(100% - 5rem);
    height: 30%;
    color: ${({ themeColors }) => themeColors.light};
    font-family: ${fontFamilies.default};
    p,
    div,
    span,
    th,
    td {
      color: ${({ themeColors }) => themeColors.light};
    }
  }
`);

export const TableCellAudience = withTheme(styled(TableCell)`
  && {
    padding: 0.8rem;
    border-bottom: 2px solid ${({ themeColors }) => themeColors.primary};
    text-align: center;
    font-family: ${fontFamilies.default};
  }
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &&.action_td {
    border: none;
  }
  &&.b-all {
    border: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  &&.b-left {
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  &&.b-right {
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-right: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  &&.b-top {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  &&.b-bottom {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  .kpi_title {
    margin: auto;
    text-align: left;
  }
`);

export const StyledButton = styled(Button)`
  && {
    background-color: ${({ color }) => color || 'white'};
  }
`;

export const AudienceGeneralTitle = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: '#4C4E58';
`;

export const SegmentNectarSubTitle = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4c4e58;
`;

export const ClickableRedirect = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
`;

export const TabSubTitle = styled.div`
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: '#4C4E58';
  margin-left: 10%;
`;

export const ConvSubTitle = styled.div`
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: '#4C4E58';
  margin-left: 1%;
`;

export const ConvRowColumn = styled.td`
  border-bottom: 1px solid #828282;
`;

export const ConvTable = styled.table`
  border-collapse: collapse;
  th {
    border-bottom: 1px solid #828282;
    padding: 10px;
  }
  tr {
    :hover {
      background-color: #f5f5f5;
    }
  }
`;

export const StyledInsightTab = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

export const InsightTable = styled.table`
  border-collapse: collapse;
`;

export const InsightTh = styled.th`
  border-bottom: 1px solid #4c4e58;
  border-right: ${({ corner }) => (corner ? '0px' : '1px')} solid #828282;
`;

export const InsightRowColumn = styled.td`
  border-bottom: 1px solid ${({ split }) => (split ? '#4C4E58' : '#C8C5C5')};
  border-right: ${({ corner }) => (corner ? '0px' : '1px')} solid #828282;
  height: ${({ split }) => (split ? '22px' : 'auto')};
`;

export const TableTitle = styled.div`
  margin-top: ${({ row }) => (row ? '25px' : '0')};
  padding: ${({ row }) => (row ? '0' : '10px')};
  color: #009fe3;
  font-family: ${({ row }) => (row ? 'Poppins' : 'Roboto')};
  font-style: normal;
  font-weight: ${({ row }) => (row ? '500' : 'bold')};
  font-size: 16px;
  line-height: ${({ row }) => (row ? '24px' : '19px')};
`;
