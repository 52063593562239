import styled from 'styled-components';
import constants from 'utils/constants';
import { withTheme } from 'contexts/ThemeContext';

const { fontSizes } = constants;

/**
 * @component
 *
 */
const BaseErrorPage = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh;
  justify-content: center;
  .parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 80%;
    padding-left: 10%;
  }
  h1 {
    color: ${({ themeColors }) => themeColors.light};
  }
  .message-row {
    color: ${({ themeColors }) => themeColors.light};
  }
  a {
    display: inline-flex;
    height: 50px;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 27px;
    text-decoration: none;
    color: ${({ themeColors }) => themeColors.primary};
    padding: 4px 16px;
    font-size: ${fontSizes.small};
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 16px;
  }
  svg {
    margin: auto;
  }
  @media (min-width: 1024px) {
    .parent {
      flex-direction: row;
    }
    svg {
      position: relative;
      left -70px;
    }
  }
`);

export default BaseErrorPage;
