import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'utils/moment';
import {
  PDFDownloadLink,
  Page,
  Text,
  Font,
  View,
  Image,
  Document
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import CSVDownload from './CSVDownload';
import styles from './style';

// Create styles
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/assets/Roboto-Regular.ttf',
      fontWeight: 'normal'
    },
    {
      src: '/assets/Roboto-Bold.ttf',
      fontWeight: 'bold'
    },
    {
      src: '/assets/Poppins-Regular.otf',
      fontWeight: 'normal'
    },
    {
      src: '/assets/Poppins-Bold.otf',
      fontWeight: 'bold'
    }
  ]
});

function PDFDownloadFunnel(props) {
  const { budget, connected, filter, funnel, id, name, objectifed } = props;

  const getAnomData = (connectedArray, allkpi, funnelObj) => {
    let nbAno = 0;
    connectedArray[3].data.map(i => {
      const date = moment(i.date);
      if (date >= moment(funnelObj.start) && date <= moment(funnelObj.end)) {
        if (i.anom !== 0) {
          nbAno += 1;
        }
      }
      return nbAno;
    });
    return nbAno;
  };

  // Create Document Component
  const synthese = CSVDownload.getsynthese(connected, id, filter);
  const analyse = CSVDownload.getanalyse(
    connected,
    id,
    filter,
    funnel,
    name,
    objectifed
  );
  const baseline = CSVDownload.getbaseline(connected, id, filter);
  const anomaly = getAnomData(connected, id, funnel);
  const dateStart = moment(funnel.start);
  const dateEnd = moment(funnel.end);
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.titleContent}>
          <Image
            style={styles.logo}
            src="/assets/img/detail-offer/logo/ermes-blue.png"
          />
          <Text style={styles.title} render={() => funnel.name} />
          <Text style={styles.area} render={() => 'Funnel'} />
        </View>
        <View style={styles.resumeContent}>
          <View style={styles.objectif}>
            <Text style={styles.uppercase} render={() => 'objectif mesuré: '} />
            <Text style={styles.bold} render={() => funnel.measure.name} />
          </View>
          <View style={styles.date}>
            <Text render={() => 'du '} />
            <Text
              style={styles.bold}
              render={() => dateStart.format('DD/MM/YYYY')}
            />
            <Text render={() => 'au '} />
            <Text
              style={styles.bold}
              render={() => dateEnd.format('DD/MM/YYYY')}
            />
          </View>
        </View>
        <View style={styles.budgetContent}>
          <View style={styles.prevu}>
            <Text>Budget prévu : </Text>
            <Text>{`${funnel.budget}€`}</Text>
          </View>
          <View style={styles.real}>
            <Text>Budget réel : </Text>
            <Text>{`${budget}€`}</Text>
          </View>
        </View>
        <View style={styles.subtitle}>
          <Text render={() => `Synthèse\n\n`} />
        </View>
        <View style={styles.syntheseContent}>
          <View style={styles.box}>
            <Text style={styles.blue}>
              {(synthese[3][1] / 1000).toFixed(0)}
            </Text>
            <Text>{`\nImpressions`}</Text>
          </View>
          <View style={styles.box}>
            <Text style={styles.blue}>
              {(synthese[4][1] / 1000).toFixed(0)}
            </Text>
            <Text>{`\n${funnel.measure.funnels[1].name}`}</Text>
          </View>
          <View style={styles.box}>
            <Text style={styles.blue}>
              {(synthese[5][1] / 1000).toFixed(0)}
            </Text>
            <Text>{`\n${funnel.measure.funnels[2].name}`}</Text>
          </View>
          <View style={styles.box}>
            <Text style={styles.blue}>
              {(synthese[6][1] / 1000).toFixed(0)}
            </Text>
            <Text>{`\n${funnel.measure.funnels[3].name}`}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.titleRow}>
            <View style={styles.void} />
            <View style={styles.cellLeft}>
              <Text>Réel</Text>
            </View>
            <View style={styles.cell}>
              <Text>Top</Text>
            </View>
            <View style={styles.cell}>
              <Text>Flop</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellLeft}>
              <Text>CPM</Text>
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[3][2]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[3][3]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[3][4]}€`} />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellLeft}>
              <Text>CPV</Text>
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[4][2]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[4][3]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[4][4]}€`} />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellLeft}>
              <Text>CPD</Text>
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[5][2]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[5][3]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[5][4]}€`} />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellLeft}>
              <Text>CPI</Text>
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[6][2]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[6][3]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${synthese[6][4]}€`} />
            </View>
          </View>
        </View>
        <View style={styles.subtitle}>
          <Text render={() => `Analyses\n`} />
        </View>
        <View style={styles.table}>
          <View style={styles.titleRow}>
            <View style={styles.cellLeft}>
              <Text>KPI</Text>
            </View>
            <View style={styles.cell}>
              <Text>Objectif</Text>
            </View>
            <View style={styles.cell}>
              <Text>Réel</Text>
            </View>
            <View style={styles.cell}>
              <Text>Impact {id[3]}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellLeft}>
              <Text render={() => `${analyse[3][0]}`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[3][1]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[3][2]}€`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[3][3]}%`} />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellLeft}>
              <Text render={() => `${analyse[4][0]}`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[4][1]}%`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[4][2]}%`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[4][3]}%`} />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellLeft}>
              <Text render={() => `${analyse[5][0]}`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[5][1]}%`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[5][2]}%`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[5][3]}%`} />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellLeft}>
              <Text render={() => `${analyse[6][0]}`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[6][1]}%`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[6][2]}%`} />
            </View>
            <View style={styles.cell}>
              <Text render={() => `${analyse[6][3]}%`} />
            </View>
          </View>
        </View>
        <View style={styles.baslineAnomTitle}>
          <Text style={styles.baselineTitle}>Baseline</Text>
          <Text style={styles.anomTitle}>Anomalies</Text>
        </View>
        <View style={styles.bottomContent}>
          <View style={styles.baselineDataContent}>
            <View style={styles.baselineDataTop}>
              <Text style={styles.blue}>{baseline[3][2]}</Text>
              <Text>{'\nadvertising'}</Text>
            </View>
            <View style={styles.baselineDataBottom}>
              <Text style={styles.grey}>{baseline[3][3]}</Text>
              <Text>{'\nbaseline'}</Text>
            </View>
          </View>
          <View style={styles.baselineDataTotal}>
            <Text style={styles.black}>{baseline[3][1]}</Text>
            <Text>{'\ninscrits au total'}</Text>
          </View>
          <View style={styles.cpihb}>
            <Text style={styles.blue}>{baseline[3][4]}€</Text>
            <Text>{'\nCPI hors baseline'}</Text>
          </View>
          <View />
          <View style={styles.anomalies}>
            <Text style={styles.blue}>{anomaly}</Text>
            <Text>{'\nanomalies'}</Text>
          </View>
        </View>
        <View style={styles.subtitle}>
          <Text>Distribution média</Text>
        </View>
        <View style={styles.distribMediaContainer}>
          <View style={styles.mediaBox}>
            <Text style={styles.blue}>5</Text>
            <Text>{'\nmédias'}</Text>
          </View>
          <View style={styles.mediaBox}>
            <Text style={styles.blue}>Facebook</Text>
            <Text>{'\nmeilleur CPM'}</Text>
          </View>
          <View style={styles.mediaBox}>
            <Text style={styles.blue}>Google</Text>
            <Text>{'\nplus gros budget'}</Text>
          </View>
          <View style={styles.mediaBox}>
            <Text style={styles.blue}>AppNexus</Text>
            <Text>{"\nle + d'impressions"}</Text>
          </View>
        </View>
        <View style={styles.footer}>
          <Text>document généré le {moment().format('DD/MM/YYYY')}</Text>
        </View>
      </Page>
    </Document>
  );
}

const connectedPropType = PropTypes.arrayOf(
  PropTypes.shape({
    connector: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      src: PropTypes.string,
      sso: PropTypes.string
    }),
    data: PropTypes.shape({}),
    fetched: PropTypes.bool,
    funnelId: PropTypes.string
  })
);

PDFDownloadFunnel.propTypes = {
  budget: PropTypes.number.isRequired,
  connected: connectedPropType.isRequired,
  filter: PropTypes.func.isRequired,
  funnel: PropTypes.shape({
    anom: PropTypes.arrayOf(PropTypes.shape({})),
    budget: PropTypes.number,
    connected: connectedPropType,
    createdAt: PropTypes.number,
    end: PropTypes.string,
    forecast: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string,
    measure: PropTypes.shape({
      name: PropTypes.string
    }),
    name: PropTypes.string,
    newFormat: PropTypes.shape({}),
    objectifed: PropTypes.arrayOf(PropTypes.shape({})),
    saved: PropTypes.bool,
    start: PropTypes.string,
    updatedAt: PropTypes.number,
    userId: PropTypes.number
  }).isRequired,
  id: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  objectifed: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export const createAndDownloadPDF = (pdfContent, filename, divId) => {
  setTimeout(() => {
    const link = (
      <div id={divId}>
        <PDFDownloadLink document={pdfContent} fileName={filename} />
      </div>
    );
    const elem = document.createElement('div');
    document.getElementById('root').appendChild(elem);
    ReactDOM.render(link, elem);
    setTimeout(() => {
      document.getElementById(divId).children[0].click();
      elem.remove();
    }, 1);
  }, 1);
};

export const CSVDownloadFunnel = CSVDownload;

export default PDFDownloadFunnel;
