import { gql } from 'apollo-boost';

export default {
  SEND_EMAIL: gql`
    mutation sendEmail($input: SendEmailInput!) {
      sendEmail(input: $input)
    }
  `,
  GET_INSIGHTS: gql`
    query insightTargets($limit: Int) {
      insightTargets(limit: $limit) {
        id
        name
        result {
          nbProfiles
        }
        createdAt
      }
    }
  `,
  GET_TAG_FUNNELS: gql`
    query tagFunnels {
      tagFunnels {
        id
        name
        funnelLevels {
          level
          label
        }
        superSegmentLevels {
          levelStart
          levelEnd
          label
        }
      }
    }
  `
};
