import React from 'react';

import HomeData from './containers/HomeData';

/**
 * AppData
 * @component
 */
function AppData() {
  return <HomeData />;
}

export default AppData;
