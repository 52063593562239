import React from 'react';
import PropTypes from 'prop-types';
import HistoryWrapper from './style';

/**
 * @component
 *
 */
function History(props) {
  const { children } = props;
  return <HistoryWrapper>{children}</HistoryWrapper>;
}

History.defaultProps = {
  children: undefined
};
History.propTypes = {
  children: PropTypes.node
};

export default History;
