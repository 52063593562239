import styled from 'styled-components';
import Text from 'components/Text';
import constants from 'utils/constants';

const { colors, fontSizes } = constants;

/**
 * BaseTextInsight
 * @component
 *
 */
const BaseTextInsight = styled(Text)`
  font-size: ${fontSizes.medium};
  text-align: center;
  color: ${colors.light};
  font-weight: bold;
`;

export default BaseTextInsight;
