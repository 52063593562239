import React from 'react';
import PropTypes from 'prop-types';

import BaseSeparator, { SeparatorWrapper } from './style';

/**
 * Separator
 * @component
 *
 */
function Separator(props) {
  const { children, color, className } = props;
  const styledProps = { color };
  if (!children) {
    return <BaseSeparator {...styledProps} className={className} />;
  }
  return (
    <SeparatorWrapper className={className}>
      <BaseSeparator {...styledProps} />
      {children}
      <BaseSeparator {...styledProps} />
    </SeparatorWrapper>
  );
}
Separator.defaultProps = {
  children: undefined,
  color: '',
  className: ''
};
Separator.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  className: PropTypes.string
};

export default Separator;
