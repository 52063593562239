import { gql } from 'apollo-boost';

export default {
  GET_TEMELIO_TOKEN: gql`
    query getTokens {
      getTemelioToken {
        token
        refresh_token
        ttl
        role
      }
    }
  `
};
