import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import Table, { TableRow, TableRowItem, TableBody } from 'components/Table';
import { withInsight } from 'Insight/contexts/InsightContext';
import { dataOrInsight } from 'utils/functions/insight';

const TableInsight = withInsight(styled(Table)`
  border-bottom-color: ${({ themeColors, isData }) =>
    dataOrInsight(isData, themeColors, 'data')};
`);

/**
 * TableRowInsight
 * @component
 *
 */
export const TableRowInsight = withInsight(
  withTheme(styled(TableRow)`
    color: ${({ themeColors, isData }) =>
      dataOrInsight(isData, themeColors, 'data')};
    border-bottom-color: ${({ isHead, themeColors, isData }) =>
      isHead ? dataOrInsight(isData, themeColors, 'data') : themeColors.light};
  `)
);

/**
 * TableItemInsight
 * @component
 *
 */
export const TableItemInsight = styled(TableRowItem)`
  && {
    justify-content: center;
  }
`;

export const TableBodyInsight = styled(TableBody)``;

export default withTheme(TableInsight);
