import React from 'react';
import styled from 'styled-components';
import Modal from 'components/Modal';
import { useTheme, withTheme } from 'contexts/ThemeContext';

// Modal use react-modal, "style" must be defined separately
// @see src/components/Modal
const getStyles = themeColors => ({
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.6)'
  },
  content: {
    opacity: '1',
    position: 'relative',
    left: '-25px',
    background: themeColors.tertiary,
    border: `1px solid ${themeColors.tertiary}`,
    borderRadius: '12px',
    outline: 'none',
    width: '26vw',
    height: 'auto',
    minWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  }
});

// Don't stylize modal here, do it in "style" object, do it above
const StyledModal = withTheme(styled(Modal)`
  outline: none;
  .children {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .buttons-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }
  button {
    border-radius: 8px;
    padding: 4px;
    outline: none;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5em 1em;
    margin: 0.5em 0.5em;
    border-radius: 20px;
  }
  button.save {
    background-color: ${({ themeColors }) => themeColors.light};
    color: ${({ themeColors }) => themeColors.dark};
    border: 2px solid ${({ themeColors }) => themeColors.dark};
    padding: 0.5em 2em;
  }
  button.cancel {
    background-color: ${({ themeColors }) => themeColors.tertiary};
    color: ${({ themeColors }) => themeColors.light};
    border: 2px solid ${({ themeColors }) => themeColors.light};
    padding: 0.5em 1.5em;
  }
`);

const BaseModal = props => {
  const { themeColors } = useTheme();
  const styles = getStyles(themeColors);
  return <StyledModal {...props} style={styles} />;
};

export default BaseModal;
