import React from 'react';
import PropTypes from 'prop-types';
import { BaseContent, Title } from './style';

function DeleteModalContent(props) {
  const { name } = props;
  return (
    <BaseContent>
      <Title>Suppression</Title>
      <div className="description">{`Êtes-vous sûre de vouloir supprimer la campagne "${name}" ?`}</div>
    </BaseContent>
  );
}

DeleteModalContent.propTypes = {
  name: PropTypes.string.isRequired
};

export default DeleteModalContent;
