export default {
  notFound: {
    title: 'Oups, la page demandée est introuvable !',
    messageRows: [
      'Il semblerait que la page n’existe pas ou est cassée.',
      ' Veuillez nous excuser pour la gêne occasionnée.'
    ],
    image: 'blindMan' // @see utils/icons
  },
  serverError: {
    title: 'Erreur Serveur',
    messageRows: [
      'Notre équipe travaille actuellement pour créer quelque chose de mieux',
      'et révolutionner le monde des médias !',
      '',
      'Veuillez nous excuser pour la gène occasionée.'
    ],
    image: 'spaceMan' // @see utils/icons
  }
};
