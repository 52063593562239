import styled from 'styled-components';
import Table, { TableRowItem, TableRow, TableBody } from 'components/Table';
import { withTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';

const { fontSizes } = constants;

/**
 * BaseTableItem
 * @component
 *
 */
export const BaseTableItem = styled(TableRowItem)`
  font-size: ${fontSizes.small};
  font-weight: ${({ mutable }) => (mutable ? 'normal' : '600')};
  text-overflow: ellipsis;
  overflow: ${({ overflow }) => overflow || 'hidden'};
  white-space: nowrap;
  justify-content: center;
  padding-left: 10%;
  &.left {
    justify-content: flex-start !important;
    padding-left: 10% !important;
  }
`;

/**
 * TableRowHead
 * @component
 *
 */
export const TableRowHead = withTheme(styled(TableRow)`
  font-weight: bold;
  display: grid;
  height: 58px;
  background-color: ${({ bgcolor }) => bgcolor};
  border: 1px solid ${({ borderbottomcolor }) => borderbottomcolor};
  border-bottom-width: 2px;
  border-bottom: 1px solid ${({ borderbottomcolor }) => borderbottomcolor};
  ${BaseTableItem} {
    overflow: initial;
  }
`);

/**
 * TableRowBody
 * @component
 *
 */
export const TableRowBody = withTheme(styled(TableRow)`
  display: grid;
  grid-auto-flow: column;
  height: 44px;
  background-color: ${({ bgcolor }) => bgcolor};
  border-left: 3px solid ${({ borderleftcolor }) => borderleftcolor};
  border-radius: 4px;
  :hover {
    background-color: ${({ themeColors }) => themeColors.secondary};
  }
`);

/**
 * TableContainerBody
 * @component
 *
 */
export const TableContainerBody = withTheme(styled(TableBody)`
  height: 76%;
  max-height: 180px;
  overflow: auto;
`);

/**
 * TableContainer
 * @component
 *
 */
export const TableContainer = withTheme(styled(Table)`
  max-height: ${({ maxheight }) => maxheight || '240px'};
  height: auto;
  margin: 0;
  min-height: 60px;
  background-color: transparent;
  border: none;
  border-radius: 1px;
`);

export default {};
