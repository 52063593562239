import React from 'react';

const FunnelGraphContext = React.createContext();

function FunnelGraphProvider(props) {
  const [allkpi, setAllkpi] = React.useState([]);
  const [allname, setAllname] = React.useState([]);
  const [graphTab, setGraphTab] = React.useState(0);
  const [graphSubTab, setSubTab] = React.useState('Synthèse');
  const [conversionRate, setConversionRate] = React.useState([]);
  const [worstlevierCPI, setWorsLevierCPI] = React.useState('');
  const value = {
    allkpi,
    setAllkpi,
    allname,
    setAllname,
    graphTab,
    setGraphTab,
    setSubTab,
    graphSubTab,
    conversionRate,
    worstlevierCPI,
    setWorsLevierCPI,
    addConversionRate(conversion) {
      setConversionRate(prevState => [...prevState, conversion]);
    }
  };
  return <FunnelGraphContext.Provider {...props} value={value} />;
}

export const useFunnelGraph = () => {
  const context = React.useContext(FunnelGraphContext);
  if (!context)
    throw new Error('useFunnelGraph must be called in FunnelGraphProvider');
  return context;
};

export default FunnelGraphProvider;
