import styled from 'styled-components';
import { Button } from '@material-ui/core';
import constants from 'utils/constants';

const { colors, fontSizes, fontFamilies } = constants;

/**
 * @params bgColor
 * @params color
 * @params width
 * @params disablehover
 *
 */
const Buttons = styled(Button)`
  && {
    background-color: ${({ bgcolor, themeColors }) =>
      bgcolor || themeColors.secondary};
    color: ${({ customcolor, themeColors }) =>
      customcolor || themeColors.secondary};
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    border-radius: 50px;
    padding: 6px 15px;
    font-family: ${fontFamilies.default};
  }
  &&:hover {
    background-color: ${({ bgcolor, disablehover, themeColors }) =>
      disablehover && (bgcolor || themeColors.secondary)};
  }
  white-space: nowrap;
`;

export const BaseButtonModal = styled.button`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 27px;
  border: 2px solid ${colors.light};
  color: ${({ color }) => color};
  cursor: pointer;
  display: inline-flex;
  font-size: ${fontSizes.extraSmall};
  font-weight: bold;
  margin: 4px 8px 0px;
  outline: none;
  padding: 3px 5px;
  text-transform: uppercase;
  &:hover {
    opacity: 0.7;
  }
  @media (min-width: 1024px) {
    margin: 0 4px;
    padding: 8px 6px;
  }
  @media (min-width: 1440px) {
    font-size: ${fontSizes.small};
    padding: 8px 12px;
    margin: 0 12px;
  }
  @media (min-width: 1800px) {
    font-size: ${fontSizes.medium};
    margin: 0 10px;
  }
`;

export default Buttons;
