import React from 'react';
import PropTypes from 'prop-types';

import { Style, doneButton, modalHeader } from './style';

export default function UploadCSVFormContent(props) {
  const { handleCsv, cancel, closeModal } = props;

  return (
    <React.Fragment>
      <div style={modalHeader} className="modal-header">
        <h5
          style={{
            textAlign: 'center',
            fontSize: '20px'
          }}
          className="modal-title align_center"
        >
          Uploader un fichier CSV
        </h5>
      </div>
      <div
        style={{
          border: '1px dashed #a0a0a0',
          borderRadius: '10px',
          textAlign: 'center',
          marginTop: '20px'
        }}
      >
        <Style>
          <label htmlFor="csv">
            Sélectionner un CSV
            <input
              type="file"
              className="input"
              name="csv"
              id="csv"
              onChange={e => handleCsv(e)}
              required
            />
          </label>
        </Style>
        <div
          className="pull-right"
          style={{ margin: '15px', cursor: 'pointer' }}
        >
          <a
            className="link"
            onClick={cancel}
            onKeyPress={cancel}
            role="button"
            tabIndex="0"
          >
            Annuler
          </a>
        </div>
      </div>
      <button
        style={doneButton}
        className="btn"
        onClick={closeModal}
        type="button"
      >
        J’ai fini
      </button>
    </React.Fragment>
  );
}

UploadCSVFormContent.propTypes = {
  handleCsv: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};
