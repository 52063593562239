import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';

import Section from './index';

const { landingRoles } = constants;
const { details, welcome, description } = landingRoles;
const getBGColor = (role, themeColors) => {
  if (role === welcome) return 'black';
  if (role === description) return themeColors.funnel;
  if (role === details) return themeColors.primary;
  return 'transparent';
};

/**
 *
 *
 * SectionLanding
 * @param {Object} props
 *
 *
 *
 */
function SectionLanding(props) {
  const { role, fullWidth } = props;
  const { themeColors } = useTheme();
  const bgColor = getBGColor(role, themeColors);
  return (
    <Section
      {...props}
      type="landing"
      bgColor={bgColor}
      color={themeColors.keep_light}
      justifyContent="center"
      padding={fullWidth ? '0' : '0 24px'}
      alignItems="center"
      minHeight={fullWidth ? '' : 'calc(100vh - 30px)'}
      containerProps={{ maxWidth: fullWidth ? false : 'lg' }}
    />
  );
}

SectionLanding.defaultProps = {
  role: '',
  fullWidth: false
};

SectionLanding.propTypes = {
  role: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default SectionLanding;
