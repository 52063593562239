import React from 'react';

import {
  DefaultButtonInsight,
  UnColoredButton,
  ButtonDeleteInsight
} from './style';

/**
 * ButtonInsight
 * @component
 *
 *
 */
function ButtonInsight(props) {
  return <DefaultButtonInsight {...props} />;
}
ButtonInsight.defaultProps = {};
ButtonInsight.propTypes = {};
export default ButtonInsight;

/** @see ResultContainer  */
export const ActionButton = props => <UnColoredButton {...props} />;

/**
 * ButtonInsightDelete
 * @see HistoryInsightContainer
 *
 */
export const ButtonInsightDelete = props => <ButtonDeleteInsight {...props} />;
