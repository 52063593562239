import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  TableCellSynthesis,
  TableRowSynthesis,
  InputSynthesis,
  ConversionRateSpan,
  ConversionContent,
  Reel
} from 'Funnel/components/FunnelRow/style';
import { formatNumber } from 'utils/functions';
import LinearProgressSynthesis from 'Funnel/components/FunnelRow/LinearProgressSynthesis';
import moment from 'moment';

/**
 * FunnelRow
 * @component
 *
 */
function FunnelRow(props) {
  const {
    funnelIndex,
    funnelRowData,
    connected,
    cr,
    funnel,
    id,
    name,
    objectif,
    objectifable,
    setObjectif
  } = props;
  const [value, setValue] = React.useState('');
  const [active, setActive] = React.useState(false);

  const handleChange = event => {
    setValue(event.target.value);
    setActive(event.target.value !== '');
  };

  const handleFocusOut = event => {
    setObjectif(id, event.target.value);
  };

  const handleBlur = event => {
    const temp = parseFloat(event.target.value.replace(',', '.')).toFixed(2);
    setObjectif(id, temp);
  };
  const show =
    _.findIndex(
      connected,
      c => (c.childId === id || c.funnelId === id) && c.fetched
    ) >= 0;

  React.useEffect(() => {
    if (objectif !== value) {
      setValue(objectif || '');
      setActive(objectif !== '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectif, connected, funnel.start, funnel.end, funnelRowData]);

  if (name === 'Baseline') return null;
  let className =
    funnelRowData && funnelRowData.real <= value ? 'success' : 'alert';
  if (!value) className = '';
  return (
    <TableRowSynthesis
      className={show ? 'connected_row' : ''}
      conversionRate={cr || null}
    >
      <ConversionContent>
        <ConversionRateSpan display={cr}>
          {cr !== null && cr > 0 ? formatNumber(cr) : ''}%
        </ConversionRateSpan>
      </ConversionContent>
      <TableCellSynthesis className="b-top b-right b-bottom connector_td">
        <p className="kpi_title">{name}</p>
        <LinearProgressSynthesis
          variant="determinate"
          value={100 - funnelIndex * 25}
          textValue={
            funnelRowData.volume ? funnelRowData.volume.toLocaleString() : '0'
          }
        />
      </TableCellSynthesis>
      <TableCellSynthesis className={`b-all ${active ? className : ''}`}>
        <div className="vertical">
          <span>{id}</span>

          <Reel objectif={value} real={funnelRowData.real}>
            {funnelRowData.real !== null && funnelRowData.real >= 0
              ? formatNumber(funnelRowData.real)
              : ''}
            €
          </Reel>
        </div>
      </TableCellSynthesis>
      <TableCellSynthesis className="b-all">
        <div className="vertical">
          <span>{id}</span>
          <strong>
            {funnelRowData.top !== null && funnelRowData.top >= 0
              ? formatNumber(funnelRowData.top)
              : ''}
            €
          </strong>
        </div>
      </TableCellSynthesis>
      <TableCellSynthesis className="b-all">
        <div className="vertical">
          <span>{id}</span>
          <strong>
            {funnelRowData.flop !== null && funnelRowData.flop >= 0
              ? formatNumber(funnelRowData.flop)
              : ''}
            €
          </strong>
        </div>
      </TableCellSynthesis>
      <TableCellSynthesis
        className={`b-top b-left b-bottom ${active ? 'active' : ''}`}
      >
        <p style={{ display: objectifable ? 'block' : 'none' }}>
          <div className="b-all vertical">
            <span className="buying_type">{id}</span>
            <span>
              <InputSynthesis
                type="text"
                className="input-row"
                value={value}
                onChange={handleChange}
                onFocusOut={handleFocusOut}
                onBlur={handleBlur}
              />
              €
            </span>
          </div>
        </p>
      </TableCellSynthesis>
      <TableCellSynthesis className="action_td">
        <div
          className="action_td_container"
          style={{ display: show ? 'block' : 'none' }}
        />
      </TableCellSynthesis>
    </TableRowSynthesis>
  );
}
FunnelRow.defaultProps = {
  cr: null,
  funnelRowData: {},
  funnelIndex: -1,
  objectif: '',
  objectifable: true
};

FunnelRow.propTypes = {
  connected: PropTypes.arrayOf(
    PropTypes.shape({
      childId: PropTypes.string,
      funnelId: PropTypes.string,
      fetched: PropTypes.bool
    })
  ).isRequired,
  cr: PropTypes.number,
  funnelRowData: PropTypes.shape({
    real: PropTypes.number,
    volume: PropTypes.number,
    top: PropTypes.number,
    flop: PropTypes.number
  }),
  funnel: PropTypes.shape({
    start: PropTypes.oneOfType([
      PropTypes.instanceOf(moment),
      PropTypes.string
    ]),
    end: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string])
  }).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  objectif: PropTypes.string,
  objectifable: PropTypes.bool,
  funnelIndex: PropTypes.number,
  setObjectif: PropTypes.func.isRequired
};

export default FunnelRow;
