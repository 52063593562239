import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React from 'react';
import constants from 'utils/constants';
import ModalHistoryData from 'Data/components/ModalHistoryData';
import moment from 'moment';
import dataStatus from 'utils/constants/dataStatus';
import { formatNumber } from 'utils/functions/index';
import { getFilenameFromHistory } from 'Data/utils/functions';
import { getMatching } from 'utils/functions/insight';
import DeleteModalContent from './DeleteModalContent';
import TableData, {
  Buttons,
  Icons,
  TableRowData,
  TableBodyData,
  TableItemData
} from './style';

const { routes } = constants;
const TABLE_LENGTH = 7;
/**
 * HistoryData
 * @component
 * @see HistoryDataContainer
 *
 */
function HistoryData(props) {
  const { children } = props;
  return (
    <TableData>
      <TableRowData isHead length={TABLE_LENGTH}>
        <TableItemData>
          Nom du
          <br />
          fichier uploadé
        </TableItemData>
        <TableItemData>
          Date
          <br />
          d&apos;upload
        </TableItemData>
        <TableItemData>Statut</TableItemData>
        <TableItemData>
          Profils
          <br />
          uploadés
        </TableItemData>
        <TableItemData>
          Profils
          <br />
          digitalisés
        </TableItemData>
        <TableItemData>
          Taux de
          <br />
          matching
        </TableItemData>
        <TableItemData>Actions</TableItemData>
      </TableRowData>
      <TableBodyData>{children}</TableBodyData>
    </TableData>
  );
}
HistoryData.defaultProps = {
  children: undefined
};
HistoryData.propTypes = {
  children: PropTypes.node
};

/**
 * BaseHistoryItem
 * @component
 * @see HistoryDataContainer
 *
 */
const BaseHistoryItem = props => {
  const { data, history, deleteData } = props;
  const localisation = `${routes.DATA}/${data.name}`;
  const [deleteClicked, setDeleteClicked] = React.useState(false);
  const closeModal = () => {
    setDeleteClicked(false);
  };
  const handleClick = () => {
    history.push(localisation);
  };
  const onDelete = () => {
    deleteData(data).then(() => {
      closeModal();
    });
  };
  const done = data.status === 'digitized';
  return (
    <TableRowData length={TABLE_LENGTH}>
      <TableItemData handleClick={handleClick}>
        {getFilenameFromHistory(data.name)}
      </TableItemData>
      <TableItemData handleClick={handleClick}>
        {moment.unix(data.date_create).format('DD/MM/YYYY')}
      </TableItemData>
      <TableItemData handleClick={handleClick}>
        {dataStatus.history[data.status || 'digitized']}
      </TableItemData>
      <TableItemData handleClick={handleClick}>
        {done ? formatNumber(data.digitizing.total.nb_records, 0) : 'N/A'}
      </TableItemData>
      <TableItemData handleClick={handleClick}>
        {done ? formatNumber(data.digitizing.total.nb_digitized, 0) : 'N/A'}
      </TableItemData>
      <TableItemData handleClick={handleClick}>
        {done ? `${formatNumber(getMatching(data))}%` : 'N/A'}
      </TableItemData>
      <TableItemData center>
        <Buttons onClick={() => setDeleteClicked(true)} type="button">
          <Icons icon="trash" />
          Supprimer
        </Buttons>
      </TableItemData>
      <ModalHistoryData
        isOpen={deleteClicked}
        handleClose={closeModal}
        handleValidation={onDelete}
        validationLabel="Supprimer"
        label="Supprimer Data historique"
      >
        <DeleteModalContent name={data.name} />
      </ModalHistoryData>
    </TableRowData>
  );
};
BaseHistoryItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  deleteData: PropTypes.func.isRequired
};

export const HistoryDataItem = withRouter(BaseHistoryItem);

export default HistoryData;
