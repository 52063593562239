import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';

const { fontFamilies } = constants;

export const TableSynthesis = withTheme(styled(Table)`
  && {
    margin-left: 2rem;
    width: calc(100% - 2rem);
    color: ${({ themeColors }) => themeColors.light};
    font-family: ${fontFamilies.default};
    p,
    div,
    span,
    th,
    td {
      color: ${({ themeColors }) => themeColors.light};
    }
  }
`);

export const TableBodySynthesis = styled(TableBody)``;

export const TableHeadSynthesis = styled(TableHead)`
  th {
    font-weight: 600;
    font-size: 1rem;
    font-family: ${fontFamilies.default};
  }
`;

export const TableCellSynthesis = withTheme(styled(TableCell)`
  && {
    padding: 0.8rem;
    border-bottom: 2px solid ${({ themeColors }) => themeColors.primary};
    text-align: center;
    font-family: ${fontFamilies.default};
  }
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &&.action_td {
    border: none;
  }
  &&.b-all {
    border: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  &&.b-left {
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  &&.b-right {
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-right: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  &&.b-top {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  &&.b-bottom {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid ${({ themeColors }) => themeColors.primary};
  }
  .kpi_title {
    text-align: left;
  }
`);

const TableRowWrapper = ({ conversionRate, ...rest }) => {
  return <TableRow {...rest} />;
};
TableRowWrapper.defaultProps = {
  conversionRate: null
};
TableRowWrapper.propTypes = {
  conversionRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const TableRowSynthesis = styled(TableRowWrapper)``;

export const InputSynthesis = withTheme(styled.input`
   {
    width: 3.6rem;
    border: 1px solid ${({ themeColors }) => themeColors.funnel};
    border-radius: 0.5rem;
    font-family: ${fontFamilies.default};
    height: 1.8rem;
    text-align: center;
    overflow: hidden;
    margin: 0.4rem;
    background-color: ${({ themeColors }) => themeColors.primary};
    color: ${({ themeColors }) => themeColors.light};
  }
`);

export const Image = styled.div``;

export const ConversionContent = styled.span`
  display: flex;
  justify-content; flex-end;
`;

const SpanWrapper = ({ display, ...rest }) => {
  return <span {...rest} />;
};
SpanWrapper.defaultProps = {
  display: false
};
SpanWrapper.propTypes = {
  display: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};

export const ConversionRateSpan = withTheme(styled(SpanWrapper)`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  position: relative;
  top: -20px;
  width: 4rem;
  height: 4rem;
  line-height: 50px;
  border-radius: 50%;
  background-color: ${({ themeColors }) => themeColors.primary};
  font-weight: bold;
  text-align: center;
  border: 1px solid ${({ themeColors }) => themeColors.funnel};
  font-size: 14px;
}`);

export const Content = withTheme(styled.span`
  margin: auto;
  color: ${({ themeColors }) => themeColors.keep_light} !important;
`);

export const LinearBar = withTheme(styled.div`
  border-radius: 7px;
  display: flex;
  background-color: ${({ themeColors }) => themeColors.funnel};
  width: ${props => props.value}%;
  height: 30px;
`);

const chooseColor = (real, obj, themeColors) => {
  if (!obj || parseFloat(real) === parseFloat(obj)) return themeColors.light;
  return parseFloat(real) < parseFloat(obj) ? 'green' : 'red';
};

export const Reel = withTheme(styled.strong`
  color: ${({ real, objectif, themeColors }) =>
    chooseColor(real, objectif, themeColors)};
`);

export const Date = withTheme(styled.div`
  margin-left: 2rem;
  color: ${({ themeColors }) => themeColors.light};
  white-space: pre-wrap;
`);
