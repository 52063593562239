import moment from 'utils/moment';

/**
 * ActivationSegment
 * @constructor
 * @desc :::
 *      Main Object used accross containers
 *
 */
export default function ActivationSegment(defaultMeasure, activation = null) {
  this.id = (activation && activation.id) || null;
  this.name = (activation && activation.name) || null;
  this.filters = (activation && activation.filters) || {};
  this.budget = parseFloat((activation && activation.budget) || '0');
  this.campaignRatios = {
    video: (activation && activation.campaignRatios.video) || 0.25,
    video_premium:
      (activation && activation.campaignRatios.video_premium) || 0.25,
    banniere: (activation && activation.campaignRatios.banniere) || 0.25,
    banniere_impact:
      (activation && activation.campaignRatios.banniere_impact) || 0.25
  };
  this.endDate = moment((activation && activation.endDate) || new Date());
  this.funnelRates = defaultMeasure.kpiList;
  this.objective = (activation && activation.objective) || '';
  this.nexusSize = (activation && activation.nexusSize) || 0;
  this.profiles = (activation && activation.profiles) || 0;
  this.startDate = moment((activation && activation.startDate) || new Date());
  this.transformationRates =
    (activation && activation.transformationRates) || {};
}
