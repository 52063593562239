import React from 'react';
import constants from 'utils/constants';
import PropTypes from 'prop-types';

import ActivationSegment from './constructors';
import getResults from './calculs';

const { measures } = constants;

const SegmentContext = React.createContext();
const ACTIVATION_MEASURES = measures.filter(({ kpiList }) => kpiList.length);

/**
 * SegmentProvider
 * @component
 * @context
 *
 */
function SegmentProvider(props) {
  const { fromHistoryActivation } = props;
  const defaultMeasure = ACTIVATION_MEASURES[0];
  const [segment, setSegment] = React.useState(
    new ActivationSegment(defaultMeasure, fromHistoryActivation || null)
  );
  const activationUpdate = async activationUpdated => {
    setSegment(activationUpdated);
  };
  const value = {
    /** segment @see ./constructors */
    ...segment,
    activation: segment,
    setActivation: activationUpdate,
    results: getResults(segment),
    activationMeasures: ACTIVATION_MEASURES,
    /** @param {Number} */
    setSegmentProfiles(nbProfiles) {
      const profiles = parseInt(nbProfiles, 10) || 0;
      setSegment({
        ...segment,
        profiles,
        nexusSize: 0.65 * profiles,
        facebookSize: 0.5 * profiles,
        vd360Size: 0.55 * profiles
      });
    },
    /** @param {String || Number} */
    setBudget(price) {
      let budget = price;
      if (typeof budget === 'string') {
        budget = parseFloat(price.replace(/\s/g, ''));
      }
      setSegment({ ...segment, budget });
    },
    /** @param {String} */
    setObjective(objective) {
      setSegment({
        ...segment,
        objective,
        funnelRates: measures.find(({ name }) => name === objective).kpiList
      });
    },
    /**
     * @param {Number} num
     * @param {Number} rate
     *
     */
    setTansformationRate({ num, rate }) {
      const { transformationRates } = segment;
      const level = `level${num}`;
      setSegment({
        ...segment,
        transformationRates: {
          ...transformationRates,
          [level]: rate
        }
      });
    },
    /**
     * setPeriod
     * @see components/DateRangePicker
     *
     */
    setPeriod({ startDate, endDate }) {
      const sDate = startDate;
      let eDate = endDate;
      if (endDate - startDate < 0) {
        eDate = sDate;
      }
      setSegment({ ...segment, startDate: sDate, endDate: eDate });
    },
    /**
     * setCampaignRatios
     * @param {string} key key to change into teh object campaignRatios
     * @param {number} val
     */
    setCampaignRatios(key, val) {
      setSegment({
        ...segment,
        campaignRatios: { ...segment.campaignRatios, [key]: val }
      });
    },
    /**
     * setFilters
     * @param {Object} filters insight or data filters
     */
    setFilters(filters) {
      setSegment({ ...segment, filters });
    },
    /**
     * setId
     * @param {string} id campaign id
     */
    setId(id) {
      setSegment({ ...segment, id });
    },
    /**
     * setName
     * @param {string} name campaign name
     */
    setName(name) {
      setSegment({ ...segment, name });
    }
  };
  return <SegmentContext.Provider {...props} value={value} />;
}
SegmentProvider.propTypes = {
  fromHistoryActivation: PropTypes.objectOf(PropTypes.any).isRequired
};
/**
 * useSegment
 * @return context
 *
 */
export const useSegment = () => {
  const context = React.useContext(SegmentContext);
  if (!context)
    throw new Error('SegmentContext must be called in SegmentProvider');
  return context;
};
export default SegmentProvider;
