import React from 'react';
import { useTheme } from 'contexts/ThemeContext';
import LabelActivation from 'Activation/components/LabelActivation';
import WrapperResume from 'Activation/components/WrapperResume';
import ResumeSentenceContainer from 'Activation/containers/ResumeSentenceContainer';

/**
 * ResumeContainer
 * @component
 *
 */
function ResumeContainer() {
  const { themeColors } = useTheme();
  return (
    <WrapperResume>
      <div
        className="column-resume"
        style={{ borderBottom: `2px solid ${themeColors.activation}` }}
      >
        <LabelActivation uppercase>
          <b>ROI DATA ERMES</b>
        </LabelActivation>
      </div>
      <div className="column-resume">
        <ResumeSentenceContainer />
      </div>
    </WrapperResume>
  );
}
ResumeContainer.defaultProps = {};
ResumeContainer.propTypes = {};

export default ResumeContainer;
