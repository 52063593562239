import React from 'react';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import configuredGraphqlClient from 'utils/api/graphql';

import AppActivation from 'Activation/AppActivation';
import ModalActivationProvider from 'Activation/contexts/ModalActivationContext';
import SegmentProvider from 'Activation/contexts/SegmentContext';
import StepProvider from 'Activation/contexts/StepContext';
import PropTypes from 'prop-types';
import { ACTIVATION } from 'src/utils/graphQL';
import LoadingActivation from 'Activation/components/LoadingActivation';

/**
 * useActivationQuery
 * @param {String} id
 * @return {Object} response
 * {Object} response.data
 * {Boolean} response.loading
 *
 */
function useActivationQuery(id) {
  const resp = useQuery(ACTIVATION.GET_ACTIVATION, {
    variables: { id },
    skip: !id,
    client: configuredGraphqlClient
  });

  return id ? resp : {};
}

/**
 * Activation
 * @component
 *
 */
function ActivationModule(props) {
  const { match } = props;
  const { id: urlID } = match.params;
  const response = useActivationQuery(urlID);
  return (
    <ApolloProvider client={configuredGraphqlClient}>
      <LoadingActivation {...response} skip={!urlID}>
        <ModalActivationProvider>
          <SegmentProvider
            fromHistoryActivation={response.data && response.data.activation}
          >
            <StepProvider>
              <AppActivation
                isFromHistory={
                  urlID && response.data && response.data.activation
                }
              />
            </StepProvider>
          </SegmentProvider>
        </ModalActivationProvider>
      </LoadingActivation>
    </ApolloProvider>
  );
}
ActivationModule.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ActivationModule;
