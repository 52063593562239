import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import Icon from 'utils/icons';
import Text from 'src/components/Text';
import Image from 'src/components/Image';
import ButtonFill from 'Data/components/ButtonFill';
import { useTheme } from 'src/contexts/ThemeContext';
import Tooltip from 'src/components/Tooltip';
import Base64Images from './base64Images';

/**
 * Block
 * @component
 */
function Block({ icon, title, text, onClick, disabled }) {
  const { themeColors } = useTheme();
  return (
    <Grid alignItems="center" justify="center" direction="column" container>
      <Image src={Base64Images[icon]} width="64px" />
      <Text textAlign="center" style={{ color: themeColors.light }}>
        <strong>{title}</strong>
      </Text>
      <Text
        style={{ minHeight: '150px', color: themeColors.light }}
        textAlign="center"
      >
        {text}
      </Text>
      {disabled ? (
        <div data-tip={disabled} data-for="data-block-tooltip">
          <ButtonFill onClick={onClick} disabled={disabled}>
            <span style={{ marginRight: '4px' }}>SÉLECTIONNER</span>
            <Icon icon="dataHomeArrow" />
          </ButtonFill>
        </div>
      ) : (
        <ButtonFill onClick={onClick} disabled={disabled}>
          <span style={{ marginRight: '4px' }}>SÉLECTIONNER</span>
          <Icon icon="dataHomeArrow" />
        </ButtonFill>
      )}
      <Tooltip
        className="fit-text"
        id="data-block-tooltip"
        text="Vous devez d’abord uploader votre base de données à l’étape 1 pour accéder à cette fonctionnalité"
      />
    </Grid>
  );
}
Block.defaultProps = {
  disabled: false
};
Block.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default Block;
