import React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dropdown from 'components/Dropdown';
import Icon from 'utils/icons';
import Button from 'components/Button';
import { withTheme, useTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';

const { colors } = constants;

export const TableAnalyses = withTheme(styled(Table)`
  && {
    width: 98%;
    color: white;
    position: relative;
    left: 1%;
    p,
    div,
    span,
    th,
    td {
      color: ${({ themeColors }) => themeColors.light};
    }
  }
`);

export const TableBodyAnalyses = styled(TableBody)``;

export const IconArrowAnalyse = props => {
  const { themeColors } = useTheme();
  return <Icon {...props} attrs={{ fill: themeColors.light }} />;
};

export const TableHeadAnalyses = withTheme(styled(TableHead)`
  text-align: center;
  background: ${({ themeColors }) => themeColors.primary};
  padding: 0;

  th {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 0;
  }
`);

export const TableRowAnalyses = styled(TableRow)``;

export const TableCellAnalyses = withTheme(styled(TableCell)`
  .label {
    text-align: right;
    padding-bottom: 27px;
    padding-top: 27px;
  }
  img {
    width: 20px;
    padding-left: 5px;
  }
  font-weight: ${({ fontWeight }) =>
    fontWeight ? 'bold' : 'normal'} !important;
  && {
    height: 35px;
    border: none;
  }
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &&.a-tableCell {
    margin: 0px auto;
    text-align: center;
    padding: 10px;
    color: ${({ themeColors }) => themeColors.light};
    width: 175px;
  }
  &&.b-tableCell {
    border-bottom: 1px solid ${({ themeColors }) => themeColors.light};
    margin: 0px auto;
    text-align: center;
    padding: 10px;
    color: ${({ themeColors }) => themeColors.light};
    width: 175px;
  }
  &&.b-tableCell-final {
    border-bottom: 3px solid ${({ themeColors }) => themeColors.light};
    margin: 0px auto;
    text-align: center;
    padding: 10px;
    color: ${({ themeColors }) => themeColors.light};
    width: 175px;
  }
  &&.b-headerCell {
    border-bottom: 2px solid ${({ themeColors }) => themeColors.light};
    max-width: 150px;
    margin: 0px auto;
    text-align: center;
    padding: 15px;
    font-weight: bold;
    color: ${({ themeColors }) => themeColors.light};
  }
`);

export const ButtonTab = styled.div`
  display: -webkit-inline-box;
  position: relative;
  left: 10%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 75%;
  border-radius: 0 0 10px 10px;
  background-color: transparent;
  padding: 20px 25px;
  bottom: 1%;
  float: left;
  width: 40%;
`;

export const ButtonAnalyses = withTheme(styled(Button)`
  && {
    margin: 0 0px;
    border: 1px solid ${({ themeColors }) => themeColors.light};
    height: 30px;
    width: 150px;
    line-height: 29px;
    text-transform: none;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    color: ${({ themeColors }) => themeColors.light};
    padding: 0;
  }
  &&:hover {
    background-color: ${({ themeColors }) => themeColors.light};
    color: ${({ themeColors }) => themeColors.dark};
    font-weight: bold;
  }
  &&.active {
    background-color: ${({ themeColors }) => themeColors.light};
    color: ${({ themeColors }) => themeColors.dark};
    font-weight: bold;
  }
`);

export const ButtonDrop = withTheme(styled(Button)`
  && {
    margin: 0 0px;
    height: 30px;
    width: 150px;
    line-height: 29px;
    left: 15%;
    text-transform: none;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    color: ${({ themeColors }) => themeColors.light};
  }
  &&:hover {
    font-weight: bold;
    background-color: ${({ themeColors }) => themeColors.tertiary};
  }
  &&.active {
    font-weight: bold;
  }
`);

export const MoveAnalyses = withTheme(styled(Button)`
  && {
    position: relative;
    margin: 0 5px;
    height: 20px;
    width: 10px;
    line-height: 29px;
    text-align: center;
    border-radius: 7px;
    top: 20%;
    cursor: pointer;
    color: ${({ themeColors }) => themeColors.light};
  }
  &&:hover {
    background-color: ${({ themeColors }) => themeColors.tertiary};
    color: ${({ themeColors }) => themeColors.tertiary};
    font-weight: bold;
  }
`);

export const InputAnalyses = withTheme(styled.input`
  width: 3.6rem;
  border: 1px solid ${({ themeColors }) => themeColors.funnel};
  border-radius: 0.5rem;
  height: 1.8rem;
  text-align: center;
  overflow: hidden;
  margin: 0.4rem;
  background-color: ${({ themeColors }) => themeColors.primary};
  color: white;
`);

export const ArrowAnalyse = withTheme(styled(Button)`
  && {
    opacity: ${({ isLighter }) => (isLighter ? '0.5' : '1')};
    margin: 0 !important;
    padding: 0 !important;
    min-width: 16px !important;
  }
  &&:disabled {
    color: ${({ themeColors }) => themeColors.secondary};
  }
  &&.left {
    transform: rotate(180deg);
    padding-left: 10px;
    padding-bottom: 10px;
  }
  &&.right {
    padding-top: 10px;
    position: relative;
    left: 10px;
  }
`);

export const Container = withTheme(styled.div`
  box-shadow: 0 0 6px 2px ${({ themeColors }) => themeColors.shadow};
  background-color: ${({ themeColors }) => themeColors.keep_light};
  border-radius: 5px;
  margin: 10px 10px;
  padding: 5px 20px;
  text-align: center;
  display: flex;
  .question_mark {
    height: 50px;
    width: 40px;
    margin-right: 10px;
  }
  .align_center {
    color: ${({ themeColors }) => themeColors.keep_dark};
  }
`);

export const ErrorMessage = withTheme(styled.p`
  color: ${({ themeColors }) => themeColors.light};
  font-size: 14px;
  padding: 10px 40px;
`);

export const LabelStyle = {
  fontSize: '14px',
  fill: colors.light
};

const ColoredTableCell = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;
  border-radius: 7px;
  font-weight: bold;
  padding: 3px 7px;
  strong {
    color: #fff;
  }
`;
export const Success = styled(ColoredTableCell)`
  background-color: green;
`;

export const Alert = styled(ColoredTableCell)`
  background-color: red;
`;

export const DropdownAnalyse = withTheme(styled(Dropdown)`
  width: 250px;
  height: 20px;
  position: relative;
  bottom: 51%;
  text-align: center;

  .dropbtn {
    height: 30px;
    color: ${({ themeColors }) => themeColors.light};
    background-color: ${({ themeColors }) => themeColors.secondary};
    padding: 0;
  }
  .custom_select_arrow {
    display: none;
  }
  .dropdown-content {
    color: ${({ themeColors }) => themeColors.light};
    background-color: ${({ themeColors }) => themeColors.secondary};
    text-align: center;
  }

  .dropdown-content,
  .dropdown-content a {
    width: 100%;
    min-width: ${({ minWidth }) => minWidth || '200px'};
    right: 0;
    cursor: pointer;
    font-size: 16px;
    outline: none;
  }
  .dropdown-content a:hover {
    width: 100%;
    font-size: 16px;
    background-color: ${({ themeColors }) => themeColors.tertiary};
    font-weight: bold;
    a {
      background-color: ${({ themeColors }) => themeColors.tertiary};
    }
  }
`);
