import React from 'react';

import Loading from 'components/Loading';

import { useCategory } from 'Insight/contexts/CategoryContext';
import { useOperator } from 'Insight/contexts/OperatorContext';
import { INSIGHT_SUR_MESURE_KEY } from 'Insight/constant';
import { useResult } from '../../contexts/ResultContext';

import Category from './Category';
import Funnel from './Funnel';

/**
 * CriteriaContainer
 * @component
 * @desc :: manage criterias logic (right board on /insight)
 *
 */
function CriteriaContainer() {
  const [tooltip, setTooltip] = React.useState('');
  const { currentCategory, tagFunnels } = useCategory();
  const { results } = useResult();
  const { isValueSelected, toggleValueSelected } = useOperator();
  const { category } = results;

  if (
    !currentCategory ||
    (!category && currentCategory !== INSIGHT_SUR_MESURE_KEY) ||
    (currentCategory === INSIGHT_SUR_MESURE_KEY && !tagFunnels)
  ) {
    const src = '/assets/img/loader_insight.svg';
    return <Loading loading src={src} refetch={() => {}} />;
  }
  if (currentCategory === INSIGHT_SUR_MESURE_KEY) {
    return (
      <Funnel
        tagFunnels={tagFunnels}
        isValueSelected={isValueSelected}
        toggleValueSelected={toggleValueSelected}
      />
    );
  }
  return (
    <Category
      category={category}
      tooltip={tooltip}
      setTooltip={setTooltip}
      categoryKey={currentCategory}
      isValueSelected={isValueSelected}
      toggleValueSelected={toggleValueSelected}
    />
  );
}

export default CriteriaContainer;
