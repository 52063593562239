import React from 'react';

import BaseLoadingActivation from './style';

/**
 * LoadingActivation
 * @component
 *
 */
function LoadingActivation(props) {
  return (
    <BaseLoadingActivation {...props} src="/assets/img/loader_activation.svg" />
  );
}
LoadingActivation.defaultProps = {};
LoadingActivation.propTypes = {};

export default LoadingActivation;
