import React from 'react';
import styled from 'styled-components';
import BaseDatePicker from 'components/DateRangePicker';
import { withTheme, useTheme } from 'contexts/ThemeContext';
import constants from 'utils/constants';

const { fontSizes } = constants;

/**
 * BaseDatePickerActivation
 * @component
 * style
 *
 */
const BaseDatePickerActivation = styled.div`
  .DateInput_input
    height: 36px;
  }
`;

export const DatePicker = props => {
  const { themeColors } = useTheme();
  return (
    <BaseDatePicker
      bgColor={themeColors.primary}
      color={themeColors.light}
      borderColor={({ errorInput }) => {
        if (errorInput) {
          return '#eb5757';
        }
        return themeColors.activation;
      }}
      fontSize={fontSizes.medium}
      width="100px"
      {...props}
    />
  );
};

export default withTheme(BaseDatePickerActivation);
