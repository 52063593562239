import React from 'react';
import { formatNumber } from 'utils/functions';

import { useSegment } from 'Activation/contexts/SegmentContext';

import IconActivation from 'Activation/components/IconActivation';
import TitleActivation from 'Activation/components/TitleActivation';
import LabelActivation from 'Activation/components/LabelActivation';
import WrapperMatchingVisualization from 'Activation/components/WrapperMatchingVisualization';

const WAITING = 'en attente d’upload d’un segment';

/**
 * MatchingVisualizationContainer
 * @container
 *
 * @desc ::
 *      The second item at the first page, match the inconming data with the
 *      app nexus
 *
 * TODO:: ADD the app nexus management
 *
 */
function MatchingVisualizationContainer() {
  const { nexusSize, facebookSize, vd360Size, results } = useSegment();
  const [roi, setRoi] = React.useState({});
  const [hasDataErmes, setHasDataErmes] = React.useState(false);
  let labelAppNexus = WAITING;
  let labelFacebook = WAITING;
  let labelDV360 = WAITING;
  if (nexusSize) {
    labelAppNexus = `${formatNumber(nexusSize, 0)} \
    profil en commun trouvé chez AppNexus suite au matching (estimation).`;
  }
  if (nexusSize && nexusSize > 1) {
    labelAppNexus = `${formatNumber(nexusSize, 0)} \
    profils en commun trouvés chez AppNexus suite au matching (estimation).`;
  }
  if (facebookSize) {
    labelFacebook = `${formatNumber(facebookSize, 0)} \
    profil en commun trouvé chez facebook suite au matching (estimation).`;
  }
  if (facebookSize && facebookSize > 1) {
    labelFacebook = `${formatNumber(facebookSize, 0)} \
    profils en commun trouvés chez facebook suite au matching (estimation).`;
  }
  if (vd360Size) {
    labelDV360 = `${formatNumber(vd360Size, 0)} \
    profil en commun trouvé chez vd360 suite au matching (estimation).`;
  }
  if (vd360Size && vd360Size > 1) {
    labelDV360 = `${formatNumber(vd360Size, 0)} \
    profils en commun trouvés chez vd360 suite au matching (estimation).`;
  }

  React.useEffect(() => {
    if (nexusSize || facebookSize || vd360Size) {
      setHasDataErmes(true);
    }
    if (results.ROI) {
      setRoi(hasDataErmes ? results.WithDataSummary : results.ROI);
    }
  }, [facebookSize, hasDataErmes, nexusSize, results, results.ROI, vd360Size]);
  return (
    <WrapperMatchingVisualization>
      {hasDataErmes && (
        <div className="row-matching">
          {`Pour ce segment de ${formatNumber(
            nexusSize,
            0
          )} profils, le CPM de la Data sera de ${formatNumber(
            (roi && roi.total && roi.total.CPMData) || 0
          )}€.`}
        </div>
      )}
      <TitleActivation mediumLarge fontWeight="bold">
        2. Visualisez le matching avec nos plateformes de trading
      </TitleActivation>
      <div className="row-matching">
        <IconActivation icon="appNexusLogo" externalLogo />
        <LabelActivation>{labelAppNexus}</LabelActivation>
      </div>
      <div className="row-matching">
        <IconActivation icon="dv360" externalLogo />
        <LabelActivation>{labelDV360}</LabelActivation>
      </div>
      <div className="row-matching">
        <IconActivation icon="facebook" externalLogo />
        <LabelActivation>{labelFacebook}</LabelActivation>
      </div>
    </WrapperMatchingVisualization>
  );
}

export default MatchingVisualizationContainer;
