import Amplify, { Auth } from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import { AUTH_TYPE } from 'aws-appsync/lib/link/auth-link';
import { InMemoryCache } from 'apollo-boost';
import awsConfig from './aws-export';

Amplify.configure(awsConfig);

/**
 * client AWSAppSyncClient
 * @WARNING there is another client in src/modules/Insight/
 */
const client = new AWSAppSyncClient(
  {
    url: awsConfig.graphqlEndpoint,
    region: awsConfig.region,
    auth: {
      // apiKey: awsConfig.apiKey,
      // Amazon Cognito Federated Identities using AWS Amplify
      // credentials: () => Auth.currentCredentials(),
      // Amazon Cognito user pools using AWS Amplify
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
      }
    },
    disableOffline: true
  },
  {
    cache: new InMemoryCache().restore({}),
    ssrMode: true
  }
);

export default client;
