import MuButton from '@material-ui/core/Button';
import styled from 'styled-components';

import { withTheme } from 'src/contexts/ThemeContext';

/**
 * ButtonBorder
 * @component
 */
const ButtonBorder = withTheme(styled(MuButton).attrs(() => ({
  variant: 'outlined'
}))`
  && {
    color: ${({ themeColors }) => themeColors.data};
    border-color: ${({ themeColors }) => themeColors.data};
    border-radius: 40px;
    margin: 8px auto 0;
    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }
  }
`);
ButtonBorder.defaultProps = {};
ButtonBorder.propTypes = {};

export default ButtonBorder;
