import React from 'react';

import { useFunnel } from 'Funnel/contexts/FunnelContext';
import AudienceSteper from './AudienceSteper';
import AudienceFirstPage from './AudienceFirstPage';
import SegmentNectar from './SegmentNectar';
import InsightPerLevel from './InsightPerLevel';
import data from './DataInsight';

function AnalysesAudienceContainer() {
  const { funnel } = useFunnel();
  const [page, setPage] = React.useState(0);

  const getPage = () => {
    if (page === 0) return <AudienceFirstPage funnel={funnel} />;
    if (page === 1)
      return <InsightPerLevel data={data} objectif={funnel.measure.details} />;
    if (page === 2) {
      return (
        <SegmentNectar
          objectif={funnel.measure.details}
          profiles="1 293 268"
          offers={37}
          visites={10}
        />
      );
    }
    return null;
  };

  const switchPage = idx => {
    setPage(idx);
  };

  return (
    <React.Fragment>
      <span
        style={{
          color: '#4c4e58',
          marginLeft: '50px',
          fontWeight: 'bold',
          border: '2px solid #4c4e58',
          padding: '5px',
          position: 'relative',
          float: 'left'
        }}
      >
        DEMO MODE
      </span>
      <div className="navigation">
        <AudienceSteper onChangeStep={switchPage} />
      </div>
      {getPage()}
    </React.Fragment>
  );
}

export default AnalysesAudienceContainer;
