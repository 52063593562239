import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

const DrawerOpener = styled.div`
  height: 2rem;
  width: 2rem;
  background-color: ${({ themeColors }) => themeColors.funnel};
  position: absolute;
  left: 0;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 800;

  &:after {
    content: '>';
  }
`;

export default withTheme(DrawerOpener);
