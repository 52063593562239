import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import constants from 'utils/constants';
import configuredGraphqlClient from 'utils/api/graphql';
import Icons from 'utils/icons';

import Loading from 'components/Loading';

import LoadingInsight from 'Insight/components/LoadingInsight';
import ModalInsight from 'Insight/components/ModalInsight';
import { ButtonInsightDelete } from 'Insight/components/ButtonInsight';
import TextInsight from 'Insight/components/TextInsight';
import TableInsight, {
  TableItemInsight,
  TableRowInsight,
  TableBodyInsight
} from 'Insight/components/TableInsight';
import { formatNumber } from 'utils/functions';

const { routes } = constants;
const GET_INSIGHTS = gql`
  {
    insightTargets {
      id
      name
      email
      filters
      frontData
      updatedAt
      createdAt
    }
  }
`;

const DELETE_INSIGHT = gql`
  mutation insightTargetDeleteMutation($id: ID!) {
    insightTargetDelete(id: $id) {
      id
      name
      email
      filters
      updatedAt
      createdAt
    }
  }
`;
const TABLE_HEAD_TITLES = [
  'Nom de la cible',
  'Contacts',
  'Date de sauvegarde',
  'Actions'
];

/**
 * @param {Object} itemInsight
 * @param {ObjectJSON} itemInsight.frontData
 * @param {Number} itemInsight.frontData.nbProfiles
 * @return String
 */
const extractProfile = itemInsight => {
  if (itemInsight.frontData) {
    return formatNumber(JSON.parse(itemInsight.frontData).nbProfiles, 0, 0);
  }
  return 'N/A';
};

/**
 * HistoryInsightContainer
 * @container
 *
 */
function HistoryInsightContainer(props) {
  const { history } = props;
  const client = configuredGraphqlClient;
  const [insights, setInsights] = React.useState([]);
  const [response, setResponse] = React.useState({});
  const [reprievedItem, setReprievedItem] = React.useState({});
  const firstResponse = useQuery(GET_INSIGHTS, { client });
  const [deleteInsight] = useMutation(DELETE_INSIGHT, { client });
  const setValues = ({ data, resp }) => {
    if (!data) return;
    setInsights(data);
    setResponse(resp);
  };
  const handleViewITem = id => {
    history.push(`${routes.INSIGHT}/${id}`);
  };
  const deleteItem = id => {
    deleteInsight({ variables: { id } }).then(() => {
      firstResponse.refetch();
      setReprievedItem({});
    });
  };
  React.useEffect(() => {
    const { data } = firstResponse || {};
    if (data) {
      setValues({ data: data.insightTargets, resp: firstResponse });
    }
  }, [firstResponse]);
  React.useEffect(() => {
    firstResponse.refetch();
  }, [firstResponse]);
  if (firstResponse.error || response.error) {
    throw firstResponse.error;
  }
  if (firstResponse.loading) {
    return (
      <Loading
        loading={firstResponse.loading}
        src="/assets/img/loader_insight.svg"
      />
    );
  }
  if (!Object.keys(response).length) {
    return <Loading loading src="/assets/img/loader_insight.svg" />;
  }
  return (
    <>
      <TableInsight>
        <TableRowInsight isHead length={TABLE_HEAD_TITLES.length}>
          {TABLE_HEAD_TITLES.map(title => (
            <TableItemInsight key={title}>{title}</TableItemInsight>
          ))}
        </TableRowInsight>
        <TableBodyInsight>
          <LoadingInsight {...response}>
            {insights.map(target => (
              <TableRowInsight
                key={target.id}
                length={TABLE_HEAD_TITLES.length}
              >
                <TableItemInsight handleClick={() => handleViewITem(target.id)}>
                  {target.name}
                </TableItemInsight>
                <TableItemInsight handleClick={() => handleViewITem(target.id)}>
                  {extractProfile(target)}
                </TableItemInsight>
                <TableItemInsight handleClick={() => handleViewITem(target.id)}>
                  {new Date(target.createdAt).toLocaleDateString()}
                </TableItemInsight>
                <TableItemInsight>
                  <ButtonInsightDelete onClick={() => setReprievedItem(target)}>
                    <Icons icon="trash" /> Supprimer
                  </ButtonInsightDelete>
                </TableItemInsight>
              </TableRowInsight>
            ))}
          </LoadingInsight>
        </TableBodyInsight>
      </TableInsight>
      <ModalInsight
        isOpen={!!reprievedItem.id}
        handleClose={() => setReprievedItem({})}
        validationLabel="Supprimer"
        label="Suppression d'Insight"
        handleValidation={() => deleteItem(reprievedItem.id)}
      >
        <TextInsight>
          Êtes-vous sûr(e) de vouloir supprimer l&apos;Insight: <br />
          <br />
          &quot; {reprievedItem.name} &quot; ?
        </TextInsight>
      </ModalInsight>
    </>
  );
}
HistoryInsightContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(HistoryInsightContainer);
