import constants from 'utils/constants';

const { thresolds } = constants;

const COSTS = {
  Setup: 750,
  WeeklyCost: 250,
  DSP: 0.15
};
const CAMPAIGN_MIXES = {
  video: {
    Tx_Clic_Max: 0.5 / 100,
    Tx_Clic_Min: 0.1 / 100,
    Tx_Clic_Default: 0.25 / 100,
    CPM: 6,
    title: 'Vidéo'
  },
  video_premium: {
    Tx_Clic_Max: 1 / 100,
    Tx_Clic_Min: 0.2 / 100,
    Tx_Clic_Default: 0.6 / 100,
    CPM: 12,
    title: 'Vidéo premium'
  },
  banniere_impact: {
    Tx_Clic_Max: 0.5 / 100,
    Tx_Clic_Min: 0.1 / 100,
    Tx_Clic_Default: 0.25 / 100,
    CPM: 6,
    title: 'Bannière Impact'
  },
  banniere: {
    Tx_Clic_Max: 0.1 / 100,
    Tx_Clic_Min: 0.02 / 100,
    Tx_Clic_Default: 0.05 / 100,
    CPM: 3,
    title: 'Bannière'
  }
};
const CAMPAIGN_TYPES = Object.keys(CAMPAIGN_MIXES);

/**
 * getThresold
 * @desc ::  prendre le dernier segment inférieur
 * @param {Number} segmentprofiles
 * @return Number
 *
 */
function getThresold(segmentprofiles) {
  const thresoldsRevert = [...thresolds].sort((a, b) => b.max - a.max); // sort DESC
  const thresold = thresoldsRevert.find(({ max }) => max <= segmentprofiles);
  if (thresold) {
    return thresold;
  }
  return thresoldsRevert[thresoldsRevert.length - 1]; // return the smaller
}

/**
 * getWeeks
 * @return Number
 *
 */
function getWeeks({ startDate, endDate }) {
  return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24 * 7));
}

/**
 * tradingPrice
 * @return Number
 *
 */
function getTradingPrice(segment) {
  return COSTS.Setup + getWeeks(segment) * COSTS.WeeklyCost;
}

/**
 * getTechnicalPrice
 * @return Number
 *
 */
function getTechnicalPrice(segment) {
  const { budget } = segment;
  return (budget - getTradingPrice(segment)) * COSTS.DSP;
}

/**
 * getTechnicalPrice
 * @return object
 *
 */
// FM = (Total - FD - SU) / 1.15
// FT = 0.15 * (Total - FD - SU) / 1.15
function getNewCalcul({ TOTAL, FD, SU }) {
  return {
    FM: (TOTAL - FD - SU) / 1.15,
    FT: (0.15 * (TOTAL - FD - SU)) / 1.15
  };
}

/**
 * getRemainingBudget
 * @return Number
 *
 */
export function getRemainingBudget(segment) {
  const { budget } = segment;
  return budget - getTradingPrice(segment) - getTechnicalPrice(segment);
}

/**
 * getCalculationTab
 * @param {Number} cpmCost
 * @return Object
 *
 */
function getCalculationTab(segment, cpmCost) {
  const { budget, campaignRatios } = segment;
  const budgetRestant =
    budget - getTradingPrice(segment) - getTechnicalPrice(segment);
  const avgCPMMedia = CAMPAIGN_TYPES.reduce((avg, curr) => {
    return avg + CAMPAIGN_MIXES[curr].CPM * campaignRatios[curr];
  }, 0);
  const fullCPMCible = avgCPMMedia + cpmCost;
  const totalImpressions = (1000 * budgetRestant) / fullCPMCible;
  const result = CAMPAIGN_TYPES.reduce((acc, currentType) => {
    return {
      ...acc,
      [currentType]: {
        pourcentage: campaignRatios[currentType],
        CPMMedia: CAMPAIGN_MIXES[currentType].CPM,
        CPMData: cpmCost,
        fullCPMCible: CAMPAIGN_MIXES[currentType].CPM + cpmCost,
        Impressions: totalImpressions * campaignRatios[currentType],
        Media:
          (CAMPAIGN_MIXES[currentType].CPM *
            totalImpressions *
            campaignRatios[currentType]) /
          1000,
        Data: (cpmCost * totalImpressions * campaignRatios[currentType]) / 1000
      }
    };
  }, {});
  const total = Object.values(result).reduce(
    (acc, resultItem) => {
      return {
        pourcentage: acc.pourcentage + resultItem.pourcentage,
        Media: acc.Media + resultItem.Media,
        Data: acc.Data + resultItem.Data
      };
    },
    { pourcentage: 0, Media: 0, Data: 0 }
  );
  total.Impressions = totalImpressions;
  total.CPMMedia = avgCPMMedia;
  total.CPMData = cpmCost;
  total.fullCPMCible = fullCPMCible;
  result.total = total;
  return result;
}

/**
 * generateFunnelSteps
 * @param {Object} segment
 * @param {Object} funnel :: result of getCalculationTab
 * @desc :: enhance getCalculationTab result with transformationRates results
 * @return Object
 *
 * @see utils/constants/kpiList for funnelRates
 *
 */
function generateFunnelSteps(segment, activationFunnel) {
  const { funnelRates, transformationRates, budget } = segment;
  const nextActivationFunnel = { ...activationFunnel };
  let currentNb = nextActivationFunnel.level1['Impressions sur cible'];
  funnelRates.map((rate, i) => {
    const rang = i + 2;
    const txRatioLevel = `level${rang}`;
    const currentRate =
      transformationRates && transformationRates[txRatioLevel]
        ? transformationRates[txRatioLevel]
        : rate.default;
    currentNb *= currentRate;
    const levelName = `level${rang}`;
    const levelWrapper = `txLevel${rang}`;
    nextActivationFunnel[levelWrapper] = {
      [rate.txName]: currentRate,
      min: rate.min,
      max: rate.max,
      default: rate.default
    };
    nextActivationFunnel[levelName] = {
      [rate.name]: currentNb,
      [rate.shortLabel]: budget / currentNb
    };
    return null;
  });
  return nextActivationFunnel;
}

/**
 *
 * getResults
 * @param {Object} segment
 * @return Object {
 *    Brief,
 *    Costs,
 *    FunnelWithData,
 *    FunnelWithoutData,
 *    CaclculedFunnelRates,
 *    ROI,
 *    Summary,
 *    WithDataSummary,
 * }
 *
 */
export default function getResults(segment) {
  const {
    budget,
    campaignRatios,
    endDate,
    funnelRates,
    nexusSize,
    profiles,
    startDate
  } = segment;
  const segmentData = getThresold(profiles);
  const CPM = segmentData.cpmData;
  const WithData = getCalculationTab(segment, CPM);
  const WithoutData = getCalculationTab(segment, 0);
  const WithDataSummary = {};
  CAMPAIGN_TYPES.map(campaignType => {
    WithDataSummary[campaignType] = {
      CPM: WithData[campaignType].fullCPMCible,
      ISC: WithData[campaignType].Impressions,
      Impressions: WithData[campaignType].Impressions,
      pourcentage: WithData[campaignType].pourcentage,
      title: CAMPAIGN_MIXES[campaignType].title
    };
    return null;
  });
  WithDataSummary.total = WithData.total;
  WithDataSummary.total.repetition =
    WithDataSummary.total.Impressions / nexusSize;
  let mixCampagneTotal = {};
  ['CPM', 'Tx_Clic_Max', 'Tx_Clic_Min', 'Tx_Clic_Default'].map(x => {
    const res = CAMPAIGN_TYPES.reduce((avg, cur) => {
      return avg + CAMPAIGN_MIXES[cur][x] * campaignRatios[cur];
    }, 0);
    mixCampagneTotal = { ...mixCampagneTotal, [x]: res };
    return null;
  });
  const calculs = {
    tradingPrice: getTradingPrice(segment),
    technicalPrice: getTechnicalPrice(segment),
    ftAndFm: getNewCalcul({
      TOTAL: budget,
      FD: WithData.total.Data,
      SU: getTradingPrice(segment)
    })
  };
  const costs = {
    SetupAndTrading: {
      price: calculs.tradingPrice,
      percent: 100 * (calculs.tradingPrice / budget),
      label: 'Set Up & Trading'
    },
    FraisTechniques: {
      price: calculs.ftAndFm.FT,
      percent: 100 * (calculs.ftAndFm.FT / budget),
      label: 'Frais techniques (DSP)'
    },
    FraisData: {
      price: WithData.total.Data,
      percent: 100 * (WithData.total.Data / budget),
      label: 'Frais Data'
    },
    FraisMedia: {
      price: calculs.ftAndFm.FM,
      percent: 100 * (calculs.ftAndFm.FM / budget),
      label: 'Frais Média'
    }
  };
  const { totalPrice, totalPercent } = Object.values(costs).reduce(
    (acc, cur) => {
      return {
        totalPrice: acc.totalPrice + cur.price,
        totalPercent: acc.totalPercent + cur.percent
      };
    },
    { totalPrice: 0, totalPercent: 0 }
  );
  costs.total = {
    price: totalPrice,
    percent: totalPercent
  };
  costs.CPMTotal = (1000 * budget) / WithData.total.Impressions;

  const BriefSummary = {
    Debut: startDate,
    Fin: endDate,
    IscHorsData: segmentData.iscHorsData,
    Montant: budget,
    SegmentCible: profiles,
    Weeks: getWeeks({ startDate, endDate })
  };
  let ROI = {};

  const calculFullCPM = (ft, fsu, imp) => {
    const total = ((ft + fsu) / imp) * 1000;

    return total;
  };

  const fullCPMTab = [];
  CAMPAIGN_TYPES.map(campaignType => {
    ROI[campaignType] = {
      ISC: WithoutData[campaignType].Impressions * segmentData.iscHorsData,
      ISCHorsData: segmentData.iscHorsData,
      Impressions: WithoutData[campaignType].Impressions,
      pourcentage: campaignRatios[campaignType],
      title: CAMPAIGN_MIXES[campaignType].title,
      CPM: CAMPAIGN_MIXES[campaignType].CPM,
      fullCPM:
        WithDataSummary[campaignType].CPM +
        calculFullCPM(
          costs.FraisTechniques.price,
          costs.SetupAndTrading.price,
          WithData.total.Impressions
        )
    };
    fullCPMTab.push(
      WithDataSummary[campaignType].CPM +
        calculFullCPM(
          costs.FraisTechniques.price,
          costs.SetupAndTrading.price,
          WithData.total.Impressions
        )
    );
    return null;
  });
  let totalROI = {};
  ['pourcentage', 'Impressions', 'ISC', 'ISCHorsData', 'CPM', 'fullCPM'].map(
    x => {
      const sum = Object.values(ROI).reduce((acc, cur) => {
        return acc + cur[x];
      }, 0);
      totalROI = { ...totalROI, [x]: sum };
      return null;
    }
  );
  ROI = { ...ROI, total: totalROI };
  let FunnelWithData = {
    level1: {
      'Impressions sur cible': WithData.total.Impressions,
      CPM: (budget * 1000) / WithData.total.Impressions
    },
    txLevel2: { txVisites: mixCampagneTotal.Tx_Clic_Default }
  };

  const CalculedFunnelRates = [...funnelRates];
  CalculedFunnelRates[0].default = mixCampagneTotal.Tx_Clic_Default;
  CalculedFunnelRates[0].min = mixCampagneTotal.Tx_Clic_Min;
  CalculedFunnelRates[0].max = mixCampagneTotal.Tx_Clic_Max;

  let FunnelWithoutData = {
    level1: {
      'Impressions sur cible': ROI.total.ISC,
      CPM: (budget * 1000) / ROI.total.ISC
    }
  };
  FunnelWithData = generateFunnelSteps(segment, FunnelWithData);
  FunnelWithoutData = generateFunnelSteps(segment, FunnelWithoutData);
  const summary = {
    dataCostPercent: costs.FraisData.percent,
    impactCPM: 1 - FunnelWithData.level1.CPM / FunnelWithoutData.level1.CPM,
    dataImpactPercent:
      (100 *
        (FunnelWithData.level1['Impressions sur cible'] -
          FunnelWithoutData.level1['Impressions sur cible'])) /
      FunnelWithoutData.level1['Impressions sur cible']
  };

  WithDataSummary.total.fullCPMTotal =
    fullCPMTab.reduce((a, b) => a + b) / fullCPMTab.length;
  const result = {
    Brief: BriefSummary,
    Costs: costs,
    FunnelWithData,
    FunnelWithoutData,
    ROI,
    Summary: summary,
    WithDataSummary,
    CalculedFunnelRates
  };
  return result;
}
