import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';
import { ResponsiveBar } from '@nivo/bar';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory';
import { formatNumber } from 'utils/functions';
import {
  TableCellDistrib,
  TableRowDistrib,
  TableCellAgregated,
  ColorCpm,
  ContentTabGraph
} from './style';

function Distrib(props) {
  const { name, tmpData, maxBudget, maxImpression, indiceCPM } = props;
  const { themeColors } = useTheme();
  const labelB = (tmpData.budget * 100) / maxBudget;
  const labelI = (tmpData.impression * 100) / maxImpression;
  const classCellDistrib = name === 'TOTAL' ? 'b-right-final' : 'b-right';
  const argVictoryChart = {
    alignment: 'left',
    horizontal: true,
    height: 90,
    style: {
      parent: {
        position: 'relative',
        right: '16%',
        width: '132%',
        bottom: '33%'
      }
    }
  };
  return (
    <TableRowDistrib>
      <TableCellDistrib
        className={name === 'TOTAL' ? 'b-left-final' : 'b-left'}
        fontWeight="true"
      >
        <div>{name === 'SEA' ? 'Search' : name}</div>
      </TableCellDistrib>
      <TableCellDistrib style={{ width: '2%' }} />
      <TableCellDistrib className={classCellDistrib}>
        <VictoryChart
          {...argVictoryChart}
          domain={{ y: [0, maxBudget.toFixed(2)] }}
        >
          <VictoryBar
            data={[tmpData.budget.toFixed(2)]}
            cornerRadius={{ topLeft: 7, topRight: 7 }}
            labels={() => `${formatNumber(labelB, 0, 0)}%`}
            style={{
              data: { fill: themeColors.funnel, width: 30 },
              labels: {
                fontSize: '16px',
                fill: themeColors.light,
                fontWeight: 'bold'
              }
            }}
            labelComponent={
              labelB >= 20 ? (
                <VictoryLabel dx={-labelB * 3.5} />
              ) : (
                <VictoryLabel />
              )
            }
          />
          <VictoryAxis
            style={{
              axis: { stroke: 'none' },
              tickLabels: { fontSize: '0px' }
            }}
          />
        </VictoryChart>
      </TableCellDistrib>
      <TableCellDistrib style={{ width: '2%' }} />
      <TableCellDistrib className={classCellDistrib}>
        <VictoryChart {...argVictoryChart} domain={{ y: [0, 100] }}>
          <VictoryBar
            data={[indiceCPM * 100]}
            labels={() => `${formatNumber(tmpData.CPM)}€`}
            cornerRadius={{ topLeft: 7, topRight: 7 }}
            style={{
              data: { fill: '#4EB1DB', width: 30 },
              labels: {
                fontSize: '16px',
                fill: themeColors.light,
                fontWeight: 'bold'
              }
            }}
            labelComponent={
              indiceCPM * 100 >= 20 ? (
                <VictoryLabel dx={-(indiceCPM * 100) * 3.5} />
              ) : (
                <VictoryLabel />
              )
            }
          />
          <VictoryAxis
            style={{
              axis: { stroke: 'none' },
              tickLabels: { fontSize: '0px' }
            }}
          />
        </VictoryChart>
      </TableCellDistrib>
      <TableCellDistrib style={{ width: '2%' }} />
      <TableCellDistrib className={classCellDistrib}>
        <VictoryChart
          {...argVictoryChart}
          domain={{ y: [0, maxImpression.toFixed(2)] }}
        >
          <VictoryBar
            y="impression"
            x="CPM"
            data={[tmpData.impression.toFixed(2)]}
            labels={() => `${formatNumber(labelI, 0, 0)}%`}
            cornerRadius={{ topLeft: 7, topRight: 7 }}
            style={{
              data: { fill: '#7DCEF1', width: 30 },
              labels: {
                fontSize: '16px',
                fill: themeColors.light,
                fontWeight: 'bold'
              }
            }}
            labelComponent={
              labelB >= 20 ? (
                <VictoryLabel dx={-labelI * 3.5} />
              ) : (
                <VictoryLabel />
              )
            }
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: 'none' },
              tickLabels: { fontSize: '0px' }
            }}
          />
        </VictoryChart>
      </TableCellDistrib>
      <TableCellDistrib style={{ width: '2%' }} />
    </TableRowDistrib>
  );
}

Distrib.defaultProps = {
  indiceCPM: 0
};

Distrib.propTypes = {
  name: PropTypes.string.isRequired,
  tmpData: PropTypes.shape({
    budget: PropTypes.number,
    impression: PropTypes.number,
    CPM: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  maxBudget: PropTypes.number.isRequired,
  maxImpression: PropTypes.number.isRequired,
  indiceCPM: PropTypes.number
};

export function Agregated(props) {
  const { tmpData, bestCPM, worstCPM } = props;
  let mode = '';
  if (tmpData.CPM === bestCPM) mode = 'success';
  else if (tmpData.CPM === worstCPM) mode = 'alert';
  return (
    <React.Fragment>
      <TableRowDistrib />
      <TableRowDistrib />
      <TableRowDistrib>
        <TableCellAgregated className="b-bottom">
          <strong>{tmpData.name === 'SEA' ? 'Search' : tmpData.name}</strong>
        </TableCellAgregated>
        <TableCellAgregated className="b-bottom">
          <strong>{formatNumber(tmpData.budget / 1000)}</strong>
        </TableCellAgregated>
        <TableCellAgregated className="b-bottom">
          <strong>{formatNumber(tmpData.impression / 1000000)}</strong>
        </TableCellAgregated>
        <TableCellAgregated className="b-bottom">
          <ColorCpm className={mode}>
            <strong>{formatNumber(tmpData.CPM)}</strong>
          </ColorCpm>
        </TableCellAgregated>
      </TableRowDistrib>
    </React.Fragment>
  );
}

Agregated.propTypes = {
  tmpData: PropTypes.shape({
    budget: PropTypes.number,
    impression: PropTypes.number,
    CPM: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  bestCPM: PropTypes.number.isRequired,
  worstCPM: PropTypes.number.isRequired
};

export function Stacked(props) {
  const { data } = props;
  if (data.length === 0)
    return (
      <div
        style={{
          float: 'left',
          width: '51%',
          position: 'relative',
          bottom: '10%',
          marginTop: '3%',
          height: '80%'
        }}
      />
    );

  const colorsGraph = [
    'rgb(213, 236, 245)',
    'rgb(157, 226, 255)',
    'rgb(66, 198, 255)',
    'rgb(0, 159, 227)',
    'rgb(0, 128, 182)',
    'rgb(0, 92, 131)',
    '#227B93',
    '#4A93A6',
    '#8BBAC6'
  ];

  // Keys for Budget Graph
  const dataGraphBKey = [...data]
    .sort((a, b) => {
      if (parseFloat(a.budget) - parseFloat(b.budget))
        return parseFloat(a.budget) - parseFloat(b.budget);
      return a.budget < b.budget ? -1 : 1;
    })
    .reverse()
    .map(item => item.name);

  // Keys for Impression Graph
  const dataGraphIKey = [...data]
    .sort((a, b) => {
      if (parseFloat(a.impression) - parseFloat(b.impression))
        return parseFloat(a.impression) - parseFloat(b.impression);
      return a.impression < b.impression ? -1 : 1;
    })
    .reverse()
    .map(item => item.name);

  // Color List for Graph & Color for Budget Graph
  const dataColorGraph = {};
  const dataColorGraphB = dataGraphBKey.map((item, index) => {
    Object.assign(dataColorGraph, {
      [item]: colorsGraph[index]
    });
    return colorsGraph[index];
  });

  // Color for Impression Graph
  const dataColorGraphI = dataGraphIKey.map(item => {
    return dataColorGraph[item];
  });

  // Total for Budget Graph
  const dataGraphBTotal = data.reduce((obj, item) => obj + item.budget, 0);

  // Total for Impression Graph
  const dataGraphITotal = data.reduce((obj, item) => obj + item.impression, 0);

  // Datas for Budget Graph
  let dataGraphB = data.reduce((obj, item) => {
    const channel = item.name;
    Object.assign(obj, {
      [channel]: parseFloat(
        formatNumber((item.budget * 100) / dataGraphBTotal, 2)
      )
    });
    return obj;
  }, {});
  dataGraphB = {
    ...dataGraphB,
    KPI: `Budget (${(dataGraphBTotal / 1000).toFixed(2)}k€)`
  };

  // Datas for Impression Graph
  let dataGraphI = data.reduce((obj, item) => {
    const channel = item.name;
    Object.assign(obj, {
      [channel]: parseFloat(
        formatNumber((item.impression * 100) / dataGraphITotal, 2)
      )
    });
    return obj;
  }, {});
  dataGraphI = {
    ...dataGraphI,
    KPI: `Impression (${(dataGraphITotal / 1000000).toFixed(2)}M)`
  };

  const axisBottomCustom = {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 10,
    legendOffset: 36
  };

  const themeCustom = {
    axis: {
      ticks: {
        text: {
          fontSize: 16
        }
      }
    },
    grid: {
      stroke: '#888',
      strokeWidth: 1
    }
  };

  const tooltipCustom = (id, value, color) => (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        width="20"
        height="20"
        style={{ position: 'relative', top: '2px' }}
      >
        <rect width="20" height="20" fill={color} />
      </svg>
      <strong style={{ position: 'relative', top: '-3px', left: '5px' }}>
        {id} - {value}%
      </strong>
    </>
  );

  return (
    <>
      <ContentTabGraph>
        <ResponsiveBar
          data={[dataGraphB]}
          layout="vertical"
          keys={dataGraphBKey}
          indexBy="KPI"
          margin={{ top: 50, right: 25, bottom: 50, left: 100 }}
          colors={dataColorGraphB}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          tooltip={({ id, value, color }) => tooltipCustom(id, value, color)}
          label={value => `${`${value.value}%`}`}
          enableGridY={false}
          axisBottom={axisBottomCustom}
          axisLeft={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 6]] }}
          theme={themeCustom}
          animate
          motionStiffness={90}
          motionDamping={15}
        />
      </ContentTabGraph>
      <ContentTabGraph className="withLegend">
        <ResponsiveBar
          data={[dataGraphI]}
          layout="vertical"
          keys={dataGraphIKey}
          indexBy="KPI"
          margin={{ top: 50, right: 200, bottom: 50, left: 25 }}
          colors={dataColorGraphI}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          tooltip={({ id, value, color }) => tooltipCustom(id, value, color)}
          label={value => `${`${value.value}%`}`}
          enableGridY={false}
          axisBottom={axisBottomCustom}
          axisLeft={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 6]] }}
          theme={themeCustom}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'right',
              direction: 'column',
              translateX: 95,
              translateY: -40,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 35,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          animate
          motionStiffness={90}
          motionDamping={15}
        />
      </ContentTabGraph>
    </>
  );
}

Stacked.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      budget: PropTypes.number,
      impression: PropTypes.number,
      CPM: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};

export default Distrib;
