import React from 'react';

import BaseWrapperIndicator from './style';

/**
 * WrapperIndicator
 * @component
 *
 */
function WrapperIndicator(props) {
  return <BaseWrapperIndicator {...props} />;
}
WrapperIndicator.defaultProps = {};
WrapperIndicator.propTypes = {};

export default WrapperIndicator;
