import React from 'react';
import { useTheme } from 'contexts/ThemeContext';
import PropTypes from 'prop-types';

import BaseSeparatorActivation from './style';

/**
 * SeparatorActivation
 * @component
 *
 */
function SeparatorActivation(props) {
  const { themeColors } = useTheme();
  const { uncolored, left } = props;
  const color = uncolored
    ? themeColors.primary
    : themeColors.activation_inversed;
  const align = left ? 'flex-start' : 'center';

  return <BaseSeparatorActivation color={color} align={align} {...props} />;
}
SeparatorActivation.defaultProps = {
  uncolored: false,
  left: false
};
SeparatorActivation.propTypes = {
  uncolored: PropTypes.bool,
  left: PropTypes.bool
};

export default SeparatorActivation;
