import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../../utils/functions';

/* eslint no-nested-ternary: 0 */

/**
 * ObjectifRowFunnel
 * @component
 *
 */
function ObjectifRowFunnel(props) {
  const { funnel, funnelIndex, savet3, newt3, worstlevierCPI } = props;

  const { title } = funnel.measure.funnels[funnelIndex];
  const affichet3 =
    funnel.funnelData &&
    funnel.funnelData.leverage &&
    funnel.funnelData.leverage.length > funnelIndex &&
    funnel.funnelData.leverage[funnelIndex].impact;
  const className = affichet3 > 0 ? 'red' : 'green';

  if (funnel.measure.funnels[funnelIndex].title === worstlevierCPI) {
    if (savet3 > affichet3)
      newt3(affichet3, funnel.measure.funnels[funnelIndex].title);
  }
  if (savet3 < affichet3) {
    newt3(affichet3, funnel.measure.funnels[funnelIndex].title);
  }

  return (
    <tr>
      <td>{title}</td>
      <td>
        {funnel.funnelData &&
          funnel.funnelData.leverage &&
          funnel.funnelData.leverage.length > funnelIndex &&
          funnel.funnelData.leverage[funnelIndex].goal &&
          formatNumber(funnel.funnelData.leverage[funnelIndex].goal)}
        {funnelIndex > 0 ? '%' : '€'}
      </td>
      <td>
        {funnel.funnelData &&
          funnel.funnelData.leverage &&
          funnel.funnelData.leverage.length > funnelIndex &&
          funnel.funnelData.leverage[funnelIndex].real &&
          formatNumber(funnel.funnelData.leverage[funnelIndex].real)}
        {funnelIndex !== 0 ? '%' : '€'}
      </td>
      <td>
        <div
          style={{
            width: '88px',
            textAlign: 'center',
            backgroundColor: className,
            color: 'white',
            borderRadius: '7px',
            fontWeight: 'bold',
            padding: '3px 7px'
          }}
        >
          {affichet3.toFixed(2)}%
        </div>
      </td>
    </tr>
  );
}

ObjectifRowFunnel.propTypes = {
  funnel: PropTypes.shape({
    measure: PropTypes.shape({
      funnels: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string
        })
      )
    }),
    newFormat: PropTypes.shape({
      leverage: PropTypes.arrayOf(
        PropTypes.shape({
          impact: PropTypes.number,
          goal: PropTypes.number,
          real: PropTypes.number
        })
      )
    })
  }).isRequired,
  funnelIndex: PropTypes.number.isRequired,
  newt3: PropTypes.func.isRequired,
  savet3: PropTypes.number.isRequired,
  worstlevierCPI: PropTypes.string.isRequired
};

export default ObjectifRowFunnel;
