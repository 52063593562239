import React from 'react';
import styled from 'styled-components';
import { useTheme } from 'contexts/ThemeContext';

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 20px 0;
  flex-wrap: wrap;
  font-size: 20px;
  .title {
    display: inline-flex;
    align-items: center;
    align-content: center;
  }
  .modal-logo {
    display: inline-flex;
    width: 100px;
    align-items: flex-end;
  }
  .connector-name {
    display: inline-flex;
  }
  .logo-connect {
    display: inline-flex;
  }
`;

export default Content;

/**
 * BaseModalContent
 * @component
 *
 */
const BaseModalContent = styled.div`
  background: none;
  outline: none;
  background-color: ${({ themeColors }) => themeColors.secondary};
  border: none;
  border-radius: 10px;
  bottom: auto;
  color: ${({ themeColors }) => themeColors.light};
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow: none;
  position: relative;
  top: 16vh;
  height: 80vh;
  width: 585px;
  @media (min-width: 1024px) {
    height: 80vh;
    width: 565px;
  }
  @media (min-width: 1440px) {
    height: 80vh;
    width: 595px;
  }
  @media (min-width: 1800px) {
    height: 80vh;
    width: 625px;
  }
`;
export const ModalContent = props => {
  const { themeColors } = useTheme();
  return <BaseModalContent themeColors={themeColors} {...props} />;
};

export const bodyStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '25px 45px',
  minHeight: '60%',
  maxHeight: '80%'
};

export const linkConnector = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  left: '7%',
  position: 'relative',
  maxWidth: '500px',
  overflowY: 'scroll'
};

export const linkConnectorSecond = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  left: '180%',
  position: 'relative'
};

export const linkConnectorItem = {
  height: '125px',
  width: '125px',
  backgroundColor: 'white',
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: '7%',
  marginBottom: '10%'
};

export const linkConnectorItemSecond = {
  height: '125px',
  width: '125px',
  backgroundColor: 'white',
  borderRadius: '50%',
  display: 'flex',
  marginBottom: '10%'
};

/**
 * ConnectorContainer
 * @component
 *
 */
export const ConnectorContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin: auto;
  min-height: 125px;
  text-align: center;
  position: ${({ isValid }) => (isValid ? 'relative' : 'static')};
  bottom: ${({ isValid }) => (isValid ? '30%' : '0')};
`;

export const imgStyle = {
  width: 'min-content',
  margin: 'auto',
  maxWidth: '100px'
};

export const nameStyle = {
  textAlign: 'center',
  fontWeight: 'bolder',
  marginTop: '3px',
  cursor: 'pointer'
};

export const nameStyleValid = {
  textAlign: 'center',
  fontWeight: 'bolder',
  marginTop: '3px',
  cursor: 'pointer',
  bottom: '30%',
  position: 'relative'
};

export const modalHeader = {
  padding: '0',
  justifyContent: 'center'
};

export const formAccountStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
};

export const doneButton = {
  margin: 'auto',
  width: '300px',
  height: '44px',
  textAlign: 'center',
  color: '#3e6de7',
  lineHeight: '40px',
  border: '2px solid #4e85ec',
  borderRadius: '22px',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  cursor: 'pointer'
};

export const Style = styled.div`
  label {
    background-color: #06cb52;
    color: white;
    border-radius: 100px;
    padding: 10px;
    display: inline-block;
    margin: 35px;
  }
  input {
    opacity: 0;
    position: absolute;
  }
`;

export const Description = styled.div`
  line-height: 40px;
  .point {
    background-color: #1a71e8;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
  }
  .permission-text {
    font-size: 14px;
  }
`;

export const Form = styled.form`
  .opt-in-container {
    font-size: 14px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  cursor: pointer;
`;

/**
 * BaseLabel
 * @component
 *
 */
const BaseLabel = styled.label`
  margin 0 8px;
  display: inline-flex;
  align-items: center;

  .input {
    padding: 0 10px;
    border: 1px solid #4e85ec;
    border-radius: 10px;
    height: 35px;
    min-width: 250px;
    margin: 0;
    font-size: 16px;
    background-color: ${({ themeColors }) => themeColors.dark};
    color: ${({ themeColors }) => themeColors.light};
  }
  .span {
    padding-right: 20px;
    width: 140px;
    min-width:120px;
  }
`;
export const Label = props => {
  const { themeColors } = useTheme();
  return <BaseLabel themeColors={themeColors} {...props} />;
};

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 12px 0;
`;

const BaseButton = styled.a`
  display: inline-flex;
  padding: 12px 25px;
  border-radius: 7px;
  color: ${({ color, themeColors }) => color || themeColors.light};
  background-color: ${({ bgColor, themeColors }) =>
    bgColor || themeColors.secondary};
`;
export const Button = props => {
  const { themeColors } = useTheme();
  return <BaseButton themeColors={themeColors} {...props} />;
};
