import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { useTheme } from 'contexts/ThemeContext';

/**
 * BaseConnectorButton
 * @component
 *
 */
const BaseConnectorButton = styled(Button)`
  && {
    color: #fff;
    width: auto;
    border-radius: 4px;
    background-color: ${({ themeColors }) => themeColors.funnel};
    margin: 8px auto 0;
    &:hover {
      background-color: ${({ themeColors }) => themeColors.funnel_dark};
    }
    &:disabled {
      background-color: ${({ themeColors }) => themeColors.secondary};
    }
  }
  svg {
    margin-right: 10px;
    min-width: 26px;
  }
`;

/**
 * ConnectorSVG
 * @component
 *
 */
export const ConnectorSVG = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="white" />
    <path
      d="M21 14.1429H14.1429V21H11.8571V14.1429H5V11.8571H11.8571V5H14.1429V11.8571H21V14.1429Z"
      fill="grey"
    />
  </svg>
);

export default props => {
  const { themeColors } = useTheme();
  return <BaseConnectorButton themeColors={themeColors} {...props} />;
};
