import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { ActionButton } from 'Insight/components/ButtonInsight';
import { useTheme } from 'src/contexts/ThemeContext';
import ModalBuySegmentForm from './ModalBuySegmentForm';
import {
  ModalBuySegmentBase,
  ModalBuySegmentTitle,
  ModalBuySegmentText
} from './style';

/**
 * ModalBuySegment
 * @component
 */
function ModalBuySegment({ onClose, onNext, open, ...props }) {
  const [step, setStep] = React.useState(0);
  const [emailData, setEmailData] = React.useState(null);
  const { themeColors } = useTheme();

  useEffect(() => {
    setStep(0);
  }, [open]);

  return (
    <ModalBuySegmentBase
      {...props}
      onClose={onClose}
      open={open}
      style={{ width: '500px', backgroundColor: themeColors.primary }}
    >
      <ModalBuySegmentTitle small>
        ACHETER LE SEGMENT DE PROFILS
      </ModalBuySegmentTitle>
      {!step ? (
        <ModalBuySegmentForm
          onClose={onClose}
          onNext={data => {
            setStep(1);
            setEmailData(data);
          }}
          {...props}
          style={{ backgroundColor: themeColors.primary }}
        />
      ) : (
        <>
          <ModalBuySegmentText>
            Vous serez rappelé sous 24h par l’équipe ERMES afin de finaliser
            votre commande et vous informer des prochaines étapes.
          </ModalBuySegmentText>
          <Grid container justify="center">
            <ActionButton
              width="180px"
              onClick={() => {
                onNext(emailData);
              }}
            >
              TERMINER
            </ActionButton>
          </Grid>
        </>
      )}
    </ModalBuySegmentBase>
  );
}
ModalBuySegment.defaultProps = {
  open: false,
  onClose: () => {},
  onNext: () => {}
};
ModalBuySegment.propTypes = {
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  open: PropTypes.bool,
  contacts: PropTypes.number.isRequired
};

export default ModalBuySegment;
