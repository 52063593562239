import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'contexts/ThemeContext';
import styled from 'styled-components';
import Dropdown from 'components/Dropdown';

/**
 * BaseAnomalyRow
 * @component
 *
 */
const BaseAnomalyRow = styled.tr``;

/**
 * AnomalyRowSelect
 * @component
 *
 */
export const AnomalyRowSelect = ({ themeColors, ...restProps }) => (
  <Dropdown
    color={themeColors.light}
    bgColor={themeColors.primary}
    borderColor={themeColors.funnel}
    isMui
    width="250px"
    {...restProps}
  />
);
AnomalyRowSelect.propTypes = {
  themeColors: PropTypes.objectOf(PropTypes.any).isRequired
};

/**
 * AnomalyRowColumn
 * @component
 *
 */
export const AnomalyRowColumn = withTheme(styled.td`
  border-bottom: 1px solid ${({ themeColors }) => themeColors.light};
  margin: 0px auto;
  text-align: left;
  padding: 15px;
  font-weight: bold;
  color: ${({ themeColors }) => themeColors.light};
  max-width: 300px;
`);

/**
 * AnomalyRowValue
 * @component
 *
 */
export const AnomalyRowValue = withTheme(styled.span`
  color: ${({ themeColors }) => themeColors.keep_light};
  background-color: ${({ isAlert, themeColors }) =>
    isAlert ? themeColors.danger : themeColors.success};
  border-radius: 7px;
  height: 24px;
  display: flex;
  width: 60px;
  justify-content: center;
`);

/**
 * BaseAnomalyInput
 * @component
 *
 */
const BaseAnomalyInput = styled.div`
  display: ${({ canDisplay }) => (canDisplay ? 'inline-flex' : 'none')};
  align-items: center;
  input {
    width: 210px;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #009fe3;
    outline: none;
    color: #fff;
    padding: 7px 0px 7px 20px;
    background-color: #1e1e2f;
    font-family: inherit;
    border-radius: 4px;
    margin: 0;
  }
`;

export const AnomalyInput = props => {
  const { canDisplay, ...rest } = props;
  return (
    <BaseAnomalyInput canDisplay={canDisplay}>
      <input {...rest} />
    </BaseAnomalyInput>
  );
};
AnomalyInput.propTypes = {
  canDisplay: PropTypes.bool.isRequired
};
export default BaseAnomalyRow;
