import React from 'react';

import BaseWrapperModelisation from './style';

/**
 * WrapperModelisation
 * @component
 *
 */
function WrapperModelisation(props) {
  return <BaseWrapperModelisation {...props} />;
}
WrapperModelisation.defaultProps = {};
WrapperModelisation.propTypes = {};

export default WrapperModelisation;
