import React from 'react';
import PropTypes from 'prop-types';

import { useModalActivation } from 'Activation/contexts/ModalActivationContext';
import ModalActivation from 'Activation/components/ModalActivation';

/**
 * ModalSwitchContainer
 * @container
 *
 * @desc ::
 *      Encapsulate all templates modales, template are created in
 *      Activation/components, should have a name which starts by "Template",
 *      and should be placed in AppActivation,
 *
 */
function ModalSwitchContainer(props) {
  const { children } = props;
  const { isModalActive, closeModal } = useModalActivation();
  return (
    <ModalActivation isOpen={isModalActive} onClose={closeModal}>
      {children}
    </ModalActivation>
  );
}
ModalSwitchContainer.defaultProps = {};
ModalSwitchContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default ModalSwitchContainer;
