import React from 'react';
import PropTypes from 'prop-types';
import BaseSaving, { ModalInput, Title } from './style';

/**
 * SavingActivation
 * @component
 *
 */

function SavingActivation({ onNameChange, targetName }) {
  return (
    <BaseSaving>
      <Title>Donner un nom à votre campagne</Title>
      <ModalInput
        id="outlined-bare"
        placeholder="Nom de la campagne"
        margin="normal"
        variant="outlined"
        value={targetName}
        onChange={({ target }) => onNameChange(target.value)}
      />
    </BaseSaving>
  );
}
SavingActivation.defaultProps = {};
SavingActivation.propTypes = {
  onNameChange: PropTypes.func.isRequired,
  targetName: PropTypes.string.isRequired
};

export default SavingActivation;
