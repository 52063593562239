import React from 'react';
import PropTypes from 'prop-types';
import constants from 'utils/constants';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { formatNumber } from 'utils/functions';
import TableBaseline, {
  ColumnBaseline,
  RowBaseline,
  TitleBaseline,
  DataBaseline,
  ErrorMessage
} from './style';

const { measures } = constants;

/**
 * BaselineFunnel
 * @component
 *
 */

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: 220,
    fontSize: '24px',
    border: '1px solid #dadde9',
    borderRadius: '4px'
  }
}))(Tooltip);

function BaselineFunnel(props) {
  const { funnel } = props;
  const total =
    funnel.funnelData &&
    funnel.funnelData.synthesis &&
    funnel.funnelData.synthesis.kpi4;
  const baselineData =
    funnel.funnelData &&
    funnel.funnelData.synthesis &&
    funnel.funnelData.synthesis.kpi4HB;
  const volumeTotal = total.volume && total.volume;
  const volumeBaseline = baselineData.volume ? baselineData.volume : 0;
  const volumeRemanence = baselineData.remanence ? baselineData.remanence : 0;
  const volumeImpact =
    baselineData.impact && baselineData.impact > 0
      ? baselineData.impact
      : volumeTotal - volumeBaseline - volumeRemanence;
  const baselineMeasures = measures.find(a => a.name === funnel.measure.name);
  const heightBase = 250;
  const heightImpact = formatNumber(
    (volumeImpact * heightBase) / volumeTotal,
    0
  );
  const heightRemanence = formatNumber(
    (volumeRemanence * heightBase) / volumeTotal,
    0
  );
  const heightBaseline = formatNumber(
    (volumeBaseline * heightBase) / volumeTotal,
    0
  );
  const checkDisplay = height => {
    return height > 60;
  };
  if (!volumeBaseline && !volumeRemanence)
    return (
      <TableBaseline>
        <tbody>
          <RowBaseline>
            <ErrorMessage>
              {
                "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
              }
            </ErrorMessage>
          </RowBaseline>
        </tbody>
      </TableBaseline>
    );

  return (
    <TableBaseline>
      <tbody>
        <RowBaseline>
          <ColumnBaseline textAlign="center">
            <TitleBaseline>
              <DataBaseline>{formatNumber(volumeTotal, 0)}</DataBaseline>
              {`\n${baselineMeasures.baseline.wording.total}`}
            </TitleBaseline>
          </ColumnBaseline>
        </RowBaseline>
        <RowBaseline style={{ height: `${heightImpact}px` }}>
          <ColumnBaseline
            color="keep_light"
            bgColor="funnel"
            textAlign="center"
            style={{ with: '75px' }}
          >
            {checkDisplay(heightImpact) ? (
              <div>
                <DataBaseline>{formatNumber(volumeImpact, 0)}</DataBaseline>
                {`\nimpact`}
              </div>
            ) : (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    {` ${formatNumber(volumeImpact, 0)} impact`}
                  </React.Fragment>
                }
                placement="bottom-end"
              >
                <Button style={{ width: `14em`, height: `25px` }} />
              </HtmlTooltip>
            )}
          </ColumnBaseline>
          <ColumnBaseline paddingLeft="15">
            <div>
              <DataBaseline light>
                {baselineMeasures.baseline.wording.advertising_title}
              </DataBaseline>
            </div>
          </ColumnBaseline>
        </RowBaseline>
        {volumeRemanence !== 0 && (
          <RowBaseline style={{ height: `${heightRemanence}px` }}>
            <ColumnBaseline
              color="funnel_baseline1"
              bgColor="funnel_baseline2"
              textAlign="center"
              style={{ with: '75px' }}
            >
              {checkDisplay(heightRemanence) ? (
                <div>
                  <DataBaseline>
                    {formatNumber(volumeRemanence, 0)}
                  </DataBaseline>
                  {`\nrémanence`}
                </div>
              ) : (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      {` ${formatNumber(volumeRemanence, 0)} rémanence`}
                    </React.Fragment>
                  }
                  placement="bottom-end"
                >
                  <Button style={{ width: `14em`, height: `25px` }} />
                </HtmlTooltip>
              )}
            </ColumnBaseline>
            <ColumnBaseline paddingLeft="15">
              <div>
                <DataBaseline light>
                  {baselineMeasures.baseline.wording.afterglow_title}
                </DataBaseline>
              </div>
            </ColumnBaseline>
          </RowBaseline>
        )}
        <RowBaseline style={{ height: `${heightBaseline}px` }}>
          <ColumnBaseline
            style={{ with: '75px' }}
            color="keep_light"
            bgColor="funnel_baseline1"
            textAlign="center"
            iversed
          >
            {checkDisplay(heightBaseline) ? (
              <div>
                <DataBaseline>{formatNumber(volumeBaseline, 0)}</DataBaseline>
                {`\nbaseline`}
              </div>
            ) : (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    {` ${formatNumber(volumeBaseline, 0)} baseline`}
                  </React.Fragment>
                }
                placement="bottom-end"
              >
                <Button style={{ width: `14em`, height: `25px` }} />
              </HtmlTooltip>
            )}
          </ColumnBaseline>
          <ColumnBaseline paddingLeft="15">
            <div>
              <DataBaseline light>
                {baselineMeasures.baseline.wording.baseline_title}
              </DataBaseline>
            </div>
          </ColumnBaseline>
        </RowBaseline>
        <RowBaseline height="30px" />
        <RowBaseline>
          <ColumnBaseline
            color="keep_light"
            bgColor="funnel"
            textAlign="center"
            padding="15px"
          >
            <DataBaseline>{formatNumber(baselineData.real, 2)}€</DataBaseline>
          </ColumnBaseline>
          <ColumnBaseline padding="10px" paddingLeft="15">
            <div>
              <DataBaseline light>
                {baselineMeasures.baseline.wording.kpi_title}
              </DataBaseline>
              {`\n${baselineMeasures.baseline.wording.pki_description}`}
            </div>
          </ColumnBaseline>
        </RowBaseline>
        <RowBaseline>
          <ColumnBaseline color="ligth" textAlign="center" padding="15px">
            <DataBaseline>{formatNumber(total.real, 2)}€</DataBaseline>
          </ColumnBaseline>
          <ColumnBaseline padding="10px" paddingLeft="15">
            <div>
              <DataBaseline light>
                Pour rappel: {baselineMeasures.funnels[3].id} consolidé
              </DataBaseline>
            </div>
          </ColumnBaseline>
        </RowBaseline>
      </tbody>
    </TableBaseline>
  );
}

// @TODO Fix this with better proptypes
/* eslint-disable react/forbid-prop-types */
BaselineFunnel.propTypes = {
  funnel: PropTypes.shape({
    newFormat: PropTypes.shape({
      synthesis: PropTypes.shape({
        kpi4: PropTypes.shape({
          volume: PropTypes.number,
          real: PropTypes.number
        }),
        kpi4HB: PropTypes.shape({
          volume: PropTypes.number,
          real: PropTypes.number
        })
      })
    }),
    measure: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired
};

export default BaselineFunnel;
