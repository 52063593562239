import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';

import BaseLabelActivation from './style';

/**
 * LabelActivation
 * @component
 *
 */
function LabelActivation(props) {
  const { children, colored, mediumLarge, bold, uppercase, fixed } = props;
  const { themeColors } = useTheme();
  const styledProps = {
    textcolor: themeColors.light
  };
  if (colored) {
    styledProps.textcolor = themeColors.activation_inversed;
  }
  if (mediumLarge) {
    styledProps.size = 'mediumLarge';
  }
  if (bold) {
    styledProps.bold = '600';
  }
  if (uppercase) {
    styledProps.uppercase = 'uppercase';
  }
  if (fixed) {
    styledProps.fixed = true;
  }
  return <BaseLabelActivation {...styledProps}>{children}</BaseLabelActivation>;
}

LabelActivation.defaultProps = {
  mediumLarge: false,
  colored: false,
  bold: false,
  fixed: false,
  uppercase: false,
  underline: false,
  children: undefined
};
LabelActivation.propTypes = {
  mediumLarge: PropTypes.bool,
  colored: PropTypes.bool,
  bold: PropTypes.bool,
  fixed: PropTypes.bool,
  uppercase: PropTypes.bool,
  underline: PropTypes.bool,
  children: PropTypes.node
};

export default LabelActivation;
