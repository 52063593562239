import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseMatchingVisualization
 * @component
 *
 */
const BaseMatchingVisualization = styled.div`
  display: flex;
  flex-direction: column;
  .row-matching {
    display: flex;
    justify-content: center;
    > *:first-child {
      margin-right: 8px;
    }
  }
`;

export default withTheme(BaseMatchingVisualization);
