import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import BaseLinearbarActivation from './style';

/**
 * LinearbarActivation
 * @component
 *
 */
function LinearbarActivation({ pourcentage, ...props }) {
  const { themeColors } = useTheme();
  return (
    <BaseLinearbarActivation
      {...props}
      style={{
        display: 'flex',
        paddingLeft: '16px',
        alignItems: 'center',
        justifyContent: 'start',
        width: `${pourcentage}%`,
        color: themeColors.keep_light
      }}
    />
  );
}
LinearbarActivation.defaultProps = {
  pourcentage: 100
};
LinearbarActivation.propTypes = {
  pourcentage: PropTypes.number
};

export default LinearbarActivation;
