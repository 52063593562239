import styled from 'styled-components';

/**
 * BaseVisibilities
 * @component
 *
 */
const BaseVisibilities = styled.div`
  position: relative;
  width: 100%;
  .visible_0 {
    display: ${({ isSecond }) => (isSecond && 'none') || 'block'};
  }
  .visible_1 {
    display: ${({ isSecond }) => (isSecond && 'block') || 'none'};
  }
`;

export default BaseVisibilities;
