import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseSeparator
 * @component
 *
 */
const BaseSeparator = styled.hr`
  border-color: ${({ color, themeColors }) => color || themeColors.dark};
  width: 80%;
  height: 0;
  align-self: center;
`;

export const SeparatorWrapper = withTheme(styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
  text-align: center;
  color: ${({ themeColors }) => themeColors.light};
`);

export default withTheme(BaseSeparator);
