/**
 * data status
 *
 */
export default {
  live: {
    uploading: 'Upload en cours',
    uploaded: 'Analyse du fichier',
    analyzing: 'Analyse du fichier',
    analyzed: 'Analyse du fichier',
    headers_checked: 'Vérification des données',
    database_creating: 'Création de la base de données',
    database_created: 'En cours',
    digitizing: 'Digitalisation',
    digitized: 'Finalisation'
  },
  history: {
    uploading: 'Annulé',
    uploaded: 'En cours',
    analyzing: 'En cours',
    analyzed: 'En cours',
    headers_checked: 'En cours',
    database_creating: 'En cours',
    database_created: 'En cours',
    digitizing: 'En cours',
    digitized: 'Traité'
  }
};
