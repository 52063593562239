import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'src/contexts/ThemeContext';

function ColorLine({ type, ...rest }) {
  const { themeColors } = useTheme();
  return (
    <div
      style={{
        width: '50px',
        height: '4px',
        display: 'inline-block',
        backgroundColor: themeColors[type]
      }}
      {...rest}
    />
  );
}
ColorLine.defaultProps = {};
ColorLine.propTypes = {
  type: PropTypes.oneOf(['success', 'danger']).isRequired
};

export default ColorLine;
