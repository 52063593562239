import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  success: { backgroundColor: 'white', color: '#28A745', width: '400px' },
  error: { backgroundColor: 'white', color: '#BD3E48', width: '400px' },
  warning: { backgroundColor: 'white', color: 'black', width: '400px' },
  info: { backgroundColor: 'white', color: 'black', width: '400px' }
});

export default styles;
