// import styled from 'styled-components';
import BaseModal from 'react-modal';

import constants from 'utils/constants';

const { colors } = constants;
BaseModal.setAppElement('#root');
export const modalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.8)'
  },
  content: {
    position: 'relative',
    background: colors.light.light,
    border: 'none',
    width: '65vw',
    height: '45vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    width: '50%',
    margin: '2rem'
  }
};

export default BaseModal;
