import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { StyledButton } from './style';

const style = {
  percent: color => {
    return {
      color,
      fontFamily: 'Poppins'
    };
  },
  structure: () => {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    };
  },
  buttonStyle: () => {
    return {
      color: 'white',
      fontFamily: 'Poppins',
      fontWeight: 600
    };
  },
  percentFlex: () => {
    return {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-around'
    };
  }
};

function GroupedCriteria(props) {
  const { criteria, index } = props;
  return (
    <div>
      <ButtonGroup size="small">
        {criteria[index].map(b => {
          return (
            <StyledButton color={b.color} disabled size="small">
              <div style={style.buttonStyle()}>{b.label}</div>
            </StyledButton>
          );
        })}
      </ButtonGroup>
      <div style={style.percentFlex()}>
        {criteria[index].map(b => {
          return (
            <div style={style.percent(b.percentColor)}>{`${b.percent}%`}</div>
          );
        })}
      </div>
    </div>
  );
}

GroupedCriteria.propTypes = {
  criteria: PropTypes.arrayOf(
    PropTypes.objectOf({
      label: PropTypes.string,
      color: PropTypes.string
    })
  ).isRequired,
  index: PropTypes.number.isRequired
};

export default GroupedCriteria;
