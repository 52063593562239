import React, { useCallback } from 'react';

import formatOperators, {
  checkHasSubGroup,
  reverseOperator,
  addValue,
  removeValue
} from './functions';

const OperatorContext = React.createContext();

/**
 * OperatorProvider
 * @provider
 * @desc ::
 *   a group is mapped to a category
 *   a subgroup is mapped to a subcategory
 *   then operatorsGroup is as a list of all categories
 *
 */
export default function OperatorProvider(props) {
  const [operatorsGroups, setOperatorsGroup] = React.useState({});
  const [selectedList, setSelectedList] = React.useState([]);

  const isValueSelected = criteriaID =>
    selectedList.some(key => key === criteriaID);
  const value = {
    operatorsGroups,
    /**
     * initOperatorsGroups
     * @desc called firts when we've urlId insight
     *
     */
    initOperatorsGroups: useCallback(initialOperatorsGroups => {
      setOperatorsGroup(initialOperatorsGroups);
      const initialSelectedList = Object.values(initialOperatorsGroups).reduce(
        (acc, group) => {
          const mergedValuesByGroup = Object.values(group).reduce(
            (subAcc, subGroup) => {
              const values = subGroup.values.map(criteria => criteria.key);
              return [...subAcc, ...values];
            },
            []
          );
          return [...acc, ...mergedValuesByGroup];
        },
        []
      );
      setSelectedList(initialSelectedList);
    }, []),
    formatedFilter: formatOperators(operatorsGroups),
    isValueSelected,
    toggleValueSelected(params) {
      const { criteria, tagFunnel } = params;
      const criteriaID =
        (criteria && criteria.key) || (tagFunnel && tagFunnel.key);
      let nextGroups = {};
      if (isValueSelected(criteriaID)) {
        // Delete criteria
        nextGroups = removeValue(params, operatorsGroups);
        const nextSelectedList = [...selectedList];
        const index = selectedList.indexOf(criteriaID);
        if (index === -1) return;
        nextSelectedList.splice(index, 1);
        setSelectedList(nextSelectedList);
      } else {
        // Add criteria
        nextGroups = addValue(params, operatorsGroups);
        setSelectedList([...selectedList, criteriaID]);
      }
      setOperatorsGroup(nextGroups || operatorsGroups);
    },
    handleSubGroupChange(params) {
      const { categoryKEY, subCategoryKEY } = params;
      if (!checkHasSubGroup(params, operatorsGroups)) return;
      const nextGroup = { ...operatorsGroups };
      const subGroup = nextGroup[categoryKEY][subCategoryKEY];
      nextGroup[categoryKEY][subCategoryKEY].type = reverseOperator(
        subGroup.type
      );
      setOperatorsGroup(nextGroup);
    },
    /**
     * handleSelectedChange
     * @desc reverse exclude state or type state from a criteria
     *
     */
    handleSelectedChange(params) {
      const { categoryKEY, subCategoryKEY, position, isTypeChange } = params;
      if (!checkHasSubGroup(params, operatorsGroups)) return;
      const nextGroup = { ...operatorsGroups };
      const selected = nextGroup[categoryKEY][subCategoryKEY].values[position];
      if (!selected) return;
      if (isTypeChange) {
        nextGroup[categoryKEY][subCategoryKEY].values[
          position
        ].type = reverseOperator(selected.type);
      } else {
        nextGroup[categoryKEY][subCategoryKEY].values[
          position
        ].exclude = !selected.exclude;
      }
      setOperatorsGroup(nextGroup);
    },
    /**
     * get selected list
     * @function
     * @return selected list
     *
     */
    getSelectedList() {
      return selectedList;
    }
  };

  return <OperatorContext.Provider {...props} value={value} />;
}

/**
 * useOperator
 * @function
 * @return value defined above (OperatorProvider)
 *
 */
export const useOperator = () => {
  const context = React.useContext(OperatorContext);
  if (!context)
    throw new Error('useOperator must be called inside OperatorProvider');
  return context;
};
