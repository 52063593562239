import React from 'react';
import PropTypes from 'prop-types';

import BaseActivation from './style';

/**
 * TitleActivation
 * @component
 *
 */
function TitleActivation(props) {
  const { left, medium, mediumLarge, bold } = props;
  const addeProps = {};
  if (left) addeProps.align = 'flex-start';
  if (medium) addeProps.fontsize = '18px';
  if (mediumLarge) addeProps.fontsize = '20px';
  if (bold) addeProps.fontWeight = '700';

  return <BaseActivation {...addeProps} {...props} />;
}
TitleActivation.defaultProps = {
  left: false,
  medium: false,
  mediumLarge: false,
  bold: false
};
TitleActivation.propTypes = {
  left: PropTypes.bool,
  medium: PropTypes.bool,
  mediumLarge: PropTypes.bool,
  bold: PropTypes.bool
};

export default TitleActivation;
