import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseInput
 * @component
 *
 */
const BaseInput = styled.input`
  width: 130px;
  height: 36px;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid ${({ themeColors }) => themeColors.funnel};
  outline: none;
  color: ${({ themeColors }) => themeColors.light};
  padding: 10px;
  background-color: ${({ themeColors }) => themeColors.primary};
  font-family: inherit;
  border-radius: 4px;
  margin: 0;
`;

export default withTheme(BaseInput);
