import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import Modal from 'Data/components/Modal';
import Title from 'components/Title';
import Text from 'components/Text';

export const ModalBuySegmentBase = withTheme(styled(Modal)`
  width: 500px;
  backgroundcolor: ${({ themeColors }) => themeColors.primary};
`);

export const ModalBuySegmentTitle = withTheme(styled(Title)`
  font-size: 24px;
  color: ${({ themeColors }) => themeColors.light};
`);

export const ModalBuySegmentText = withTheme(styled(Text)`
  margin-bottom: 24px;
  color: ${({ themeColors }) => themeColors.light};
`);

export default ModalBuySegmentBase;
