import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseLayout
 * @component
 *
 */
const BaseLayout = styled.div`
  background-color: ${({ themeColors }) => themeColors.primary};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default withTheme(BaseLayout);
