import styled from 'styled-components';
import Modal from 'components/Modal';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseModalActivation
 * @component
 *
 */
const BaseModalActivation = styled(Modal)`
  height: 500px;
  min-height: 75vh;
  border-radius: 12px;
  overflow: hidden;
`;

export const contentStyle = {
  width: '44vw',
  height: '68vh'
};

export default withTheme(BaseModalActivation);
