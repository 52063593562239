import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'Data/components/Modal';
import Loading from 'src/components/Loading';

function DatePickerModal({ onClose, ...props }) {
  return (
    <Modal {...props} onClose={onClose}>
      <Loading loading />
    </Modal>
  );
}

DatePickerModal.defaultProps = {
  open: false,
  onClose: () => {}
};
DatePickerModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default DatePickerModal;
