import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Grid from 'components/Grid';
import { withTheme } from 'contexts/ThemeContext';
import Button from 'components/Button';
import Text from 'components/Text';
import Title from 'components/Title';
import Box from '@material-ui/core/Box';
import Image from 'components/Image';
import PropTypes from 'prop-types';

const DashboardWrapper = props => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    min-height="100%"
    max-width="1440px"
    margin="0 auto"
    line-height="1.5"
    item
    xs={12}
    {...props}
  />
);

export const Links = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
`;

/**
 * LinkHistory
 * @component
 *
 */
export const LinkHistory = withTheme(styled(Link)`
color: white;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
 `);

export const DashboardButton = withTheme(styled(Button)`
  && {
    background-color: white;
    color: ${({ themeColors }) => themeColors.keep_dark};
    font-weight: 700;

    &:hover {
      background-color: white;
    }
  }
  svg {
    margin-left: 5px;
  }
`);

export const DashboardBoxCta = styled(Box)`
  position: relative;
  margin-bottom: 20px;
  text-align: center;
`;

export const DashboardBox = styled(Box)`
  && {
    display: grid;
    grid-template-rows: 28px 119px 228px 1fr;
    background-color: ${({ color }) => color};
    flex-direction: column;
    border-radius: ${({ borderRadius }) => borderRadius};
    opacity: ${({ opacity }) => opacity};
    justify-content: space-between;
    position: relative;
    flex: 0 0 auto;
    width: 25%;
    color: #fff;
    padding: 15px;
    min-height: 460px;
    max-height: 650px;
    margin-top: 15%;
    @media (max-width: $1024px) {
      width: 100%;
    }

    @media (max-width: 1280px) {
      width: 50%;
    }
  }
`;

// export const DashboardBox = styled(Grid)`
//   display: grid;
//   grid-template-rows: 28px 119px 228px 1fr;
//   background-color: ${({ color }) => color};
//   border-radius: ${({ borderRadius }) => borderRadius};
// `;

export const DashboardBoxModule = props => {
  const { color } = props;
  return (
    <DashboardBox
      color={color}
      borderRadius="10px 0  0 10px"
      maxWidth="800px"
      width="25%"
      {...props}
    />
  );
};
DashboardBoxModule.propTypes = {
  color: PropTypes.objectOf(PropTypes.any).isRequired
};

export const DashboardBoxSubtile = styled(Box)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
`;

export const DashboardBoxElement = styled(Box)`
  font-size: 0.9rem;
  line-height: 1.3;
  max-width: 260px;
  display: block;
  margin: 20px 0;
`;

export const DashboardTitle = styled(Title)`
  margin-left: 8px;
  margin-top: 0;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  align-items: center;
`;

export const DashboardTextSubtile = styled(Text)`
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 6px;
`;

export const DashboardText = styled(Text)`
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.3;
  max-width: 260px;
  margin-bottom: 45px;
`;

export const DashboardImage = styled(Image)`
  height: 20px;
  margin-right: 15px;
`;

export default DashboardWrapper;
