import MuButton from '@material-ui/core/Button';
import styled from 'styled-components';

import { withTheme } from 'src/contexts/ThemeContext';

/**
 * ButtonFill
 * @component
 */
const ButtonFill = withTheme(styled(MuButton)`
  && {
    color: ${({ themeColors }) => themeColors.keep_light};
    background-color: ${({ themeColors }) => themeColors.data};
    border-radius: 40px;
    width: ${({ width }) => width || 'auto'};
    &:hover {
      background-color: ${({ themeColors }) => themeColors.data};
      opacity: 0.7;
    }
    &:disabled {
      color: ${({ themeColors }) => themeColors.keep_light};
      background-color: ${({ themeColors }) => themeColors.data};
      opacity: 0.5;
    }
  }
`);
ButtonFill.defaultProps = {};
ButtonFill.propTypes = {};

export default ButtonFill;
