import list from './list';

/**
 * Icon
 *
 */
function Icon({ icon, attrs, ...props }) {
  const Ico = list[icon](attrs, props);
  return Ico || list.default;
}

export default Icon;
