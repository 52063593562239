import React from 'react';
import PropTypes from 'prop-types';

import BaseConnectorButton, { ConnectorSVG } from './style';

/**
 * ConnectorButtonFunnel
 * @component
 *
 */
function ConnectorButtonFunnel(props) {
  const { handleClick, name } = props;
  return (
    <BaseConnectorButton onClick={handleClick}>
      <ConnectorSVG />
      <strong>{name}</strong>
    </BaseConnectorButton>
  );
}
ConnectorButtonFunnel.defaultProps = {};
ConnectorButtonFunnel.propTypes = {
  handleClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default ConnectorButtonFunnel;
