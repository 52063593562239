import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ResponsiveStream } from '@nivo/stream';
import moment from 'moment';
import { formatNumber } from 'utils/functions';
import { useTheme } from 'contexts/ThemeContext';
import { ContentTab, ErrorMessage } from './style';

/**
 * GraphTab
 * @component
 *
 */
function GraphTab(props) {
  const { funnel } = props;
  const { themeColors } = useTheme();
  const baseline =
    funnel.funnelData &&
    funnel.funnelData.timeseries &&
    funnel.funnelData.timeseries.baseline;
  const remanence =
    funnel.funnelData &&
    funnel.funnelData.timeseries &&
    funnel.funnelData.timeseries.remanence;
  const impact =
    funnel.funnelData &&
    funnel.funnelData.timeseries &&
    funnel.funnelData.timeseries.impact;
  const dataGraph = impact.map((val, i) => {
    const impactFormatted =
      impact && impact[i] && impact[i][1] > 0 ? impact[i][1] : 0;
    const remanenceFormatted = remanence && remanence[i] ? remanence[i][1] : 0;
    const baselineFormatted = baseline && baseline[i] ? baseline[i][1] : 0;
    return {
      date: moment(val[0], 'DD/MM/YYYY').format('DD/MM/YYYY'),
      Impact: formatNumber(impactFormatted, 0),
      Rémanence: formatNumber(remanenceFormatted, 0),
      Baseline: formatNumber(baselineFormatted, 0)
    };
  });
  const xScale = 15;
  const dataGraphFilter = Math.round(dataGraph.length / xScale);
  const tickGraph = dataGraph
    .map((value, index) => index)
    .filter(
      (value, i) =>
        dataGraph.length < xScale ||
        (dataGraph.length > xScale && i % dataGraphFilter === 0) ||
        dataGraph.length - 1 === i
    );

  return (
    <Grid container justify="center" alignItems="center" alignContent="center">
      <ContentTab>
        {!remanence && !impact ? (
          <ErrorMessage>
            {
              "Il semblerait que les données analysées ne permettent pas d'afficher ce module. Dans le cas d'un csv uploadé, assurez-vous que celui-ci est au bon format ou bien contactez l'équipe Ermes"
            }
          </ErrorMessage>
        ) : (
          <ResponsiveStream
            data={dataGraph}
            keys={['Baseline', 'Rémanence', 'Impact']}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            fillOpacity={0.85}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 30,
              legend: '',
              legendOffset: 36,
              tickValues: tickGraph,
              format: x => {
                return dataGraph[x] && dataGraph[x].date && dataGraph[x].date;
              }
            }}
            axisLeft={{
              orient: 'left',
              tickValues: 4,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: -40,
              format: x => {
                return x * 100;
              }
            }}
            theme={{
              textColor: themeColors.light,
              tickColor: themeColors.funnel_baseline1
            }}
            enableGridX={false}
            enableGridY
            curve="linear"
            offsetType="expand"
            colors={[
              themeColors.funnel_baseline1,
              themeColors.funnel_baseline2,
              themeColors.funnel_baseline3
            ]}
            borderColor={{ from: 'color', modifiers: [] }}
            dotSize={2}
            dotColor={{ from: 'color' }}
            dotBorderWidth={2}
            dotBorderColor={{ from: 'color', modifiers: [['darker', 0.7]] }}
            animate
            motionStiffness={90}
            motionDamping={15}
            legends={[
              {
                anchor: 'top-right',
                direction: 'column',
                translateX: 95,
                itemWidth: 80,
                itemHeight: 40,
                itemTextColor: themeColors.funnel_baseline4,
                symbolSize: 24,
                symbolShape: 'square',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: themeColors.light
                    }
                  }
                ]
              }
            ]}
          />
        )}
      </ContentTab>
    </Grid>
  );
}

// @TODO Fix this with better proptypes
/* eslint-disable react/forbid-prop-types */
GraphTab.propTypes = {
  funnel: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default GraphTab;
