import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'utils/moment';
import React from 'react';
import constants from 'utils/constants';
import ModalHistoryFunnel from 'Funnel/components/ModalHistoryFunnel';
import DeleteModalContent from './DeleteModalContent';
import TableFunnel, {
  Buttons,
  Icons,
  TableRowFunnel,
  TableBodyFunnel,
  TableItemFunnel
} from './style';

const { routes } = constants;
const TABLE_LENGTH = 5;
/**
 * HistoryFunnel
 * @component
 * @see HistoryFunnelContainer
 *
 */
function HistoryFunnel(props) {
  const { children } = props;
  return (
    <TableFunnel>
      <TableRowFunnel isHead length={TABLE_LENGTH}>
        <TableItemFunnel>Nom du Funnel</TableItemFunnel>
        <TableItemFunnel>Objectif analysé</TableItemFunnel>
        <TableItemFunnel>Date de sauvegarde</TableItemFunnel>
        <TableItemFunnel>Période analysée</TableItemFunnel>
        <TableItemFunnel> Actions </TableItemFunnel>
      </TableRowFunnel>
      <TableBodyFunnel>{children}</TableBodyFunnel>
    </TableFunnel>
  );
}
HistoryFunnel.defaultProps = {
  children: undefined
};
HistoryFunnel.propTypes = {
  children: PropTypes.node
};

/**
 * BaseHistoryItem
 * @component
 * @see HistoryFunnelContainer
 *
 */
const BaseHistoryItem = props => {
  const { funnel, refetch, history, deleteFunnel } = props;
  const measure = JSON.parse(funnel.measure);
  const localisation = `${routes.FUNNEL}/${funnel.id}`;
  const [deleteClicked, setDeleteClicked] = React.useState(false);
  const closeModal = () => {
    setDeleteClicked(false);
  };
  const handleClick = () => {
    history.push(localisation);
  };
  const variables = { id: funnel.id };
  const onDelete = () => {
    deleteFunnel({ variables }).then(() => {
      closeModal();
      refetch();
    });
  };
  return (
    <TableRowFunnel length={TABLE_LENGTH}>
      <TableItemFunnel handleClick={handleClick}>{funnel.name}</TableItemFunnel>
      <TableItemFunnel handleClick={handleClick}>
        {measure.name}
      </TableItemFunnel>
      <TableItemFunnel handleClick={handleClick}>
        {moment.unix(funnel.updatedAt / 1000).format('L')}
      </TableItemFunnel>
      <TableItemFunnel handleClick={handleClick}>
        {moment(funnel.start).format('L')}-{moment(funnel.end).format('L')}
      </TableItemFunnel>
      <TableItemFunnel center>
        <Buttons onClick={() => setDeleteClicked(true)} type="button">
          <Icons icon="trash" />
          Supprimer
        </Buttons>
      </TableItemFunnel>
      <ModalHistoryFunnel
        isOpen={deleteClicked}
        handleClose={closeModal}
        handleValidation={onDelete}
        validationLabel="Supprimer"
        label="Supprimer funnel historique"
      >
        <DeleteModalContent name={funnel.name} />
      </ModalHistoryFunnel>
    </TableRowFunnel>
  );
};
BaseHistoryItem.propTypes = {
  funnel: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  refetch: PropTypes.func.isRequired,
  deleteFunnel: PropTypes.func.isRequired
};

export const HistoryFunnelItem = withRouter(BaseHistoryItem);

export default HistoryFunnel;
