import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ColorLinearProgress = withStyles({
  barColorPrimary: {
    backgroundColor: '#e5007d'
  }
})(LinearProgress);

export default ColorLinearProgress;
