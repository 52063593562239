import { gql } from 'apollo-boost';

export default {
  LIST_FUNNEL: gql`
    query funnels($limit: Int) {
      funnels(limit: $limit) {
        id
        name
        measure
        funnelData
        start
        end
        createdAt
        updatedAt
      }
    }
  `,
  extractListFunnel: response => {
    if (response.loading) return [];
    return response.data.funnels;
  },
  LIST_FUNNEL_ADD: gql`
    query funnels($nextToken: String, $limit: Int) {
      funnels(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          measure
          funnelData
          start
          end
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `,
  DELETE_FUNNEL: gql`
    mutation deleteFunnel($id: ID!) {
      deleteFunnel(id: $id)
    }
  `,

  CREATE_FUNNEL: gql`
    mutation createFunnel(
      $userId: ID!
      $name: String
      $budget: Float
      $saved: Boolean
      $start: DateTime
      $end: DateTime
      $anom: Json
      $connected: Json
      $forecast: Json
      $measure: Json
      $funnelData: Json
      $objectifed: Json
    ) {
      createFunnel(
        input: {
          userId: $userId
          name: $name
          budget: $budget
          saved: $saved
          start: $start
          end: $end
          anom: $anom
          connected: $connected
          forecast: $forecast
          measure: $measure
          funnelData: $funnelData
          objectifed: $objectifed
        }
      ) {
        anom
        budget
        connected
        createdAt
        end
        forecast
        id
        measure
        funnelData
        name
        objectifed
        saved
        start
        updatedAt
        userId
      }
    }
  `,
  GET_FUNNEL: gql`
    query funnel($id: ID!) {
      funnel(id: $id) {
        anom
        budget
        connected
        createdAt
        end
        forecast
        id
        measure
        funnelData
        name
        objectifed
        saved
        start
        updatedAt
        userId
      }
    }
  `,
  UPDATE_FUNNEL: gql`
    mutation updateFunnel(
      $id: ID!
      $userId: ID!
      $name: String
      $budget: Float
      $saved: Boolean
      $start: DateTime
      $end: DateTime
      $anom: Json
      $connected: Json
      $forecast: Json
      $measure: Json
      $funnelData: Json
      $objectifed: Json
    ) {
      updateFunnel(
        input: {
          anom: $anom
          budget: $budget
          connected: $connected
          end: $end
          forecast: $forecast
          id: $id
          measure: $measure
          funnelData: $funnelData
          name: $name
          objectifed: $objectifed
          saved: $saved
          start: $start
          userId: $userId
        }
      ) {
        anom
        budget
        connected
        createdAt
        end
        forecast
        id
        measure
        funnelData
        name
        objectifed
        saved
        start
        updatedAt
      }
    }
  `
};
