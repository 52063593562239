import styled from 'styled-components';
import { withTheme } from 'src/contexts/ThemeContext';
import Modal from 'Data/components/Modal';
import Title from 'src/components/Title';
import Text from 'src/components/Text';

export const ModalLaunchCampaignBase = withTheme(styled(Modal)`
  width: 500px;
  backgroundcolor: ${({ themeColors }) => themeColors.primary};
`);

export const ModalLaunchCampaignTitle = withTheme(styled(Title)`
  font-size: 24px;
  color: ${({ themeColors }) => themeColors.light};
`);

export const ModalLaunchCampaignText = withTheme(styled(Text)`
  margin-bottom: 24px;
  color: ${({ themeColors }) => themeColors.light};
`);

export default ModalLaunchCampaignBase;
