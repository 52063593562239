import React from 'react';
import PropTypes from 'prop-types';

import QueryLoadingFunnel, { QueryErrorFunnel } from './style';

/**
 * QueryHandlingFunnel
 * @component
 * @desc catch the response of a getFunnel query
 * @return  loading or error or children
 * @see modules/Funnel/index.js
 *
 */
function QueryHandlingFunnel(props) {
  const { error, loading, children, data } = props;
  const { funnel } = data;
  const canDisplay = !error && !loading && funnel;
  if (canDisplay) return children;
  if (loading) return <QueryLoadingFunnel {...props} />;
  return <QueryErrorFunnel />;
}

QueryHandlingFunnel.defaultProps = {
  data: {},
  error: null
};

QueryHandlingFunnel.propTypes = {
  error: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node.isRequired
};

export default QueryHandlingFunnel;
