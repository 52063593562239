import { StyleSheet } from '@react-pdf/renderer';
import constants from 'utils/constants';

const { colors } = constants;

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 25
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    width: '55%',
    color: colors.funnel,
    fontWeight: 'bold',
    fontSize: '16'
  },
  titleContent: {
    fontFamily: 'Roboto',
    paddingTop: 5,
    paddingBottom: 10,
    paddingHorizontal: 45,
    display: 'flex',
    flexDirection: 'row'
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '15%'
  },
  area: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',
    width: '30%',
    color: '#93939D'
  },
  resumeContent: {
    fontFamily: 'Roboto',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#E6E6E6',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10'
  },
  objectif: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    paddingLeft: 40
  },
  date: {
    display: 'flex',
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 40
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  bold: {
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  budgetContent: {
    fontFamily: 'Roboto',
    paddingTop: 5,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10'
  },
  prevu: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    paddingLeft: 40
  },
  real: {
    display: 'flex',
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 40
  },
  subtitle: {
    fontFamily: 'Roboto',
    marginTop: 20,
    fontSize: '14',
    textAlign: 'center',
    color: '#979797'
  },
  syntheseContent: {
    fontFamily: 'Roboto',
    paddingHorizontal: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  box: {
    height: '65',
    padding: '10 5',
    width: '23%',
    textAlign: 'center',
    fontSize: 10,
    border: '1 solid #009fe3',
    color: 'black',
    borderRadius: 10
  },
  baslineAnomTitle: {
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    fontSize: 14,
    textAlign: 'center',
    color: '#979797'
  },
  baselineTitle: {
    width: '60%'
  },
  anomTitle: {
    width: '40%'
  },
  bottomContent: {
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'row'
  },
  baselineDataContent: {
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    width: '20%',
    textAlign: 'center',
    justifyContent: 'center'
  },
  baselineDataTop: {
    margin: '5 0 0 45',
    padding: '7 0',
    border: '1 solid #009fe3',
    color: colors.funnel,
    borderRadius: 3
  },
  baselineDataBottom: {
    margin: '1 0 0 45',
    padding: '7 0 0 0',
    border: '1 solid black',
    color: 'black',
    borderRadius: 3
  },
  baselineDataTotal: {
    fontSize: 10,
    textAlign: 'center',
    justifyContent: 'center',
    color: 'black',
    width: '20%'
  },
  cpihb: {
    fontSize: 10,
    textAlign: 'center',
    justifyContent: 'center',
    color: colors.funnel,
    width: '20%'
  },
  anomalies: {
    justifyContent: 'center',
    fontSize: 10,
    textAlign: 'center',
    width: '40%'
  },
  blue: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 'bold',
    color: colors.funnel
  },
  black: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 'bold',
    color: colors.dark
  },
  grey: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 'bold',
    color: '828282'
  },
  distribMediaContainer: {
    fontFamily: 'Roboto',
    paddingHorizontal: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  mediaBox: {
    marginTop: '10',
    height: '50',
    padding: '10 5',
    width: '20%',
    textAlign: 'center',
    fontSize: 10,
    color: 'black'
  },
  footer: {
    margin: '20 0 0 0',
    fontFamily: 'Roboto',
    fontSize: 8,
    display: 'flex',
    textAlign: 'center'
  },

  table: {
    fontFamily: 'Roboto',
    margin: '0 45',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1 solid black'
  },
  void: {
    display: 'flex',
    flexDirection: 'row',
    width: '25%'
  },
  cell: {
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    width: '25%',
    alignItems: 'center',
    padding: '5 0',
    borderTop: '1 solid black',
    borderRight: '1 solid black'
  },
  cellLeft: {
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    width: '25%',
    alignItems: 'center',
    padding: '5 0',
    borderLeft: '1 solid black',
    borderRight: '1 solid black',
    borderTop: '1 solid black'
  },
  titleRow: {
    display: 'flex',
    padding: '10 0 0 0',
    flexDirection: 'row',
    textAlign: 'center',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    width: '100%'
  }
});

export default styles;
