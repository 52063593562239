import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import Label from 'components/Label';
import constants from 'utils/constants';

const { fontSizes } = constants;

/**
 * BaseLabelActivation
 * @component
 *
 */
const BaseLabelActivation = styled(Label)`
  font-weight: ${({ bold }) => bold || 'normal'};
  color: ${({ textcolor }) => textcolor};
  font-size: ${({ size }) => fontSizes[size] || 'inherit'};
  u {
    text-transform: ${({ uppercase }) => uppercase || 'none'};
    text-decoration-color: ${({ themeColors }) =>
      themeColors.activation_inversed};
  }
  .colored {
    color: ${({ themeColors }) => themeColors.activation};
  }
  ${({ fixed, themeColors }) =>
    fixed
      ? `
    background: ${themeColors.primary};
    border: 1px solid ${themeColors.insight_tertiary};
    border-radius: 3px;
    padding: 8px 20px;
    `
      : ''}
  span {
    width: auto;
    text-align: center;
  }
`;

export default withTheme(BaseLabelActivation);
