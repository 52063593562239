import React from 'react';

import { useModalActivation } from 'Activation/contexts/ModalActivationContext';
import { useInsightCible } from 'src/contexts/InsightCibleContext';
import { useSegment } from 'Activation/contexts/SegmentContext';
import { useTheme } from 'src/contexts/ThemeContext';
import { useDataCible } from 'src/contexts/DataCibleContext';
import { useStep } from 'Activation/contexts/StepContext';

import ButtonActivation from 'Activation/components/ButtonActivation';
import IconActivation from 'Activation/components/IconActivation';
import LabelActivation from 'Activation/components/LabelActivation';
import TitleActivation from 'Activation/components/TitleActivation';
import WrapperAudiencesChoice from 'Activation/components/WrapperAudiencesChoice';

/**
 *
 * AudiencesChoiceContainer
 *
 * @container
 *
 * @desc ::
 *      The top part at the first page, allow to display modal for choose
 *      data/insight.
 *
 * @see Activation/containers/ImportInsightContainer -> insight modal import
 * @see Activation/containers/ImportDataContainer -> data modal import
 * TODO:: enlever ces tyles dans les buttons svp
 */
function AudiencesChoiceContainer() {
  const { activeDataModal, activeInsightModal } = useModalActivation();
  const { cible, setIsSegmentSet } = useInsightCible();
  const { data, setIsSegmentSetData } = useDataCible();
  const { setSegmentProfiles, id } = useSegment();
  const { themeColors } = useTheme();
  const { validProfiles, setValidProfiles } = useStep();

  if (!id && cible.nbProfile !== 0 && !cible.isSegmentSet) {
    setSegmentProfiles(cible.nbProfile);
    setIsSegmentSet(true);
  } else if (!id && data.nbProfile && !data.isSegmentSet) {
    setSegmentProfiles(data.nbProfile);
    setIsSegmentSetData(true);
  }

  return (
    <WrapperAudiencesChoice>
      <TitleActivation mediumLarge fontWeight="bold">
        1. Choisissez votre audience
      </TitleActivation>
      <div className="row-audience">
        <div className="column-audience">
          <LabelActivation>
            À partir de vos données propriétaires
          </LabelActivation>
          <ButtonActivation
            padding="1px 10px"
            width="210px"
            onClick={() => {
              activeDataModal();
              setValidProfiles(false);
            }}
            bgcolor={themeColors.data}
            bordercolor={themeColors.data}
          >
            <IconActivation icon="arrowDown" />
            <span>Importer un segment</span>
          </ButtonActivation>
        </div>
        <hr />
        <div className="column-audience">
          <LabelActivation>À partir des audiences Ermes </LabelActivation>
          <ButtonActivation
            padding="1px 10px"
            width="210px"
            onClick={() => {
              activeInsightModal();
              setValidProfiles(false);
            }}
            bgcolor={themeColors.insight}
            bordercolor={themeColors.insight}
          >
            <IconActivation icon="arrowDown" />
            <span>Importer un segment</span>
          </ButtonActivation>
        </div>
      </div>
      {validProfiles && (
        <div className="column-error">Vous devez importer une audience.</div>
      )}
    </WrapperAudiencesChoice>
  );
}
AudiencesChoiceContainer.defaultProps = {};
AudiencesChoiceContainer.propTypes = {};

export default AudiencesChoiceContainer;
