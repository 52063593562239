import constants from 'utils/constants';
import {
  DefaultSelected,
  DefaultSubGroup,
  FilterComposed,
  FilterSimple
} from './constructor';

const { insightCriteriaTypes } = constants;
const { OR, AND, SIMPLE, EXCLUDE } = insightCriteriaTypes;

const createFirstFilter = item => new FilterSimple(item);

const transformFirstFilterToComposed = ({ acc, item }) =>
  new FilterComposed({
    type: item.type,
    value: [acc, new FilterSimple(item)]
  });

const addNewValueToFilter = ({ acc, item }) => ({
  ...acc,
  value: [...acc.value, new FilterSimple(item)]
});

const inverseFilterType = ({ item, acc }) => {
  const filterItem = new FilterSimple(item);
  if (item.type.NAME === OR.NAME) {
    return new FilterComposed({
      type: OR.NAME,
      value: [acc, filterItem]
    });
  }
  const newAcc = { ...acc };
  const lastItem = newAcc.value.pop();
  newAcc.value.push({
    type: AND.NAME,
    value: [lastItem, filterItem]
  });
  return newAcc;
};

/**
 * formatOperators
 * @constructor
 * @desc format operators to send to the API (graphql)
 *
 */
export default function formatOperators(operatorsGroups) {
  const categories = [];
  Object.values(operatorsGroups).forEach(category => {
    const filterCategory = Object.values(category)
      .filter(subCategory => subCategory.values.length)
      .map(subCategory => ({
        // create one filter by subcategory, becoming subCategoryFilter
        type: subCategory.type,
        filter: subCategory.values.reduce((acc, item) => {
          if (!acc) {
            return createFirstFilter(item);
          }
          if (!acc.value) {
            return transformFirstFilterToComposed({ item, acc });
          }
          if (acc.type === item.type.NAME) {
            return addNewValueToFilter({ item, acc });
          }
          return inverseFilterType({ acc, item });
        }, null)
      }))
      .reduce((acc, groupItem) => {
        // merge all subCategoryFilters into one categoryFilter
        if (!acc) return [groupItem.filter];
        const nextAcc = Array.isArray(acc) ? [...acc] : [acc];
        if (groupItem.type.NAME === AND.NAME) {
          return [...nextAcc, groupItem.filter];
        }
        const lastItem = nextAcc.pop();
        if (lastItem.type === OR.NAME) {
          lastItem.value.push(groupItem.filter);
          return [...nextAcc, lastItem];
        }
        const newComposedGroup = new FilterComposed({
          value: [lastItem, groupItem.filter]
        });
        return newComposedGroup;
      }, null);
    if (filterCategory) categories.push(filterCategory);
  });
  return {
    type: AND.NAME,
    value: [...categories.flat()]
  };
}

const getSubcategoryByFilter = ({ criteriaKey, allFilters }) => {
  let criteria = null;
  const subCategory = allFilters.find(subFilter => {
    const crit = subFilter.criterias.find(({ key }) => key === criteriaKey);
    if (crit) criteria = crit;
    return !!crit;
  });
  return { subCategory, criteria };
};

const searchCriteriaKey = value => {
  return value[0].criteriaKey || searchCriteriaKey(value[0].value);
};

export const getCriteriaParam = (criteria, position) => {
  return {
    ...criteria,
    exclude: criteria.type === SIMPLE.NAME,
    type: position
  };
};

export const exInitialFilters = {
  type: 'AND',
  value: [
    { type: 'IS', criteriaKey: 'C_14' },
    { type: 'IS', criteriaKey: 'C_2' },
    {
      type: 'OR',
      value: [
        { type: 'IS', criteriaKey: 'C_436' },
        { type: 'IS', criteriaKey: 'C_451' },
        { type: 'IS', criteriaKey: 'C_447' }
      ]
    },
    {
      type: 'AND',
      value: [
        { type: 'IS', criteriaKey: 'C_65' },
        { type: 'IS', criteriaKey: 'C_66' }
      ]
    },
    {
      type: 'OR',
      value: [
        { type: 'IS', criteriaKey: 'C_131' },
        {
          type: 'OR',
          value: [
            { type: 'IS', criteriaKey: 'C_147' },
            { type: 'IS', criteriaKey: 'C_149' }
          ]
        }
      ]
    }
  ]
};

const createSubGroup = topParams => {
  const { group, subCategory } = topParams;
  const subGroup = group[subCategory.key] || new DefaultSubGroup();
  return subGroup;
};

function recursiveAddOperator(
  { criteriaKey, type, value },
  { initialFilters, allFilters, group }
) {
  const key = criteriaKey || searchCriteriaKey(value);
  const { subCategory, criteria } = getSubcategoryByFilter({
    criteriaKey: key,
    allFilters
  });
  const subGroup = createSubGroup({ group, subCategory });
  if (criteriaKey) {
    const position = (subGroup.values && subGroup.values.length) || 0;
    const params = {
      ...criteria,
      exclude: criteria.type !== SIMPLE.NAME,
      type: initialFilters.type,
      position
    };
    const segment = new DefaultSelected(params);
    subGroup.values.push(segment);
    return { subId: subCategory.key, subGroup };
  }
  subGroup.type = insightCriteriaTypes.find(t => t.NAME === type);
  value.forEach(subValue => {
    if (subValue.criteriaKey) {
      const position = (subGroup.values && subGroup.values.length) || 0;
      const params = {
        ...criteria,
        exclude: criteria.type !== SIMPLE.NAME,
        type: initialFilters.type,
        position
      };
      const segment = new DefaultSelected(params);
      subGroup.values.push(segment);
    }
  });
  return { subId: subCategory.key, subGroup };
}

export function parseOperator(
  operators,
  { initialFilters, allFilters, currentCategory }
) {
  const group = operators[currentCategory] || {};
  initialFilters.value.forEach(valueTypes => {
    const { subId, subGroup } = recursiveAddOperator(valueTypes, {
      initialFilters,
      allFilters,
      currentCategory,
      group
    });
    group[subId] = subGroup;
  });
  return group;
}

export const end = {
  '903afe5b-1b52-4250-a9a5-258256c46d49': {
    '3322d116-1ae9-4120-89c8-95cf5633dc74': {
      type: { NAME: 'AND', LABEL: 'ET' },
      values: [
        {
          exclude: false,
          key: 'C_14',
          name: 'Femme',
          position: 0,
          type: { NAME: 'OR', LABEL: 'OU' }
        }
      ]
    },
    '654cadcf-ae8b-4fa2-85f6-1f1ad3240ef5': {
      type: { NAME: 'AND', LABEL: 'ET' },
      values: [
        {
          exclude: false,
          key: 'C_2',
          name: '18-24',
          position: 0,
          type: { NAME: 'OR', LABEL: 'OU' }
        }
      ]
    }
  },
  '13799a41-8235-449f-806a-4a6ec746c69b': {
    '4bd0d721-3fef-4cdb-877f-6d9bcb650848': {
      type: { NAME: 'AND', LABEL: 'ET' },
      values: [
        {
          exclude: false,
          key: 'C_451',
          name: '75 - Paris',
          position: 0,
          type: { NAME: 'OR', LABEL: 'OU' }
        }
      ]
    }
  }
};
export const checkHasSubGroup = (params, ref) => {
  const { categoryKEY, subCategoryKEY } = params;
  if (!ref[categoryKEY]) return false;
  if (!ref[categoryKEY][subCategoryKEY]) return false;
  return true;
};

/**
 * reverseOperator
 * @param [Object] type
 * @return Object
 *
 */
export const reverseOperator = type => {
  if (type.NAME === OR.NAME) return AND;
  if (type.NAME === AND.NAME) return OR;
  if (type.NAME === SIMPLE.NAME) return EXCLUDE;
  return SIMPLE;
};

/**
 * addValue
 * @desc add a criteria to a group
 * @return new group with new value
 */
export const addValue = (params, operatorsGroups) => {
  const { categoryKEY, subCategoryKEY, criteria, tagFunnel } = params;
  const nextGroup = { ...operatorsGroups };
  // add to operatorsGroups
  if (!checkHasSubGroup(params, operatorsGroups)) {
    nextGroup[categoryKEY] = nextGroup[categoryKEY] || {};
    nextGroup[categoryKEY][subCategoryKEY] = new DefaultSubGroup();
  }
  const subGroup = nextGroup[categoryKEY][subCategoryKEY];
  nextGroup[categoryKEY][subCategoryKEY].values.push(
    new DefaultSelected({
      key: (criteria && criteria.key) || (tagFunnel && tagFunnel.key),
      name: (criteria && criteria.name) || (tagFunnel && tagFunnel.name),
      position: subGroup.values.length,
      tagFunnel: tagFunnel
        ? { id: tagFunnel.id, level: tagFunnel.level }
        : undefined
    })
  );
  return nextGroup;
};

/**
 * removeValue
 * @desc remove a criteria from a group
 * @return new group without the value
 *
 */
export const removeValue = (params, operatorsGroups) => {
  const { categoryKEY, subCategoryKEY, criteria, tagFunnel } = params;
  const keyToRemove =
    (criteria && criteria.key) || (tagFunnel && tagFunnel.key);
  if (!checkHasSubGroup(params, operatorsGroups)) return null;
  // update operatorsGroups
  const nextGroup = { ...operatorsGroups };
  const subGroup = nextGroup[categoryKEY][subCategoryKEY];
  if (!subGroup.values.length) return null;
  if (subGroup.values.length === 1) {
    delete nextGroup[categoryKEY][subCategoryKEY];
    return nextGroup;
  }
  nextGroup[categoryKEY][subCategoryKEY].values = subGroup.values.filter(
    ({ key }) => key !== keyToRemove
  );
  return nextGroup;
};
