import React from 'react';
import PropTypes from 'prop-types';
import {
  AudienceGeneralTitle,
  InsightTable,
  InsightRowColumn,
  StyledInsightTab,
  InsightTh,
  TableTitle
} from './style';

const styles = {
  insight: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '18px'
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'stretch',
    alignContent: 'stretch',
    marginRight: '14%',
    marginTop: '10px'
  },
  tableRowTitle: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
    paddingRight: '20px',
    marginTop: '15px'
  }
};

function Separator() {
  return (
    <tr>
      <InsightRowColumn split className="insight" />
      <InsightRowColumn split className="popFr" />
      <InsightRowColumn split className="Ermes" />
      <InsightRowColumn corner split className="Campaign" />
    </tr>
  );
}

const formatValue = val => {
  const res = val ? `${val}%` : '-';
  return res;
};

function InsightPerLevel(props) {
  const { data, objectif } = props;
  return (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <AudienceGeneralTitle>
          Les 5 insights (hors Age, Sexe, CSP et Habitat) principaux en indice
          sur base de Taxonomie Ermes sont :
        </AudienceGeneralTitle>
      </div>
      <div style={styles.table}>
        <div style={styles.tableRowTitle}>
          <TableTitle row>{`${objectif[0]}`}</TableTitle>
          <TableTitle row>{`${objectif[1]}`}</TableTitle>
          <TableTitle row>{`${objectif[2]}`}</TableTitle>
        </div>
        <InsightTable>
          <tr>
            <InsightTh>
              <TableTitle>Insights</TableTitle>
            </InsightTh>
            <InsightTh>
              <TableTitle>Pop. française</TableTitle>
            </InsightTh>
            <InsightTh>
              <TableTitle>Ermes</TableTitle>
            </InsightTh>
            <InsightTh corner>
              <TableTitle>Campagne</TableTitle>
            </InsightTh>
          </tr>
          <tbody>
            {data.kpi2.map(item => {
              return (
                <tr>
                  <InsightRowColumn className="insight">
                    <StyledInsightTab>{item.insight}</StyledInsightTab>
                  </InsightRowColumn>
                  <InsightRowColumn className="popFr">
                    <StyledInsightTab>
                      {formatValue(item.popFr)}
                    </StyledInsightTab>
                  </InsightRowColumn>
                  <InsightRowColumn className="Ermes">
                    <StyledInsightTab>
                      {formatValue(item.ermesData)}
                    </StyledInsightTab>
                  </InsightRowColumn>
                  <InsightRowColumn corner className="Campaign">
                    <StyledInsightTab>{`${item.campaign.value}% (indice ${item.campaign.indicator})`}</StyledInsightTab>
                  </InsightRowColumn>
                </tr>
              );
            })}
          </tbody>
          <Separator />
          <tbody>
            {data.kpi2.map(item => {
              return (
                <tr>
                  <InsightRowColumn className="insight">
                    <StyledInsightTab>{item.insight}</StyledInsightTab>
                  </InsightRowColumn>
                  <InsightRowColumn className="popFr">
                    <StyledInsightTab>
                      {formatValue(item.popFr)}
                    </StyledInsightTab>
                  </InsightRowColumn>
                  <InsightRowColumn className="Ermes">
                    <StyledInsightTab>
                      {formatValue(item.ermesData)}
                    </StyledInsightTab>
                  </InsightRowColumn>
                  <InsightRowColumn corner className="Campaign">
                    <StyledInsightTab>{`${item.campaign.value}% (indice ${item.campaign.indicator})`}</StyledInsightTab>
                  </InsightRowColumn>
                </tr>
              );
            })}
            <tr />
          </tbody>
          <Separator />
          <tbody>
            {data.kpi3.map(item => {
              return (
                <tr>
                  <InsightRowColumn className="insight">
                    <StyledInsightTab>{item.insight}</StyledInsightTab>
                  </InsightRowColumn>
                  <InsightRowColumn className="popFr">
                    <StyledInsightTab>
                      {formatValue(item.popFr)}
                    </StyledInsightTab>
                  </InsightRowColumn>
                  <InsightRowColumn className="Ermes">
                    <StyledInsightTab>
                      {formatValue(item.ermesData)}
                    </StyledInsightTab>
                  </InsightRowColumn>
                  <InsightRowColumn corner className="Campaign">
                    <StyledInsightTab>{`${item.campaign.value}% (indice ${item.campaign.indicator})`}</StyledInsightTab>
                  </InsightRowColumn>
                </tr>
              );
            })}
            <tr />
          </tbody>
        </InsightTable>
      </div>
    </React.Fragment>
  );
}

InsightPerLevel.propTypes = {
  data: PropTypes.objectOf({
    kpi2: PropTypes.arrayOf(
      PropTypes.objectOf({
        insight: PropTypes.string,
        popFr: PropTypes.number,
        ermesData: PropTypes.number,
        campaign: PropTypes.objectOf({
          value: PropTypes.number,
          indicator: PropTypes.number
        })
      })
    ),
    kpi3: PropTypes.arrayOf(
      PropTypes.objectOf({
        insight: PropTypes.string,
        popFr: PropTypes.number,
        ermesData: PropTypes.number,
        campaign: PropTypes.objectOf({
          value: PropTypes.number,
          indicator: PropTypes.number
        })
      })
    ),
    kpi4: PropTypes.arrayOf(
      PropTypes.objectOf({
        insight: PropTypes.string,
        popFr: PropTypes.number,
        ermesData: PropTypes.number,
        campaign: PropTypes.objectOf({
          value: PropTypes.number,
          indicator: PropTypes.number
        })
      })
    )
  }).isRequired,
  objectif: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default InsightPerLevel;
