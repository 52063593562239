import React from 'react';
import PropTypes from 'prop-types';

import DropdownInsight from '../DropdownInsight';
import BaseOperator, { BaseOperatorText } from './style';

/**
 * OperatorInsight
 * @component
 * @see OperatorContainer
 *
 */
function OperatorInsight(props) {
  const { operator, hide, rounded, handleChange, options, interactive } = props;
  function checkChange(newValue) {
    if (operator.toLowerCase() !== newValue.toLowerCase()) {
      handleChange();
    }
  }
  return (
    <BaseOperator href="#" hide={hide} rounded={rounded}>
      {interactive ? (
        <DropdownInsight
          defaultValue={operator}
          options={options}
          onChange={checkChange}
        />
      ) : (
        <BaseOperatorText>{operator}</BaseOperatorText>
      )}
    </BaseOperator>
  );
}
OperatorInsight.defaultProps = {
  hide: false,
  rounded: false,
  interactive: true
};
OperatorInsight.propTypes = {
  handleChange: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  operator: PropTypes.string.isRequired,
  rounded: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  interactive: PropTypes.bool
};

export default OperatorInsight;
