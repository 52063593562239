import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Title from 'src/components/Title';
import Text from 'src/components/Text';
import Modal from 'Data/components/Modal';
import InputUploadFile from 'Data/components/InputUploadFile';
import ButtonBorder from 'Data/components/ButtonBorder';
import ButtonFill from 'Data/components/ButtonFill';
import { useData } from 'src/modules/Data/contexts/DataContext';
import { useTheme } from 'src/contexts/ThemeContext';
import dataStatus from 'src/utils/constants/dataStatus';

/**
 * ModalImportFile
 * @component
 */
function ModalImportFile({ onClose, onNext, ...props }) {
  const {
    uploadProgress,
    setFile,
    uploadError,
    uploadStatus,
    historyName
  } = useData();
  const { themeColors } = useTheme();

  return (
    <Modal
      {...props}
      onClose={onClose}
      style={{ width: '500px', backgroundColor: themeColors.primary }}
    >
      <Title small fontSize={24} style={{ color: themeColors.light }}>
        AJOUTER VOTRE BASE DE DONNÉES
      </Title>
      <Text style={{ color: themeColors.light }}>
        Vous êtes invité à uploader votre base de données en respectant les
        conditions requises : <br />
        <br />
        • Le fichier doit être au format .csv, .txt, .zip ou .gz. <br />
        • Si vous importez un fichier avec des emails non chiffrés, notre module
        les chiffrera automatiquement.
        <br />
        • Vous pouvez importer un fichier avec seulement une colonne d’emails ou
        bien plusieurs colonne du type: Email / Téléphone / Nom / Prénom /
        Adresse / etc.
        <br />
        <br />
        Afin d’avoir des résultats satisfaisants, nous recommandons d’uploader
        un fichier d’au moins 100.000 profils.
        <br />
        <br />A noter : si vous utilisez un Adblock, il est possible que cela
        empêche l’upload ou le matching de votre base. Dans ce cas, il est
        recommandé de le désactiver le temps du matching
      </Text>

      <Grid container justify="center">
        <div style={{ width: '80%', margin: '24px' }}>
          <InputUploadFile
            onChange={setFile}
            progress={uploadProgress}
            error={uploadError}
            status={
              uploadStatus
                ? dataStatus.live[uploadStatus]
                : dataStatus.live.uploading
            }
            historyName={historyName}
          />
        </div>
      </Grid>

      <Grid container justify="space-around">
        <ButtonBorder style={{ width: '180px', margin: 0 }} onClick={onClose}>
          ANNULER
        </ButtonBorder>
        <ButtonFill
          width="180px"
          disabled={uploadProgress !== 100}
          onClick={onNext}
        >
          SUIVANT
        </ButtonFill>
      </Grid>
    </Modal>
  );
}
ModalImportFile.defaultProps = {
  open: false,
  onClose: () => {},
  onNext: () => {}
};
ModalImportFile.propTypes = {
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  open: PropTypes.bool
};

export default ModalImportFile;
