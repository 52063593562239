import React from 'react';
// import PropTypes from 'prop-types';

import BaseMapInsight from './style';

/**
 * MapInsight
 * @component
 *
 */
function MapInsight(props) {
  return <BaseMapInsight {...props} />;
}
MapInsight.defaultProps = {};
MapInsight.propTypes = {};

export default MapInsight;
