import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseWrapperResult
 * @component
 *
 */
const BaseWrapperResult = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  padding: 0 16px;
  .column-result {
    display: flex;
    flex-direction: column;
    background-color: ${({ themeColors }) => themeColors.secondary};
    border-radius: 4px;
    padding: 16px 48px 32px;
    box-shadow: 0 0 4px -1px ${({ themeColors }) => themeColors.shadow};
  }
  .row-result {
    display: grid;
    grid-column-gap: 8px;
    align-items: center;
  }
  .row-result.bar {
    grid-template-columns: 1fr 96px;
    margin-left: 100px;
  }
  .row-result.info {
    grid-template-columns: 80px 4px 1fr;
  }
`;

export default withTheme(BaseWrapperResult);
