import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { withTheme } from 'contexts/ThemeContext';

/**
 * ModalContent
 * @component
 *
 */
export const ModalContent = withTheme(styled.div`
  background: ${({ themeColors }) => themeColors.tertiary};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  top: 20vh;
  left: -25px;
  margin: auto;
  width: 26vw;
  height: auto;
  max-height: 60vh;
  min-width: 500px;
  opacity: 1;
  border: 1px solid ${({ themeColors }) => themeColors.tertiary};
  border-radius: 12px;
  outline: none;
  overflow-y: auto;
  .children {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .buttons-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }
  button {
    border-radius: 8px;
    padding: 4px;
    outline: none;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5em 1em;
    margin: 0.5em 0.5em;
    border-radius: 20px;
  }
  button.save {
    background-color: ${({ themeColors }) => themeColors.light};
    color: ${({ themeColors }) => themeColors.dark};
    border: 2px solid ${({ themeColors }) => themeColors.dark};
    padding: 0.5em 2em;
  }
  button.cancel {
    background-color: ${({ themeColors }) => themeColors.tertiary};
    color: ${({ themeColors }) => themeColors.light};
    border: 2px solid ${({ themeColors }) => themeColors.light};
    padding: 0.5em 1.5em;
  }
`);

const BaseModal = Modal;
export default BaseModal;
