import React from 'react';
import isEqual from 'lodash/isEqual';
import { useApi } from 'utils/hooks/useAPI';
import { sleep } from 'src/utils/functions';

function getConfig(Authorization) {
  return {
    method: 'get',
    headers: {
      Authorization: `bearer: ${Authorization}`
    }
  };
}

/**
 * useTemelioBases hook to track bases
 *
 * @export
 * @param {string} token
 * @returns
 */
export function useTemelioBases(token, refetchInterval = 30) {
  const [bases, setBases] = React.useState([]);
  const [total, setTotal] = React.useState(null);
  const [config, setConfig] = React.useState(getConfig(token));
  const [seconds, setSeconds] = React.useState(0);

  React.useEffect(() => {
    sleep(refetchInterval * 1000).then(() => {
      setSeconds(seconds + refetchInterval);
    });
  });

  React.useEffect(() => {
    if (token) setConfig(getConfig(token));
  }, [token, seconds]);

  const { error, loading, data } = useApi(
    `${process.env.REACT_APP_TEMELIO_API_URL}/bases`,
    config,
    !token
  );
  if (data && !isEqual(bases, data.bases)) {
    setBases(data.bases);
  }
  if (data && !isEqual(total, data.total)) {
    setTotal(data.total);
  }

  return { bases, total, error, loading };
}

export default useTemelioBases;
