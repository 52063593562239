import React from 'react';
import moment from 'utils/moment';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import BaseAnomalyRow, {
  AnomalyRowSelect,
  AnomalyRowColumn,
  AnomalyInput,
  AnomalyRowValue
} from './style';

const MOTIFS = [
  'Meteo',
  'Problème technique',
  'Defaut de paiement',
  'Changement de publicité',
  'Changement de cible',
  'Autre'
];

const INPUT_PLACEHOLDER = 'Lancement campagne TV...';
const SELECT_PLACEHOLDER = 'Sélectioner une option';

/**
 *
 * getAnomalyMotif
 *
 * @param {Object} funnel
 * @param {String*} kpi
 * @param {Date} time
 * @return {String}
 *
 */
const getAnomalyMotif = (funnel, kpi, time) => {
  if (funnel.anom) {
    const kpis = funnel.anom.filter(e => e.funnelId === kpi);
    if (kpis.length > 0) {
      const f = kpis.filter(i => i.date === time.format('DD-MM-YYYY'));
      if (f.length > 0) {
        return f[f.length - 1].motifanom;
      }
    }
  }
  return SELECT_PLACEHOLDER;
};

/**
 * funnelPeriodFilter
 * @params {Object} funnel
 * @param {Date} date
 * @return {boolean}
 *
 */
const funnelPeriodFilter = (funnel, date) =>
  moment(date).isBetween(
    moment(funnel.start).subtract(1, 'days'),
    moment(funnel.end),
    null,
    []
  );

/**
 * AnomalySelect
 * @component
 *
 */
const AnomalySelect = props => {
  const { motif, ...rest } = props;
  const { themeColors } = useTheme();
  const [selected, setSelected] = React.useState(motif);
  React.useEffect(() => {
    setSelected(motif);
  }, [motif]);
  return (
    <AnomalyRowSelect
      defaultValue={selected}
      selected={selected}
      options={MOTIFS}
      themeColors={themeColors}
      {...rest}
    />
  );
};
AnomalySelect.propTypes = {
  motif: PropTypes.string.isRequired
};

/**
 * AnomalyInputCustom
 * @component
 *
 */
const AnomalyInputCustom = props => {
  const { onBlur, ...rest } = props;
  const [value, setValue] = React.useState('');
  const { themeColors } = useTheme();
  const handleChange = strValue => {
    setValue(strValue);
  };
  const handleBlur = () => onBlur(value);
  const handleKeyPRess = e => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };
  return (
    <AnomalyInput
      value={value}
      onChange={e => handleChange(e.target.value)}
      onKeyPress={handleKeyPRess}
      onBlur={handleBlur}
      themeColors={themeColors}
      {...rest}
    />
  );
};
AnomalyInputCustom.propTypes = {
  onBlur: PropTypes.func.isRequired
};

/**
 *
 * AnomalyRow
 * @component
 *
 */
function AnomalyRow(props) {
  const { allkpi, funnel, setAnom } = props;
  const { themeColors } = useTheme();
  const handleChange = (motifanom, funnelId, date) => {
    setAnom({ funnelId, date: moment(date).format('DD-MM-YYYY'), motifanom });
  };
  const anomalyList = [...funnel.funnelData.anomalies].filter(({ date }) =>
    funnelPeriodFilter(funnel, date)
  );
  anomalyList.sort((a, b) => a.date - b.date);
  if (anomalyList.length === 0) {
    return <tbody>pas d&apos;anomalie détectée</tbody>;
  }
  return (
    <tbody>
      {anomalyList.map(i => {
        const date = moment(i.date);
        const kpi = ['kpi1', 'kpi2', 'kpi3', 'kpi4'].indexOf(i.kpi);
        const motif = getAnomalyMotif(funnel, allkpi[kpi], date);
        const onChange = e => handleChange(e, allkpi[kpi], i.date);
        return (
          <BaseAnomalyRow key={`${kpi}-${i.date}`}>
            <AnomalyRowColumn>{date.format('DD-MM-YYYY')}</AnomalyRowColumn>
            <AnomalyRowColumn>{allkpi[kpi]}</AnomalyRowColumn>
            <AnomalyRowColumn>
              <AnomalyRowValue isAlert={parseFloat(i.value) > i.mean}>
                {i.value.toFixed(2)}€
              </AnomalyRowValue>
            </AnomalyRowColumn>
            <AnomalyRowColumn themeColors={themeColors}>
              {parseFloat(i.mean).toFixed(2)}€
            </AnomalyRowColumn>
            <AnomalyRowColumn themeColors={themeColors}>
              {(parseFloat(i.deviation) * 100).toFixed(2)}%
            </AnomalyRowColumn>
            <AnomalyRowColumn themeColors={themeColors}>
              <AnomalySelect motif={motif} onSelectChange={onChange} />
              <AnomalyInputCustom
                type="text"
                placeholder={INPUT_PLACEHOLDER}
                canDisplay={motif === 'Autre'}
                onBlur={onChange}
              />
            </AnomalyRowColumn>
          </BaseAnomalyRow>
        );
      })}
    </tbody>
  );
}
AnomalyRow.propTypes = {
  allkpi: PropTypes.arrayOf(PropTypes.string).isRequired,
  funnel: PropTypes.shape({
    anom: PropTypes.arrayOf(
      PropTypes.shape({
        funnelId: PropTypes.string,
        newFormat: PropTypes.shape({
          anomalies: PropTypes.arrayOf({
            date: PropTypes.instanceOf(moment),
            kpi: PropTypes.string,
            deviation: PropTypes.number,
            value: PropTypes.number,
            mean: PropTypes.number
          })
        })
      })
    )
  }).isRequired,
  setAnom: PropTypes.func.isRequired
};

export default AnomalyRow;
