import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { Auth } from 'aws-amplify';

/**
 * authMiddleware
 * @function
 * @desc :: Async-able middleware to get and refresh the current session
 *
 */
const authMiddleware = setContext(async (_, { headers }) => {
  const currentUser = await Auth.currentSession();
  return {
    headers: {
      ...headers,
      authorization: currentUser.idToken.jwtToken
    }
  };
});

/**
 * client
 * @return an Apollo client
 *
 */
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    authMiddleware,
    new HttpLink({
      uri: `${process.env.REACT_APP_API_URL || 'http://localhost:3000'}/graphql`
    })
  ]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

export default client;
