import React from 'react';
import { useMutation } from '@apollo/react-hooks';

import { getFunnelPDF, getFunnelName } from 'utils/api/pdf';

import { useNotif } from 'contexts/NotifContext';
import { useUser } from 'contexts/UserContext';
import { useFunnel } from 'Funnel/contexts/FunnelContext';
import { FUNNEL } from 'utils/graphQL';

import PersistFunnel from 'Funnel/components/PersistFunnel';

/**
 * getNotifyParams
 * @param {String} name
 * @return {title, message, variants}
 *
 */
const getNotifyParams = {
  success: name => ({
    title: 'Funnel enregistré',
    message: `Votre funnel ${name} a bien été enregistré,\nsuite à votre demande.`,
    variants: 'success'
  }),
  error: name => ({
    title: 'Erreur lors de l’enregistrement',
    message: `Nous n’avons pas pu enregistrer votre funnel ${name}”.\n Merci de bien vouloir réessayer.`,
    variants: 'error'
  }),
  pdfPending: () => ({
    title: 'Génération du PDF',
    message: `Le PDF est en cours de création`,
    variants: 'info'
  }),
  pdfSuccess: name => ({
    title: 'Génération du PDF',
    message: `La génération du PDF ${name} est terminée.`,
    variants: 'success'
  }),
  pdfError: () => ({
    title: 'Génération du PDF',
    message: `La génération du PDF a échoué`,
    variants: 'error'
  }),
  noObjective: () => ({
    title: "Pas d'objectif renseigné",
    message: `Veuillez remplir les objectifs dans votre synthèse afin de générer le PDF`,
    variants: 'error'
  })
};

/**
 * PersistContainer
 * @component
 * @desc funnel saving management (button: sauvegarder funnel)
 *
 */
function PersistContainer() {
  const { notify } = useNotif();
  const { user } = useUser();
  const { setFunnel, funnel, funnelStringified } = useFunnel();
  const [createFunnel] = useMutation(FUNNEL.CREATE_FUNNEL);
  const [updateFunnel] = useMutation(FUNNEL.UPDATE_FUNNEL);
  const currentSaveFunc = funnel.id ? updateFunnel : createFunnel;
  const variables = {
    ...funnelStringified,
    saved: true,
    userId: user.username
  };
  const saveFunnel = name => {
    setFunnel({ ...funnel, name });
    variables.name = name;
    currentSaveFunc({ variables })
      .then(({ data }) => {
        const idResponse = data.createFunnel && data.createFunnel.id;
        if (!funnel.id) setFunnel({ ...funnel, id: idResponse });
        notify(getNotifyParams.success(name));
      })
      .catch(() => {
        notify(getNotifyParams.error(name));
      });
  };

  const downloadPDF = () => {
    if (!funnel.objectifed || !funnel.objectifed.length) {
      notify(getNotifyParams.noObjective());
      return;
    }
    notify(getNotifyParams.pdfPending());
    getFunnelPDF({ ...funnel, userName: user.attributes['custom:nickname'] })
      .then(response => {
        // force local PDF download
        const nameFunnel = `${getFunnelName(funnel)}.pdf`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nameFunnel);
        document.body.appendChild(link);
        link.click();
        notify(getNotifyParams.pdfSuccess(nameFunnel));
      })
      .catch(e => {
        console.error(e);
        notify(getNotifyParams.pdfError());
      });
  };

  return (
    <PersistFunnel
      name={funnel.name}
      saveFunnel={saveFunnel}
      downloadPDF={downloadPDF}
    />
  );
}

export default PersistContainer;
