import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseTemplateImport
 * @component
 *
 */
const BaseTemplateImport = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ themeColors }) => themeColors.primary};
  padding: 12px 28px;
  display: grid;
  grid-template-rows: ${({ adapt }) => adapt || 'auto'};
  align-items: center;
  .row-import {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    small {
      padding-top: 10px;
    }
  }
  .bottom {
    bottom: 16px;
  }
  small {
    color: ${({ themeColors }) => themeColors.light};
  }
`;

export default withTheme(BaseTemplateImport);
