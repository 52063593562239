import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';

import BaseActivation from './style';

/**
 * ButtonActivation
 * @component
 *
 */
function ButtonActivation(props) {
  const { themeColors } = useTheme();
  const { inversed, children, onClick, small, medium, disabled } = props;
  const styledProps = {
    bgcolor: themeColors.activation,
    textcolor: themeColors.keep_light,
    bordercolor: themeColors.activation,
    onClick,
    disabled
  };
  if (inversed) {
    styledProps.bgcolor = 'transparent';
    styledProps.textcolor = themeColors.activation_inversed;
    styledProps.bordercolor = themeColors.activation_inversed;
  }
  if (medium) {
    styledProps.width = '110px';
    styledProps.padding = '2px 15px';
  }
  if (small) {
    styledProps.bgcolor = 'transparent';
    styledProps.textcolor = themeColors.success;
    styledProps.bordercolor = themeColors.success;
    styledProps.width = '72px';
    styledProps.padding = '4px';
    styledProps.borderradius = '4px';
  }
  return (
    <BaseActivation {...styledProps} {...props}>
      {children}
    </BaseActivation>
  );
}
ButtonActivation.defaultProps = {
  inversed: false,
  small: false,
  medium: false,
  disabled: false
};
ButtonActivation.propTypes = {
  children: PropTypes.node.isRequired,
  inversed: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ButtonActivation;
