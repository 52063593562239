import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

import Text from 'src/components/Text';
import ActionButton from 'Activation/components/ButtonActivation';
import { useTheme } from 'src/contexts/ThemeContext';
import { isErrorPhoneNumber } from 'src/utils/functions';

function ModalLaunchCampaignForm({ onClose, onNext, data }) {
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [values, setValues] = React.useState({ number: '', text: '' });
  const [errors, setErrors] = React.useState({ number: false });
  const { themeColors } = useTheme();

  function onNextWrapper() {
    onNext({
      phone: values.number,
      description: values.text,
      ...data
    });
  }

  const handleChange = name => event => {
    const val = event.target.value;
    setValues(prev => ({ ...prev, [name]: val }));
    if (name === 'number') {
      setErrors(prev => ({
        ...prev,
        [name]: !val || val.length < 3 || isErrorPhoneNumber(val)
      }));
    }
  };

  useEffect(() => {
    setIsFormValid(values.number && !errors.number);
  }, [errors.number, values]);

  return (
    <>
      <form noValidate>
        <div style={{ marginBottom: '16px' }}>
          <Text style={{ color: themeColors.light }}>
            Votre numéro de téléphone pour vous contacter :
          </Text>
          <TextField
            id="name"
            type="tel"
            value={values.number}
            onChange={handleChange('number')}
            error={errors.number}
            fullWidth
            variant="outlined"
          />
        </div>
      </form>
      <div style={{ marginBottom: '16px' }}>
        <Text style={{ color: themeColors.light }}>
          Ajouter un complément d’information (facultatif) :
        </Text>
        <TextField
          id="text"
          value={values.text}
          onChange={handleChange('text')}
          fullWidth
          multiline
          rows={5}
          variant="outlined"
        />
      </div>

      <Text style={{ marginBottom: '32px', color: themeColors.light }}>
        Vous serez rappelé sous 24h par l’équipe ERMES afin de finaliser votre
        commande et vous informer des prochaines étapes.
      </Text>

      <Grid container justify="space-around">
        <ActionButton inversed onClick={onClose} width="180px">
          ANNULER
        </ActionButton>
        <ActionButton
          width="180px"
          disabled={!isFormValid}
          onClick={onNextWrapper}
        >
          ÊTRE RAPPELÉ
        </ActionButton>
      </Grid>
    </>
  );
}
ModalLaunchCampaignForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  data: PropTypes.shape({
    objectif: PropTypes.string.isRequired,
    activationId: PropTypes.string.isRequired,
    dates: PropTypes.string.isRequired,
    budget: PropTypes.string.isRequired,
    nbProfilsAppnexus: PropTypes.string.isRequired,
    videoPercentage: PropTypes.string.isRequired,
    videoPremiumPercentage: PropTypes.string.isRequired,
    bannerImpactPercentage: PropTypes.string.isRequired,
    bannerPercentage: PropTypes.string.isRequired,
    objectiveOneName: PropTypes.string.isRequired,
    objectiveOnePercentage: PropTypes.string.isRequired,
    objectiveTwoName: PropTypes.string.isRequired,
    objectiveTwoPercentage: PropTypes.string.isRequired,
    objectiveThreeName: PropTypes.string.isRequired,
    objectiveThreePercentage: PropTypes.string.isRequired
  }).isRequired
};

export default ModalLaunchCampaignForm;
