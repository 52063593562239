import React from 'react';
import MuButton from '@material-ui/core/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseButton
 * @component
 */
export const BaseButton = withTheme(styled(MuButton)`
  && {
    color: ${({ themeColors }) => themeColors.keep_light};
    font-size: 14px;
    width: auto;
    background-color: ${({ themeColors }) => themeColors.data};
    margin: 8px auto 0;
    &:hover {
      background-color: ${({ themeColors }) => themeColors.data};
      opacity: 0.7;
    }
    &:disabled {
      background-color: ${({ themeColors }) => themeColors.data};
      opacity: 0.5;
    }
  }
  svg {
    margin-right: 10px;
    min-width: 26px;
  }
`);

/**
 * ConnectorSVG
 * @component
 */
export function ConnectorSVG() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.69065 17.4156C13.4904 17.4156 17.3813 13.672 17.3813 9.054C17.3813 4.43601 13.4904 0.692383 8.69065 0.692383C3.89094 0.692383 0 4.43601 0 9.054C0 13.672 3.89094 17.4156 8.69065 17.4156ZM9.37348 9.71098H13.4705V8.39701H9.37348V4.45511H8.00781V8.39701H3.91079V9.71098H8.00781V13.6529H9.37348V9.71098Z"
        fill="#EEEEEE"
      />
    </svg>
  );
}

/**
 * ButtonUploadFile
 * @component
 */
export default function ButtonUploadFile({ onClick, text, ...rest }) {
  return (
    <BaseButton onClick={onClick} {...rest}>
      <ConnectorSVG />
      <strong>{text}</strong>
    </BaseButton>
  );
}
ButtonUploadFile.defaultProps = {
  text: 'Ajouter un nouveau fichier'
};
ButtonUploadFile.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string
};
