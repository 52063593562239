import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';

import { ApolloProvider } from 'react-apollo';

import UserProvider from 'contexts/UserContext';
import NotifProvider from 'contexts/NotifContext';
import ThemeProvider from 'contexts/ThemeContext';
import InsightCibleProvider from 'contexts/InsightCibleContext';
import DataCibleProvider from 'contexts/DataCibleContext';
import Notification from 'components/Notification';
import App from 'App';

import configuredGraphqlClient from 'utils/api/graphql';
import configuredAWSClient from 'utils/api/aws';

import 'index.css';

ReactDOM.render(
  <ApolloProvider client={configuredAWSClient}>
    <UserProvider>
      <ApolloProvider client={configuredGraphqlClient}>
        <ThemeProvider>
          <Notification>
            <NotifProvider>
              <InsightCibleProvider>
                <DataCibleProvider>
                  <App />
                </DataCibleProvider>
              </InsightCibleProvider>
            </NotifProvider>
          </Notification>
        </ThemeProvider>
      </ApolloProvider>
    </UserProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
