/**
 * thresolds
 * @desc :: limites to not be excedeed in activation
 *
 */
export default [
  {
    max: 1250000,
    cpmData: 5,
    populationRatio: 0.05,
    iscHorsData: 0.2
  },
  {
    max: 2500000,
    cpmData: 3,
    populationRatio: 0.1,
    iscHorsData: 0.3
  },
  {
    max: 7500000,
    cpmData: 2,
    populationRatio: 0.3,
    iscHorsData: 0.5
  },
  {
    max: 12500000,
    cpmData: 1.5,
    populationRatio: 0.5,
    iscHorsData: 0.7
  }
];
