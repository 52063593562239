import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from 'contexts/UserContext';
import { useTheme } from 'src/contexts/ThemeContext';
import { Query } from 'react-apollo';
import { ACTIVATION, FUNNEL, INSIGHT, DATA } from 'utils/graphQL';
import Loading from 'components/Loading';
import moment from 'moment';
import constants from 'utils/constants';
import { formatNumber } from 'src/utils/functions';
import { useTemelioBases } from 'Data/hooks/useTemelioBases';
import { useQuery } from '@apollo/react-hooks';
import client from 'utils/api/graphql';
import DashboardWrapper, {
  DashboardBoxCta,
  DashboardBoxElement,
  DashboardBoxModule,
  DashboardBoxSubtile,
  DashboardButton,
  DashboardImage,
  DashboardText,
  DashboardTextSubtile,
  DashboardTitle,
  LinkHistory,
  Links
} from './style';

const { routes } = constants;

/**
 * Dashboard
 * @component
 */
const modules = {
  data: {
    name: 'DATA',
    icon: './assets/img/icon-data.png',
    new: 'Enrichir sa data',
    description:
      'Enrichissez vos données de CRM et DMP pour en savoir plus sur vos clients et améliorer vos campagnes',
    route: routes.DATA,
    value: {
      offset: item => formatNumber(item.offset, 0)
    },
    valueDescription: []
  },
  insight: {
    name: 'INSIGHT',
    icon: './assets/img/icon-insight.png',
    new: 'Nouvelle analyse',
    description:
      'Découvrez réellement qui est votre cible et comparez-là avec votre audience actuelle',
    route: routes.INSIGHT,
    query: INSIGHT.GET_INSIGHTS,
    objectKey: 'insightTargets',
    value: {
      profiles: item => formatNumber(item.result.nbProfiles, 0)
    },
    valueDescription: ['de profils']
  },
  activation: {
    name: 'ACTIVATION',
    icon: './assets/img/icon-activation.png',
    new: 'Nouvelle campagne',
    description:
      'Réalisez des campagnes marketing très ciblées ou plus mass média et améliorer votre ROI',
    route: routes.ACTIVATION,
    query: ACTIVATION.GET_ACTIVATIONS,
    objectKey: 'activations',
    value: {
      startDate: item => moment(item.startDate).format('L'),
      endDate: item => moment(item.endDate).format('L')
    },
    valueDescription: ['du', 'au']
  },
  funnel: {
    name: 'FUNNEL',
    icon: './assets/img/icon-funnel.png',
    new: 'Nouveau Funnel',
    description:
      'Comprenez, optimisez et anticipez vos performances quels que soient vos objectifs.',
    route: routes.FUNNEL,
    query: FUNNEL.LIST_FUNNEL,
    objectKey: 'funnels',
    value: {
      startDate: item => moment(item.start).format('L'),
      endDate: item => moment(item.end).format('L')
    },
    valueDescription: ['du', 'au']
  }
};

const DashboardHistoryItem = props => {
  const { module, item, valueDescription } = props;

  return (
    <DashboardBoxElement>
      {/* eslint-disable-next-line no-underscore-dangle */}
      <LinkHistory to={`${module.route}/${item.id || item.name}`}>
        <div style={{ fontSize: '0.75rem' }}>
          <strong>{item.name}</strong>
        </div>
        <span style={{ fontSize: '0.75rem' }}>
          {valueDescription.length > 1 && valueDescription[0]}
          {Object.keys(module.value).map((key, index, tab) => {
            let value = module.value[key](item);
            if (valueDescription.length) {
              value = ' '.concat(value);
              if (index !== tab.length - 1) {
                value = value.concat(' ', valueDescription[1]);
              } else if (tab.length === 1) {
                value = value.concat(' ', valueDescription[0]);
              }
            }
            return value;
          })}
        </span>
      </LinkHistory>
    </DashboardBoxElement>
  );
};
DashboardHistoryItem.propTypes = {
  module: PropTypes.objectOf(modules).isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  valueDescription: PropTypes.arrayOf(PropTypes.string).isRequired
};

const AddedItems = props => {
  const { module, historyItemsNumber } = props;
  return (
    <DashboardBoxCta>
      {historyItemsNumber === 0 ? (
        <DashboardButton>
          <Links to={module.route}>
            <strong>{`${module.new}>`}</strong>
          </Links>
        </DashboardButton>
      ) : (
        <DashboardButton>
          <Links to={module.route}>
            <strong>
              {module.new} {'>'}
            </strong>
          </Links>
        </DashboardButton>
      )}
    </DashboardBoxCta>
  );
};
AddedItems.propTypes = {
  module: PropTypes.objectOf(modules).isRequired,
  historyItemsNumber: PropTypes.number.isRequired
};

const DashboardHistory = props => {
  const { module, response, historyData, isLoading } = props;
  const historyItem =
    module === modules.data
      ? historyData
      : response && response.data && response.data[module.objectKey];
  return (
    <Loading
      loading={(response && response.loading) || isLoading}
      error={response && response.error}
    >
      <div>
        <DashboardBoxSubtile>
          <DashboardTextSubtile>DERNIERS {module.name}</DashboardTextSubtile>
          <DashboardTextSubtile>{'>'}</DashboardTextSubtile>
        </DashboardBoxSubtile>
        {historyItem &&
          historyItem
            .slice(0, 3)
            .sort((a, b) => b.createdAt - a.createdAt)
            .map(item => (
              <DashboardHistoryItem
                module={module}
                item={item}
                valueDescription={module.valueDescription}
              />
            ))}
      </div>
      <AddedItems
        module={module}
        historyItemsNumber={(historyItem && historyItem.length) || 0}
      />
    </Loading>
  );
};
DashboardHistory.defaultProps = {
  response: null,
  historyData: null,
  isLoading: false
};
DashboardHistory.propTypes = {
  module: PropTypes.objectOf(modules).isRequired,
  response: PropTypes.objectOf(PropTypes.any),
  historyData: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool
};

const DashboardModuleContent = props => {
  const {
    module,
    isAuthenticated,
    color,
    borderRadius,
    historyData,
    isLoading
  } = props;
  return (
    <DashboardBoxModule color={color} borderRadius={borderRadius}>
      <DashboardTitle small>
        <DashboardImage src={module.icon} alt="Ermes data" />
        {module.name}
      </DashboardTitle>
      <DashboardText>{module.description}</DashboardText>
      {module !== modules.data ? (
        <Query
          query={module.query}
          variables={{ limit: 3 }}
          skip={!isAuthenticated}
        >
          {response => <DashboardHistory module={module} response={response} />}
        </Query>
      ) : (
        <DashboardHistory
          module={module}
          historyData={historyData}
          isLoading={isLoading}
        />
      )}
    </DashboardBoxModule>
  );
};
DashboardModuleContent.defaultProps = {
  borderRadius: '0 0 0 0',
  historyData: null,
  isLoading: false
};
DashboardModuleContent.propTypes = {
  module: PropTypes.objectOf(modules).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  color: PropTypes.objectOf(PropTypes.any).isRequired,
  borderRadius: PropTypes.objectOf(PropTypes.any),
  historyData: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool
};

const useDataLoginQuery = () => {
  const { loading, error, data } = useQuery(DATA.GET_TEMELIO_TOKEN, {
    client
  });
  return {
    loading,
    error,
    token: data && data.getTemelioToken ? data.getTemelioToken.token : null
  };
};

function Dashboard() {
  const { token, loading: loginLoading } = useDataLoginQuery();
  const { bases: historyData, loading: basesLoading } = useTemelioBases(
    token,
    300
  );
  const { themeColors } = useTheme();
  const { isAuthenticated } = useUser();

  return (
    <DashboardWrapper>
      <DashboardModuleContent
        module={modules.data}
        isAuthenticated={isAuthenticated}
        color={themeColors.data}
        borderRadius="10px 0 0 10px"
        historyData={historyData}
        isLoading={loginLoading || basesLoading || !historyData}
      />
      <DashboardModuleContent
        module={modules.insight}
        isAuthenticated={isAuthenticated}
        color={themeColors.insight}
      />
      <DashboardModuleContent
        module={modules.activation}
        isAuthenticated={isAuthenticated}
        color={themeColors.activation}
      />
      <DashboardModuleContent
        module={modules.funnel}
        isAuthenticated={isAuthenticated}
        color={themeColors.funnel}
        borderRadius="0 10px 10px 0"
      />
    </DashboardWrapper>
  );
}

export default Dashboard;
