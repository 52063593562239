import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from 'components/ErrorPage';
import { withRouter } from 'react-router-dom';

import FeatureDetails from 'components/FeatureDetails';
import Grid from 'components/Grid';
import Image from 'components/Image';
import Text from 'components/Text';
import constants from 'utils/constants';

const { featureContent } = constants;

/**
 *
 * FeatureDetailsContainer
 * @desc affiche les features sur une page
 * @component
 * @param {Object} props
 *
 */
function FeatureDetailsContainer(props) {
  const {
    match: { params }
  } = props;
  const { feature } = params;
  const infos = featureContent[feature];
  if (!infos) {
    return <ErrorPage />;
  }
  return !infos ? null : (
    <FeatureDetails>
      <Grid item sm={12} md={6} padding="40px">
        <Image width="100%" padding="0 30px" src={infos.img} alt="mockup" />
        <Text textAlign="center" fontSize="15px" fontWeight="700">
          {infos.description}
        </Text>
      </Grid>
      <Grid item sm={12} md={6}>
        <div>
          <h1>{infos.title}</h1>
          {infos.paragraphes.map((text, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={index}>{text}</p>
          ))}
        </div>
      </Grid>
    </FeatureDetails>
  );
}

FeatureDetailsContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(FeatureDetailsContainer);
