import React from 'react';
import PropTypes from 'prop-types';
import BaseLink from './style';

/**
 * Link
 * @desc use react router dom
 *
 */
function Link({ href, to, ...rest }) {
  const direction = href || to;
  return <BaseLink {...rest} to={direction} />;
}
Link.defaultProps = {
  href: undefined,
  to: undefined
};
Link.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string
};

export default Link;
