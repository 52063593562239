import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import configuredGraphqlClient from 'utils/api/graphql';
import ErrorBoundary from 'ErrorBoundary';

import AppInsight from 'Insight/AppInsight';
import InsightProvider from 'Insight/contexts/InsightContext';
import ResultProvider from 'Insight/contexts/ResultContext';
import CategoryProvider from 'Insight/contexts/CategoryContext';
import OperatorProvider from 'Insight/contexts/OperatorContext';
import LoadingInsight from 'Insight/components/LoadingInsight';

/**
 * GET_INSIGHT
 * @graphql
 *
 */
const GET_INSIGHT = gql`
  query insight($id: ID!) {
    insightTarget(id: $id) {
      name
      filters
      frontData
    }
  }
`;

/**
 * useInsightQuery
 * @param {String} id
 * @return {Object} response
 * {Object} response.data
 * {Boolean} response.loading
 *
 */
function useInsightQuery(id) {
  const tmp = useQuery(GET_INSIGHT, {
    variables: { id },
    skip: !id,
    client: configuredGraphqlClient
  });

  return id ? tmp : {};
}

/**
 * Insight
 * @component
 * @desc :: Manage Insight App part
 * @uri :: /insight
 *
 */
function InsightModule(props) {
  const { match } = props;
  const { id: urlID } = match.params;
  const response = useInsightQuery(urlID);
  return (
    <ErrorBoundary>
      <InsightProvider>
        <ApolloProvider client={configuredGraphqlClient}>
          <LoadingInsight {...response} skip={!urlID}>
            <CategoryProvider>
              <OperatorProvider>
                <ResultProvider
                  insightTarget={response.data && response.data.insightTarget}
                >
                  <AppInsight />
                </ResultProvider>
              </OperatorProvider>
            </CategoryProvider>
          </LoadingInsight>
        </ApolloProvider>
      </InsightProvider>
    </ErrorBoundary>
  );
}
InsightModule.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired
};

export default InsightModule;
