import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import constants from 'utils/constants';
import Icon from 'utils/icons';

import BaseErrorPage from './style';

const { notFound, serverError } = constants.errorPage;
const BACK = "Revenir sur la page d'accueil";

/**
 * ErrorPage
 * @component
 * @return notFound error or server error
 * TODO:: Add a log management system for cacthing error message
 *
 */
function ErrorPage(props) {
  const { isServer } = props;
  const currentError = isServer ? serverError : notFound;
  return (
    <BaseErrorPage>
      <div className="parent">
        <div className="text">
          <h1>{currentError.title}</h1>
          <p>
            {currentError.messageRows.map(row =>
              row ? (
                <div className="message-row" key={row}>
                  {row}
                </div>
              ) : (
                <br />
              )
            )}
          </p>
          {!isServer && <Link to="/">{BACK}</Link>}
        </div>
        <Icon className="image" icon={currentError.image} />
      </div>
    </BaseErrorPage>
  );
}
ErrorPage.defaultProps = {
  isServer: false
};
ErrorPage.propTypes = {
  isServer: PropTypes.bool
};

export default ErrorPage;
