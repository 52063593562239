import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseWrapperResume
 * @component
 *
 */
const BaseWrapperResume = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ themeColors }) => themeColors.secondary};
  border-radius: 8px;
  box-shadow: 0 0 1px 1px ${({ themeColors }) => themeColors.shadow};
  justify-content: space-evenly;
  margin: 0px 16px;
  padding: 8px 24px;
  .column-resume {
    > * {
      margin-right: 16px;
    }
  }
`;

export default withTheme(BaseWrapperResume);
