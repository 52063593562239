import React from 'react';
import moment from 'utils/moment';
import PropTypes from 'prop-types';
import {
  TableRowSynthesis,
  ConversionContent,
  ConversionRateSpan,
  TableBodySynthesis,
  TableHeadSynthesis
} from 'Funnel/components/FunnelRow/style';
import LinearProgressSynthesis from 'Funnel/components/FunnelRow/LinearProgressSynthesis';
import { ErrorMessage } from 'Funnel/containers/SynthesisContainer/style';
import { formatNumber } from 'utils/functions';
import GroupedCriteria from './groupedCriteria';
import {
  sexeCriteria,
  ageCriteria,
  cspCriteria,
  zoneCriteria
} from './criteria';
import fillTab from './conversionTab';
import {
  AudienceGeneralTitle,
  TableCellAudience,
  TabSubTitle,
  ConvRowColumn,
  ConvTable,
  ConvSubTitle,
  TableAudience
} from './style';

function AudienceFirstPage(props) {
  const { funnel } = props;
  const conversionTab = fillTab(funnel.measure.details);

  if (
    !funnel.start ||
    !funnel.end ||
    !moment(funnel.start).isValid() ||
    !moment(funnel.end).isValid() ||
    moment(funnel.start).isAfter(moment(funnel.end))
  ) {
    return (
      <div className="kpi_table_description align_center">
        <ErrorMessage>
          {
            'Vos données ne nous permettent pas d’afficher la synthèse de votre funnel'
          }
        </ErrorMessage>
      </div>
    );
  }

  const formatTab = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    paddingLeft: '20px',
    paddingRight: '20px'
  };

  return (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <AudienceGeneralTitle>
          Les consomateurs ayant réagi à votre campagne sont décrits ci-dessous
          :
        </AudienceGeneralTitle>
      </div>
      <div>
        <TableAudience>
          <TableHeadSynthesis>
            <TableRowSynthesis>
              <TableCellAudience />
              <TableCellAudience />
              <TableCellAudience>Sexe</TableCellAudience>
              <TableCellAudience>Age</TableCellAudience>
              <TableCellAudience>CSP</TableCellAudience>
              <TableCellAudience>Zone</TableCellAudience>
              <TableCellAudience />
            </TableRowSynthesis>
          </TableHeadSynthesis>
          <TableBodySynthesis>
            {funnel.measure
              ? funnel.measure.funnels.map((f, idx) => {
                  const kpis = ['kpi1', 'kpi2', 'kpi3', 'kpi4'];
                  const funnelRowData =
                    funnel.funnelData && funnel.funnelData.synthesis
                      ? funnel.funnelData.synthesis[kpis[idx]]
                      : {};
                  let cr = [null, 'cr1_2', 'cr2_3', 'cr3_4'];
                  cr =
                    idx === 0
                      ? null
                      : funnel.funnelData &&
                        funnel.funnelData.synthesis &&
                        funnel.funnelData.synthesis[cr[idx]];
                  return funnelRowData ? (
                    <TableRowSynthesis>
                      <ConversionContent>
                        <ConversionRateSpan display={cr}>
                          {cr !== null && cr > 0 ? formatNumber(cr) : ''}%
                        </ConversionRateSpan>
                      </ConversionContent>
                      <TableCellAudience className="b-top b-right b-bottom">
                        <p className="kpi_title">{f.name}</p>
                        <LinearProgressSynthesis
                          variant="determinate"
                          value={100 - idx * 25}
                          textValue={
                            funnelRowData && funnelRowData.volume
                              ? funnelRowData.volume
                                  .toLocaleString()
                                  .replace(/,/g, ' ')
                              : '0'
                          }
                        />
                      </TableCellAudience>
                      <TableCellAudience className="b-all">
                        <div className="vertical">
                          <GroupedCriteria
                            criteria={sexeCriteria}
                            index={idx}
                          />
                        </div>
                      </TableCellAudience>
                      <TableCellAudience className="b-all">
                        <div className="vertical">
                          <GroupedCriteria criteria={ageCriteria} index={idx} />
                        </div>
                      </TableCellAudience>
                      <TableCellAudience className="b-all">
                        <div className="vertical">
                          <GroupedCriteria criteria={cspCriteria} index={idx} />
                        </div>
                      </TableCellAudience>
                      <TableCellAudience>
                        <div className="vertical">
                          <GroupedCriteria
                            criteria={zoneCriteria}
                            index={idx}
                          />
                        </div>
                      </TableCellAudience>
                    </TableRowSynthesis>
                  ) : null;
                })
              : null}
          </TableBodySynthesis>
        </TableAudience>
        <TabSubTitle>
          Ces estimations sont fondées sur 5847 visites homepages synchronisées
          avec la base Ermes, soit 51,73% du total.
        </TabSubTitle>
      </div>
      <br />
      <div style={{ textAlign: 'center' }}>
        <AudienceGeneralTitle>
          Les chiffres de conversion peuvent ainsi être modélisés :
        </AudienceGeneralTitle>
      </div>
      <div style={{ marginLeft: '10%' }}>
        <ConvTable>
          <tr>
            <th />
            <th />
            <th style={{ color: '#009FE3', fontFamily: 'Roboto' }}>Sexe</th>
            <th style={{ color: '#009FE3', fontFamily: 'Roboto' }}>Age</th>
            <th style={{ color: '#009FE3', fontFamily: 'Roboto' }}>CSP</th>
            <th style={{ color: '#009FE3', fontFamily: 'Roboto' }}>Zone</th>
          </tr>
          <tbody>
            {conversionTab.map(column => {
              return (
                <tr>
                  <ConvRowColumn>
                    <div
                      style={{
                        color: column.title[0].color,
                        paddingRight: '40px'
                      }}
                    >
                      {column.title[0].content}
                    </div>
                  </ConvRowColumn>
                  <ConvRowColumn>
                    <div
                      style={{
                        color: column.total[0].color,
                        paddingLeft: '20px',
                        paddingRight: '20px'
                      }}
                    >
                      {column.total[0].content}
                    </div>
                  </ConvRowColumn>
                  <ConvRowColumn>
                    <div className="Sexe" style={formatTab}>
                      {column.sexe.map(item => {
                        return (
                          <div style={{ color: item.color, padding: '5px' }}>
                            {item.content}
                          </div>
                        );
                      })}
                    </div>
                  </ConvRowColumn>
                  <ConvRowColumn>
                    <div className="Age" style={formatTab}>
                      {column.age.map(item => {
                        return (
                          <div style={{ color: item.color, padding: '5px' }}>
                            {item.content}
                          </div>
                        );
                      })}
                    </div>
                  </ConvRowColumn>
                  <ConvRowColumn>
                    <div className="csp" style={formatTab}>
                      {column.csp.map(item => {
                        return (
                          <div style={{ color: item.color, padding: '5px' }}>
                            {item.content}
                          </div>
                        );
                      })}
                    </div>
                  </ConvRowColumn>
                  <ConvRowColumn>
                    <div className="zone" style={formatTab}>
                      {column.zone.map(item => {
                        return (
                          <div style={{ color: item.color, padding: '5px' }}>
                            {item.content}
                          </div>
                        );
                      })}
                    </div>
                  </ConvRowColumn>
                </tr>
              );
            })}
          </tbody>
        </ConvTable>
        <ConvSubTitle>
          Retrouvez en vert les taux de conversions supérieurs à 10% du Total et
          en rouge les taux de conversion inférieurs à 90% du Total.
        </ConvSubTitle>
      </div>
    </React.Fragment>
  );
}

AudienceFirstPage.propTypes = {
  funnel: PropTypes.objectOf.isRequired
};

export default AudienceFirstPage;
