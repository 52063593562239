import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BasePlaneMap
 * @component
 *
 */
const BasePlaneMap = styled.div`
  display: block;
`;

export const SVG = styled.svg`
  min-height: 268px;
  width: 100%;
  @media (min-width: 1024px) {
    min-height: 380px;
  }
  @media (min-width: 1440px) {
    min-height: 410px;
  }
  @media (min-width: 1800px) {
    min-height: 495px;
  }
`;

/** @component */
export const Path = styled.path`
  cursor: pointer;
`;

/**
 * MainNode
 *
 */
export const MainNode = withTheme(styled.g`
  fill: ${({ themeColors }) => themeColors.primary};
  stroke: ${({ themeColors }) => themeColors.light};
  stroke-width: 1;
  fill-rule: evenodd;
  transform: scale(0.45) translate(-5%);
  @media (min-width: 1024px) {
    transform: scale(0.65) translate(-3%);
  }
  @media (min-width: 1440px) {
    transform: scale(0.7) translate(15%);
  }
  @media (min-width: 1800px) {
    transform: scale(0.85) translate(17%);
  }
`);

export default BasePlaneMap;
