const config = {
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT
};

export const COGNITO_ID_TOKEN_COOKIE_NAME =
  process.env.REACT_APP_COGNITO_ID_TOKEN_COOKIE_NAME;

export default config;
