import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseWrapperResume
 * @component
 *
 */
const BaseWrapperResumeSentence = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: space-evenly;
  margin: 0px 16px;
  padding: 8px 24px;
  .column-resume {
    > * {
      margin-right: 16px;
    }
  }
`;

export default withTheme(BaseWrapperResumeSentence);
