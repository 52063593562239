import styled from 'styled-components';
import BasePlaneMap from 'components/PlaneMap';
import { withTheme } from 'contexts/ThemeContext';
import { dataOrInsight } from 'utils/functions/insight';
import { withInsight } from 'Insight/contexts/InsightContext';

/**
 * @component
 *
 */
const BaseMapInsight = withInsight(styled(BasePlaneMap)`
  stroke: ${({ themeColors, isData }) =>
    dataOrInsight(isData, themeColors, 'data')};
  align-items: center;
`);

export default withTheme(BaseMapInsight);
