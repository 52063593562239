import React, { useEffect } from 'react';

import { formatNumber } from 'utils/functions';
import { useSegment } from 'Activation/contexts/SegmentContext';
import { useStep } from 'Activation/contexts/StepContext';

import DatePickerActivation from 'Activation/components/DatePickerActivation';
import DropdownActivation from 'Activation/components/DropdownActivation';
import InputActivation from 'Activation/components/InputActivation';
import LabelActivation from 'Activation/components/LabelActivation';
import TitleActivation from 'Activation/components/TitleActivation';
import WrapperBrief from 'Activation/components/WrapperBrief';

/**
 *
 * BriefContainer
 *
 * @container
 *
 * @desc ::
 *      the last part at the first page, allow to set some activation values
 *
 */
function BriefContainer() {
  const {
    activationMeasures,
    budget,
    endDate,
    objective,
    setBudget,
    setObjective,
    setPeriod,
    startDate,
    results
  } = useSegment();
  const {
    validDate,
    validBudget,
    validObjectif,
    setValidDate,
    setValidBudget,
    setValidObjectif
  } = useStep();
  const [localBudget, setLocalBudget] = React.useState(budget);
  const updateBudget = e => {
    const { value } = e.target;
    if (localBudget % 1 !== 0) {
      setLocalBudget(formatNumber(localBudget));
    } else {
      setLocalBudget(formatNumber(localBudget, 0));
    }
    setBudget(value);
  };

  const setDate = () => {
    setValidDate(false);
  };

  useEffect(() => {
    setValidBudget(null);
  }, [localBudget, setValidBudget]);

  const weekDiff = results.Brief.Weeks;
  return (
    <WrapperBrief>
      <TitleActivation mediumLarge fontWeight="bold">
        3. Indiquez votre brief
      </TitleActivation>
      <div className="row-brief">
        <LabelActivation>
          <b>DATES DE CAMPAGNE :</b>
        </LabelActivation>
        <DatePickerActivation
          setPeriod={setPeriod}
          startDate={startDate}
          endDate={endDate}
          toTop
          onClose={setDate}
          errorInput={validDate ? 'true' : 'false'}
        />
        {weekDiff > 0 && (
          <LabelActivation>
            <b style={{ marginLeft: '-40px' }}>
              SOIT&nbsp;<span className="colored">{weekDiff}</span>&nbsp;SEMAINE
              {weekDiff > 1 && 'S'}
            </b>
          </LabelActivation>
        )}
      </div>
      {validDate && (
        <div className="row-error">
          <div />
          <div>Vous devez saisir une période pour votre campagne</div>
        </div>
      )}
      <div className="row-brief">
        <LabelActivation>
          <b>BUDGET ESTIMÉ :</b>
        </LabelActivation>
        {validBudget !== null ? (
          <InputActivation
            suffix={<span>€</span>}
            onBlur={updateBudget}
            value={localBudget}
            onChange={e => {
              setLocalBudget(e.target.value.replace(/\s/g, ''));
            }}
            defaultValue={budget}
            onClose={setDate}
            errorInput
          />
        ) : (
          <InputActivation
            suffix={<span>€</span>}
            onBlur={updateBudget}
            value={localBudget}
            onChange={e => {
              setLocalBudget(e.target.value.replace(/\s/g, ''));
            }}
            defaultValue={budget}
            onClose={setDate}
          />
        )}
      </div>
      {validBudget !== null ? (
        <div className="row-error">
          <div />
          <div>{validBudget}</div>
        </div>
      ) : null}
      <div className="row-brief">
        <LabelActivation>
          <b>OBJECTIF :</b>
        </LabelActivation>
        {validObjectif ? (
          <DropdownActivation
            fix
            options={activationMeasures.map(({ name }) => name)}
            onSelectChange={setObjective}
            onChange={() => setValidObjectif(false)}
            selected={objective}
            errorInput
          />
        ) : (
          <DropdownActivation
            fix
            options={activationMeasures.map(({ name }) => name)}
            onSelectChange={setObjective}
            onChange={() => setValidObjectif(false)}
            selected={objective}
          />
        )}
      </div>
      {validObjectif && (
        <div className="row-error">
          <div />
          <div>Vous devez sélectionner un objectif pour votre campagne.</div>
        </div>
      )}
    </WrapperBrief>
  );
}

export default BriefContainer;
