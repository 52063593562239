import styled from 'styled-components';
import constants from 'utils/constants';
import { withTheme } from 'contexts/ThemeContext';
import { Button } from '@material-ui/core';
import Icon from 'utils/icons';

const { fontSizes, fontFamilies } = constants;

/**
 * BaseTable
 * @component
 *
 */
const BaseTable = styled.div`
  border-bottom-width: 2px ;
  border-bottom-style: solid;
  border-collapse: collapse;
  width: 100%;
  background-color: ${({ themeColors }) => themeColors.secondary};
  border-radius: 10px;
  max-width: 740px;
  margin: 28px auto 0px auto;
  height: calc(622px - 28px);
  overflow: hidden;
  @media (min-width: 1024px) {
    margin: 33px auto 0px auto;
    max-width: 950px;
    height: calc(622px - 33px);
  }
  @media (min-width: 1440px) {
    margin: 16px auto 0px auto;
    max-width: 1150px;
    height: calc(622px - 16px);
  }
  @media (min-width: 1800px) {
    margin: 50px auto 0px auto;
    max-width: 1500px;
    height: calc(622px - 50px);
  }
}
`;

/**
 * TableBody
 * @component
 *
 */
export const TableBody = styled.section`
  height: 87%;
  min-height: 100px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

/**
 * TableItem
 * @component
 *
 */
export const TableItem = styled.div`
  color: ${({ color, themeColors }) => color || themeColors.light};
  display: flex;
  align-items: center;
  justify-content: ${({ center }) =>
    center ? 'center !important' : 'inherit'};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

/**
 * TableLine
 * @component
 */
export const TableLine = withTheme(styled.div`
  display: grid;
  color: ${({ themeColors }) => themeColors.light};
  border-bottom-width: ${({ isHead }) => (isHead ? '2px' : '1px')};
  border-bottom-style: solid;
  font-size: ${({ isHead }) =>
    isHead ? fontSizes.extraSmall : fontSizes.lower};
  font-weight: ${({ isHead }) => (isHead ? '600' : '300')};
  grid-template-columns: repeat(${({ length }) => length}, 1fr);
  height: 72px;
  &:hover {
    background-color:${({ themeColors }) => themeColors.tertiary};
  } 
  ${TableItem}{
    justify-content: 'center';
    padding-left: 10%;
  }
  @media (min-width: 1024px) {
    height: 76px;
    font-size: ${({ isHead }) =>
      isHead ? fontSizes.small : fontSizes.extraSmall}};
  }
  @media (min-width: 1800px) {
    height: 105px;
    font-size: ${({ isHead }) =>
      isHead ? fontSizes.medium : fontSizes.small}};
  }
}
`);

export const Icons = styled(Icon)`
  height:32px;
  padding:8px;
}
`;

/**
 * Buttons
 *
 */
export const Buttons = withTheme(styled(Button)`
  && {
    background-color: ${({ bgcolor, themeColors }) =>
      bgcolor || themeColors.red};
    color: ${({ color, themeColors }) => color || themeColors.light};
    width: ${({ width }) => width || 'auto'};
    text-align : left;
    padding: 10px;
    font-family: ${fontFamilies.default};
    svg {
      margin-right: 10px;
    }
  }
  &&:hover {
    background-color: ${({ bgcolor, disableHover, themeColors }) =>
      disableHover && (bgcolor || themeColors.secondary)};
  }
  white-space: nowrap;
    }
`);

/**
 * OriginBaseContent
 * @component In Modal
 *
 */
export const BaseContent = withTheme(styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${({ themeColors }) => themeColors.light};
  align-items: center;

  .description {
    display: flex;
    width: 100%;
    padding: 20px 20px;
    justify-content: flex-start;
    white-space: pre-wrap;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 500;
  }
`);

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 20px;
`;

export default withTheme(BaseTable);
