import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'contexts/ThemeContext';

import Checkbox from 'components/Checkbox';
import Content, {
  ButtonContainer,
  Button,
  Form,
  bodyStyle,
  formAccountStyle,
  modalHeader
} from './style';

/**
 * Component :
 * Display the list of items (accounts, properties, views, camapigns...)
 * If these items have children (accounts -> campaigns) when one is chosen
 * we dive further in the tree (displaying relative campaigns) and add
 * the parent in the parent list.
 * If these items have no children we call setItem(currItem, parentList)
 * in order to lift up the chosen campaign/views and its parents
 * @param {*} props
 */
export default function AccountChoiceFormContent(props) {
  /**
   * ElementTree :
   * Tree of : accounts - campaigns / accounts - properties - views
   * [{type: 'account', id, name, checked, children: [{type: 'campaigns', id, name, checked}]}]
   * [{type: 'account', id, name, checked, children: [{type: 'properties', id, name, checked, children}]}]
   * Or their children : campaigns / properties - views / views
   * [{type: 'campaigns', id, name, checked}]
   * [{type: 'properties', id, name, checked, children: [{type: 'views', id, name, checked}]}]
   * [{type: 'views', id, name, checked}]
   */

  const {
    elementTree,
    unsetAccessArguments,
    setAccessArguments,
    connectorName,
    validate
  } = props;
  const { themeColors } = useTheme();

  const [currentTree, setCurrentTree] = useState(elementTree);
  const [parentList, setParentList] = useState([]);

  useEffect(() => {
    if (!parentList.length) return setCurrentTree(elementTree);
    let currNode = { children: elementTree };
    for (let i = 0; i < parentList.length; i += 1) {
      currNode = [
        ...currNode.children.filter(child => child.id === parentList[i])
      ].pop();
    }
    return setCurrentTree(currNode.children);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementTree]);

  const onChange = (value, node) => {
    const nodeMutable = node;
    if (node.children) {
      setCurrentTree(node.children);
      setParentList([...parentList, node.id]);
    } else {
      nodeMutable.checked = value;
      (value ? setAccessArguments : unsetAccessArguments)([
        ...parentList,
        node.id
      ]);
    }
  };

  const goBack = () => {
    setCurrentTree(elementTree);
    setParentList([]);
  };

  if (!currentTree || !currentTree.length) return null;
  return (
    <React.Fragment>
      <Content style={{ display: 'inline-block' }} className="modal-header">
        <Content style={modalHeader}>
          Importer vos données de campagnes {connectorName}
        </Content>
      </Content>
      <div className="modal-body" style={bodyStyle}>
        <Form style={formAccountStyle}>
          <div>
            <p>
              {currentTree.length} {currentTree[0].type} ont été trouvées.
            </p>
            <p>Lesquelles voulez-vous importer ?</p>
          </div>
          <div style={{ flex: '1', overflowY: 'scroll' }}>
            {currentTree.map(node => (
              <Checkbox
                label={node.name}
                key={node.id}
                checked={!!node.checked}
                onChange={value => onChange(value, node)}
              />
            ))}
          </div>
        </Form>
      </div>
      <ButtonContainer className="input-container is-flex justify-content-between">
        <Button
          color={themeColors.funnel}
          onClick={goBack}
          onKeyPress={goBack}
          role="button"
          tabIndex="0"
        >
          Retour
        </Button>
        <Button
          bgColor={themeColors.funnel}
          onClick={validate}
          onKeyPress={validate}
          role="button"
          tabIndex="0"
        >
          Valider
        </Button>
      </ButtonContainer>
    </React.Fragment>
  );
}

const connectorNodeShape = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};
const connectorNode = PropTypes.shape(connectorNodeShape);
connectorNodeShape.children = PropTypes.arrayOf(connectorNode).isRequired;

AccountChoiceFormContent.propTypes = {
  elementTree: PropTypes.arrayOf(connectorNode).isRequired,
  connectorName: PropTypes.string.isRequired,
  setAccessArguments: PropTypes.func.isRequired,
  unsetAccessArguments: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired
};
