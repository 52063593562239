import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';
import Section from 'components/Section';

const BaseSectionFunnel = withTheme(styled(Section)`
  background-color: ${({ themeColors }) => themeColors.secondary};
  border-radius: 4px;
  padding: 8px 16px 16px;
  color: ${({ themeColors }) => themeColors.light};
  .content {
    font-size: 14px;
    padding: 8px 0;
  }
`);

export const BaseSectionFunnelSide = withTheme(styled(Section)`
  background-color: ${({ themeColors }) => themeColors.secondary};
  padding: 0px 16px 0px;
  color: ${({ themeColors }) => themeColors.light};
  position: relative;
  margin-top: 8px;
  height: 622px;
  .content {
    font-size: 16px;
    padding: 8px 0;
  }
`);

export default BaseSectionFunnel;
