import React from 'react';

import { useCategory } from 'Insight/contexts/CategoryContext';
import CategoriesInsight, {
  CategoriesItem
} from 'Insight/components/CategoriesInsight';
import LoadingInsight from 'Insight/components/LoadingInsight/index';

/**
 * CategoriesContainer
 * @component
 *
 */
function CategoriesContainer() {
  const {
    categoriesLoading,
    categoriesError,
    categories,
    currentCategory,
    setCurrentCategory
  } = useCategory();
  const handleSelect = categoryKey => {
    setCurrentCategory(categoryKey);
  };

  if (categoriesLoading) return <LoadingInsight loading refetch={() => {}} />;
  if (categoriesError) return <p>Error while loading categories...</p>;

  return (
    <CategoriesInsight length={categories.length}>
      {categories.map((category, index) => (
        <CategoriesItem
          key={category.key}
          name={category.name}
          index={index}
          isActive={currentCategory === category.key}
          title={category.name}
          description={category.description}
          handleSelect={() => handleSelect(category.key)}
        />
      ))}
    </CategoriesInsight>
  );
}

export default CategoriesContainer;
