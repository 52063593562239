import React from 'react';
import BaseAudiencesChoiceWrapper from './style';

/**
 * WrappersActivation
 * @component
 *
 */
function AudiencesChoiceWrapper(props) {
  return <BaseAudiencesChoiceWrapper {...props} />;
}
AudiencesChoiceWrapper.defaultProps = {};
AudiencesChoiceWrapper.propTypes = {};

export default AudiencesChoiceWrapper;
