import styled from 'styled-components';
import Separator from 'components/Separator';
import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseSeparatorActivation
 * @component
 *
 */
const BaseSeparatorActivation = styled(Separator)`
  margin: 16px 0;
  width: 60%;
  align-self: ${({ align }) => align};
`;

export default withTheme(BaseSeparatorActivation);
