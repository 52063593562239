import styled from 'styled-components';
import { withTheme } from 'contexts/ThemeContext';

/**
 * @component
 * @see OperatorContainer
 */
const BaseOperatedCategory = styled.div`
  background-color: 'transparent';
  padding: 4px;
  border-top: 1px solid ${({ themeColors }) => themeColors.insight_secondary};
  max-height: 67px; // firefox
  overflow-y: auto;
  @media (min-width: 1440px) {
    max-height: 78px; // firefox
  }
  @media (min-width: 1800px) {
    max-height: 88px; // firefox
  }
`;

export default withTheme(BaseOperatedCategory);
