import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'contexts/ThemeContext';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatDate } from 'utils/functions';
import constants from 'utils/constants';
import Loading from 'src/components/Loading';
import {
  CloseIcon,
  Drawer,
  DrawerFooter,
  DrawerFooterTitle,
  DrawerFooterSubtitle,
  DividerDrawer,
  FunnelDrawerContainer,
  ListItemDrawerText,
  TitleDrawer
} from './style';

const { routes } = constants;

function HistoryDrawer(props) {
  const {
    isOpen,
    onClose,
    historyItems,
    deleteFunnel,
    history,
    isDataLoaded
  } = props;

  return (
    <div style={{ position: 'relative' }}>
      <Drawer open={isOpen} onClose={onClose}>
        <FunnelDrawerContainer role="presentation">
          <CloseIcon onClick={onClose} />
          <TitleDrawer style={{ marginTop: '2rem' }}>
            Historique de vos funnels
          </TitleDrawer>
          <List>
            <ListItem button>
              <ListItemDrawerText primary="Nom du funnel" />
              <ListItemDrawerText primary="Date de sauvegarde" />
              <DeleteIcon style={{ visibility: 'hidden' }} />
            </ListItem>
            <DividerDrawer />
            {isDataLoaded ? (
              historyItems.slice(0, 5).map(item => (
                <React.Fragment>
                  <ListItem
                    button
                    key={item.id}
                    onClick={() => {
                      onClose();
                      history.push(`${routes.FUNNEL}/${item.id}`);
                    }}
                  >
                    <ListItemDrawerText secondary={item.name} />
                    <ListItemDrawerText
                      secondary={formatDate(item.updated_at)}
                    />
                    <DeleteIcon onClick={deleteFunnel} />
                  </ListItem>
                  <DividerDrawer />
                </React.Fragment>
              ))
            ) : (
              <ListItem button>
                <Loading loading />
              </ListItem>
            )}
          </List>
          {isDataLoaded && (
            <DrawerFooter>
              <DrawerFooterTitle to={`${routes.HISTORY}/funnel`}>
                {`voir l'historique complet`}
              </DrawerFooterTitle>
              <DrawerFooterSubtitle>
                ({historyItems.length} funnels de plus)
              </DrawerFooterSubtitle>
            </DrawerFooter>
          )}
        </FunnelDrawerContainer>
      </Drawer>
    </div>
  );
}

HistoryDrawer.defaultProps = {
  isOpen: true,
  onClose() {},
  deleteFunnel() {},
  historyItems: [],
  isDataLoaded: false
};
HistoryDrawer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  deleteFunnel: PropTypes.func,
  historyItems: PropTypes.arrayOf(PropTypes.any),
  isDataLoaded: PropTypes.bool
};

export default withRouter(withTheme(HistoryDrawer));
