import React from 'react';
import PropTypes from 'prop-types';
import { getMatching } from 'utils/functions/insight';
import fontSizes from 'utils/constants/fontSizes';
import { formatNumber } from 'utils/functions';
import StyledResultColumn, { StyledSpan, StyledText } from './style';

export function DataResultColumn({ base }) {
  const totalProfiles =
    base && base.digitizing ? base.digitizing.total.nb_records : 0;
  return (
    <StyledResultColumn>
      <StyledText fontSize={fontSizes.extraSmall}>
        <StyledSpan>Taux de matching de </StyledSpan>
        <StyledSpan number>{formatNumber(getMatching(base))}%</StyledSpan>
        <StyledSpan> entre la base de données ERMES et vos </StyledSpan>
        <StyledSpan number>{formatNumber(totalProfiles, 0)}</StyledSpan>
        <StyledSpan> profils uploadés, soit :</StyledSpan>
      </StyledText>
    </StyledResultColumn>
  );
}
DataResultColumn.propTypes = {
  base: PropTypes.shape({}).isRequired
};

export default DataResultColumn;
